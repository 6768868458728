import { mergeDeepRight } from 'ramda';

import type { Entity } from '../../types/Entity';
import { entityConfiguration } from '../config';
import {
  type CreateTransaction,
  type DeleteTransaction,
  type ReviewTransaction,
  type Transaction,
  TransactionType,
  type UpdateTransaction,
} from '../types';
import type { LocalDbManager } from './localdb-manager';

export class CachingManager {
  private entitiesLookup: Record<string, Map<string, Entity>> = {};

  constructor(private readonly localDbManager: LocalDbManager) {}

  private lastCachedDate = new Date().getTime();

  /**
   * Clear the cache.
   */
  clear() {
    this.entitiesLookup = {};
  }

  /**
   * Clear cached entity data
   */
  clearEntityData(entityType: string) {
    this.entitiesLookup[entityType]?.clear();
  }

  /**
   * Retrieves all cached entities lookup.
   */
  getEntitiesLookup() {
    return this.entitiesLookup;
  }

  /**
   * Retrieves all cached entities.
   */
  getAllEntityData() {
    const entities: Record<string, Entity[]> = {};
    for (const entityType in this.entitiesLookup) {
      const entityLookupData = this.entitiesLookup[entityType];
      if (entityLookupData) {
        entities[entityType] = [...entityLookupData.values()];
      }
    }
    return entities;
  }

  /**
   * Retrieves all cached entity.
   */
  getEntities(entityType: string) {
    return this.getAllEntityData()[entityType] ?? [];
  }

  /**
   * Retrieves a cached entity.
   */
  getEntity(entityType: string, entityGUID: string) {
    const entities = this.getEntities(entityType);

    const collectorEntity = entityConfiguration.entities[entityType];

    if (!collectorEntity) {
      throw new Error(`Unexpected entity name: ${entityType}`);
    }

    return entities.find(item => {
      return item[collectorEntity.keyPath] === entityGUID;
    });
  }

  /**
   * Retrieves all caching store count.
   */
  cachingStoreCount() {
    return Object.keys(this.entitiesLookup).length;
  }

  /**
   * Retrieves the number of records in each cached data.
   */
  statistics() {
    const stats: Record<string, number> = {};

    const allEntities = this.getAllEntityData();

    for (const entityType in allEntities) {
      stats[entityType] = allEntities[entityType]?.length ?? 0;
    }

    return stats;
  }

  /**
   * Cached provided entity records.
   */
  cacheEntities(entityRecords: Record<string, Entity[]>) {
    for (const entityType in entityRecords) {
      const entities = entityRecords[entityType];
      if (entities) {
        for (const item of entities) {
          if (item['isDeleted']) {
            this.deleteEntity({ entityType, item });
          } else {
            this.putEntity({ entityType, item });
          }
        }
      }
    }
  }

  /**
   * Cached provided entity.
   */
  cacheEntity(entityRecords: Record<string, Entity>) {
    for (const entityType in entityRecords) {
      const item = entityRecords[entityType];
      if (item) {
        if (item['isDeleted']) {
          this.deleteEntity({ entityType, item });
        } else {
          this.putEntity({ entityType, item });
        }
      }
    }
  }

  /**
   * Cache existing data when component intialize.
   */
  async cacheExistingData() {
    const transactions = await this.localDbManager.getAllTransactions();

    for (const entityType of this.localDbManager.entityStoreNames()) {
      if (
        entityType !== '_meta' &&
        entityType !== '_transactions' &&
        entityType !== '_transactionLog'
      ) {
        this.entitiesLookup[entityType] = new Map<string, Entity>();
        const entities = await this.localDbManager.getAllEntities(entityType);

        const filteredTransactions = transactions.filter(item => item.entityType === entityType);

        const transactionJoinedEntities = this.joinTransactions(entities, filteredTransactions);

        for (const item of transactionJoinedEntities) {
          this.putEntity({ entityType, item });
        }
      }
    }
  }

  /**
   * Returns last cached date.
   */
  getLastCachedDate = () => {
    return this.lastCachedDate;
  };

  /**
   * A function to delete cached entity.
   */
  private deleteEntity = ({ entityType, item }: { entityType: string; item: Entity }) => {
    const keyPath = this.localDbManager.getKeyPath(entityType);
    this.entitiesLookup[entityType]?.delete(item[keyPath] as string);
    this.lastCachedDate = new Date().getTime();
  };

  /**
   * A function to put entity into the cached.
   */
  private putEntity = ({ entityType, item }: { entityType: string; item: Entity }) => {
    const keyPath = this.localDbManager.getKeyPath(entityType);
    this.entitiesLookup[entityType]?.set(item[keyPath] as string, item);
    this.lastCachedDate = new Date().getTime();
  };

  /**
   * Joining transactions in to the indexedDb cache.
   */
  private joinTransactions = (entities: Entity[], transactions: Transaction[]): Entity[] => {
    let joinedEntities = entities;
    for (const transaction of transactions) {
      joinedEntities = this.joinTransactionItemDispatcher(joinedEntities, transaction);
    }
    return joinedEntities;
  };

  private joinTransactionItemDispatcher(entities: Entity[], transaction: Transaction) {
    switch (transaction.type) {
      case TransactionType.Create:
        return this.joinCreateItem(entities, transaction);
      case TransactionType.Update:
        return this.joinUpdateOrReviewItem(entities, transaction);
      case TransactionType.Review:
        return this.joinUpdateOrReviewItem(entities, transaction);
      case TransactionType.Delete:
        return this.joinDeleteItem(entities, transaction);
    }
  }

  private joinCreateItem(entities: Entity[], transaction: CreateTransaction): Entity[] {
    return [...entities, transaction.payload];
  }

  private joinDeleteItem(entities: Entity[], transaction: DeleteTransaction) {
    return entities.filter(item => {
      const collectorEntity = entityConfiguration.entities[transaction.entityType];
      if (!collectorEntity) {
        return false;
      }

      return item[collectorEntity.keyPath] !== transaction.entityGUID;
    });
  }

  private joinUpdateOrReviewItem(
    entities: Entity[],
    transaction: UpdateTransaction | ReviewTransaction
  ): Entity[] {
    const clonedEntities = [...entities];
    const itemIndex = entities.findIndex(item => {
      const collectorEntity = entityConfiguration.entities[transaction.entityType];
      if (!collectorEntity) {
        return false;
      }

      return item[collectorEntity.keyPath] === transaction.entityGUID;
    });

    const updatingEntity = clonedEntities[itemIndex];

    if (updatingEntity) {
      // @TODO this may replace any other librarys merge method instead of Ramda...
      clonedEntities[itemIndex] = mergeDeepRight(updatingEntity, transaction.payload);
    }

    return clonedEntities;
  }
}
