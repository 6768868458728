import type { Row, unsafe_GridApi, unsafe_RowNode } from 'src/components/DataGrid';
import { type Dictionary } from 'src/types/MappedTypes';
import { BrandLineTypes } from '../shared/types';
import type { Entity } from '../types/Entity';

export const setTotals = (
  api: unsafe_GridApi,
  node: unsafe_RowNode,
  field: string,
  newValue: string | number,
  primaryField: string
): [string, Entity] | undefined => {
  const totalToMeet = parseFloat(newValue.toString().toLowerCase().replace('=t', ''));
  const volumesPrefix = field.split('.')[0];
  const year = field.split('.')[1];
  const isValidNumberValue = typeof totalToMeet === 'number' && !Number.isNaN(totalToMeet);
  const groupSiblings = node.parent?.childrenAfterSort ?? [];
  const hasSingleOtherLine =
    groupSiblings.filter(s => (s.data as Row)['brandLineTypeId'] === BrandLineTypes.OTHER)
      .length === 1;

  if (hasSingleOtherLine && isValidNumberValue && volumesPrefix && year) {
    const othersNode = groupSiblings.find(row => {
      const rowData = row.data as Row;
      return rowData['brandLineTypeId'] === BrandLineTypes.OTHER;
    });

    if (othersNode) {
      const notOthersNode = groupSiblings.filter(row => {
        const rowData = row.data as Row;
        return rowData['brandLineTypeId'] === BrandLineTypes.BRANDED;
      });

      const allValidYearVolumes: number[] = notOthersNode.map(row => {
        const rowData = row.data as Row;

        const volumeObject = rowData[volumesPrefix] as Dictionary;

        if (volumeObject[year]) return volumeObject[year] as number;

        return 0;
      });

      const difference = allValidYearVolumes.reduce(
        (prevValue, currentValue) => prevValue + currentValue,
        0
      );

      const othersRowData = othersNode.data as Row;
      const othersVolumeObject = othersRowData[volumesPrefix] as Dictionary;

      const newOtherVolume = Number(totalToMeet - difference);

      if (newOtherVolume >= 0) {
        othersVolumeObject[year] = newOtherVolume;
        const entityGuid = String(othersRowData[primaryField]);
        api.applyTransactionAsync({ update: [othersRowData] });

        return [
          entityGuid,
          {
            [volumesPrefix]: {
              [year]: newOtherVolume || null,
            },
          },
        ];
      } else {
        /*
          TODO: AG-GRID is supporting only one color for flashing...
          so if for red flashing, swapping css values live should be done.

          A GitHub issue was created to keep track of this:
          https://github.com/theiwsr/collector-web/issues/342
        */
        api.flashCells({ rowNodes: [othersNode], columns: [`${volumesPrefix}.${year}`] });
      }
    }
  }

  return undefined;
};
