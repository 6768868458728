import type { FormulaFunction } from 'src/components/DataGrid';

export const relativeChange: FormulaFunction = (startValue: unknown, endValue: unknown) => {
  const config = {
    startValue: !isNaN(startValue as number) ? (startValue as number) : 0,
    endValue: !isNaN(endValue as number) ? (endValue as number) : 0,
  };

  const relativeChange = (config.endValue - config.startValue) / config.startValue;

  if (isNaN(relativeChange)) return undefined;
  if (!isFinite(relativeChange)) return undefined;
  if (relativeChange === 0) return 0.00001;
  if (!startValue || !endValue) return undefined;

  return relativeChange;
};
