import { pxToRem } from 'src/theme/utils';

export const optionLiContainer = {
  paddingLeft: 0,
};

export const optionWrapper = {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  width: '100%',
};

export const recordContext = {
  display: 'grid',
};

export const optionBrandName = {
  fontSize: pxToRem('14px'),
  marginBottom: pxToRem('-4px'),
};

export const optionOwnerName = {
  fontSize: pxToRem('14px'),
  alignSelf: 'center',
};
