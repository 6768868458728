//  TODO:   **** ALL TODO's IN THIS FILE ARE FROM ISOTOMA
import { lensPath, pipe, set } from 'ramda';

import type { CountryList, Market } from '../../shared';
import { UserNumberEntry } from '../../shared';
import type { BrandLineFormItem } from '../addBrandLineUtils';
import { isMin } from '../brandSalesSettersGetters/volumeValues';
import { numToLocaleString } from '../colDefs';
import { getStrippedString, getVolumeDecimalPlaces } from '../gridUtils';
import { isLocalOrImportedValueInvalid } from './validateLocalOrImported';

const setInvalid = (item: BrandLineFormItem, property: string, message: string) =>
  pipe(
    set(lensPath([property, 'isValid']), false),
    set(lensPath([property, 'message']), message)
  )(item);

const setValid = (item: BrandLineFormItem, property: string, value: string) =>
  pipe(
    set(lensPath([property, 'isValid']), true),
    set(lensPath([property, 'message']), ''),
    set(lensPath([property, 'value']), value)
  )(item);

const setEmpty = (item: BrandLineFormItem, property: string, value: string) =>
  pipe(
    set(lensPath([property, 'isValid']), true),
    set(lensPath([property, 'message']), ''),
    set(lensPath([property, 'value']), value),
    set(lensPath([property, 'touched']), false)
  )(item);

interface ValidationReturn {
  isValid: boolean;
  message?: string;
  newValue: string;
}

// not 1e-10 as we are rounding
const getMinVolume = (decimalPlaces: number) => (decimalPlaces === 2 ? 0.01 : 0.001);

// get the number - ignoring comma seperators
const getStrippedNumber = (value: string) => {
  const strippedValue = getStrippedString(value);
  return Number.parseFloat(strippedValue);
};

const validPositiveNumber = (
  value: string,
  name: string,
  decimalPlaces: number,
  allowZero = true
): ValidationReturn => {
  const numberValue = getStrippedNumber(value);

  // must be a float
  if (Number.isNaN(numberValue)) {
    return { isValid: false, message: `${name} must be a positive number`, newValue: value };
  }

  // must be positive
  if (numberValue < 0)
    return { isValid: false, message: `${name} must be a positive number`, newValue: value };

  // reject zeros
  if (!allowZero && numberValue === 0) {
    return { isValid: false, message: `${name} must not be zero`, newValue: value };
  }

  // format to correct decimal places
  const newValue = numToLocaleString(numberValue, decimalPlaces);
  return { isValid: true, newValue };
};

const volumeValid = (value: string, market: string): ValidationReturn => {
  // blank is valid
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (value === '' || value === undefined) return { isValid: true, newValue: value };

  // min is valid
  if (isMin(value)) return { isValid: true, newValue: 'min' };

  const decimalPlaces = getVolumeDecimalPlaces(market as Market);
  const valid = validPositiveNumber(value, 'Volume', decimalPlaces);

  // change to 'min' for small values
  if (valid.isValid) {
    if (Number.parseFloat(valid.newValue) < getMinVolume(decimalPlaces)) {
      return { isValid: true, newValue: 'min' };
    }
  }

  return valid;
};

const priceValid = (value: string, decimalPlaces: number): ValidationReturn => {
  const match = value.match(/^[~?](.*)/);
  let numberString = value;

  // @ts-expect-error: legacy code
  // eslint-disable-next-line prefer-destructuring
  if (match) numberString = match[1];

  const valid = validPositiveNumber(numberString, 'Price', decimalPlaces);
  if (!valid.isValid) return valid;

  // @ts-expect-error: legacy code
  // check biggerThan and biggerEqualThan - for price they are checked on blur!
  const { biggerThan, biggerEqualThan } = UserNumberEntry.price;
  if (biggerEqualThan != null && Number.parseFloat(valid.newValue) < biggerEqualThan) {
    valid.isValid = false;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    valid.message = `Price must be greater than or equal to ${biggerEqualThan}.`;
    return valid;
  }
  if (biggerThan != null && Number.parseFloat(valid.newValue) <= biggerThan) {
    valid.isValid = false;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    valid.message = `Price must be greater than ${biggerThan}.`;
    return valid;
  }

  valid.newValue = match ? `~${valid.newValue}` : valid.newValue;
  return valid;
};

const localOrImportedValid = (
  value: string,
  countries: CountryList,
  countryId: string,
  originName: string
): ValidationReturn => {
  if (!value) return { isValid: false, message: 'Value required', newValue: value };

  const isValid = !isLocalOrImportedValueInvalid(value, countries, countryId, originName);

  if (isValid) return { isValid, newValue: value };
  return {
    isValid,
    message: `Place of manufacture cannot be ${value}; origin is ${originName}`,
    newValue: value,
  };
};

// *************************

export const validateVolume = (item: BrandLineFormItem, market: string): BrandLineFormItem => {
  const { value } = item.volume;
  if (value === '') return setEmpty(item, 'volume', value);

  const valid = volumeValid(value, market);

  if (valid.isValid) return setValid(item, 'volume', valid.newValue);
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return setInvalid(item, 'volume', valid.message || 'Invalid');
};

export const validatePrice = (item: BrandLineFormItem, decimalPlaces: number) => {
  const { value } = item.price;
  if (value === '') return setEmpty(item, 'price', value);

  const valid = priceValid(value, decimalPlaces);

  if (valid.isValid) return setValid(item, 'price', valid.newValue);
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return setInvalid(item, 'price', valid.message || 'Invalid');
};

export const validateContainerSize = (item: BrandLineFormItem) => {
  const { value } = item.containerSize;
  if (value === '') return setEmpty(item, 'containerSize', value);

  // TODO - confirm what decimal places
  const valid = validPositiveNumber(value, 'Container size', 2, false);
  // TODO - reconfirm why float is correct

  if (valid.isValid) return setValid(item, 'containerSize', valid.newValue);
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return setInvalid(item, 'containerSize', valid.message || 'Invalid');
};

export const validateLocalOrImported = (
  item: BrandLineFormItem,
  countries: CountryList,
  countryId: string,
  originName: string
) => {
  const { value } = item.localOrImported;

  const valid = localOrImportedValid(value, countries, countryId, originName);

  if (valid.isValid) return setValid(item, 'localOrImported', valid.newValue);
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return setInvalid(item, 'localOrImported', valid.message || 'Invalid');
};

export const validatePriceBand = (item: BrandLineFormItem) => {
  const { value } = item.priceBandId;

  if (value) return setValid(item, 'priceBandId', value);
  return setInvalid(item, 'priceBandId', 'Value required');
};
