import { type CSSProperties } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  faExclamationTriangle,
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { useMarket } from 'src/markets';
import type { BrandLine } from 'src/shared/types';
import {
  selectBrandLine,
  unselectBrandLine,
  unstageNewBrandLine,
  unstageNewBrandOwner,
  unstageNewParentBrand,
  updateStagedBrandOwner,
  updateStagedParentBrand,
} from 'src/store/features/brandLineAdd/actions';
import { type BrandLineWithUniqueId } from 'src/store/features/brandLineAdd/selectors';
import { IconButton, Th } from './styledComponents';

interface HighlightTdProps {
  isNew?: boolean;
  isDuplicate?: boolean;
}

const HighlightTd = styled.td<HighlightTdProps>`
  position: relative;
  color: ${(props: HighlightTdProps) => (props.isNew ? 'red' : 'black')};
  background: ${(props: HighlightTdProps) => (props.isDuplicate ? '#FFEEEE' : undefined)};
`;

const WarningIcon = styled(FontAwesomeIcon).attrs({ icon: faExclamationTriangle })`
  color: ${props => props.color};
  position: absolute;
  right: 5px;
  top: 10px;
`;

const NEW_PREFIX = 'new#';

interface BrandLineTableProps {
  brandLines: BrandLineWithUniqueId[];
  duplicatedBrandLineGUIDs?: string[];
  isSelected: boolean;
  containerStyles?: CSSProperties;
}

const BrandLineTable = ({
  brandLines,
  duplicatedBrandLineGUIDs = [],
  isSelected,
  containerStyles,
}: BrandLineTableProps) => {
  const dispatch = useDispatch();

  const { market } = useMarket();

  const handleClick = (brandLine: BrandLine) => () => {
    if (!isSelected) dispatch(selectBrandLine(brandLine));
    else {
      const { brandLineGUID } = brandLine;
      if (brandLineGUID.startsWith(NEW_PREFIX)) {
        const matches = brandLines.filter((p: BrandLine) => {
          return p.brandLineGUID.split('.')[0] === brandLineGUID.split('.')[0];
        });
        // only remove if there's only one occurrence
        if (matches.length === 1) {
          dispatch(unstageNewBrandLine(brandLineGUID));
        }
      }

      const { brandGUID, ownerGUID } = brandLine;
      if (brandGUID.startsWith(NEW_PREFIX)) {
        const matches = brandLines.filter(
          (p: BrandLine) =>
            p.brandGUID === brandGUID || p.brandGUID === brandGUID.substring(NEW_PREFIX.length)
        );
        if (matches.length === 1) {
          dispatch(unstageNewParentBrand(brandGUID.slice(NEW_PREFIX.length)));
        } else {
          // get the first brandLine with the new brand but w/o the new# prefix
          // there has to be one as only one line is given the new# prefix
          const brandLineToChange = matches.find(
            p => p.brandGUID === brandGUID.substring(NEW_PREFIX.length)
          );
          dispatch(updateStagedParentBrand(brandLineToChange!.brandLineGUID));
        }
      }

      if (ownerGUID.startsWith(NEW_PREFIX)) {
        const matches = brandLines.filter(
          (p: BrandLine) =>
            p.ownerGUID === ownerGUID || p.ownerGUID === ownerGUID.substring(NEW_PREFIX.length)
        );
        if (matches.length === 1) {
          dispatch(unstageNewBrandOwner(ownerGUID.slice(NEW_PREFIX.length)));
        } else {
          // get the first brandLine with the new owner but w/o the new# prefix
          // there has to be one as only one line is given the new# prefix
          const brandLineToChange = matches.find(
            p => p.ownerGUID === ownerGUID.substring(NEW_PREFIX.length)
          );
          dispatch(updateStagedBrandOwner(brandLineToChange!.brandLineGUID));
        }
      }
      dispatch(unselectBrandLine(brandLine));
    }
  };

  const renderBrandLine = (
    brandLine: BrandLineWithUniqueId,
    duplicateBrandLineGUIDs: string[],
    countryName: string
  ) => {
    const isNew = brandLine.brandLineGUID.startsWith(NEW_PREFIX);
    const isDuplicate = duplicateBrandLineGUIDs.includes(brandLine.brandLineGUID);
    const sanitisedBrandLineName = brandLine.brandLineName.replace(
      '{originName}',
      brandLine.originName
    );

    return (
      <tr key={brandLine.uniqueId ?? brandLine.brandLineGUID}>
        <HighlightTd isNew={isNew} isDuplicate={isDuplicate}>
          <IconButton type="button" onClick={handleClick(brandLine)}>
            {isSelected && <FontAwesomeIcon icon={faMinusCircle} />}
            {!isSelected && <FontAwesomeIcon icon={faPlusCircle} />}
          </IconButton>
          {sanitisedBrandLineName}
        </HighlightTd>
        <HighlightTd isDuplicate={isDuplicate}>{brandLine.brandLineDisplayName}</HighlightTd>
        <HighlightTd isDuplicate={isDuplicate}>{brandLine.category5Name}</HighlightTd>
        <HighlightTd isNew={isNew} isDuplicate={isDuplicate}>
          {brandLine.brandName}
        </HighlightTd>
        <HighlightTd isDuplicate={isDuplicate}>{brandLine.originName}</HighlightTd>
        <HighlightTd isDuplicate={isDuplicate} isNew={isNew}>
          {brandLine.ownerName}
          {isDuplicate && (
            <WarningIcon
              color="#990000"
              title={`WARNING: There are already sales in ${countryName} for this brand line.`}
            />
          )}
        </HighlightTd>
      </tr>
    );
  };

  return (
    <div style={{ ...containerStyles, zIndex: 0, position: 'relative', overflowY: 'auto' }}>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <Th>Brand line</Th>
            <Th width="15%">Display name</Th>
            <Th width="15%">Category 5</Th>
            <Th width="15%">Parent brand</Th>
            <Th width="15%">Origin</Th>
            <Th>Owner</Th>
          </tr>
        </thead>
        <tbody>
          {brandLines.map(brandline =>
            renderBrandLine(brandline, duplicatedBrandLineGUIDs, market.marketName)
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default BrandLineTable;
