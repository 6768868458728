import type { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

interface Props {
  children: ReactNode;
  disabled?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  open: boolean;
  submitButtonText: string;
  title: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const RecordDialog = ({
  children,
  disabled = false,
  maxWidth,
  open,
  submitButtonText,
  title,
  onClose,
  onSubmit,
}: Props) => (
  <Dialog fullWidth open={open} onClose={onClose} {...(maxWidth ? { maxWidth } : {})}>
    <DialogTitle>
      {title}
      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    {children}
    <DialogActions>
      <Button color="secondary" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="contained" onClick={onSubmit} disabled={disabled}>
        {submitButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);
