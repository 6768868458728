import { useMemo, useState } from 'react';
import { Alert, Col, Container, Form } from 'react-bootstrap';
import Select from 'react-select';
import { faExclamationTriangle, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import { Spinner } from 'src/components/Spinner';
import { COLLECTED_YEAR } from 'src/constants';
import { Button } from 'src/css/styled-components';
import { useRecords } from 'src/records/hooks/useRecords';
import { type DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type BaseOption, BrandLineTypes } from 'src/shared';
import { type CategoriesEntity } from 'src/types/entity/Categories';
import { useTable } from 'src/views/hooks/useTable';
import { exportToCSV } from './exportToCsv';
import { CustomRow, ExcelIconContainer, Heading, Title } from './styledComponents';
import WrapperComponent from './Wrapper';

export * from './ExportReportTrigger';

enum FilterType {
  Distributors,
  Owners,
}

enum LoadingSpinnerDisplay {
  hidden = 'none',
  visible = 'block',
}

const compareLabels = ({ label }: BaseOption, { label: otherLabel }: BaseOption) =>
  label.toLocaleUpperCase().localeCompare(otherLabel.toLocaleUpperCase());

const ExportReport = ({ closeModal }: { closeModal: () => void }) => {
  const {
    market: { marketId, marketName },
  } = useTable();

  const allCategories = useRecords<CategoriesEntity>('categories');

  const [selectedDistributor, setSelectedDistributor] = useState<BaseOption[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<BaseOption[]>([]);
  const [selectionRequiredError, setSelectionRequiredError] = useState<string | undefined>(
    undefined
  );

  // eslint-disable-next-line react/hook-use-state
  const [loadingSpinner] = useState<LoadingSpinnerDisplay>(LoadingSpinnerDisplay.hidden);

  // @TODO Check if sections and COLLECTED_YEAR are still relevant with new code
  // This report requires that all sales records are fetched instead of the filtered one.
  // This is why section is set as "allValue" and Category1 is set as "undefined"
  /*const data: SaleList = useMemo(
    () => getFilteredBrandSaleGridData(saleData, ALL_VALUE, sections, COLLECTED_YEAR),
    [saleData, sections, COLLECTED_YEAR]
  );*/

  const brandSales = useRecords<DenormalizedBrandSales>('brandSales', marketId);
  const [distributorOptions, ownerOptions] = useMemo(() => {
    const distributors: BaseOption[] = [];
    const owners: BaseOption[] = [];

    brandSales.forEach(
      ({
        distributorGUID,
        distributorName,
        ownerGUID,
        ownerName,
      }: {
        distributorGUID?: string;
        distributorName?: string;
        ownerGUID?: string;
        ownerName?: string;
      }) => {
        if (
          distributorGUID &&
          distributorName &&
          !distributors.find(e => e.value === distributorGUID)
        ) {
          distributors.push({
            label: distributorName,
            value: distributorGUID,
          });
        }
        if (ownerGUID && ownerName && !owners.find(e => e.value === ownerGUID)) {
          owners.push({
            label: ownerName,
            value: ownerGUID,
          });
        }
      }
    );

    return [distributors.sort(compareLabels), owners.sort(compareLabels)];
  }, [brandSales]);

  const generateExcelData = (
    filterType: FilterType,
    selectedEntity: BaseOption,
    forceDownload = true
  ) => {
    const getDetail = ({
      category2Id,
      originName,
      category3Name,
    }: {
      category2Id: number;
      originName: string;
      category3Name: string;
    }) => {
      const BrandyId = 5;
      const WhiskyId = 13;
      const SparklingWineId = 14;
      const StillWineId = 15;

      // Still Wine or Sparkling Wine => originName
      // Whisky or Brandy => category3Name

      if (category2Id === SparklingWineId || category2Id === StillWineId) {
        return originName;
      }

      if (category2Id === WhiskyId || category2Id === BrandyId) {
        return category3Name;
      }

      return '';
    };

    const rows = brandSales
      .filter(sale =>
        filterType === FilterType.Distributors
          ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            sale.distributorGUID === selectedEntity?.value
          : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            sale.ownerGUID === selectedEntity?.value
      )
      .filter(sale => sale.brandLineTypeId !== BrandLineTypes.FORECAST)
      .map(sale => ({ ...sale, detail: getDetail(sale) }))
      .map(sale => ({
        ...sale,
        category2DisplayOrder: allCategories.find(
          ({ level, id }) => level === 2 && id === sale.category2Id
        )?.displayOrder,
      }))
      .sort((item1, item2) => {
        const firstSort = (item1.category2DisplayOrder ?? 0) - (item2.category2DisplayOrder ?? 0);

        if (firstSort !== 0) return firstSort;

        const secondSort = item1.detail
          .toLocaleUpperCase()
          .localeCompare(item2.detail.toLocaleUpperCase());

        if (secondSort !== 0) return secondSort;

        return item1.brandLineDisplayName > item2.brandLineDisplayName ? 1 : -1;
      });

    const reportTypeLabel = filterType === FilterType.Owners ? 'Owner :' : 'Distributor :';
    const reportTypeValue = selectedEntity.label;

    return exportToCSV(
      rows,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      `${reportTypeValue?.replace(/[/\\?%*:|"<>]/g, '-')}-${new Date()
        .toISOString()
        .slice(0, 10)}.xlsx`,
      reportTypeLabel,
      reportTypeValue,
      forceDownload,
      marketName,
      COLLECTED_YEAR,
      // The number of decimal places.
      // Domestic markets sohuld 2dp, Travel-retail should be 3dp
      // @TODO Currently we can't distinguish between the two markets
      2
    );
  };

  const downloadExcelMulti = (filterType: FilterType): void => {
    if (filterType === FilterType.Distributors && selectedDistributor.length === 0) {
      setSelectionRequiredError('Please select a distributor');
      return;
    }
    if (filterType === FilterType.Owners && selectedOwner.length === 0) {
      setSelectionRequiredError('Please select an owner');
      return;
    }

    const entity = filterType === FilterType.Distributors ? selectedDistributor : selectedOwner;
    entity.forEach(el => generateExcelData(filterType, el));
  };

  const downloadExcelAll = async (filterType: FilterType) => {
    const zip = new JSZip();
    const entity = filterType === FilterType.Distributors ? distributorOptions : ownerOptions;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const xlsData = entity?.map(el => generateExcelData(filterType, el, false));

    xlsData.forEach(el => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (el?.fileName && el?.blobData) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        zip.file(el.fileName, el.blobData);
      }
    });

    const zipFile = await zip.generateAsync({ type: 'blob' });
    const filename = `${
      filterType === FilterType.Distributors ? 'distributors' : 'owners'
    }_${new Date().toISOString().slice(0, 10)}.zip`;
    saveAs(zipFile, filename);
  };

  const dropdownSelectors = [
    {
      type: FilterType.Distributors,
      name: 'distributor',
      options: distributorOptions,
      setSelection: setSelectedDistributor,
      value: selectedDistributor,
    },
    {
      type: FilterType.Owners,
      name: 'owner',
      options: ownerOptions,
      setSelection: setSelectedOwner,
      value: selectedOwner,
    },
  ];

  return (
    <WrapperComponent closeEvent={closeModal}>
      <Container
        style={{
          backgroundColor: 'rgba(64,64,64,.5)',
          height: '100%',
          width: '100%',
          zIndex: 100,
          position: 'absolute',
          display: loadingSpinner,
        }}
      >
        <Spinner />
      </Container>
      <Container>
        {selectionRequiredError && (
          <CustomRow key="error-message">
            <Col>
              <Alert variant="danger">
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                <strong>{selectionRequiredError}</strong>
              </Alert>
            </Col>
          </CustomRow>
        )}
        {dropdownSelectors.map(el => (
          <CustomRow key={el.name}>
            <Col sm={2}>
              <ExcelIconContainer>
                <FontAwesomeIcon icon={faFileExcel} size="5x" />
              </ExcelIconContainer>
            </Col>
            <Col sm={10}>
              <Heading>
                <Title>{`Export By ${el.name.charAt(0).toUpperCase()}${el.name.slice(1)}`}</Title>
                {`All the data relating to a given ${el.name}.`}
              </Heading>

              <Form.Row>
                <Col>
                  <Form.Group>
                    <Select
                      options={el.options}
                      placeholder="Select up to 10 items"
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={items => {
                        setSelectionRequiredError(undefined);
                        // this is require on reset, component is changing the value to null,
                        // but it can also be undefined
                        el.setSelection(Array.isArray(items) ? items.slice(0, 10) : []);
                      }}
                      value={el.value}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Button
                      type="button"
                      variant="primary"
                      data-testid={`download-selected-${el.name}`}
                      onClick={() => downloadExcelMulti(el.type)}
                    >
                      Download Selected
                    </Button>
                    &nbsp;
                    <Button
                      type="button"
                      variant="primary"
                      data-testid={`download-all-${el.name}`}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={() => downloadExcelAll(el.type)}
                    >
                      Download All
                    </Button>
                  </Form.Group>
                </Col>
              </Form.Row>
            </Col>
          </CustomRow>
        ))}
        <CustomRow />
      </Container>
    </WrapperComponent>
  );
};

export default ExportReport;
