import type { FC } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Box, Button } from '@mui/material';

interface FilterMenuProps {
  onClearAllFilters: () => void;
}

export const FilterMenu: FC<FilterMenuProps> = ({ onClearAllFilters }) => {
  return (
    <NavDropdown title="Filter" id="filterMenu">
      <Box sx={{ m: 1 }}>
        <Button onClick={onClearAllFilters} variant="outlined">
          Clear all filters
        </Button>
      </Box>
    </NavDropdown>
  );
};
