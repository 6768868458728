import type { BrandLine } from 'src/shared';
import type { NewFormItem } from 'src/store/features/brandLineAdd/reducer';
import type { DistributorOption } from 'src/types/DistributorOption';
import type { BrandLineFormItem } from 'src/utils/addBrandLineUtils';

export enum BrandLineAddActionType {
  SHOW_MODAL = 'brandLineAdd.SHOW_MODAL',
  HIDE_MODAL = 'brandLineAdd.HIDE_MODAL',
  SELECT_BRAND_LINE = 'brandLineAdd.SELECT_BRAND_LINE',
  UNSELECT_BRAND_LINE = 'brandLineAdd.UNSELECT_BRAND_LINE',
  GO_TO_CREATE = 'brandLineAdd.GO_TO_CREATE',
  GO_TO_STEP2 = 'brandLineAdd.GO_TO_STEP2',
  GO_TO_STEP1 = 'brandLineAdd.GO_TO_STEP1',
  UPDATE_FORM_STATE = 'brandLineAdd.UPDATE_FORM_STATE',
  STAGE_NEW_DISTRIBUTOR = 'brandLineAdd.STAGE_NEW_DISTRIBUTOR',
  STAGE_NEW_BRAND_LINE = 'brandLineAdd.STAGE_NEW_BRAND_LINE',
  UNSTAGE_NEW_BRAND_LINE = 'brandLineAdd.UNSTAGE_NEW_BRAND_LINE',
  STAGE_NEW_PARENT_BRAND = 'brandLineAdd.STAGE_NEW_PARENT_BRAND',
  UNSTAGE_NEW_PARENT_BRAND = 'brandLineAdd.UNSTAGE_NEW_PARENT_BRAND',
  STAGE_NEW_BRAND_OWNER = 'brandLineAdd.STAGE_NEW_BRAND_OWNER',
  UNSTAGE_NEW_BRAND_OWNER = 'brandLineAdd.UNSTAGE_NEW_BRAND_OWNER',
  UPDATE_STAGED_BRAND_OWNER = 'brandLineAdd.UPDATE_STAGED_BRAND_OWNER',
  UPDATE_STAGED_PARENT_BRAND = 'brandLineAdd.UPDATE_STAGED_PARENT_BRAND',
}
export type BrandLineAddAction =
  | { type: BrandLineAddActionType.SHOW_MODAL }
  | { type: BrandLineAddActionType.HIDE_MODAL }
  | { type: BrandLineAddActionType.SELECT_BRAND_LINE; brandLine: BrandLine }
  | { type: BrandLineAddActionType.UNSELECT_BRAND_LINE; brandLine: BrandLine }
  | { type: BrandLineAddActionType.GO_TO_CREATE; isCreatingBrandLineForm: boolean }
  | { type: BrandLineAddActionType.GO_TO_STEP2 }
  | { type: BrandLineAddActionType.GO_TO_STEP1; preselectedCategory?: string }
  | { type: BrandLineAddActionType.UPDATE_FORM_STATE; index: number; item: BrandLineFormItem }
  | { type: BrandLineAddActionType.STAGE_NEW_DISTRIBUTOR; distributor: DistributorOption }
  | { type: BrandLineAddActionType.STAGE_NEW_BRAND_LINE; brandLine: BrandLine }
  | { type: BrandLineAddActionType.UNSTAGE_NEW_BRAND_LINE; brandLineGUID: string }
  | { type: BrandLineAddActionType.STAGE_NEW_PARENT_BRAND; parentBrand: NewFormItem }
  | { type: BrandLineAddActionType.UNSTAGE_NEW_PARENT_BRAND; parentbrandGUID: string }
  | { type: BrandLineAddActionType.STAGE_NEW_BRAND_OWNER; brandOwner: NewFormItem }
  | { type: BrandLineAddActionType.UNSTAGE_NEW_BRAND_OWNER; brandownerGUID: string }
  | { type: BrandLineAddActionType.UPDATE_STAGED_BRAND_OWNER; brandLineGUID: string }
  | { type: BrandLineAddActionType.UPDATE_STAGED_PARENT_BRAND; brandLineGUID: string };

export const showAddModal = () => ({ type: BrandLineAddActionType.SHOW_MODAL });

export const hideAddModal = () => ({ type: BrandLineAddActionType.HIDE_MODAL });

// Step 1

export const selectBrandLine = (brandLine: BrandLine) => ({
  type: BrandLineAddActionType.SELECT_BRAND_LINE,
  brandLine,
});

export const unselectBrandLine = (brandLine: BrandLine) => ({
  type: BrandLineAddActionType.UNSELECT_BRAND_LINE,
  brandLine,
});

export const goToCreate = (isCreatingBrandLineForm?: boolean) => ({
  type: BrandLineAddActionType.GO_TO_CREATE,
  isCreatingBrandLineForm,
});

/* TODO: Legacy - remove */
export const GO_TO_EDIT = 'brandLineAdd.GO_TO_EDIT';
export const goToEdit = (isEditingBrandLineForm?: boolean, brandLineToEdit?: object) => ({
  type: GO_TO_EDIT,
  isEditingBrandLineForm,
  brandLineToEdit,
});

/* TODO: Legacy - remove */
export interface UpdateBrandLineInput {
  brandLineGUID: string;
  brandGUID?: string;
  originId?: number;
  previousOriginId?: number;
  isFlavoured?: boolean;
  previousIsFlavoured?: boolean;
  isCraft?: boolean;
  previousIsCraft?: boolean;
  alcoholicStrengthId?: number;
  previousAlcoholicStrengthId?: number;
  previousBrandType?: string;
  category5Id?: number;
  previousCategory5Id?: number;
  bleId?: number;
  previousBleId?: number;
  ageId?: number;
  previousAgeId?: number;
  ageInYears?: number;
  previousAgeInYears?: number;
  maltRegionId?: number;
  previousMaltRegionId?: number;
  ownerGUID?: string;
  previousOwnerGUID?: string;
  ownerName?: string;
  previousOwnerName?: string;
  brandLineDisplayName?: string;
  brandLineName?: string;
  previousBrandLineDisplayName?: string;
  // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style, @typescript-eslint/no-explicit-any
  additional?: { [key: string]: any };
}
/* TODO: Legacy - remove */
export interface UpdateBrandInput {
  brandGUID: string;
  brandName: string;
  previousBrandName?: string;
  displayName: string;
  previousDisplayName?: string;
  ownerGUID?: string;
  countryId?: number;
}
/* TODO: Legacy - remove */
export interface UpdateBrandOwnerInput {
  ownerGUID: string;
  ownerName: string;
  previousOwnerName?: string;
}

/* TODO: Legacy - remove */
export const UPDATE_BRAND_LINE = 'UPDATE_BRAND_LINE';
export const updateBrandLine = (
  updateBrandLineInput?: UpdateBrandLineInput,
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  fromSync: boolean = false
) => ({
  type: UPDATE_BRAND_LINE,
  updateBrandLineInput,
  fromSync,
});

/* TODO: Legacy - remove */
export const UPDATE_PARENT_BRAND = 'UPDATE_PARENT_BRAND';
export const updateParentBrand = (
  updateBrandInput?: UpdateBrandInput,
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  fromSync: boolean = false
) => ({
  type: UPDATE_PARENT_BRAND,
  updateBrandInput,
  fromSync,
});

/* TODO: Legacy - remove */
export const UPDATE_BRAND_OWNER = 'UPDATE_BRAND_OWNER';
export const updateBrandOwner = (
  updateBrandOwnerInput?: UpdateBrandOwnerInput,
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  fromSync: boolean = false
) => ({
  type: UPDATE_BRAND_OWNER,
  updateBrandOwnerInput,
  fromSync,
});

export const goToStep2 = () => ({ type: BrandLineAddActionType.GO_TO_STEP2 });

// Step 2

export const goToStep1 = ({
  preselectedCategory,
}: {
  preselectedCategory?: string | undefined;
} = {}) => ({
  type: BrandLineAddActionType.GO_TO_STEP1,
  preselectedCategory,
});

export const updateFormState = (index: number, item: BrandLineFormItem) => ({
  type: BrandLineAddActionType.UPDATE_FORM_STATE,
  index,
  item,
});

export const stageNewDistributor = (distributor: DistributorOption) => ({
  type: BrandLineAddActionType.STAGE_NEW_DISTRIBUTOR,
  distributor,
});

// Create

export const stageNewBrandLine = (brandLine: BrandLine) => ({
  type: BrandLineAddActionType.STAGE_NEW_BRAND_LINE,
  brandLine,
});
export const unstageNewBrandLine = (brandLineGUID: string) => ({
  type: BrandLineAddActionType.UNSTAGE_NEW_BRAND_LINE,
  brandLineGUID,
});

export const stageNewParentBrand = (parentBrand: NewFormItem) => ({
  type: BrandLineAddActionType.STAGE_NEW_PARENT_BRAND,
  parentBrand,
});
export const unstageNewParentBrand = (parentbrandGUID: string) => ({
  type: BrandLineAddActionType.UNSTAGE_NEW_PARENT_BRAND,
  parentbrandGUID,
});

export const stageNewBrandOwner = (brandOwner: NewFormItem) => ({
  type: BrandLineAddActionType.STAGE_NEW_BRAND_OWNER,
  brandOwner,
});
export const unstageNewBrandOwner = (brandownerGUID: string) => ({
  type: BrandLineAddActionType.UNSTAGE_NEW_BRAND_OWNER,
  brandownerGUID,
});
export const updateStagedBrandOwner = (brandLineGUID: string) => ({
  type: BrandLineAddActionType.UPDATE_STAGED_BRAND_OWNER,
  brandLineGUID,
});
export const updateStagedParentBrand = (brandLineGUID: string) => ({
  type: BrandLineAddActionType.UPDATE_STAGED_PARENT_BRAND,
  brandLineGUID,
});
