import { type FormEvent, type FormEventHandler, useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import { Base, PasswordHelp } from './Base';

interface Props {
  onStateChange: (state: string) => void;
}

export const ForgotPassword = ({ onStateChange }: Props) => {
  const [delivery, setDelivery] = useState<unknown>();
  const [error, setError] = useState<{ message: string } | null>(null);
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  const sendInputRef = useRef<HTMLInputElement>(null);
  const submitInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputRef = delivery ? submitInputRef : sendInputRef;

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [delivery]);

  const handleSend = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (username.length === 0) {
      return setError({ message: 'Email is required' });
    }

    return Auth.forgotPassword(username.toLowerCase())
      .then(data => {
        setError(null);
        setDelivery(data);
      })
      .catch(setError);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (code.length === 0) {
      return setError({ message: 'Code is required' });
    }

    if (password.length === 0) {
      return setError({ message: 'New password is required' });
    }

    Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
      .then(() => onStateChange('signInWithPasswordAuth'))
      .catch(setError);
    return null;
  };

  const handleCancel = () => {
    onStateChange('signInWithPasswordAuth');
  };

  const sendBody = (
    <>
      <p>You will receive a verification code by email to reset your password.</p>
      <Form.Group as={Form.Row}>
        <Form.Label column sm={2} htmlFor="forgotPassword-Username">
          Email
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            id="forgotPassword-Username"
            value={username}
            type="text"
            onChange={({ target }) => setUsername(target.value)}
            isInvalid={!!error}
            ref={sendInputRef}
          />
        </Col>
      </Form.Group>
    </>
  );

  const submitBody = (
    <>
      <p>Enter the code you received by email and set a new password.</p>
      <Form.Group as={Form.Row}>
        <Form.Label column sm={4} htmlFor="forgotPassword-Code">
          Code
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            id="forgotPassword-Code"
            value={code}
            type="text"
            onChange={({ target }) => setCode(target.value)}
            ref={submitInputRef}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Form.Row}>
        <Form.Label column sm={4} htmlFor="forgotPassword-Password">
          New password
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="password"
            id="forgotPassword-Password"
            aria-describedby="forgotPassword-Password-help"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />
        </Col>
      </Form.Group>
      <PasswordHelp id="forgotPassword-Password-help" />
    </>
  );

  return (
    <Base
      title="Reset your password"
      body={delivery ? submitBody : sendBody}
      submitTxt={delivery ? 'Verify' : 'Next'}
      onSubmit={(delivery ? handleSubmit : handleSend) as FormEventHandler}
      cancelTxt="Back to sign in"
      onCancel={handleCancel}
      error={error}
    />
  );
};
