import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { getSearchResults } from './helpers';

interface HookParams {
  brandLines: DenormalizedBrandLine[];
  brandSale: DenormalizedBrandSales | null;
}

const MIN_CHARACTERS_FOR_SEARCH = 2;

export const DEBOUNCE_DELAY_MS = 200;

export const useBrandLineSearch = ({ brandSale, brandLines }: HookParams) => {
  const noSearchResultsCopy = useRef('');
  const [search, setSearch] = useState('');
  const [selection, setSelection] = useState<DenormalizedBrandLine | null>(null);
  const [filteredList, setFilteredList] = useState<DenormalizedBrandLine[]>([]);

  const hasSelectionInSearchResults = (filteredList: DenormalizedBrandLine[]) => {
    return filteredList.some(fbl => fbl.brandLineGUID === selection?.brandLineGUID);
  };

  const clearFilteredListAndNoSearchResultsCopy = () => {
    setFilteredList([]);
    noSearchResultsCopy.current = '';
  };

  const debouncedHandleSearchResults = useDebouncedCallback(
    () => {
      if (search.length < MIN_CHARACTERS_FOR_SEARCH) {
        clearFilteredListAndNoSearchResultsCopy();
        return;
      }

      const searchResults = getSearchResults({
        brandLines,
        brandSale,
        search,
      });
      setFilteredList(searchResults);

      if (searchResults.length === 0) {
        noSearchResultsCopy.current = `No brand lines found for "${search}"`;
      }

      if (!hasSelectionInSearchResults(searchResults)) {
        setSelection(null);
      }
    },
    DEBOUNCE_DELAY_MS,
    { trailing: true }
  );

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
    debouncedHandleSearchResults();
  };

  const handleSelection = (selection: DenormalizedBrandLine | null) => {
    setSelection(selection);
  };

  /*
    Necessary workaround for clearing state, because the dialog does not get unmounted when closed.
    TODO: Consider conditionally rendering the component in "BrandSalesTableViewPage" with the "isOpen" Redux store state to guarantee it's unmounted when closing;
    only thing is that doing so appears to cause a delay when opening the dialog, potentially due to re-renders of the "BrandSalesTableViewPage".
  */
  const resetHookState = () => {
    setFilteredList([]);
    setSelection(null);
    setSearch('');
    noSearchResultsCopy.current = '';
  };

  return {
    search,
    filteredList,
    noSearchResultsCopy: noSearchResultsCopy.current,
    selection,
    handleSearch,
    handleSelection,
    resetHookState,
  };
};
