import { type HTMLAttributes, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import Autocomplete, {
  type AutocompleteInputChangeReason,
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

import { RecordDialog } from 'src/components/RecordDialog/RecordDialog';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { useAppDispatch, useAppSelector } from 'src/store';
import { swapDistributorDialogActions } from 'src/store/features/swapDistributorDialog/slice';
import { useSyncContext } from 'src/sync';
import type { DistributorsEntity } from 'src/types/entity/Distributors';
import { CreateDistributorDialog, type NewDistributor } from './components/CreateDistributorDialog';
import { DistributorSearch } from './components/DistributorSearch';
import { useDistributorSearch } from './hooks/useDistributorSearch';
import * as styles from './styles/swapDistributorDialog';

const COMPONENT_PROPS: AutocompleteProps<
  DenormalizedBrandSales,
  false,
  false,
  true
>['componentsProps'] = {
  popper: {
    disablePortal: true,
  },
};

const SwapDistributorDialogComponent = () => {
  const syncContext = useSyncContext();
  const dispatch = useAppDispatch();
  const [modalState, setModalState] = useState<'swapping' | 'creating'>('swapping');
  const brandSale = useAppSelector(state => state.swapDistributorDialog.brandSale);
  const [distributors, setDistributors] = useState(useRecords<DistributorsEntity>('distributors'));
  const [selection, setSelection] = useState<DistributorsEntity | null>(null);

  const {
    filteredList,
    noSearchResultsCopy,
    search,
    handleSearch,
    handleSelection,
    resetHookState,
  } = useDistributorSearch({
    brandSale,
    distributors,
    selection,
    setSelection,
  });

  const clearState = () => {
    setModalState('swapping');
    resetHookState();
  };

  const handleClose = () => {
    clearState();
    dispatch(swapDistributorDialogActions.close());
  };

  const handleInputChange = (newValue: string, reason: AutocompleteInputChangeReason) => {
    // 'reset' is the reason that comes through when the user selects an autocomplete option.
    if (reason === 'reset') {
      return;
    }

    handleSearch(newValue);
  };

  if (brandSale == null) {
    return null;
  }

  const updateEntity = () => {
    void syncContext.updateEntity('brandSales', brandSale.saleGUID, {
      distributorGUID: selection?.distributorGUID,
      distributorName: selection?.distributorName,
    });

    return;
  };

  const handleSwap = () => {
    updateEntity();
    handleClose();
  };

  const renderInput = (inputProps: AutocompleteRenderInputParams) => (
    <TextField
      {...inputProps}
      InputLabelProps={{ className: '' }}
      placeholder={searchPlaceholderCopy}
      size="small"
      InputProps={{
        ...inputProps.InputProps,
        startAdornment: <SearchIcon />,
      }}
    />
  );

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, distributor: DistributorsEntity) => (
    <DistributorSearch
      {...props}
      distributorName={distributor.distributorName}
      selectedOption={selection}
      onChange={() => handleSelection(distributor)}
    />
  );

  const swapCopy = `Swap distributor`;
  const searchPlaceholderCopy = `Search distributor...`;
  const findCopy = `Find a distributor to swap with`;
  const ariaLabel = `swap-distributor-search`;
  const isFilteredListOpen = filteredList.length > 0 || noSearchResultsCopy.length > 0;

  if (modalState === 'creating') {
    const processNewRecord = (newDistributor: NewDistributor) => {
      setModalState('swapping');
      setDistributors([...distributors, newDistributor as DistributorsEntity]);
      setSelection(newDistributor as DistributorsEntity);
      handleSearch(newDistributor.distributorName);
    };

    return (
      <CreateDistributorDialog
        distributors={distributors}
        onCancel={() => setModalState('swapping')}
        onSubmit={processNewRecord}
        open
        onClose={handleClose}
        defaultValue={search}
      />
    );
  }

  return (
    <RecordDialog
      disabled={!selection}
      open
      onClose={handleClose}
      onSubmit={handleSwap}
      submitButtonText={swapCopy}
      title={swapCopy}
    >
      <DialogContent dividers>
        <Typography variant="body1" fontWeight={600} sx={styles.distributorName}>
          {brandSale.distributorName}
        </Typography>
        <FormControl fullWidth>
          <FormLabel htmlFor={ariaLabel} sx={styles.findToSwapWith} focused={false}>
            <p></p>
            {findCopy}
          </FormLabel>
          <Autocomplete
            componentsProps={COMPONENT_PROPS}
            disableCloseOnSelect
            disablePortal
            filterOptions={opts => opts}
            getOptionLabel={opt => opt.distributorName}
            id={ariaLabel}
            inputValue={search}
            noOptionsText={noSearchResultsCopy}
            onChange={(_e, newValue) => handleSelection(newValue)}
            onInputChange={(_e, newValue, reason) => handleInputChange(newValue, reason)}
            open={isFilteredListOpen}
            options={filteredList}
            popupIcon={null}
            renderInput={renderInput}
            renderOption={renderOption}
            value={selection}
          />
        </FormControl>

        {search.length > 0 && filteredList.length === 0 && (
          <FormControl>
            <Button
              variant="text"
              startIcon={<AddIcon color="disabled" />}
              color="secondary"
              onClick={() => setModalState('creating')}
            >
              Create Distributor
            </Button>
          </FormControl>
        )}
      </DialogContent>
    </RecordDialog>
  );
};

export const SwapDistributorDialog = () => {
  const isOpen = useAppSelector(state => state.swapDistributorDialog.isOpen);
  return isOpen && <SwapDistributorDialogComponent />;
};
