import type { Ref } from 'react';
import { forwardRef, Fragment, useMemo } from 'react';
import { Box } from '@mui/material';
import type { ActionId, ActionImpl } from 'kbar';

import { ActionIcon } from './ActionIcon';
import * as styles from '../CommandBar.styles';

interface Props {
  action: ActionImpl;
  active: boolean;
  currentRootActionId?: ActionId | null | undefined;
}

export const ResultItem = forwardRef(function ResultItem(props: Props, ref: Ref<HTMLDivElement>) {
  const { action, active, currentRootActionId } = props;

  const ancestors = useMemo(() => {
    if (!currentRootActionId) return action.ancestors;
    const index = action.ancestors.findIndex(ancestor => ancestor.id === currentRootActionId);
    // +1 removes the currentRootAction; e.g. if we are on the "Set theme" parent action,
    // the UI should not display "Set theme… > Dark" but rather just "Dark"
    return action.ancestors.slice(index + 1);
  }, [action.ancestors, currentRootActionId]);

  return (
    <Box ref={ref} sx={styles.OuterContainer[active ? 'selected' : 'default']}>
      <Box sx={styles.InnerContainer}>
        {action.icon && <ActionIcon action={action} />}
        <Box>
          <Box>
            {ancestors.length > 0 &&
              ancestors.map(ancestor => (
                <Fragment key={ancestor.id}>
                  <Box component="span">{ancestor.name}</Box>
                  <Box component="span" sx={styles.RightAngle}>
                    &rsaquo;
                  </Box>
                </Fragment>
              ))}
            <Box component="span">{action.name}</Box>
          </Box>
          {action.subtitle && <Box component="span">{action.subtitle}</Box>}
        </Box>
      </Box>
      {action.shortcut?.length ? (
        <Box aria-hidden sx={styles.KbdContainer}>
          {action.shortcut.map(sc => (
            <Box component="kbd" key={sc} style={styles.Kbd}>
              {sc}
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
});
