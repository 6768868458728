import { Children, forwardRef, useEffect, useMemo, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';

import { createGetHeight, flattenGroupedChildren, getCurrentIndex, sum } from './utils';

// @ts-expect-error: legacy code
function MenuList(props) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const childElements = useMemo(
    () => {
      // eslint-disable-next-line react/destructuring-assignment, react/prop-types, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      const children = Children.toArray(props.children);

      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      const head = children[0] || {};
      // @ts-expect-error: legacy code
      const { props: { data: { options = [] } = {} } = {} } = head;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const groupedChildrenLength = options.length;
      const isGrouped = groupedChildrenLength > 0;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const flattenedChildren = isGrouped && flattenGroupedChildren(children);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return isGrouped ? flattenedChildren : children;
    },
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types, @typescript-eslint/no-unsafe-member-access
    [props.children]
  );

  // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-assignment
  const { getStyles } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const groupHeadingStyles = getStyles('groupHeading', props);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const loadingMsgStyles = getStyles('loadingMessage', props);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const noOptionsMsgStyles = getStyles('noOptionsMessage', props);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const optionStyles = getStyles('option', props);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const getHeight = createGetHeight({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    groupHeadingStyles,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    noOptionsMsgStyles,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    optionStyles,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    loadingMsgStyles,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  const heights = useMemo(() => childElements.map(getHeight), [childElements, getHeight]);

  const currentIndex = useMemo(() => getCurrentIndex(childElements), [childElements]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const itemCount = childElements.length;

  // calc menu height
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    maxHeight,
    paddingBottom = 0,
    paddingTop = 0,
    ...menuListStyle
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  } = getStyles('menuList', props);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  const totalHeight = useMemo(() => heights.reduce(sum, 0), [heights]);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/restrict-plus-operands
  const totalMenuHeight = totalHeight + paddingBottom + paddingTop;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const menuHeight = Math.min(maxHeight, totalMenuHeight);
  const estimatedItemSize = Math.floor(totalHeight / itemCount);

  // eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-assignment
  const { innerRef, selectProps } = props;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { classNamePrefix, isMulti } = selectProps || {};

  const list = useRef(null);

  useEffect(() => {
    /**
     * not sure why this is necessary
     */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (childElements.length === 1) {
      // @ts-expect-error: legacy code
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      list.current.resetAfterIndex(0);
    }

    /**
     * enables scrolling on key down arrow
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (currentIndex >= 0 && list.current !== null) {
      // @ts-expect-error: legacy code
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      list.current.scrollToItem(currentIndex);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  }, [childElements.length, currentIndex, list]);

  return (
    // @ts-expect-error: legacy code
    <List
      className={
        classNamePrefix
          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${classNamePrefix}__menu-list${
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              isMulti ? ` ${classNamePrefix}__menu-list--is-multi` : ''
            }`
          : ''
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      style={menuListStyle}
      ref={list}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      outerRef={innerRef}
      estimatedItemSize={estimatedItemSize}
      // eslint-disable-next-line react/display-name, react/no-unstable-nested-components, react/prop-types
      innerElementType={forwardRef(({ style, ...rest }, ref) => (
        <div
          ref={ref}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          style={{
            ...style,
            // eslint-disable-next-line react/prop-types, @typescript-eslint/restrict-plus-operands, @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            height: `${parseFloat(style.height) + paddingBottom + paddingTop}px`,
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      ))}
      height={menuHeight}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      itemCount={itemCount}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      itemData={childElements}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
      itemSize={index => heights[index]}
    >
      {({ data, index, style }) => (
        <div
          style={{
            ...style,
            // @ts-expect-error: legacy code
            // eslint-disable-next-line react/prop-types, @typescript-eslint/restrict-template-expressions, @typescript-eslint/restrict-plus-operands
            top: `${parseFloat(style.top) + paddingTop}px`,
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {data[index]}
        </div>
      )}
    </List>
  );
}
export default MenuList;
