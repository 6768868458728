import Box from '@mui/material/Box';
import Typography, { type TypographyOwnProps } from '@mui/material/Typography';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import * as styles from '../styles/brandLineDetailsList';

interface Props {
  brandSale: DenormalizedBrandSales;
  selection?: DenormalizedBrandLine | null;
}

const listItemProps: TypographyOwnProps = { color: 'text.secondary', variant: 'subtitle2' };

interface RenderFieldProps {
  label: string;
  value: string;
}

interface RenderDetailsProps {
  details: DenormalizedBrandLine | DenormalizedBrandSales;
}

const RenderField = (props: RenderFieldProps) => (
  <Typography {...listItemProps} sx={styles.container}>
    <Box component="span" sx={styles.leftCol}>
      {props.label}:
    </Box>
    <Box component="span" sx={styles.rightCol}>
      {props.value}
    </Box>
  </Typography>
);

const RenderDetails = (props: RenderDetailsProps) => (
  <>
    <Typography
      variant="caption"
      color="text.primary"
      sx={styles.brandLineName}
      data-testid={props.details.brandLineName}
    >
      {props.details.brandLineName}
    </Typography>
    <RenderField label="Display name" value={props.details.brandLineDisplayName} />
    <RenderField label="Category 5" value={props.details.category5Name} />
    <RenderField label="Brand" value={props.details.brandName} />
    <RenderField label="Owner" value={props.details.ownerName} />
    <RenderField label="Origin" value={props.details.originName} />
  </>
);

export const BrandLineDetailsList = ({ brandSale, selection = null }: Props) => {
  return (
    <>
      <RenderDetails details={brandSale} />
      {selection && (
        <>
          <p style={styles.preposition}>with</p>
          <RenderDetails details={selection} />
        </>
      )}
    </>
  );
};
