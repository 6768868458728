import type { FormulaFunction } from 'src/components/DataGrid';

export const compoundAnnualGrowthRate: FormulaFunction = (
  startValue: unknown,
  endValue: unknown,
  duration: unknown
) => {
  const config = {
    startValue: !isNaN(startValue as number) ? (startValue as number) : 0,
    endValue: !isNaN(endValue as number) ? (endValue as number) : 0,
    duration: !isNaN(duration as number) ? (duration as number) : 0,
  };

  const exponent: number = 1 / config.duration;
  const cagr: number = (config.endValue / config.startValue) ** exponent - 1;

  const percentage: number = cagr;

  if (isNaN(percentage)) return undefined;
  if (!isFinite(percentage)) return undefined;
  if (percentage === 0) return 0.00001;
  if (!endValue || !startValue) return undefined;

  return percentage;
};
