import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useRegisterActions } from 'kbar';

import { Button } from 'src/css/styled-components';
import { randomId } from 'src/utils/string';
import { useSyncContext } from '../../sync';
import { useAuth } from '../hooks';

function getInitials(name: string): string {
  return name
    .split(' ')
    .map(word => word.charAt(0))
    .slice(0, 3)
    .join('')
    .toUpperCase();
}

export const AccountMenu: FC = () => {
  const { user, logOut } = useAuth();
  const [open, setOpen] = useState(false);

  const { onDisconnect } = useSyncContext();

  useEffect(() => {
    return onDisconnect(() => {
      logOut();
    });
  });

  const accountActions = useMemo(
    () => [
      {
        id: randomId(),
        name: 'Log out',
        section: 'Navigation',
        priority: 10,
        perform: () => logOut(),
      },
    ],
    [logOut]
  );

  useRegisterActions(accountActions, [user]);

  const handleConfirm = () => {
    logOut();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleLogOut = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  if (!user) return null;

  return (
    // @TODO - use Avatar from design-system
    <NavDropdown title={getInitials(user.name)} id="accountMenu" alignRight>
      <NavDropdown.Item onClick={handleLogOut}>Log out</NavDropdown.Item>
      <Dialog open={open} keepMounted aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This will delete local data and will limit the ability to diagnose problems.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="link">
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </NavDropdown>
  );
};
