import { pxToRem } from 'src/theme/utils';

export const optionLiContainer = {
  paddingLeft: 0,
};

export const optionWrapper = {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
};

export const recordContext = {
  display: 'grid',
};

export const distributorName = {
  fontSize: pxToRem('14px'),
  marginBottom: pxToRem('-4px'),
  fontWeight: 600,
};
