import { Button } from 'react-bootstrap';
import { Box } from '@mui/material';

export const AdminClearCachedMarkets = () => {
  const handleClick = () => {
    localStorage.removeItem('cached-market-ids');
    window.alert('Cached markets are cleared succesfully!');
  };

  return (
    <Box sx={{ m: 4 }}>
      <Button onClick={handleClick}>Clear Cached Markets</Button>
    </Box>
  );
};
