import * as t from 'io-ts';

const CategoryRequired = t.type({
  id: t.number,
  name: t.string,
  level: t.number,
  displayOrder: t.number,
});
export type CategoryRequired = t.TypeOf<typeof CategoryRequired>;

const FlatCategory = t.intersection([
  CategoryRequired,
  t.type({
    value: t.string,
    label: t.string,
    isDisabled: t.boolean,
    originId: t.string,
    forecastByOrigin: t.boolean,
    parents: t.array(CategoryRequired),
  }),
]);

export type FlatCategory = t.TypeOf<typeof FlatCategory>;
const FlatCategoryList = t.array(FlatCategory);
export type FlatCategoryList = t.TypeOf<typeof FlatCategoryList>;
