import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { CognitoUser as BaseCognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

import { useAppDispatch, useAppSelector } from 'src/store';
import { authActions } from 'src/store/features/auth/slice';

// @TODO - replace this with a better type when we upgrade amplify
interface CognitoUser extends BaseCognitoUser {
  attributes: {
    email: string;
    family_name: string;
    name: string;
    sub: string;
  };
}

export function useAuth() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.auth.user);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const { attributes } = (await Auth.currentAuthenticatedUser()) as CognitoUser;
        dispatch(
          authActions.setUser({
            email: attributes.email,
            id: attributes.sub,
            name: `${attributes.name} ${attributes.family_name}`,
          })
        );
      } catch (error) {
        const errorMessage =
          typeof error === 'string' ? error : (error as Error | null)?.message ?? '';
        console.error(`Error authenticating user in Amplify: "${errorMessage}"`);
        dispatch(authActions.setUser(null));
      }
    };

    void checkUser();
  }, [dispatch]);

  const logOut = () => navigate('/logout');

  return {
    user,
    logOut,
  };
}
