import { Modal, Navbar } from 'react-bootstrap';
import { components } from 'react-select';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { config } from 'src/config';
import type { LookUp } from 'src/shared';
import { smSizeWindow } from 'src/utils/appUtils';
import { useScrollIntoView } from 'src/utils/utilHooks';
import { btn, btnBorder, btnDisabled, btnHover, btnTextTransform, colors } from '../theme';

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid lightgrey;
  overflow: auto;
  display: ${() => {
    if (smSizeWindow) return 'none';
    return undefined;
  }};

  @media print {
    border: none;
  }
`;

interface ButtonProps {
  variant: string; // primary, secondary, link
  theme: LookUp;
  disabled?: boolean;
}

export const Button = styled.button`
  color: ${({ variant }: ButtonProps) => btn[variant]?.color};
  background-color: ${({ variant }: ButtonProps) => btn[variant]?.backgroundColor};
  text-align: center;
  vertical-align: middle;
  border: ${({ variant }: ButtonProps) => btnBorder[variant]};
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: ${({ variant }: ButtonProps) => btnTextTransform[variant]};

  &:hover {
    background-color: ${({ variant }: ButtonProps) => btnHover[variant]?.backgroundColor};
    color: ${({ variant }: ButtonProps) => btnHover[variant]?.color};
  }
  &:focus {
    background-color: ${({ variant }: ButtonProps) => btnHover[variant]?.backgroundColor};
    color: ${({ variant }: ButtonProps) => btnHover[variant]?.color};
  }
  &:disabled {
    background-color: ${({ variant }: ButtonProps) => btnDisabled[variant]?.backgroundColor};
    color: ${({ variant }: ButtonProps) => btnDisabled[variant]?.color};

    &:hover {
      background-color: ${({ variant }: ButtonProps) => btnDisabled[variant]?.backgroundColor};
      color: ${({ variant }: ButtonProps) => btnDisabled[variant]?.color};
    }
  }
`;

export const DropdownHeader = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.midPurple};
  text-transform: uppercase;
`;

interface ToggleProps {
  main?: boolean;
}

export const StyledNavToggle = styled(Navbar.Toggle)`
  border: none !important;
  width: ${({ main }: ToggleProps) => (main ? undefined : '100%')};
`;

export const StyledNavbar = styled(Navbar)`
  background-color: ${() => (config.isPreview ? 'hsla(20, 100%, 50%, 1)' : '#302e60')};
  visibility: ${({ navopen }) => navopen !== 'true' && 'hidden'};
  position: ${({ navopen }) => (navopen === 'true' ? 'static' : 'absolute')};
  top: -100px;

  & .navbar-nav .nav-link {
    color: white !important;
  }

  & .navbar-nav .nav-link:hover {
    color: #ccc !important;
  }

  & .nav-tabs {
    border-bottom: 0;
  }

  & .nav-tabs .nav-link:hover {
    border: solid 1px transparent;
  }

  & .nav-tabs .nav-link.active {
    color: rgba(0, 0, 0, 0.5) !important;
    background-color: #f8f9fa;
    border: solid 1px transparent;
    &:hover {
      border: solid 1px transparent;
    }
  }

  & .nav-pills .nav-link.active {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const ModalHeader = styled(Modal.Header)`
  color: ${() => colors.white};
  background-color: ${() => colors.midPurple};
  font-weight: bold;
  font-size: 14pt;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CircularButton = styled(Button as any)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #302e60;
  background: white;
  color: #302e60;

  &:hover {
    background: white;
  }
`;

interface OptionProps {
  level: number;
  isSelected: boolean;
  theme: LookUp;
}

const StyledOption = styled.div`
  display: flex;
  padding-left: ${({ level }: OptionProps) => `${(level - 1) * 10}px`};
  font-size: ${({ level }: OptionProps) => (level === 1 ? '12pt' : '11pt')};
  background-color: ${({ isSelected, theme }: OptionProps) => {
    // @ts-expect-error: legacy code
    return isSelected ? theme.darkBlue : theme.white;
  }};
  &:hover {
    background-color: ${({ isSelected }: OptionProps) => !isSelected && '#DEEBFF'};
  }
`;

interface IconProps {
  isSelected: boolean;
  theme: LookUp;
}

const StyledIcon = styled.div`
  padding: 5px 0px;
  color: ${({ isSelected, theme }: IconProps) => {
    // @ts-expect-error: legacy code
    return isSelected ? theme.white : theme.darkGrey;
  }};
  flex-basis: 40px;
  margin: 0px;
  height: 32px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SectionDropdownOption = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, isSelected } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const ref = useScrollIntoView(isSelected);
  return (
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    <StyledOption level={data.level} isSelected={isSelected}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...props} innerRef={ref} />
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      {data.locked && (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <StyledIcon isSelected={isSelected}>
          <FontAwesomeIcon icon={faLock} />
        </StyledIcon>
      )}
    </StyledOption>
  );
};
