import ModalType from 'src/components/ModalDialog/ModalType';
import { SET_SHOW_MODAL_DIALOG } from './actions';

const modalDialogInitialState: {
  modalType: ModalType;
} = {
  modalType: ModalType.NONE,
};

/* eslint-disable-next-line default-param-last, @typescript-eslint/no-explicit-any */
const modalDialogReducer = (state = modalDialogInitialState, action: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  switch (action.type) {
    case SET_SHOW_MODAL_DIALOG: {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
      return { ...state, modalType: action.modalType, properties: action.properties };
    }
    default:
      return state;
  }
};

export default modalDialogReducer;
