import { syncingConfig } from '../config';
import { createConnectivityDetector, createObservable } from '../functions';
import {
  BroadcastMessageType,
  OnlineState,
  QueueManagerState,
  SyncContextState,
  SyncManagerState,
} from '../types';
import { BroadcastManager } from './broadcast-manager';
import { CachingManager } from './caching-manager';
import { LocalDbManager } from './localdb-manager';
import { QueueManager } from './queue-manager';
import { SyncManager } from './sync-manager';

const SYNC_CONTEXT_STATUS = 'sync-context-status';

export class SyncContext {
  private static instance: SyncContext | undefined;

  public readonly connectivity = createConnectivityDetector();
  public onlineStatus = OnlineState.Online;
  public readonly onSyncContextStateChange = createObservable<SyncContextState>();
  public readonly onDisconnect = createObservable<boolean>();

  private constructor(
    public readonly broadcastManager: BroadcastManager,
    public readonly localDbManager: LocalDbManager,
    public readonly queueManager: QueueManager,
    public readonly cachingManager: CachingManager,
    public readonly syncManager: SyncManager,
    public status: SyncContextState
  ) {
    const handleStatusChange = () => {
      let status: SyncContextState = SyncContextState.Available;

      // if it is returning offline to online, it should sync waiting records in the queue!
      if (
        this.status === SyncContextState.Offline &&
        this.connectivity.onlineStatus === OnlineState.Online
      ) {
        void this.queueManager.syncTransactions();
      }

      if (this.connectivity.onlineStatus === OnlineState.Offline) {
        status = SyncContextState.Offline;
      } else if (
        this.queueManager.status === QueueManagerState.TimedOut ||
        this.syncManager.status === SyncManagerState.TimedOut
      ) {
        status = SyncContextState.ServerOffline;
      } else if (
        this.queueManager.status === QueueManagerState.Failed ||
        this.syncManager.status === SyncManagerState.Failed ||
        this.queueManager.getFailedTransactionsCount() > 0
      ) {
        status = SyncContextState.Failed;
      } else if (this.syncManager.status === SyncManagerState.InProgress) {
        status = SyncContextState.Caching;
      } else if (this.queueManager.status === QueueManagerState.InProgress) {
        status = SyncContextState.Saving;
      } else {
        status = SyncContextState.Available;
      }

      if (this.status !== status) {
        this.setSyncState(status);
      }
    };

    this.connectivity.onOnlineStatusChange.subscribe(handleStatusChange);
    this.queueManager.onQueueManagerStateChange.subscribe(handleStatusChange);
    this.syncManager.onSyncManagerStateChange.subscribe(handleStatusChange);
    this.broadcastManager.onDisconnect.subscribe(() => {
      this.onDisconnect.notify(true);
    });
  }

  public static Build = () => {
    if (!this.instance) {
      const broadcastManager = new BroadcastManager(new BroadcastChannel('broadcast-data'));
      const localDbManager = new LocalDbManager('localDb');
      const cachingManager = new CachingManager(localDbManager);
      const queueManager = new QueueManager(localDbManager, cachingManager, broadcastManager);
      const syncManager = new SyncManager(
        localDbManager,
        broadcastManager,
        cachingManager,
        queueManager
      );
      const persistedSyncStateString = localStorage.getItem(SYNC_CONTEXT_STATUS);

      this.instance = new SyncContext(
        broadcastManager,
        localDbManager,
        queueManager,
        cachingManager,
        syncManager,
        persistedSyncStateString
          ? (persistedSyncStateString as SyncContextState)
          : SyncContextState.Available
      );
    }

    return this.instance;
  };

  async connect() {
    let result: {
      error?: Error | undefined;
      status: SyncContextState;
    } = {
      status: this.status,
    };

    // in order to cause other tabs to wait running connect on the active tab.
    await navigator.locks.request('syncContext-connect', async () => {
      // extra control if navigator locks not works...
      await this.localDbManager.open();
      await this.cachingManager.cacheExistingData();
      await this.queueManager.cacheExistingTransactions();
      const { error, status } = await this.syncManager.syncData();

      if (status === SyncManagerState.Failed) {
        result = {
          error,
          status: SyncContextState.BootstrapError,
        };
      }

      // if initial syncing done, connect to broadcast channel to retrieve events.
      //start to listen broadcast channel messages.
      this.broadcastManager.connect();

      // when the page refreshed, try to push the existing waiting transactions.
      void this.queueManager.syncTransactions();

      result = {
        status: SyncContextState.Available,
      };
    });

    return result;
  }

  async disconnect() {
    const lastVisitedMarket = this.syncManager.getLastVisitedMarketId();
    window.localStorage.clear();

    // after clearing localStorage set back lastVisited market.
    if (lastVisitedMarket) this.syncManager.setLastVisitedMarketId(lastVisitedMarket);

    await this.localDbManager.resetLocalDatabase();
    this.queueManager.clear();
    this.cachingManager.clear();
    this.broadcastDisconnect();
  }

  broadcastDisconnect() {
    this.broadcastManager.postMessage({
      type: BroadcastMessageType.Disconnect,
    });
  }

  async statistics() {
    return {
      indexedDbStats: await this.localDbManager.statistics(),
      cachingStats: this.cachingManager.statistics(),
    };
  }

  async resetLocalDatabase() {
    await this.localDbManager.resetLocalDatabase();
    this.cachingManager.clear();
  }

  async deleteDatabase() {
    await this.localDbManager.deleteDatabase();
    this.cachingManager.clear();
  }

  isMarketQuery(entityType: string) {
    const collectorEntity = syncingConfig.entities[entityType];
    return collectorEntity ? collectorEntity.isMarketQuery ?? false : false;
  }

  private setSyncState(status: SyncContextState) {
    this.status = status;
    this.setPersistedSyncState(status);
    this.onSyncContextStateChange.notify(status);
  }

  private setPersistedSyncState(status: SyncContextState) {
    localStorage.setItem(SYNC_CONTEXT_STATUS, status);
  }
}
