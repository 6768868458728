import type { MutableRefObject } from 'react';
import { type GridApi } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { GridOptions, Row } from '../../types';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useRowsFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  gridApiRef: MutableRefObject<GridApi<R> | undefined>,
  props: Pick<DataGridProps<R>, 'getRowId' | 'rows'>
) => {
  const logger = useGridLogger(gridRef, 'useRowsFeature');

  const { getRowId, rows } = props;

  useInitialiseState(() => {
    gridRef.current.rowData = rows;
  });

  useEffectAfterFirstRender(() => {
    logger.log(`Updating all rows, new length ${rows.length}`);

    gridApiRef.current?.setGridOption('rowData', rows);
  }, [rows]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `getRowId` is not supported.');
  }, [getRowId]);
};
