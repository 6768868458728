import { COLLECTED_YEAR, FORECASTING_YEARS_MODIFIER } from 'src/constants';
import { type Market } from 'src/markets';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type BrandSalesEntity } from 'src/types/entity/BrandSales';
import type { WineColourSalesEntity } from 'src/types/entity/WineColourSales';

interface WineColourSalesWithVolume extends WineColourSalesEntity {
  volume: Record<number, number>;
}

const YEARS_WITH_FORECAST_YEARS = COLLECTED_YEAR + FORECASTING_YEARS_MODIFIER;

const getFirstYear = (yearValueMap: Record<string, number>) => {
  return Math.min(...Object.keys(yearValueMap).map(Number));
};

export const calculateVolumesFromPercentages = (
  stillWineSaleVolumes: DenormalizedBrandSales['volume'],
  data: WineColourSalesEntity[]
): WineColourSalesWithVolume[] => {
  const mutatedSalesWithVolume = [...data] as WineColourSalesWithVolume[];
  for (const entity of mutatedSalesWithVolume) {
    const firstYear = getFirstYear(entity.percentage);

    for (let year = firstYear; year <= YEARS_WITH_FORECAST_YEARS; year += 1) {
      const yearTotals = stillWineSaleVolumes[year] ?? 0;
      const yearValue = entity.percentage[year];
      const isNumericalYearValue = yearValue != null && !isNaN(yearValue);

      if (isNumericalYearValue) {
        entity.volume = {
          ...entity.volume,
          [year]: yearValue * yearTotals,
        };
      }
    }
  }

  return mutatedSalesWithVolume;
};

type VolumeOrPercentage = DenormalizedBrandSales['volume'] | WineColourSalesEntity['percentage'];

const getYearlyTotals = (totals: VolumeOrPercentage, volumeOrPercentage: VolumeOrPercentage) => {
  const newTotals: VolumeOrPercentage = { ...totals };
  const firstYear = getFirstYear(volumeOrPercentage);

  for (let year = firstYear; year <= YEARS_WITH_FORECAST_YEARS; year += 1) {
    const yearAmount = volumeOrPercentage[year];

    if (yearAmount == null) {
      continue;
    }

    if (!totals[year]) {
      newTotals[year] = yearAmount;
      continue;
    }

    newTotals[year] += yearAmount;
  }

  return newTotals;
};

export const getStillWineTotals = (
  stillWines: DenormalizedBrandSales[],
  wineSales: WineColourSalesEntity[]
) => {
  const stillWineVolumeTotals = stillWines.reduce<VolumeOrPercentage>((totals, sale) => {
    return getYearlyTotals(totals, sale.volume);
  }, {});

  const stillWinePercentageTotals = wineSales.reduce<VolumeOrPercentage>((totals, sale) => {
    return getYearlyTotals(totals, sale.percentage);
  }, {});

  return { stillWineVolumeTotals, stillWinePercentageTotals };
};

export const getUpdatedBrandSales = ({
  brandSales,
  brandSaleEntities,
  market,
}: {
  brandSales: DenormalizedBrandSales[];
  brandSaleEntities: BrandSalesEntity[];
  market: Market;
}) => {
  return brandSales.map(brandSale => {
    const brandSaleEntity = brandSaleEntities.find(
      brandSaleEntity =>
        brandSaleEntity.brandLineGUID === brandSale.brandLineGUID &&
        brandSaleEntity.countryId === market.marketId
    );

    if (brandSaleEntity) {
      return {
        ...brandSale,
        ...brandSaleEntity,
      };
    }

    return brandSale;
  });
};
