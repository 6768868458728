import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Action } from 'kbar';
import { useRegisterActions } from 'kbar';

export const useNavigateActions = (): void => {
  const navigate = useNavigate();

  const navActions = useMemo(
    () => [
      {
        id: 'checks',
        name: 'Go to Checks',
        keywords: 'checks',
        section: 'Navigation',
        shortcut: ['g', 'c'],
        priority: 10,
        perform: () => navigate('checks'),
      },
      {
        id: 'data',
        name: 'Go to Data',
        keywords: 'data',
        section: 'Navigation',
        shortcut: ['g', 'd'],
        priority: 10,
        perform: () => navigate('.'),
      },
    ],
    [navigate]
  );

  useRegisterActions(navActions.filter(Boolean) as Action[], [navActions]);
};
