import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';

import { useFormValidation } from 'src/hooks/useFormValidation/useFormValidation';
import type { Market } from 'src/markets/types';
import { useSyncContext } from 'src/sync/hooks/useSyncContext';
import type { OwnersEntity } from 'src/types/entity/Owners';
import type { StrictOmit } from 'src/types/MappedTypes';
import * as styles from '../styles/createOwnerDialog.styles';

export type NewOwner = StrictOmit<
  OwnersEntity,
  'approvalComment' | 'lastUpdatedDate' | 'approvalStatus'
>;

interface Props {
  defaultValue: string;
  market: Market;
  open: boolean;
  owners: OwnersEntity[];
  onCancel: () => void;
  onClose: () => void;
  onSubmit: (newOwner: NewOwner) => void;
}

interface Form {
  ownerName: string;
}

const createOwnerCopy = 'Create owner';

export const CreateRecordDialog = ({
  defaultValue,
  market,
  open,
  owners,
  onCancel,
  onClose,
  onSubmit,
}: Props) => {
  const { errors, form, handleInputChange, handleSubmit } = useFormValidation<Form>(
    {
      ownerName: defaultValue,
    },
    {
      ownerName: {
        required: {
          msg: 'Owner name is required',
          required: true,
        },
        validate: {
          msg: 'Owner name has already been taken',
          validate: updatedForm => {
            const isNotExistingOwner = owners.every(
              owner => owner.ownerName.toLowerCase() !== updatedForm.ownerName.toLowerCase()
            );

            return isNotExistingOwner;
          },
        },
      },
    }
  );

  const { createEntity } = useSyncContext();

  const handleFormSubmit = () => {
    const newOwner: NewOwner = {
      createdIn: market.marketId,
      ownerName: form.ownerName,
      ownerGUID: uuidv4(),
    };

    void createEntity('owners', newOwner);

    onSubmit(newOwner);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle fontWeight={600}>{createOwnerCopy}</DialogTitle>
      <DialogContent dividers>
        <div>
          <FormControl fullWidth>
            <FormLabel htmlFor="ownerName" sx={styles.formLabel}>
              <Typography variant="body1">
                {'Name'} - <Typography variant="caption">name of the owner</Typography>
              </Typography>
            </FormLabel>
            <TextField
              id="ownerName"
              name="ownerName"
              value={form.ownerName}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              error={Boolean(errors.ownerName)}
              helperText={errors.ownerName}
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSubmit(handleFormSubmit)}
          disabled={Object.keys(errors).length > 0}
        >
          {createOwnerCopy}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
