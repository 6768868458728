import uniqBy from 'lodash/uniqBy';

import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import type { DistributorsEntity } from 'src/types/entity/Distributors';

const MAX_DISTRIBUTORS_TO_SHOW = 20;

export const getExactMatch = (search: string, distributors: DistributorsEntity[]) =>
  distributors.filter(
    distributor => distributor.distributorName.toLowerCase() === search.toLowerCase()
  );

export const getContainsMatch = (search: string, distributors: DistributorsEntity[]) =>
  distributors.filter(distributor =>
    distributor.distributorName.toLowerCase().includes(search.toLowerCase())
  );

interface GetSearchResultsParams {
  search: string;
  distributors: DistributorsEntity[];
  brandSale: DenormalizedBrandSales | null;
}

export const getSearchResults = ({ distributors, brandSale, search }: GetSearchResultsParams) => {
  if (distributors.length && brandSale && search) {
    const filteredDistributors = [
      ...getExactMatch(search, distributors),
      ...getContainsMatch(search, distributors),
    ].filter(({ distributorGUID }) => distributorGUID !== brandSale.distributorGUID);

    const dedupedFilteredDistributors = uniqBy(filteredDistributors, 'distributorGUID');
    return dedupedFilteredDistributors.slice(0, MAX_DISTRIBUTORS_TO_SHOW);
  }

  return [];
};
