import type { Entity } from '../../types/Entity';
import { createObservable } from '../functions';
import {
  type BroadcastMessage,
  BroadcastMessageType,
  type QueueManagerState,
  type SyncManagerState,
  type Transaction,
} from '../types';

export class BroadcastManager {
  /**
   * An observable to monitor syncing manager status changes on other tabs.
   */
  public readonly onSyncManagerStateChange = createObservable<SyncManagerState>();

  /**
   * An observable to monitor syncing manager percentage changes on other tabs.
   */
  public readonly onSyncPercentageChange = createObservable<number>();

  /**
   * An observable to monitor queue manager status changes on other tabs.
   */
  public readonly onQueueManagerStateChange = createObservable<QueueManagerState>();

  /**
   * An observable to monitor receiving new entities during delta syncinc on other tabs.
   */
  public readonly onEntitiesReceived = createObservable<Record<string, Entity[]>>();

  /**
   * An observable to monitor new transaction creation on other tabs.
   */
  public readonly onTransactionCreated = createObservable<Transaction>();

  /**
   * An observable to monitor new transaction removal on other tabs.
   */
  public readonly onTransactionRemoved = createObservable<Transaction>();

  /**
   * An observable to monitor disconnect action on other tabs.
   */
  public readonly onDisconnect = createObservable<boolean>();

  /**
   * An observable to monitor connect action on other tabs.
   */
  public readonly OnConnect = createObservable<boolean>();

  constructor(private readonly channel: BroadcastChannel) {}

  connect() {
    this.channel.addEventListener('message', (event: MessageEvent<BroadcastMessage>) => {
      switch (event.data.type) {
        case BroadcastMessageType.SyncManagerStateChange:
          this.onSyncManagerStateChange.notify(event.data.payload);
          break;
        case BroadcastMessageType.SyncPercentageChange:
          this.onSyncPercentageChange.notify(event.data.payload);
          break;
        case BroadcastMessageType.QueueManagerStateChange:
          this.onQueueManagerStateChange.notify(event.data.payload);
          break;
        case BroadcastMessageType.EntitiesReceived:
          this.onEntitiesReceived.notify(event.data.payload);
          break;
        case BroadcastMessageType.TransactionCreated:
          this.onTransactionCreated.notify(event.data.payload);
          break;
        case BroadcastMessageType.TransactionRemoved:
          this.onTransactionRemoved.notify(event.data.payload);
          break;
        case BroadcastMessageType.Disconnect:
          this.onDisconnect.notify(true);
          break;
      }
    });
  }

  postMessage(message: BroadcastMessage) {
    this.channel.postMessage(message);
  }
}
