import type { HTMLAttributes } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import * as styles from '../styles/brandLineSearch';

interface Props extends HTMLAttributes<HTMLLIElement> {
  brandLine: DenormalizedBrandLine;
  selectedOption: DenormalizedBrandLine | null;
  onChange: () => void;
}

const RenderField = (props: { label: string; value: string }) => (
  <Box sx={{ display: 'flex', flexFlow: 'row nowrap' }}>
    <Box component="span" sx={{ flex: 1 }}>
      <Typography variant="caption">{props.label}:</Typography>
    </Box>
    <Box component="span" sx={{ flex: 3.7 }}>
      <Typography variant="caption">{props.value}</Typography>
    </Box>
  </Box>
);

export const BrandLineSearch = ({
  brandLine,
  selectedOption,
  onChange,
  ...liElementProps
}: Props) => {
  const isChecked = () => {
    return brandLine.brandLineName === selectedOption?.brandLineName;
  };

  return (
    <li {...liElementProps} style={styles.optionLiContainer}>
      <Box sx={styles.optionWrapper}>
        <Radio
          size="small"
          value={brandLine.brandLineName}
          checked={isChecked()}
          onChange={onChange}
        />
        <Box sx={styles.recordContext}>
          <Typography variant="body1" fontWeight={600} sx={styles.optionOwnerName}>
            {brandLine.brandLineName}
          </Typography>
          <RenderField label="Display name" value={brandLine.brandLineDisplayName} />
          <RenderField label="Category" value={brandLine.category5Name} />
          <RenderField label="Brand" value={brandLine.brandName} />
          <RenderField label="Owner" value={brandLine.ownerName} />
          <RenderField label="Origin" value={brandLine.originName} />
        </Box>
      </Box>
    </li>
  );
};
