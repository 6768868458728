import { Container, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { path } from 'ramda';

import type { unsafe_RowNode } from 'src/components/DataGrid';
import { Button, ModalHeader } from '../../css/styled-components';
import type { Sale, SaleApi } from '../../shared';
import { useSyncContext } from '../../sync';

interface Props {
  node: Partial<unsafe_RowNode> | undefined;
  closeModal: () => void;
  countryName?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  brandLineDisplayName?: string;
}

export const doesSaleHaveData = (sale: Sale): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!sale.yearData) return false;
  const years = Object.keys(sale.yearData);
  if (years.length === 0) return false;

  let hasData = false;
  let i = 0;
  do {
    const year = years[i];
    // @ts-expect-error: legacy code
    if (path(['yearData', year, 'volume'], sale)) hasData = true;
    // @ts-expect-error: legacy code
    if (path(['yearData', year, 'price'], sale)) hasData = true;
    i += 1;
  } while (!hasData && i < years.length);
  return hasData;
};

const doesNewSaleHaveData = (sale: SaleApi): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!sale.volume && !sale.price) return false;

  for (const key of Object.keys(sale.volume)) {
    if (sale.volume[key as unknown as number]) {
      return true;
    }
  }

  for (const key of Object.keys(sale.price)) {
    if (sale.price[key as unknown as number]) {
      return true;
    }
  }

  return false;
};

const DeleteSaleModal = ({ node, closeModal, countryName, brandLineDisplayName }: Props) => {
  const { deleteEntity } = useSyncContext();

  if (!node) return null;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const sale: SaleApi = node.data;

  const hasData = doesNewSaleHaveData(sale);

  const handleOk = () => {
    void deleteEntity('brandSales', sale.saleGUID);
    closeModal();
  };

  return (
    <Modal show centered animation={false} backdrop="static">
      <ModalHeader>Delete brand sale: {brandLineDisplayName}</ModalHeader>
      <Container>
        <Modal.Body>
          {hasData && <p>You can not delete this sale because it has volume and/or price data</p>}
          {!hasData && (
            <>
              Are you sure you want to delete this sale line?
              <br />
              <strong>
                {countryName}
                &nbsp;-&nbsp;
                {brandLineDisplayName}
              </strong>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="link" onClick={closeModal}>
            Cancel
          </Button>
          {!hasData && (
            <Button type="submit" variant="primary" disabled={hasData} onClick={handleOk}>
              Ok
            </Button>
          )}
        </Modal.Footer>
      </Container>
    </Modal>
  );
};

export default connect()(DeleteSaleModal);
