import { find, propEq, test } from 'ramda';

import type { CountryList, Sale } from '../../shared';
import type { ValidationFunction } from '../../types/validateTypes';
import { initialValidationParams } from '../../types/validateTypes';

export enum LocalOrImported {
  Local = 'Local',
  Imported = 'Imported',
}

type IsLocalOrImportedValueInvalid = (
  value: string,
  countries: CountryList,
  countryId: string,
  originName: string
) => boolean;

export const isLocalOrImportedValueInvalid: IsLocalOrImportedValueInvalid = (
  value,
  countries,
  countryId,
  originName
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const isValueLocal = value === LocalOrImported.Local;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const isValueImported = value === LocalOrImported.Imported;

  // value parsed/transformed prior to validation; any other input is invalid
  if (!isValueLocal && !isValueImported) return true;

  const internationalOrUndefined = /^(international|undefined)$/i;
  const isOriginInternationalOrUndefined =
    !originName || test(internationalOrUndefined, originName);

  // either value is valid
  if (isOriginInternationalOrUndefined) return false;

  const countryDemonym = find(propEq('id', countryId), countries)?.demonym;
  const originDemonym = find(propEq('demonym', originName), countries)?.demonym;

  // nothing to validate against
  if (!countryDemonym || !originDemonym) return false;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const isSaleLocal = countryDemonym?.toLowerCase() === originDemonym?.toLowerCase();

  const localInvalid = isSaleLocal && !isValueLocal;
  const importedInvalid = !isSaleLocal && !isValueImported;

  return localInvalid || importedInvalid;
};

type IsLocalOrImportedInvalid = (
  countries: CountryList | undefined,
  value?: string
) => (sale: Sale) => boolean;

export const isLocalOrImportedInvalid: IsLocalOrImportedInvalid = (countries, value) => sale => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!sale || !countries) return false;

  const { originName, countryId, localOrImported } = sale;

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const valueToValidate = value || localOrImported;

  //  FIXME:  Don't force countryId to string.
  const isInvalid = isLocalOrImportedValueInvalid(
    valueToValidate,
    countries,
    countryId.toString(),
    originName
  );
  return isInvalid;
};

export const validateLocalOrImported: ValidationFunction =
  ({ countries } = initialValidationParams) =>
  sale => {
    if (!sale) return { isInvalid: false };

    const isInvalid: boolean = isLocalOrImportedInvalid(countries)(sale);
    return { isInvalid };
  };
