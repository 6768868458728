import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {
  CaptureConsole as CaptureConsoleIntegration,
  Offline as OfflineIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { CHECK_FAIL_ERROR_MESSAGE } from 'src/checks/hooks/useTriggerCheckRun';

export const configureSentry = () => {
  if (`${process.env.REACT_APP_SENTRY_DSN}`.startsWith('https://')) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_MODE ?? 'development',
      // @TODO - remove the non-null assertion when Sentry allows undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      release: process.env.REACT_APP_REVISION!,
      integrations: [
        ...(process.env.REACT_APP_MODE === 'production'
          ? [
              new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                  useEffect,
                  useLocation,
                  useNavigationType,
                  createRoutesFromChildren,
                  matchRoutes
                ),
              }),
            ]
          : []),
        new CaptureConsoleIntegration({ levels: ['error', 'warn'] }),
        new OfflineIntegration(),
        new ReportingObserverIntegration(),
      ],
      tracesSampleRate: 1.0,
      normalizeDepth: 10,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: [/AppException/],
      beforeSend: function (event) {
        const errorDetails = event.contexts?.['error_details'];

        if (errorDetails?.['syncingException'] === true && errorDetails['transactionId']) {
          event.fingerprint = ['{{ default }}', String(errorDetails['transactionId'])];
        }

        if (event.message?.includes(CHECK_FAIL_ERROR_MESSAGE) && errorDetails) {
          event.fingerprint = [
            '{{ default }}',
            String(errorDetails['marketId']),
            String(errorDetails['checkId']),
          ];
        }

        return event;
      },
    });
  }
};
