import type { unsafe_RowNode } from 'src/components/DataGrid';
import { COLLECTED_YEAR, FORECASTING_YEARS_MODIFIER } from 'src/constants';
import { BrandLineTypes } from 'src/shared';
import type { Entity } from 'src/types/Entity';
import type { Field } from 'src/views/types';

export const excludeForecasts = (rowNode: unsafe_RowNode<Entity>) => {
  const { data } = rowNode;
  if (!data) return true;

  return data['brandLineTypeId'] !== BrandLineTypes.FORECAST;
};

export const forecastsOnly = (rowNode: unsafe_RowNode<Entity>) => {
  const { data } = rowNode;
  if (!data) return true;
  return data['brandLineTypeId'] === BrandLineTypes.FORECAST;
};

export const isForecastYearColumn = ({
  headerName: year,
  periodiseOptions,
}: Pick<Field, 'headerName' | 'periodiseOptions'>) => {
  const { forecastYearsToAdd = FORECASTING_YEARS_MODIFIER } = periodiseOptions ?? {};
  const isAfterCollectedYear = Number(year) > COLLECTED_YEAR;
  const isWithinForecastYearSpan = Number(year) <= COLLECTED_YEAR + forecastYearsToAdd;

  return isAfterCollectedYear && isWithinForecastYearSpan;
};

const isYearColumn = ({ field }: Field) =>
  field.includes('volume.') || field.includes('percentage.');

export const getSuffixedForecastYear = (year: string | number) => year.toString().concat('f');

export const getColumnsWithSuffixedForecastYearHeaders = (columns: Field[]): Field[] => {
  return columns.map((column): Field => {
    const isVolumeForecastYear = isYearColumn(column) && isForecastYearColumn(column);

    if (isVolumeForecastYear) {
      return {
        ...column,
        headerName: getSuffixedForecastYear(column.headerName),
      };
    }

    return column;
  });
};
