import type { FC, Ref } from 'react';
import Select, { components } from 'react-select';

import { baseDropdownStyles } from '../../css/react-select-styles';
import { useScrollIntoView } from '../../utils/utilHooks';
import DropdownIndicator from './DropdownIndicator';
import { getSelectValue, getSelectValueFromGroupedOptions } from './helpers';
import type { DropdownOptions } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BaseOption = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { isSelected } = props;
  const ref = useScrollIntoView(!!isSelected);
  return <components.Option {...props} innerRef={ref} />;
};

const DefaultGroupHeading = () => null;

interface DropdownProps {
  ariaLabelledBy?: string;
  className?: string;
  group?: FC;
  groupHeading?: FC;
  id?: string;
  innerRef?: Ref<HTMLElement>;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  groupedOptions?: boolean;
  noOptionsMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (item: any) => void;
  option?: FC;
  options: DropdownOptions;
  placeholder: string;
  selected?: string | number | undefined;
  styles?: object;
}

interface SelectComponents {
  Group?: FC;
  GroupHeading?: FC;
  Option?: FC;
}

const getSelectComponents = (group?: FC, groupHeading?: FC, option?: FC): SelectComponents => {
  const selectComponents: SelectComponents = { Option: BaseOption };
  if (group) {
    selectComponents.Group = group;
    selectComponents.GroupHeading = groupHeading ?? DefaultGroupHeading;
  }
  if (option) selectComponents.Option = option;
  return selectComponents;
};

export const Dropdown = ({
  ariaLabelledBy = '',
  className = '',
  group,
  groupHeading,
  id = '',
  innerRef,
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  isSearchable = true,
  groupedOptions,
  noOptionsMessage,
  onSelect,
  option,
  options,
  placeholder,
  selected,
  styles,
}: DropdownProps) => {
  const selectedValue = groupedOptions
    ? getSelectValueFromGroupedOptions(selected, options)
    : getSelectValue(selected, options);

  return (
    <Select
      inputId={id ? `${id}_input` : ''}
      id={id}
      key={`select_${selected ?? ''}`}
      innerRef={innerRef}
      className={className}
      placeholder={isLoading ? null : placeholder}
      options={options}
      isDisabled={isDisabled}
      isLoading={isLoading}
      styles={styles ?? baseDropdownStyles}
      components={{ ...getSelectComponents(group, groupHeading, option), DropdownIndicator }}
      onChange={onSelect}
      value={selectedValue}
      isSearchable={isSearchable}
      isClearable={isClearable}
      aria-labelledby={ariaLabelledBy}
      escapeClearsValue
      noOptionsMessage={() => noOptionsMessage ?? null}
      openMenuOnFocus
    />
  );
};
