import { useOutletContext } from 'react-router-dom';

import type { Market } from '../../markets';
import type { Table } from '../types';

interface TableContext {
  market: Market;
  table: Table;
}

export function useTable() {
  return useOutletContext<TableContext>();
}
