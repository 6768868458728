import { type MutableRefObject, useCallback } from 'react';
import type { ColumnPinnedEvent, ColumnState, GridApi } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { GridOptions, PinnedColumnsModel, Row } from '../../types';
import { isColGroupDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useColumnPinningFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  gridApiRef: MutableRefObject<GridApi<R> | undefined>,
  props: Pick<
    DataGridProps<R>,
    'columnGrouping' | 'columns' | 'onPinnedColumnsChange' | 'pinnedColumns'
  >
) => {
  const logger = useGridLogger(gridRef, 'useColumnPinningFeature');

  const { columnGrouping = [], columns, onPinnedColumnsChange, pinnedColumns = {} } = props;

  const handlePinnedColumnsChange = useCallback(
    (e: ColumnPinnedEvent) => {
      const pinnedColumnsModel: PinnedColumnsModel = {
        left: [],
        right: [],
      };
      const processedFields: Record<string, boolean> = {};

      e.api.getDisplayedLeftColumns().forEach(col => {
        let field: string;

        const parent = col.getOriginalParent();
        if (parent && Object.keys(parent.getColGroupDef() ?? {}).length > 0) {
          field = parent.getGroupId();
        } else {
          field = col.getId();
        }

        if (processedFields[field]) return;
        pinnedColumnsModel.left?.push(field);
        processedFields[field] = true;
      });

      e.api.getDisplayedRightColumns().forEach(col => {
        let field: string;

        const parent = col.getOriginalParent();
        if (parent && Object.keys(parent.getColGroupDef() ?? {}).length > 0) {
          field = parent.getGroupId();
        } else {
          field = col.getId();
        }

        if (processedFields[field]) return;
        pinnedColumnsModel.right?.push(field);
        processedFields[field] = true;
      });

      onPinnedColumnsChange?.(pinnedColumnsModel);
    },
    [onPinnedColumnsChange]
  );

  useInitialiseState(() => {
    columns.forEach(column => {
      if (column.primary) {
        const colDef = gridRef.current.columnDefs?.find(
          col => !isColGroupDef<R>(col) && col.field === column.field
        );

        if (!colDef || isColGroupDef<R>(colDef)) return;
        colDef.pinned = 'left';
      }
    });

    pinnedColumns.left?.forEach(field => {
      const colDef = gridRef.current.columnDefs?.find(col =>
        isColGroupDef<R>(col) ? col.groupId === field : col.field === field
      );

      if (!colDef) return;

      if (isColGroupDef<R>(colDef)) {
        colDef.children.forEach(leafColumn => {
          const newLeafColumn = leafColumn;

          const column = columns.find(col => col.field === leafColumn.field);
          if (column && column.pinnable !== false) {
            newLeafColumn.pinned = 'left';
          }
        });
      } else {
        if (colDef.lockPinned === false) {
          colDef.pinned = 'left';
        }
      }
    });

    pinnedColumns.right?.forEach(field => {
      const colDef = gridRef.current.columnDefs?.find(col =>
        isColGroupDef<R>(col) ? col.groupId === field : col.field === field
      );

      if (!colDef) return;

      if (isColGroupDef<R>(colDef)) {
        colDef.children.forEach(leafColumn => {
          const newLeafColumn = leafColumn;

          const column = columns.find(col => col.field === leafColumn.field);
          if (column && column.pinnable !== false) {
            newLeafColumn.pinned = 'right';
          }
        });
      } else {
        if (colDef.lockPinned === false) {
          colDef.pinned = 'right';
        }
      }
    });

    gridRef.current.onColumnPinned = handlePinnedColumnsChange;
  });

  useEffectAfterFirstRender(() => {
    logger.log('Setting pinned column model');

    const pinnedCriteria: ColumnState[] = [];

    columns.forEach(column => {
      if (column.primary) {
        pinnedCriteria.push({
          colId: column.field,
          pinned: 'left',
        });
      }
    });

    pinnedColumns.left?.forEach(field => {
      const group = columnGrouping.find(group => group.groupId === field);
      if (group) {
        group.children.forEach(leafColumn => {
          const column = columns.find(col => col.field === leafColumn.field);
          if (column && column.pinnable !== false) {
            pinnedCriteria.push({
              colId: leafColumn.field,
              pinned: 'left',
            });
          }
        });
      } else {
        const column = columns.find(col => col.field === field);
        if (column && column.pinnable !== false) {
          pinnedCriteria.push({
            colId: field,
            pinned: 'left',
          });
        }
      }
    });

    pinnedColumns.right?.forEach(field => {
      const group = columnGrouping.find(group => group.groupId === field);
      if (group) {
        group.children.forEach(leafColumn => {
          const column = columns.find(col => col.field === leafColumn.field);
          if (column && column.pinnable !== false) {
            pinnedCriteria.push({
              colId: leafColumn.field,
              pinned: 'right',
            });
          }
        });
      } else {
        const column = columns.find(col => col.field === field);
        if (column && column.pinnable !== false) {
          pinnedCriteria.push({
            colId: field,
            pinned: 'right',
          });
        }
      }
    });

    gridApiRef.current?.applyColumnState({
      state: pinnedCriteria,
      applyOrder: true,
      defaultState: {
        pinned: null,
      },
    });
  }, [columns, columnGrouping, pinnedColumns]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onPinnedColumnsChange` is not supported.');
  }, [onPinnedColumnsChange]);
};
