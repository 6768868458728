import { useEffect, useRef } from 'react';

export const useTitle = (title: string): void => {
  const prevTitleRef = useRef<string>(document.title);

  useEffect(() => {
    document.title = title;
    const prevTitle = prevTitleRef.current;

    return () => {
      document.title = prevTitle;
    };
  }, [title]);
};
