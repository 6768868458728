import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { RecordDialog } from 'src/components/RecordDialog';
import { useFormValidation } from 'src/hooks/useFormValidation/useFormValidation';
import { useRecords } from 'src/records/hooks/useRecords';
import { isTextInputValid } from 'src/records/utils/modals/recordUtils';
import { useAppDispatch, useAppSelector } from 'src/store';
import { renameRecordDialogActions } from 'src/store/features/renameRecordDialog/slice';
import type { RecordKind } from 'src/store/features/types/BrandLineDialog';
import { useSyncContext } from 'src/sync/hooks/useSyncContext';
import type { EntityType } from 'src/types/Entity';
import { RenameConfirmationDialog } from './components/RenameConfirmationDialog';
import { getExistingRecordNames } from './helpers';
import * as styles from './styles';
import type { Record, Records } from './types';

const getRecordTableName = (recordKind: RecordKind | null): EntityType | '' => {
  if (recordKind) {
    return `${recordKind}s` as const;
  }

  return '';
};

const error = {
  required: 'Name is required',
  invalid: 'Name is invalid',
  taken: 'Name has already been taken',
} as const;

interface Form {
  name: string;
}

export const RenameRecordDialog = () => {
  const brandLine = useAppSelector(state => state.renameRecordDialog.brandLine);
  const isOpen = useAppSelector(state => state.renameRecordDialog.isOpen);
  const recordKind = useAppSelector(state => state.renameRecordDialog.recordKind);
  const [modalState, setModalState] = useState<'renaming' | 'confirming-name'>('renaming');
  const dispatch = useAppDispatch();
  const { updateEntity } = useSyncContext();
  const records = useRecords<Record>(getRecordTableName(recordKind)) as Records;
  const recordNames = useMemo(
    () => getExistingRecordNames(recordKind, records),
    [recordKind, records]
  );

  const { errors, form, handleInputChange, resetForm, handleSubmit, setFormValue } =
    useFormValidation<Form>(
      {
        name: '',
      },
      {
        name: {
          required: { required: true, msg: error.required },
          validate: {
            validate: updatedForm => {
              const isTaken = recordNames.some(
                name => name.toLowerCase() === updatedForm.name.toLowerCase()
              );

              if (isTaken) {
                return error.taken;
              }

              const textEntry = `${recordKind!}Name` as const;

              if (!isTextInputValid(updatedForm.name, textEntry)) {
                return error.invalid;
              }

              return true;
            },
          },
        },
      }
    );

  useEffect(() => {
    if (brandLine != null) {
      const initialNameToRender =
        recordKind === 'brand' ? brandLine.brandDisplayName : brandLine.ownerName;
      setFormValue({ value: initialNameToRender, name: 'name' });
    }
  }, [brandLine, recordKind, setFormValue]);

  const resetState = () => {
    resetForm();
    setModalState('renaming');
  };

  const handleClose = () => {
    resetState();
    dispatch(renameRecordDialogActions.close());
  };

  const getGUID = () => {
    if (recordKind === 'brand') {
      return brandLine!.brandGUID;
    }

    return brandLine!.ownerGUID;
  };

  const handleRename = () => {
    const entityKey = recordKind === 'brand' ? 'brandDisplayName' : 'ownerName';
    void updateEntity(getRecordTableName(recordKind), getGUID(), {
      [entityKey]: form.name,
    });

    handleClose();
  };

  if (!isOpen || recordKind == null) {
    return null;
  }

  if (modalState === 'confirming-name') {
    return (
      <RenameConfirmationDialog
        open={isOpen}
        recordKind={recordKind}
        GUID={getGUID()}
        onCancel={() => setModalState('renaming')}
        onSubmit={() => handleSubmit(handleRename)}
      />
    );
  }

  return (
    <RecordDialog
      open={isOpen}
      disabled={Object.keys(errors).length > 0}
      title={`Rename ${recordKind}`}
      submitButtonText={`Rename ${recordKind}`}
      onClose={handleClose}
      onSubmit={() => setModalState('confirming-name')}
    >
      <DialogContent>
        <FormControl fullWidth>
          {brandLine && recordKind === 'brand' && (
            <Box display="flex" sx={{ mb: 2 }}>
              <Typography variant="caption">Brand:&nbsp;{brandLine.brandName}</Typography>
            </Box>
          )}
          <FormLabel htmlFor={'name'} required>
            {recordKind === 'brand' ? 'Display name' : 'Name'}
          </FormLabel>
          <TextField
            id="name"
            variant="outlined"
            name="name"
            type="text"
            fullWidth
            required
            value={form.name}
            error={Boolean(errors.name)}
            helperText={errors.name}
            FormHelperTextProps={{ style: styles.formTextFieldHelper }}
            onChange={handleInputChange}
            onBlur={handleInputChange}
          />
        </FormControl>
      </DialogContent>
    </RecordDialog>
  );
};
