import type { FC } from 'react';
import { Form } from 'react-bootstrap';

import { ValidNumberField } from 'src/components/UserEnteries';
import { NumberEntryEnum } from 'src/shared';
import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildAgeInYearsHandler } from 'src/utils/createBrandLineFormHandlers';
import { Label, WarningDiv } from './styledComponents';

const CreateBrandLineAgeInYearsSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  if (!formState.category) return null;

  return (
    <Form.Group>
      <Label>Age</Label>
      <Label style={{ fontSize: '12px', fontStyle: 'italic' }}>&nbsp;&mdash;&nbsp;year-old</Label>

      <ValidNumberField
        numberEntryEnum={NumberEntryEnum.Age}
        value={formState.ageInYears.toString()}
        onChange={e => buildAgeInYearsHandler(dispatchFormAction)(e)}
        disabled={formState.attributesDisabled}
        data-testid="create_brandline_age_in_years_input"
        id="create_brandline_age_in_years_input"
      />
      {formState.errorMessages['ageInYears'] && (
        <WarningDiv>{formState.errorMessages['ageInYears']}</WarningDiv>
      )}
    </Form.Group>
  );
};

export default CreateBrandLineAgeInYearsSection;
