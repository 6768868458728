import type { Dispatch, FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { MaltRegionsEntity } from 'src/types/entity/MaltRegions';
import { buildNumericValuesHandler } from 'src/utils/createBrandLineFormHandlers';
import {
  mapMaltRegionValue,
  mapMaltRegionValues,
  mapNumericsToValues,
} from 'src/utils/createBrandLineFormMappers';
import type { CreateBrandLineState } from 'src/utils/createBrandLineReducer';
import { Label, WarningDiv } from './styledComponents';

interface CreateBrandLineMaltRegionSectionProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchFormAction: Dispatch<any>;
  formState: CreateBrandLineState;
  maltRegions: MaltRegionsEntity[];
}

const CreateBrandLineMaltRegionSection: FC<CreateBrandLineMaltRegionSectionProps> = ({
  dispatchFormAction,
  formState,
  maltRegions,
}) => {
  const maltRegionValues = mapMaltRegionValues(maltRegions);

  if (!formState.category) return null;

  return (
    <Form.Group>
      <Label>Malt Scotch Region</Label>
      <Select
        options={mapNumericsToValues(maltRegionValues)}
        value={mapMaltRegionValue(formState.maltRegion, maltRegions)}
        onChange={buildNumericValuesHandler(dispatchFormAction, 'maltRegion')}
        isDisabled={formState.attributesDisabled}
        maxMenuHeight={200}
        data-testid="create_brand_line_maltregion_dropdown"
        id="create_brand_line_maltregion_dropdown"
      />
      {formState.errorMessages['maltScotch'] && (
        <WarningDiv>{formState.errorMessages['maltScotch']}</WarningDiv>
      )}
    </Form.Group>
  );
};

export default CreateBrandLineMaltRegionSection;
