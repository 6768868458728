import { useSyncContext } from 'src/sync/hooks';
import type { Table, View, ViewSection } from '../types';

export function useViews(table: Table) {
  const { getEntities } = useSyncContext();

  const allViews = getEntities('views') as unknown as View[];
  const views = allViews
    .filter(view => view.tableId === table.tableId)
    .sort(
      ({ displayOrder }, { displayOrder: otherDisplayOrder }) => displayOrder - otherDisplayOrder
    );

  const allViewSections = getEntities('viewSections') as unknown as ViewSection[];
  const sections = allViewSections
    .filter(section => section.tableId === table.tableId)
    .sort(
      ({ displayOrder }, { displayOrder: otherDisplayOrder }) => displayOrder - otherDisplayOrder
    );

  return { sections, views };
}
