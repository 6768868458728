import type { HTMLAttributes } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import * as styles from '../styles/searchSuggestion.styles';

interface Props extends HTMLAttributes<HTMLLIElement> {
  brandName: string;
  ownerName: string;
  checked: boolean;
  onChange: () => void;
}

export const SearchSuggestion = ({
  brandName,
  ownerName,
  checked,
  onChange,
  ...liElementProps
}: Props) => {
  return (
    <li {...liElementProps} style={styles.optionLiContainer}>
      <Box sx={styles.optionWrapper}>
        <Radio size="small" value={brandName} checked={checked} onChange={onChange} />

        <Typography variant="body1" fontWeight={600} sx={styles.optionOwnerName}>
          {ownerName}
        </Typography>
      </Box>
    </li>
  );
};
