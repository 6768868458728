import type { FC } from 'react';

import FilterSelectFormGroup from 'src/components/Reusable/FilterSelectFormGroup';
import type { Brand } from 'src/shared';
import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildParentBrandHandler } from 'src/utils/createBrandLineFormHandlers';
import type { SelectItem } from 'src/utils/fiterSelectUtils';
import CreateBrandLineParentBrandCreateNew from './CreateBrandLineParentBrandCreateNew';

const CreateBrandLineParentBrandSection: FC<
  CreateBrandLineSectionProps & {
    parentBrandOptions: SelectItem[];
    ownerOptions: SelectItem[];
    brands: Brand[];
  }
> = ({ dispatchFormAction, formState, parentBrandOptions, ownerOptions, brands }) => {
  if (!formState.category) return null;

  return (
    <FilterSelectFormGroup
      property="parentBrand"
      label="Parent brand"
      options={parentBrandOptions}
      onSelect={buildParentBrandHandler(dispatchFormAction, ownerOptions, brands)}
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      length={brands?.length}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      selected={formState.parentBrand}
      disabled={formState.parentBrandDisabled}
      formState={formState}
      extra={
        formState.isNewParentBrand && (
          <CreateBrandLineParentBrandCreateNew
            dispatchFormAction={dispatchFormAction}
            formState={formState}
          />
        )
      }
      dataTestId="create_brand_line_parent_brand"
    />
  );
};

export default CreateBrandLineParentBrandSection;
