import type { Entity } from '../../../types/Entity';

export const getAffectedBrandLines = (
  entitiesLookup: Record<string, Map<string, Entity>>,
  data: Record<string, Entity[]>
) => {
  const maltRegions = data['maltRegions'] ?? [];
  const alcoholicStrengths = data['alcoholicStrengths'] ?? [];
  const ageStyles = data['ageStyles'] ?? [];
  const origins = data['origins'] ?? [];
  const owners = data['owners'] ?? [];
  const brands = data['brands'] ?? [];
  const brandLines = data['brandLines'] ?? [];
  const categories = data['categories'] ?? [];
  let cachedBrandLines: Entity[] = [];
  let cachedBrands: Entity[] = [];
  let cachedCategories: Entity[] = [];

  const brandsLinesLookupData = entitiesLookup['brandLines'];
  if (brandsLinesLookupData) {
    cachedBrandLines = [...brandsLinesLookupData.values()];
  }

  const brandsLookupData = entitiesLookup['brands'];
  if (brandsLookupData) {
    cachedBrands = [...brandsLookupData.values()];
  }

  const categoriesLookupData = entitiesLookup['categories'];
  if (categoriesLookupData) {
    cachedCategories = [...categoriesLookupData.values()];
  }

  categories.forEach(category => {
    const level = category['level'] as number;
    let parentCategoryIds: number[] = [category['id'] as number];

    if (level !== 5) {
      for (let index = level + 1; index <= 5; index++) {
        parentCategoryIds = cachedCategories
          .filter(
            item =>
              item['level'] === index && parentCategoryIds.includes(item['parentId'] as number)
          )
          .map(item => item['id'] as number);
      }
    }

    brandLines.push(
      ...cachedBrandLines.filter(item => parentCategoryIds.includes(item['category5Id'] as number))
    );
  });

  // if there are any changes on maltRegions find which brand lines belong to these malt regions
  maltRegions.forEach(maltRegion => {
    brandLines.push(
      ...cachedBrandLines.filter(item => item['maltRegionId'] === maltRegion['maltRegionId'])
    );
  });

  // if there are any changes on alcoholicStrengths find which brand lines belong to these age styles.
  alcoholicStrengths.forEach(alcoholicStrength => {
    brandLines.push(
      ...cachedBrandLines.filter(
        item => item['alcoholicStrengthId'] === alcoholicStrength['alcoholicStrengthId']
      )
    );
  });

  // if there are any changes on ageStyles find which brand lines belong to these age styles.
  ageStyles.forEach(ageStyle => {
    brandLines.push(...cachedBrandLines.filter(item => item['ageId'] === ageStyle['ageId']));
  });

  // if there are any changes on origins find which brand lines belong to these origin.
  origins.forEach(origin => {
    brandLines.push(...cachedBrandLines.filter(item => item['originId'] === origin['originId']));
  });

  // if there are any changes on brands find which brand lines belong to these owners.
  owners.forEach(owner => {
    brands.push(...cachedBrands.filter(item => item['ownerGUID'] === owner['ownerGUID']));
  });

  // if there are any changes on brands find which brand lines belong to these brands.
  brands.forEach(brand => {
    brandLines.push(...cachedBrandLines.filter(item => item['brandGUID'] === brand['brandGUID']));
  });

  // remove duplicated records and return back.
  return Array.from(new Set(brandLines));
};
