import type { Row, unsafe_GridApi, unsafe_RowNode } from 'src/components/DataGrid';
import { type Dictionary } from 'src/types/MappedTypes';
import { BrandLineTypes } from '../shared/types';
import type { Entity } from '../types/Entity';

const getOtherSiblingNode = (siblings: unsafe_RowNode[]) => {
  return siblings.find(row => {
    const rowData = row.data as Row;

    return rowData['brandLineTypeId'] === BrandLineTypes.OTHER;
  });
};

export const lockTotals = (
  api: unsafe_GridApi,
  node: unsafe_RowNode,
  field: string,
  newValue: string | number,
  oldValue: number | undefined,
  primaryField: string
): [string, Entity][] | undefined => {
  const lockTotalsTransaction: Row[] = [];
  const cellToUpdate: unsafe_RowNode | undefined = node;
  const newValuePure = parseFloat(newValue.toString().toLowerCase().replace('=l', ''));
  const newOldValue = oldValue ?? 0;
  const volumesPrefix = field.split('.')[0];
  const year = field.split('.')[1];
  const isValidNumberValue = typeof newValuePure === 'number' && !Number.isNaN(newValuePure);
  const groupSiblings: unsafe_RowNode[] = node.parent?.childrenAfterSort ?? [];
  const hasSingleOtherLine =
    groupSiblings.filter(s => (s.data as Row)['brandLineTypeId'] === BrandLineTypes.OTHER)
      .length === 1;

  if (hasSingleOtherLine && isValidNumberValue && volumesPrefix && year) {
    const cellToUpdateRow: Row = cellToUpdate.data as Row;

    const othersNode = getOtherSiblingNode(groupSiblings);
    const othersRow = othersNode?.data as Row;

    if (othersRow['category5Id'] !== cellToUpdateRow['category5Id']) {
      console.warn('Invalid data set - lock totals used for different category5Ids');
    }

    if (othersNode && cellToUpdateRow['brandLineTypeId'] !== BrandLineTypes.OTHER) {
      const othersVolumeObject = othersRow[volumesPrefix] as Dictionary;

      const newOtherVolume =
        Number(othersVolumeObject[year] ?? 0) - Number((newValuePure - newOldValue).toFixed(2));

      if (newOtherVolume >= 0) {
        const cellToUpdateVolumeObject = cellToUpdateRow[volumesPrefix] as Dictionary;
        cellToUpdateVolumeObject[year] = newValuePure;
        othersVolumeObject[year] = newOtherVolume;
        lockTotalsTransaction.push(cellToUpdateRow, othersRow);

        api.applyTransactionAsync({ update: lockTotalsTransaction });

        return [
          [
            String(cellToUpdateRow[primaryField]),
            {
              [volumesPrefix]: {
                [year]: newValuePure || null,
              },
            },
          ],
          [
            String(othersRow[primaryField]),
            {
              [volumesPrefix]: {
                [year]: newOtherVolume || null,
              },
            },
          ],
        ];
      } else {
        /*
            TODO: AG-GRID is supporting only one color for flashing...
            so if for red flashing, swapping css values live should be done.

            A GitHub issue was created to keep track of this:
            https://github.com/theiwsr/collector-web/issues/342
          */
        api.flashCells({ rowNodes: [othersNode], columns: [`${volumesPrefix}.${year}`] });
      }
    }
  }
  return undefined;
};
