/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { append, clone, insert, lensPath, pathOr, pipe, remove, set } from 'ramda';

import type { BrandLine } from 'src/shared';
import {
  type BrandLineAddAction,
  BrandLineAddActionType,
} from 'src/store/features/brandLineAdd/actions';
import type { DistributorOption } from 'src/types/DistributorOption';
import { type BrandLineFormItem, makeEmptyItem, ModalState } from 'src/utils/addBrandLineUtils';
import isDeepEqual from 'src/utils/isDeepEqual';

export interface BrandLineAddState {
  modalState?: ModalState | undefined;
  modalVisible?: boolean | undefined;
  step1?: { selected: BrandLine[]; preselectedCategory: string | undefined } | undefined;
  step2?: { formState: BrandLineFormItem[] } | undefined;
  create?: {
    parentBrands?: NewFormItem[] | undefined;
    brandOwners?: NewFormItem[] | undefined;
    brandLines?: BrandLine[] | undefined;
    distributors?: DistributorOption[] | undefined;
  };
}

export interface NewFormItem {
  value: string;
  label: string;
  isNew: boolean;
  search: string;
}

const selectedPath = ['step1', 'selected'];
const step1PreselectedCategoryPath = ['step1', 'preselectedCategory'];

const step2FormStatePath = ['step2', 'formState'];
const getStep2FormStateItemPath = (index: number) => ['step2', 'formState', index];

const addToList = (state: any, item: any, path: any[]) => {
  const list = pathOr([], path, state);
  const existsInList = list.some((p: any) => isDeepEqual(p, item));
  if (!existsInList) return set(lensPath(path), append(item, list), state);
  return state;
};

const removeFromList = (state: any, id: string, path: any[]) => {
  const list = pathOr([], path, state);
  const index = list.findIndex((item: any) => item.value === id);
  if (index < 0) return state;
  return set(lensPath(path), remove(index, 1, list), state);
};

const brandLineAddReducer = (
  state: BrandLineAddState = {},
  action: BrandLineAddAction
): BrandLineAddState => {
  switch (action.type) {
    case BrandLineAddActionType.SHOW_MODAL: {
      return set(lensPath(['modalVisible']), true, state);
    }
    case BrandLineAddActionType.HIDE_MODAL: {
      return {};
    }

    case BrandLineAddActionType.GO_TO_CREATE: {
      if (action.isCreatingBrandLineForm)
        return set(lensPath(['modalState']), ModalState.CreateBrandLine, state);
      return set(lensPath(['modalState']), ModalState.Create, state);
    }

    case BrandLineAddActionType.GO_TO_STEP2: {
      return set(lensPath(['modalState']), ModalState.Step2, state);
    }
    case BrandLineAddActionType.GO_TO_STEP1: {
      return pipe(
        set(lensPath(['modalState']), ModalState.Step1),
        set(lensPath(step1PreselectedCategoryPath), action.preselectedCategory)
      )(state);
    }

    case BrandLineAddActionType.SELECT_BRAND_LINE: {
      const selected: BrandLine[] = pathOr([], selectedPath, state);
      const formState = pathOr([], step2FormStatePath, state) as any[];

      const brandLineToAdd = { ...action.brandLine };
      const newSelected = selected.concat(brandLineToAdd);
      const item = makeEmptyItem();
      const newFormState = formState.concat(item);
      return pipe(
        set(lensPath(selectedPath), newSelected),
        set(lensPath(step2FormStatePath), newFormState)
      )(state);
    }
    case BrandLineAddActionType.UNSELECT_BRAND_LINE: {
      const { brandLine } = action;
      const selected = pathOr([], selectedPath, state);
      const formState = pathOr([], step2FormStatePath, state) as any[];
      const index = selected.findIndex(
        (item: BrandLine) => item.brandLineGUID === brandLine.brandLineGUID
      );
      if (index >= 0) {
        const newSelected = remove(index, 1, selected);
        const newFormState = remove(index, 1, formState);
        return pipe(
          set(lensPath(selectedPath), newSelected),
          set(lensPath(step2FormStatePath), newFormState)
        )(state);
      }
      return state;
    }

    case BrandLineAddActionType.UPDATE_FORM_STATE: {
      const { index, item } = action;
      return set(lensPath(getStep2FormStateItemPath(index)), item, state);
    }
    case BrandLineAddActionType.STAGE_NEW_DISTRIBUTOR: {
      return addToList(state, action.distributor, ['create', 'distributors']);
    }

    case BrandLineAddActionType.STAGE_NEW_BRAND_LINE: {
      return addToList(state, action.brandLine, ['create', 'brandLines']);
    }
    case BrandLineAddActionType.UNSTAGE_NEW_BRAND_LINE: {
      if (state.create?.brandLines?.length) {
        const brandLineGUIDToRemove = (action as { brandLineGUID: string }).brandLineGUID;
        return {
          ...state,
          create: {
            ...state.create,
            brandLines: state.create.brandLines.filter(
              brandLine => brandLine.brandLineGUID !== brandLineGUIDToRemove
            ),
          },
        };
      }
      return state;
    }
    case BrandLineAddActionType.STAGE_NEW_PARENT_BRAND: {
      return addToList(state, action.parentBrand, ['create', 'parentBrands']);
    }
    case BrandLineAddActionType.UNSTAGE_NEW_PARENT_BRAND: {
      return removeFromList(state, action.parentbrandGUID, ['create', 'parentBrands']);
    }
    case BrandLineAddActionType.STAGE_NEW_BRAND_OWNER: {
      return addToList(state, action.brandOwner, ['create', 'brandOwners']);
    }
    case BrandLineAddActionType.UNSTAGE_NEW_BRAND_OWNER: {
      return removeFromList(state, action.brandownerGUID, ['create', 'brandOwners']);
    }
    case BrandLineAddActionType.UPDATE_STAGED_BRAND_OWNER: {
      const { brandLineGUID } = action;

      const replaceOwnerGUID = (brandLinesLensPath: string[]) => {
        const brandLines: BrandLine[] = pathOr([], brandLinesLensPath, state);
        const currBrandLine = brandLines.find(b => b.brandLineGUID === brandLineGUID);
        const newBrandLine = clone(currBrandLine);
        newBrandLine!.ownerGUID = 'new#'.concat(newBrandLine!.ownerGUID);
        const index = brandLines.findIndex(item => item.brandLineGUID === brandLineGUID);

        if (index < 0) {
          throw new Error(
            'updateStagedBrandOwner - Cannot find brandLine which should logically be there!'
          );
        }

        return insert(index, newBrandLine, remove(index, 1, brandLines));
      };

      const step1BrandLinesPath = ['step1', 'selected'];
      const createBrandLinesPath = ['create', 'brandLines'];
      const newStep1BrandLines = replaceOwnerGUID(step1BrandLinesPath);
      const newCreateBrandLines = replaceOwnerGUID(createBrandLinesPath);

      return pipe(
        set(lensPath(step1BrandLinesPath), newStep1BrandLines),
        set(lensPath(createBrandLinesPath), newCreateBrandLines)
      )(state);
    }
    case BrandLineAddActionType.UPDATE_STAGED_PARENT_BRAND: {
      const { brandLineGUID } = action;

      const replaceBrandGUID = (brandLinesLensPath: string[]) => {
        const brandLines: BrandLine[] = pathOr([], brandLinesLensPath, state);
        const currBrandLine = brandLines.find(b => b.brandLineGUID === brandLineGUID);
        const newBrandLine = clone(currBrandLine);
        newBrandLine!.brandGUID = 'new#'.concat(newBrandLine!.brandGUID);
        const index = brandLines.findIndex(item => item.brandLineGUID === brandLineGUID);

        if (index < 0) {
          throw new Error(
            'updateStagedParentBrand - Cannot find brandLine which should logically be there!'
          );
        }

        return insert(index, newBrandLine, remove(index, 1, brandLines));
      };

      const step1BrandLinesPath = ['step1', 'selected'];
      const createBrandLinesPath = ['create', 'brandLines'];
      const newStep1BrandLines = replaceBrandGUID(step1BrandLinesPath);
      const newCreateBrandLines = replaceBrandGUID(createBrandLinesPath);

      return pipe(
        set(lensPath(step1BrandLinesPath), newStep1BrandLines),
        set(lensPath(createBrandLinesPath), newCreateBrandLines)
      )(state);
    }

    default:
      return state;
  }
};

export default brandLineAddReducer;
