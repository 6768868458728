import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface NumberOfYearsToShowInputProps {
  initialValue: number;
  onChange: (newValue: number) => void;
  readOnly: boolean;
  fieldName: string;
}

export const NumberOfYearsToShowInput = ({
  initialValue,
  onChange,
  readOnly,
  fieldName,
}: NumberOfYearsToShowInputProps) => {
  const [value, setValue] = useState<number | null>(initialValue);

  const handleSubmit = () => {
    if (!value || value > 99) {
      setValue(initialValue);
    } else {
      onChange(value);
    }
  };

  return (
    <>
      {readOnly ? (
        <Box data-testid={`${fieldName}-number-of-columns-to-show-value`}>{value}</Box>
      ) : (
        <TextField
          variant="outlined"
          name="numOfYearsToShow"
          size="small"
          sx={{ width: 50, '& .MuiOutlinedInput-input': { height: '1em' } }}
          value={value}
          onChange={event => {
            const newValue = Number(event.target.value);
            if (newValue > 0) {
              setValue(newValue);
            } else {
              setValue(null);
            }
          }}
          onBlur={handleSubmit}
          onKeyDown={event => {
            if (event.key === 'Enter') handleSubmit();
          }}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]{2}',
            'data-testid': `${fieldName}-number-of-columns-to-show-input`,
          }}
        />
      )}
    </>
  );
};
