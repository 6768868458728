import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMarkets } from 'src/markets/hooks';
import { useSyncContext } from 'src/sync';

export const HomePage = () => {
  const navigate = useNavigate();
  const { cachedMarketIds, getLastVisitedMarketId } = useSyncContext();
  const markets = useMarkets();

  const market = useMemo(() => {
    // if there is not any market to display return undefined in order to display not any market found.
    if (markets.length === 0) {
      return undefined;
    }

    // get the lastest visited market
    // if any market has not been visited yet, get the first assigned market.
    // check if any market assigned to the user, if it has been assigned, direct him to first assigned market
    const marketId = getLastVisitedMarketId() ?? cachedMarketIds[0];

    // if there is not any visited market or any assignment market, get the first avaliable market to navigate.
    if (!marketId) {
      return markets[0];
    }

    return markets.find(market => market.marketId === marketId);
  }, [cachedMarketIds, getLastVisitedMarketId, markets]);

  useEffect(() => {
    navigate(market?.marketSlug ?? 'market-not-found', { replace: true });
  }, [navigate, market]);

  return null;
};
