import type { ReactNode } from 'react';
import { Form } from 'react-bootstrap';

import type { CreateBrandLineState } from '../../utils/createBrandLineReducer';
import type { SelectItem } from '../../utils/fiterSelectUtils';
import { Label, WarningDiv } from '../BrandSales/AddBrandLine/styledComponents';
import FilterSelect from './FilterSelect';

interface FilterSelectProps {
  property: string;
  label: string;
  options: SelectItem[];
  length: number;
  formState: CreateBrandLineState;
  onSelect: (selected: SelectItem) => void;
  // eslint-disable-next-line react/require-default-props
  selected?: SelectItem;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  sortValues?: boolean;
  // eslint-disable-next-line react/require-default-props
  extra?: ReactNode;
  // eslint-disable-next-line react/require-default-props
  dataTestId?: string;
}

const FilterSelectFormGroup = ({
  property,
  label,
  length,
  formState,
  options,
  onSelect,
  selected,
  disabled,
  sortValues,
  extra,
  dataTestId,
}: FilterSelectProps) => (
  <Form.Group>
    <Label>{label}</Label>
    {/* @ts-expect-error: legacy code */}
    <FilterSelect
      options={options}
      onSelect={onSelect}
      selected={selected}
      maxLength={50}
      disabled={disabled}
      sortValues={sortValues}
      dataTestId={dataTestId}
    />
    <Form.Text>{length} possible matches</Form.Text>
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
    {formState.errorMessages[property] && (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      <WarningDiv>{formState.errorMessages[property]}</WarningDiv>
    )}

    {extra ?? null}
  </Form.Group>
);

export default FilterSelectFormGroup;
