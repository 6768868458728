import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';

import { useFormValidation } from 'src/hooks/useFormValidation/useFormValidation';
import { useSyncContext } from 'src/sync/hooks/useSyncContext';
import type { DistributorsEntity } from 'src/types/entity/Distributors';
import type { StrictOmit } from 'src/types/MappedTypes';

export type NewDistributor = StrictOmit<DistributorsEntity, 'lastUpdatedDate'>;

interface Props {
  open: boolean;
  onSubmit: (newOwner: NewDistributor) => void;
  onCancel: () => void;
  onClose: () => void;
  distributors: DistributorsEntity[];
  defaultValue: string | undefined;
}

interface Form {
  distributorName: string;
}

export const CreateDistributorDialog = ({
  open,
  distributors,
  onCancel,
  onClose,
  onSubmit,
  defaultValue = '',
}: Props) => {
  const { errors, form, handleInputChange, handleSubmit } = useFormValidation<Form>(
    {
      distributorName: defaultValue,
    },
    {
      distributorName: {
        required: {
          msg: 'Distributor name is required',
          required: true,
        },
        validate: {
          msg: 'Distributor name has already been taken',
          validate: updatedForm => {
            const isNotExistingOwner = distributors.every(
              distributor =>
                distributor.distributorName.toLowerCase() !==
                updatedForm.distributorName.toLowerCase()
            );

            return isNotExistingOwner;
          },
        },
      },
    }
  );

  const { createEntity } = useSyncContext();

  const handleFormSubmit = () => {
    const newDistributor: NewDistributor = {
      distributorName: form.distributorName,
      distributorGUID: uuidv4(),
    };

    void createEntity('distributors', newDistributor);

    onSubmit(newDistributor);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle fontWeight={600}>Create Distributor</DialogTitle>
      <DialogContent dividers>
        <div>
          <FormControl fullWidth>
            <FormLabel sx={{ display: 'flex' }}>
              <Typography variant="body1">
                Name - <Typography variant="caption">Name of the distributor</Typography>
              </Typography>
            </FormLabel>
            <TextField
              name="distributorName"
              value={form.distributorName}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              error={Boolean(errors.distributorName)}
              helperText={errors.distributorName}
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSubmit(handleFormSubmit)}
          disabled={Object.keys(errors).length > 0}
        >
          Create Distributor
        </Button>
      </DialogActions>
    </Dialog>
  );
};
