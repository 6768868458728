import type { Market } from 'src/markets/types';

export const getAssignedMarketIds = (markets: Market[]): number[] =>
  markets
    .filter(market => market.isWrite || market.isMaintain || market.isAdmin)
    .map(market => market.marketId);

export const getAssignedMarkets = (markets: Market[]): Market[] =>
  markets.filter(market => market.isWrite || market.isMaintain || market.isAdmin);

export const getUnassignedMarkets = (markets: Market[]): Market[] =>
  markets.filter(market => !market.isWrite && !market.isMaintain && !market.isAdmin);
