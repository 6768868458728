import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';

interface ReviewBrandLineDialogState {
  brandLine?: DenormalizedBrandLine | undefined;
  isOpen: boolean;
}

const initialState: ReviewBrandLineDialogState = {
  brandLine: undefined,
  isOpen: false,
};

export const {
  actions: reviewBrandLineDialogActions,
  reducer: reviewBrandLineDialogReducer,
  name: reviewBrandLineDialogSliceName,
} = createSlice({
  name: 'reviewBrandLineDialog',
  initialState,
  reducers: {
    open(state, action: PayloadAction<DenormalizedBrandLine>) {
      state.brandLine = action.payload;
      state.isOpen = true;
    },
    close() {
      return initialState;
    },
  },
});
