import { connect } from 'react-redux';

import { setModalDialogType } from 'src/store/features/modalDialog/actions';
import ExportReport from '../ExportReport';
import ConfirmationDialog from './ConfirmationDialog';
import ModalType from './ModalType';

const ModalDialog = ({
  dispatch,
  type,
}: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  dispatch: Function;
  // eslint-disable-next-line react/require-default-props
  type?: ModalType;
}) => {
  const closeModal = () => {
    dispatch(setModalDialogType(ModalType.NONE));
  };

  switch (type) {
    case ModalType.CONFIRMATION:
      return <ConfirmationDialog closeModal={closeModal} />;
    case ModalType.EXPORT_REPORT:
      return <ExportReport closeModal={closeModal} />;

    default:
      return null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
  const { modalType } = state.modalDialog;

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    type: modalType,
  };
};

export default connect(mapStateToProps)(ModalDialog);
