import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Box, MenuItem, Select, type SelectChangeEvent } from '@mui/material';

import { useSyncContext } from 'src/sync';
import { objectStores } from 'src/sync/config';

export const AdminExportLocalDB = () => {
  const { getAllDatabaseData } = useSyncContext();
  const [selectedTable, setSelectedTable] = useState('_transactions');

  const tableNames = ['_transactions', '_meta', '_transactionLog'].concat(
    objectStores.map(item => item[0])
  );

  const handleChange = (event: SelectChangeEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    setSelectedTable(event.target.value as string);
  };

  const handleClick = async () => {
    const blob = new Blob([JSON.stringify(await getAllDatabaseData(), null, 4)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'localDB.json';
    link.href = url;
    link.click();
  };

  const handleTableDownloadClick = async () => {
    if (selectedTable) {
      const dataInDb = await getAllDatabaseData();

      const blob = new Blob([JSON.stringify(dataInDb[selectedTable], null, 4)], {
        type: 'application/json',
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = `${selectedTable}-${new Date().toISOString()}.json`;
      link.href = url;
      link.click();
    }
  };

  return (
    <>
      <Box sx={{ m: 4 }}>
        <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleClick}
        >
          Export Local DB ⤓
        </Button>
      </Box>

      <Box sx={{ m: 4 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedTable}
          defaultValue="_transactions"
          label="table"
          onChange={handleChange}
        >
          {tableNames.map((value, index) => (
            <MenuItem key={index.toString()} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
        <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleTableDownloadClick}
          style={{ marginLeft: 20 }}
        >
          Export Table ⤓
        </Button>
      </Box>
    </>
  );
};
