import type { BaseOption } from '../shared';

export const maxResults = 10;

export interface SelectItem extends BaseOption {
  search: string;
  isNew?: boolean;
}

export interface FilterResults {
  filteredOptions: SelectItem[];
  exact: SelectItem | undefined;
  partial: boolean;
}

export const filterOptions = (
  items: SelectItem[],
  matchString: string,
  sortValues?: boolean
): FilterResults => {
  const lowerMatchString = matchString.toLocaleLowerCase();

  let filteredOptions: SelectItem[];
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
  const exact: SelectItem | undefined = items.find((o: any) => o.search === lowerMatchString); // assumes items are unique
  filteredOptions = items.filter(
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any */
    (o: any) => o.search.includes(lowerMatchString) && o.search !== lowerMatchString
  );

  if (exact) {
    filteredOptions.unshift(exact);
  } else {
    if (sortValues) {
      filteredOptions.sort((a, b) => a.search.localeCompare(b.search));
    }

    filteredOptions = [
      {
        value: matchString,
        label: matchString,
        isNew: true,
        search: lowerMatchString,
      },
      ...filteredOptions,
    ].slice(0, maxResults);
  }

  return {
    exact,
    filteredOptions,
    partial: filteredOptions.length === 0,
  };
};
