import type { FC, ReactNode } from 'react';
import { Modal } from 'react-bootstrap';

import { Button, ModalHeader } from '../../css/styled-components';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type WrapperComponentProps = {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/ban-types
  closeEvent: Function;
};

const WrapperComponent: FC<WrapperComponentProps> = ({ children, closeEvent }) => (
  <>
    <Modal show centered animation={false} backdrop="static" size="lg">
      <ModalHeader>Export</ModalHeader>
      {children}
      <Modal.Footer>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
        <Button type="button" variant="link" onClick={() => closeEvent()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </>
);

export default WrapperComponent;
