import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import {
  DataGrid,
  type unsafe_GetContextMenuItemsParams,
  type unsafe_GridApi,
  type unsafe_GridReadyEvent,
  type unsafe_NewValueParams,
  type unsafe_RowNode,
} from 'src/components/DataGrid';
import { GroupingMenu } from 'src/components/GroupingMenu';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { Content } from 'src/css/styled-components';
import { useDataGridUnmountKey } from 'src/hooks/useDataGridUnmountKey/useDataGridUnmountKey';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import type { DenormalizedOrganicWineSales } from 'src/records/types/DenormalizedOrganicWineSales';
import { denormalizeOrganicWineDataUtil } from 'src/records/utils/denormalizers';
import { useSyncContext } from 'src/sync';
import type { Entity, EntityType } from 'src/types/Entity';
import { type BrandSalesEntity } from 'src/types/entity/BrandSales';
import { type OrganicWineSalesEntity } from 'src/types/entity/OrganicWineSales';
import isDeepEqual from 'src/utils/isDeepEqual';
import { getBooleanValueFromString, isStringifiedBooleanValue } from 'src/utils/string';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import { absoluteChange } from 'src/views/utils';
import { getColumnsWithSuffixedForecastYearHeaders } from 'src/views/utils/forecasting/grid';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import { pasteFromClipboard } from 'src/views/utils/grid/gridClipboardPasteUtils';
import createContextMenu from '../common/getContextMenuItems';
import {
  calculateVolumes,
  type DenormalizedOrganicWineSalesWithPercentage,
  getStillWineTotals,
  getUpdatedBrandSales,
} from './helpers';

import 'src/css/grid.css';

const STILL_WINE_CATEGORY3 = 41;

export const OrganicWineTableViewPage = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);
  const { viewSlug } = useParams<'viewSlug'>();
  const { key } = useDataGridUnmountKey({ market });

  const { updateEntity, onEntitiesReceived } = useSyncContext();
  const dataGridApiRef = useRef<unsafe_GridApi<DenormalizedOrganicWineSales>>();

  const [brandSales, setBrandSales] = useState(
    useRecords<DenormalizedBrandSales>('brandSales', market.marketId).filter(
      item => item.category3Id === STILL_WINE_CATEGORY3
    )
  );

  let gridData = useRecords<DenormalizedOrganicWineSales>(table.source, market.marketId);
  gridData = calculateVolumes(getStillWineTotals(brandSales), gridData);

  const updateAgGridWithSalesTableData = (brandSaleEntities: BrandSalesEntity[]) => {
    const updatedBrandSales = getUpdatedBrandSales({
      brandSaleEntities,
      market,
      brandSales,
    });
    const brandSalesStillWineTotals = getStillWineTotals(updatedBrandSales);

    dataGridApiRef.current?.setGridOption('pinnedTopRowData', [
      {
        organic: `${market.marketName.toUpperCase()}: STILL WINE`,
        volume: brandSalesStillWineTotals,
      },
    ]);
    setBrandSales(updatedBrandSales);
  };

  const updateAgGridWithOrganicWineTableData = (organicWineSales: OrganicWineSalesEntity[]) => {
    const brandSalesStillWineTotals = getStillWineTotals(brandSales);
    let selectedNode: unsafe_RowNode<DenormalizedOrganicWineSales> | undefined;

    organicWineSales.forEach(organicWineEntity => {
      // eslint-disable-next-line no-param-reassign
      gridData = calculateVolumes(brandSalesStillWineTotals, [
        ...gridData.filter(
          gridItem =>
            gridItem[table.primaryField as keyof typeof organicWineEntity] !==
            organicWineEntity[table.primaryField as keyof typeof organicWineEntity]
        ),
        organicWineEntity,
      ]);

      gridData.forEach(organicWine => {
        dataGridApiRef.current?.forEachNode(
          (node: unsafe_RowNode<DenormalizedOrganicWineSales>) => {
            const rowId = getRowId() as keyof DenormalizedOrganicWineSales;

            if (node.data && node.data[rowId] === organicWine[rowId]) {
              selectedNode = node;
              return;
            }
          }
        );

        if (selectedNode) {
          // update the existing ag grid organic colour sale item.
          selectedNode.data = denormalizeOrganicWineDataUtil({ ...organicWine });

          dataGridApiRef.current?.applyTransaction({
            update: [selectedNode.data],
          });
        }
      });
    });

    dataGridApiRef.current?.setGridOption('pinnedTopRowData', [
      {
        organic: `${market.marketName.toUpperCase()}: STILL WINE`,
        volume: brandSalesStillWineTotals,
      },
    ]);
  };

  useEffect(() => {
    // When data received from other tabs or received from delta sync, update AG-GRID data without re-rendering.
    return onEntitiesReceived(data => {
      const typedData = data as Partial<
        Record<EntityType, OrganicWineSalesEntity[] | BrandSalesEntity[]>
      >;

      if (typedData[table.source]) {
        updateAgGridWithOrganicWineTableData(typedData[table.source] as OrganicWineSalesEntity[]);
        return;
      }

      if (typedData.brandSales) {
        updateAgGridWithSalesTableData(typedData.brandSales as BrandSalesEntity[]);
      }
    });
  });

  const view = useMemo(() => views.find(view => view.slug === viewSlug), [views, viewSlug]);

  const section = useMemo(
    () => sections.find(section => section.id === view?.sectionId),
    [sections, view]
  );

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const { convertFieldsToColumns } = useColumns();

  const { columns, columnGrouping } = useMemo(
    () => {
      const columnsAndColumnGrouping = convertFieldsToColumns(table.fields, {
        lockedView: Boolean(view?.locked),
        periodiseByRows: false,
      });

      return {
        ...columnsAndColumnGrouping,
        columns: getColumnsWithSuffixedForecastYearHeaders(columnsAndColumnGrouping.columns),
      };
    },
    // Exclude convertFieldsToColumns from list to prevent redraw
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.fields, view?.tableId, view?.locked]
  );

  const {
    handleColumnOrderChange,
    handleColumnVisibilityChange,
    handleColumnWidthsChange,
    handlePinnedColumnsChange,
    handleRowGroupingChange,
    state,
  } = useGridViewState(table);

  const formulaFunctions = useMemo(() => ({ absoluteChange }), []);

  const getContextMenuItems = (params: unsafe_GetContextMenuItemsParams<Entity>) =>
    createContextMenu(params, table.canRemoveRecords);

  const pinnedColumns = useMemo(
    () => state.pinnedColumns ?? { left: view?.pinnedColumns ?? [] },
    [view, state]
  );

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const handleCellValueChange = useCallback(
    ({ column, data, newValue, oldValue, node }: unsafe_NewValueParams<Entity>) => {
      const columnId = column.getColId();
      const hasDifferentValue = !isDeepEqual(oldValue, newValue);

      // Volume is updated concurrently with percentage, so make sure logic doesn't run for the volume column as well.
      if (columnId.startsWith('percentage') && hasDifferentValue) {
        const entityGuid = String(data[table.primaryField]);
        const [columnName, year] = columnId.split('.') as [string, string];
        const payloadValue = isStringifiedBooleanValue(newValue)
          ? getBooleanValueFromString(newValue)
          : (newValue as number);
        const payload = {
          [columnName]: {
            [year]: payloadValue,
          },
        };

        const stillWineSaleVolumes = getStillWineTotals(brandSales);

        if (node?.data) {
          const newVolume =
            newValue != null ? (stillWineSaleVolumes[Number(year)] ?? 0) * newValue : null;

          const organicWineSaleEntity =
            node.data as unknown as DenormalizedOrganicWineSalesWithPercentage;

          organicWineSaleEntity.volume = {
            ...organicWineSaleEntity.volume,
            [year]: newVolume,
          };

          dataGridApiRef.current?.applyTransaction({
            update: [organicWineSaleEntity],
          });
        }

        void updateEntity(table.source, entityGuid, payload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.primaryField]
  );

  const handleGridReady = useCallback(
    (params: unsafe_GridReadyEvent) => {
      dataGridApiRef.current = params.api;

      dataGridApiRef.current.setGridOption('pinnedTopRowData', [
        {
          organic: `${market.marketName.toUpperCase()}: STILL WINE`,
          volume: getStillWineTotals(brandSales),
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view]
  );

  if (!viewSlug) return <p>Loading.</p>;

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            allTableFields={table.fields}
            columnVisibility={state.columnVisibility}
            pinnedColumns={state.pinnedColumns}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnPinnedChange={handlePinnedColumnsChange}
            disableChangingVisibility={!table.canChangeColumnVisibility}
            disableChangingOrder={!table.canChangeColumnOrder}
          />
          <GroupingMenu
            columns={table.fields}
            disabled={!table.canChangeRowGrouping || view.locked}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            onRowGroupingChange={handleRowGroupingChange}
          />
        </Nav>
      </Navbar>

      <Container id="viewContainer">
        {views.length > 1 && (
          <StyledSidebar sidebarOpen data-testid="sidebar">
            <SidebarHeader sidebarOpen>
              <SidebarTitle>Options</SidebarTitle>
            </SidebarHeader>
            <SidebarBody sidebarOpen>
              {sections.length > 0 && (
                <ViewSectionSwitcher currentSection={section} currentView={view} />
              )}
              <ViewSwitcher currentSection={section} currentView={view} />
            </SidebarBody>
          </StyledSidebar>
        )}
        <Content>
          <DataGrid<Entity>
            key={key}
            aggregation={view.aggregation}
            canChangeColumnOrder={table.canChangeColumnOrder}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={table.canChangeRowGrouping}
            columnGrouping={columnGrouping}
            columnOrder={state.columnOrder}
            columns={columns}
            columnVisibility={state.columnVisibility}
            columnWidths={state.columnWidths}
            onCellValueChange={handleCellValueChange}
            filter={view.filter}
            formulaFunctions={formulaFunctions}
            getRowId={getRowId}
            groupingColumn={{ leafField: view.leafField }}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnWidthChange={handleColumnWidthsChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onRowGroupingChange={handleRowGroupingChange}
            onGridReady={handleGridReady}
            pinnedColumns={pinnedColumns}
            rows={gridData as unknown as Entity[]}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            sort={view.sort}
            getContextMenuItems={getContextMenuItems}
            sendToClipboard={sendToClipboard}
            processDataFromClipboard={pasteFromClipboard}
            enterNavigatesVerticallyAfterEdit
            enterNavigatesVertically
          />
        </Content>
      </Container>
    </>
  );
};
