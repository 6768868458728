import type { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildNumericValuesHandler } from 'src/utils/createBrandLineFormHandlers';
import { mapNumericsToValues, mapNumericToValue } from 'src/utils/createBrandLineFormMappers';
import { Label } from './styledComponents';

const OPTIONS = [
  { id: 0, text: 'Branded' },
  { id: 3, text: 'Private Label' },
];

const CreateBrandLineTypeSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  if (!formState.category) return null;

  return (
    <Form.Group>
      <Label>Brand Type</Label>
      <Select
        options={mapNumericsToValues(OPTIONS)}
        value={mapNumericToValue(formState.brandLineTypeId)}
        onChange={buildNumericValuesHandler(dispatchFormAction, 'brandLineTypeId')}
        data-testid="create_brand_line_type_dropdown"
        id="create_brand_line_type_dropdown"
      />
    </Form.Group>
  );
};

export default CreateBrandLineTypeSection;
