import type { EntityConfiguration, EntitySchema } from './types';

interface SyncingConfigProps {
  version: number;
  markets: EntitySchema;
  entities: Record<string, EntitySchema>;
}

export const syncingConfig: SyncingConfigProps = {
  version: 21,
  markets: {
    keyPath: 'marketId',
    path: 'meta/markets',
  },
  entities: {
    tables: {
      keyPath: 'tableId',
      isMarketQuery: true,
    },
    views: {
      keyPath: 'id',
      isMarketQuery: true,
    },
    viewSections: {
      keyPath: 'id',
      isMarketQuery: true,
    },
    owners: {
      keyPath: 'ownerGUID',
    },
    brands: {
      keyPath: 'brandGUID',
    },
    brandLines: {
      keyPath: 'brandLineGUID',
    },
    distributors: {
      keyPath: 'distributorGUID',
    },
    origins: {
      keyPath: 'originId',
    },
    alcoholicStrengths: {
      keyPath: 'alcoholicStrengthId',
    },
    priceBands: {
      keyPath: 'priceBandId',
    },
    maltRegions: {
      keyPath: 'maltRegionId',
    },
    ageStyles: {
      keyPath: 'ageId',
    },
    categories: {
      keyPath: 'legacyId',
    },
    checks: {
      keyPath: 'checkKey',
      isMarketQuery: true,
    },
    checkErrors: {
      keyPath: 'errorDetailId',
      isMarketQuery: true,
      path: 'checks/0',
    },
    externalData: {
      keyPath: 'externalBrandSalesId',
      isMarketQuery: true,
    },
    ecommerceSales: {
      keyPath: 'ecommerceGUID',
      isMarketQuery: true,
    },
    brandSales: {
      keyPath: 'saleGUID',
      isMarketQuery: true,
    },
    previousForecast: {
      keyPath: 'saleGUID',
      isMarketQuery: true,
    },
    wineVarietalOptions: {
      keyPath: 'wineVarietalOptionId',
    },
    wineVarietalSales: {
      keyPath: 'wineVarietalGUID',
      isMarketQuery: true,
    },
    wineColourSales: {
      keyPath: 'wineColourGUID',
      isMarketQuery: true,
    },
    priceBandLimits: {
      keyPath: 'id',
      isMarketQuery: true,
    },
    organicWineSales: {
      keyPath: 'organicWineGUID',
      isMarketQuery: true,
    },
    wineRegionSales: {
      keyPath: 'wineVarietalGUID',
      isMarketQuery: true,
    },
    onPremiseSales: {
      keyPath: 'onPremiseGUID',
      isMarketQuery: true,
    },
    modelledForecast: {
      keyPath: 'saleGUID',
      isMarketQuery: true,
    },
  },
};

export const objectStores = Object.entries({
  markets: syncingConfig.markets,
  ...syncingConfig.entities,
});

export const entityConfiguration: EntityConfiguration = {
  entities: {
    markets: syncingConfig.markets,
    ...syncingConfig.entities,
  },
  schemaVersion: syncingConfig.version,
};
