type AppEnv = 'production' | 'preview' | 'staging' | 'sandbox' | 'localhost';

const getAppEnv = (): AppEnv => {
  const host = window.location.host.toLowerCase();

  if (host.includes('localhost') || host.includes('127.0.0.1')) {
    return 'localhost';
  }

  if (host.endsWith('theiwsr.net')) {
    const env = host.split('.')[1];
    return env as AppEnv;
  }

  return 'production';
};

export default getAppEnv;
