import type { Theme } from '@mui/material/styles';
import type { DefaultTheme } from 'styled-components';

import type { ValidationCheck } from './types';

interface ValidationCheckColorParams {
  theme: DefaultTheme;
  status: ValidationCheck['checkStatus'];
  selected?: boolean;
}

export const validationCheckColor = ({
  theme,
  status,
  selected = false,
}: ValidationCheckColorParams) => {
  if (selected) return theme.white;
  switch (status) {
    case 'Success':
      return theme.darkGreen;
    case 'Warning':
      return theme.red;
    default:
      return theme.darkGrey;
  }
};

interface ValidationCheckMUIColorParams {
  theme: Theme;
  status: ValidationCheck['checkStatus'];
}

export const getValidationCheckMUIColor = ({ theme, status }: ValidationCheckMUIColorParams) => {
  switch (status) {
    case 'Success':
      return theme.palette.success.dark;
    case 'Warning':
      return theme.palette.error.dark;
    default:
      return theme.palette.text.secondary;
  }
};
