import type { unsafe_GetContextMenuItemsParams } from 'src/components/DataGrid';
import type { Entity } from 'src/types/Entity';
import { createContextMenu, type GridContextMenuItem } from 'src/views/utils/contextmenu';

const getContextMenuItems = (
  params: unsafe_GetContextMenuItemsParams<Entity>,
  canRemoveRecords: boolean | undefined
) => {
  const gridContextMenuItems: GridContextMenuItem[] = [];

  return createContextMenu(canRemoveRecords, params, gridContextMenuItems);
};

export default getContextMenuItems;
