type Empty = null | undefined | string | unknown[] | object;

// Always returns a value of true, irrespective of parameters.
export const alwaysTrue = <T>(arg?: T): boolean => (arg ? true : true);

// Returns a new array containing the contents of the given array, followed by the given element.
export const append = <T>(element: T, arr: T[]) => arr.concat([element]);

// Make a shallow clone of an object, setting or overriding the specified property with the given value.
export const assoc = <T>(prop: string, value: unknown, obj: T): T => ({ ...obj, [prop]: value });

// Drop the first n elements from an array, returning a new array.
export const drop = <T>(num: number, arr: T[]): T[] => arr.slice(num).map(item => item);

// Drop the last n elements from an array, returning a new array.
export const dropLast = <T>(num: number, arr: T[]): T[] => arr.slice(0, -num).map(item => item);

// Returns true if the given value is its type's empty value; false otherwise.
// Not an exhaustive list of types but should do for our needs.
export const isEmpty = (param: Empty) =>
  param === null
    ? false
    : typeof param === 'undefined'
      ? false
      : typeof param === 'string' && param === ''
        ? true
        : Array.isArray(param) && !param.length
          ? true
          : typeof param === 'object' && !Object.keys(param).length
            ? true
            : false;

// Check if the input value is null or undefined.
export const isNil = (param: unknown) => param === null || typeof param === 'undefined';

// Returns a partial (shallow) copy of an object omitting the keys specified.
// TODO: Maybe can use omitProps in an Omit as a type for the output?
export const omit = (omitProps: string[], obj: Record<string, unknown>) => {
  const newObj: Record<string, unknown> = {};

  Object.keys(obj).forEach(key => {
    if (!omitProps.includes(key)) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

// Returns a new array with the given element at the front, followed by the contents of the array.
export const prepend = <T>(element: T, arr: T[]): T[] => [element].concat(arr);

// Remove a group of elements from an array, starting at from, for count.
export const remove = <T>(from: number, count: number, arr: T[]): T[] => [
  ...arr.slice(0, from),
  ...arr.slice(from + count, arr.length),
];

// Returns a new copy of the array with the element at the provided index replaced with the given value.
export const update = <T>(index: number, element: T, arr: T[]): T[] => [
  ...arr.slice(0, index),
  element,
  ...arr.slice(index + 1, arr.length),
];

// Returns the value of a given property of an object or at the given index of an array
export const prop = (
  name: string | number,
  obj: Record<string, unknown> | unknown[]
): unknown | undefined => {
  if (Array.isArray(obj)) {
    if (typeof name !== 'number') return undefined;
    if (name >= obj.length) return undefined;
    if (name < 0 && obj.length + name < 0) return undefined;

    return name >= 0 ? obj[name] : obj[obj.length + name];
  }

  if (typeof name === 'number' && name < 0) return undefined;

  const name2 = typeof name === 'string' ? name : name.toString();
  return obj[name2];
};

// Returns true/falue if the value of a given property of an object
// or at the given index of an array is equal to incoming value
export const propEq = (
  value: unknown,
  name: string | number,
  obj: Record<string, unknown> | unknown[]
): boolean => JSON.stringify(value) === JSON.stringify(prop(name, obj));

export const findAndReplace = <T>(
  predicate: (element: T) => boolean,
  data: T[],
  replacer: T
): T[] => {
  const index = data.findIndex(predicate);

  if (index != -1) {
    data.splice(index, 1, replacer);
  }

  return data;
};

export const filterUndefinedValuesDeeply = <
  T extends Record<string, unknown> | unknown[] | unknown,
>(
  obj: T
): T => {
  if (Array.isArray(obj)) {
    return obj.map(item => filterUndefinedValuesDeeply(item as T)) as T;
  }

  if (typeof obj === 'object' && obj !== null) {
    const filteredObj = {} as Record<string, unknown>;

    for (const key in obj) {
      const value: T = obj[key] as T;

      if (value !== undefined) {
        filteredObj[key] = typeof value === 'object' ? filterUndefinedValuesDeeply(value) : value;
      }
    }

    return filteredObj as T;
  }

  return obj;
};
