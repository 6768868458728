import type { Entity } from '../types/Entity';
import type { OriginsEntity } from '../types/entity/Origins';
import type { FlatCategoryItem } from '../views/types';

export const createBrandLineNameFromCategory5 = (
  entity: Entity,
  category5Id: number,
  categories: FlatCategoryItem[],
  origins: OriginsEntity[]
) => {
  const category5 = categories.find(item => item.id === category5Id && item.level === 5);

  if (!category5) return '';

  const namingPatternBrandLine = category5.namingPatternBrandLine;

  const categoryHierarchy = getCategoryHierarchyForCategory5(categories, category5.id);

  const brandLineName = namingPatternBrandLine
    ?.replaceAll('{brandLineDisplayName}', String(entity['brandLineDisplayName']))
    .replaceAll('{category5Name}', category5.name)
    .replaceAll('{category4Name}', String(categoryHierarchy[4]?.name))
    .replaceAll('{category3Name}', String(categoryHierarchy[3]?.name))
    .replaceAll('{category2Name}', String(categoryHierarchy[2]?.name))
    .replaceAll('{category1Name}', String(categoryHierarchy[1]?.name))
    .replaceAll(
      '{originName}',
      String(origins.find(origin => origin.originId === entity['originId'])?.originName)
    );

  return brandLineName;
};

const getCategoryHierarchyForCategory5 = (categories: FlatCategoryItem[], category5Id: number) => {
  const category5 = categories.find(item => item.id === category5Id && item.level === 5);
  const category4 = categories.find(item => item.id === category5?.parentId && item.level === 4);
  const category3 = categories.find(item => item.id === category4?.parentId && item.level === 3);
  const category2 = categories.find(item => item.id === category3?.parentId && item.level === 2);
  const category1 = categories.find(item => item.id === category2?.parentId && item.level === 1);

  return { 1: category1, 2: category2, 3: category3, 4: category4, 5: category5 };
};
