import { useCallback, useEffect, useMemo, useRef } from 'react';
// @TODO - remove these legacy imports
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import {
  type CellDoubleClickedEvent,
  DataGrid,
  type unsafe_GetContextMenuItemsParams,
  type unsafe_GridApi,
  type unsafe_GridReadyEvent,
  type unsafe_NewValueParams,
  type unsafe_RowClassParams,
  type unsafe_RowNode,
} from 'src/components/DataGrid';
import type { FilterItem } from 'src/components/DataGrid/types';
import { CreateBrandLineDialog } from 'src/components/Dialogs/CreateBrandLineDialog';
import { RenameRecordDialog } from 'src/components/Dialogs/RenameRecordDialog';
import {
  ApprovalStatus,
  ReviewBrandLineDialog,
} from 'src/components/Dialogs/ReviewBrandLineDialog';
import { SwapBrandDialog } from 'src/components/Dialogs/SwapBrandDialog';
import { SwapCategory5Dialog } from 'src/components/Dialogs/SwapCategory5Dialog';
import { SwapOwnerDialog } from 'src/components/Dialogs/SwapOwnerDialog';
import { FilterMenu } from 'src/components/FilterMenu';
import { GridSearch } from 'src/components/GridSearch';
import { GroupingMenu } from 'src/components/GroupingMenu';
import ModalDialog from 'src/components/ModalDialog';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { Content } from 'src/css/styled-components';
import { isFeatureFlagSet } from 'src/feature-flags';
import { useDataGridUnmountKey } from 'src/hooks/useDataGridUnmountKey/useDataGridUnmountKey';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import { denormalizeBrandLine } from 'src/records/utils/denormalizers';
import { useAppDispatch } from 'src/store';
import { renameRecordDialogActions } from 'src/store/features/renameRecordDialog/slice';
import { reviewBrandLineDialogActions } from 'src/store/features/reviewBrandLineDialog/slice';
import { swapBrandDialogActions } from 'src/store/features/swapBrandDialog/slice';
import { swapCategory5DialogActions } from 'src/store/features/swapCategory5Dialog/slice';
import { swapOwnerDialogActions } from 'src/store/features/swapOwnerDialog/slice';
import type { RecordKind } from 'src/store/features/types/BrandLineDialog';
import type { Transaction } from 'src/sync';
import { useSyncContext } from 'src/sync';
import type { Entity } from 'src/types/Entity';
import type { OriginsEntity } from 'src/types/entity/Origins';
import { createBrandLineNameFromCategory5 } from 'src/utils/createBrandLineNameFromCategory5';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import type { FlatCategoryItem } from 'src/views/types';
import { createContextMenu } from 'src/views/utils/contextmenu';
import { getAffectedBrandLines } from 'src/views/utils/filters/getAffectedBrandLines';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import { pasteFromClipboard } from 'src/views/utils/grid/gridClipboardPasteUtils';
import { getContextMenuItems as getContextMenuItemsUtil } from './getContextMenuItems';
import { JumpToNextApprovalButton } from './JumpToNextApprovalButton';

import 'src/css/grid.css';

const BRANDLINES_UPDATE_DIRECTLY_ON_AG_GRID = 'BrandlinesUpdateDirectlyOnAgGrid';
const BRANDLINES_UPDATE_BRANDLINENAME = 'BrandlinesUpdateBrandLineName';

export const BrandLinesTableViewPage = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);
  const { viewSlug } = useParams<'viewSlug'>();
  const { key } = useDataGridUnmountKey({ market });

  const dataGridApiRef = useRef<unsafe_GridApi>();

  const { updateEntity, onEntitiesReceived, onTransactionCreated, getEntitiesLookup } =
    useSyncContext();

  const updateAgGridData = (data: Record<string, Entity[]>) => {
    const entitiesLookup = getEntitiesLookup();
    const brandLines = getAffectedBrandLines(entitiesLookup, data);
    let selectedNode: unsafe_RowNode | undefined;

    brandLines.forEach(brandLine => {
      dataGridApiRef.current?.forEachNode(node => {
        if (node.data && (node.data as Entity)[getRowId()] === brandLine[getRowId()]) {
          selectedNode = node;
          return;
        }
      });

      // get all required fields in order to denormalize brand sale.
      const denormalizedBrandLine = denormalizeBrandLine(entitiesLookup, brandLine);

      // check if the coming brand sale exist in the grid
      if (selectedNode) {
        // if comming brand sale has isDeleted flag, then remove the item from the grid.
        if (brandLine['isDeleted']) {
          dataGridApiRef.current?.applyTransaction({ remove: [selectedNode.data] });
        } else {
          // update the existing ag grid brand sale item.
          selectedNode.data = {
            ...denormalizedBrandLine,
          };
          dataGridApiRef.current?.applyTransaction({ update: [selectedNode.data] });
        }
      } else {
        // if coming brand sale does not exist, add it to the grid.
        dataGridApiRef.current?.applyTransaction({
          add: [
            {
              ...denormalizedBrandLine,
            },
          ],
        });
      }

      selectedNode = undefined;
    });
  };

  useEffect(() => {
    // When data received from other tabs or received from delta sync, update AG-GRID data without re-rendering.
    return onEntitiesReceived((data: Record<string, Entity[]>) => {
      updateAgGridData(data);
    });
  });

  const categories = useRecords<FlatCategoryItem>('categories');
  const origins = useRecords<OriginsEntity>('origins');

  useEffect(() => {
    // After any transaction created, update AG-GRID data without re-rendering.
    return onTransactionCreated((data: { entity: Entity; transaction: Transaction }) => {
      const { entity, transaction } = data;
      if (
        transaction.actionType === BRANDLINES_UPDATE_BRANDLINENAME &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        transaction.type === 'update' &&
        transaction.payload['category5Id']
      ) {
        const newBrandLineName = createBrandLineNameFromCategory5(
          entity,
          Number(transaction.payload['category5Id']),
          categories,
          origins
        );
        updateAgGridData({
          [transaction.entityType]: [
            {
              ...entity,
              brandLineName: newBrandLineName,
            },
          ],
        });
      } else if (transaction.actionType !== BRANDLINES_UPDATE_DIRECTLY_ON_AG_GRID) {
        updateAgGridData({ [transaction.entityType]: [entity] });
      }
    });
  });

  const dispatch = useAppDispatch();

  const view = useMemo(() => views.find(view => view.slug === viewSlug), [views, viewSlug]);

  const section = useMemo(
    () => sections.find(section => section.id === view?.sectionId),
    [sections, view]
  );

  const fields = table.fields;

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const handleGridReady = useCallback((params: unsafe_GridReadyEvent) => {
    dataGridApiRef.current = params.api;
  }, []);

  const gridData = useRecords<DenormalizedBrandLine>(table.source, market.marketId);

  const { convertFieldsToColumns } = useColumns();

  const { columns } = useMemo(
    () => convertFieldsToColumns(fields),
    // do not put convertFieldsToColumns in this dependency list as it causes re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, view?.name]
  );

  const {
    handleColumnOrderChange,
    handleColumnVisibilityChange,
    handleColumnWidthsChange,
    handlePinnedColumnsChange,
    handleRowGroupingChange,
    handleFilterChange,
    state,
  } = useGridViewState(table);

  const pinnedColumns = useMemo(
    () => state.pinnedColumns ?? { left: view?.pinnedColumns ?? [] },
    [view, state]
  );

  const theFilter: FilterItem[] | undefined = useMemo<FilterItem[] | undefined>(
    () => (state.filter ? state.filter : view?.filter),
    [view, state]
  );

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const getContextMenuItems = useCallback(
    (params: unsafe_GetContextMenuItemsParams<Entity>) => {
      const items = getContextMenuItemsUtil({
        canWriteRecords: table.canWriteRecords,
        params,
        dispatch,
        onRenameAction: () => {
          const brandLine = params.node?.data as unknown as DenormalizedBrandLine;
          const recordKind = params.column?.getColDef().headerName?.toLowerCase() as RecordKind;
          dispatch(renameRecordDialogActions.open({ recordKind, brandLine }));
        },
        onSwapAction: () => {
          const brandLine = params.node?.data as unknown as DenormalizedBrandLine;
          const recordKind = params.column?.getColDef().headerName?.toLowerCase() as RecordKind;
          recordKind === 'brand'
            ? dispatch(swapBrandDialogActions.open({ brandLine }))
            : dispatch(swapOwnerDialogActions.open({ brandLine }));
        },
        onSwapCategory5Action: () => {
          const brandLine = params.node?.data as unknown as DenormalizedBrandLine;
          dispatch(swapCategory5DialogActions.open(brandLine));
        },
      });

      return createContextMenu(table.canRemoveRecords, params, items);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.canRemoveRecords, table.canWriteRecords]
  );

  const handleCellValueChange = useCallback(
    ({ column, data, newValue }: unsafe_NewValueParams<Entity>) => {
      const colId = column.getId();
      const value: unknown = newValue;

      const columnDefinition = columns.find(item => item.field === colId);

      if (columnDefinition) {
        if (columnDefinition.type === 'select' || columnDefinition.type === 'entity') {
          const selectedOption = columnDefinition.options.choices.find(
            item => item.label === newValue || item.value === newValue
          );

          if (selectedOption) {
            void updateEntity(
              table.source,
              data[getRowId()] as string,
              {
                [colId]: selectedOption.value,
              },
              BRANDLINES_UPDATE_BRANDLINENAME
            );
          }
        } else if (columnDefinition.type === 'number') {
          void updateEntity(
            table.source,
            data[getRowId()] as string,
            {
              [colId]: parseFloat(value as string),
            },
            BRANDLINES_UPDATE_DIRECTLY_ON_AG_GRID
          );
        } else {
          void updateEntity(
            table.source,
            data[getRowId()] as string,
            {
              [colId]: value,
            },
            BRANDLINES_UPDATE_DIRECTLY_ON_AG_GRID
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.name, viewSlug]
  );

  const getRowClass = useCallback((params: unsafe_RowClassParams) => {
    if (params.node.group) {
      return `row-group-${params.node.level}`;
    }
    return '';
  }, []);

  const handleCellDoubleClicked = useCallback(
    ({ node, column }: CellDoubleClickedEvent<Entity>) => {
      if (column.getColId() === 'approvalStatus' && table.canApproveRecords) {
        const field = fields.find(({ field }) => field === 'approvalStatus');
        const brandLine = node.data as unknown as DenormalizedBrandLine;
        const canReview =
          brandLine.approvalStatus === ApprovalStatus.PENDING ||
          brandLine.approvalStatus === ApprovalStatus.REFERRED;

        if (field?.editable && canReview) {
          dispatch(reviewBrandLineDialogActions.open(brandLine));
        }
      }
    },
    [dispatch, fields, table]
  );

  if (!viewSlug) return <p>Loading.</p>;

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            allTableFields={fields}
            columnVisibility={state.columnVisibility}
            pinnedColumns={state.pinnedColumns}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnPinnedChange={handlePinnedColumnsChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            disableChangingVisibility={!!view.locked}
            disableChangingOrder={!table.canChangeColumnOrder}
          />
          <FilterMenu
            onClearAllFilters={() => {
              dataGridApiRef.current?.setFilterModel({});
            }}
          />
          <GroupingMenu
            columns={fields}
            disabled={!table.canChangeRowGrouping || view.locked}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            onRowGroupingChange={handleRowGroupingChange}
          />
        </Nav>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box>
            <JumpToNextApprovalButton
              dataGridApiRef={dataGridApiRef}
              isVisible={Boolean(table.canApproveRecords)}
              isDisabled={
                Boolean(dataGridApiRef.current?.isAnyFilterPresent()) ||
                Boolean(state.rowGrouping?.length)
              }
              approverCountries={table.approverCountries}
            />
          </Box>
          {isFeatureFlagSet('grid-search') && (
            <Box>
              <GridSearch
                dataGridApiRef={dataGridApiRef}
                searchableFields={[
                  { property: 'brandLineName', column: 'brandLineName' },
                  { property: 'brandName', column: 'brandName' },
                  { property: 'ownerName', column: 'ownerName' },
                ]}
              />
            </Box>
          )}
        </Box>
      </Navbar>

      <Container id="viewContainer">
        <StyledSidebar
          sidebarOpen
          data-testid="sidebar"
          style={{ display: views.length > 1 ? 'block' : 'none' }}
        >
          <SidebarHeader sidebarOpen>
            <SidebarTitle>Options</SidebarTitle>
          </SidebarHeader>
          <SidebarBody sidebarOpen>
            {sections.length > 0 && (
              <ViewSectionSwitcher currentSection={section} currentView={view} />
            )}
            <ViewSwitcher currentSection={section} currentView={view} />
            <br />
          </SidebarBody>
        </StyledSidebar>

        <Content>
          <DataGrid
            key={key}
            debug
            aggregation={view.aggregation}
            canChangeColumnOrder={table.canChangeColumnOrder}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={table.canChangeRowGrouping}
            columnOrder={state.columnOrder}
            columns={columns}
            columnVisibility={state.columnVisibility}
            columnWidths={state.columnWidths}
            filter={theFilter}
            getRowId={getRowId}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnWidthChange={handleColumnWidthsChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onRowGroupingChange={handleRowGroupingChange}
            pinnedColumns={pinnedColumns}
            getRowClass={getRowClass}
            onCellValueChange={handleCellValueChange}
            rows={gridData}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            sort={view.sort}
            onGridReady={handleGridReady}
            getContextMenuItems={getContextMenuItems}
            onFilterChange={handleFilterChange}
            sendToClipboard={sendToClipboard}
            processDataFromClipboard={pasteFromClipboard}
            enterNavigatesVerticallyAfterEdit
            enterNavigatesVertically
            onCellDoubleClicked={handleCellDoubleClicked}
          />
        </Content>
      </Container>

      <ModalDialog />

      <CreateBrandLineDialog />

      <ReviewBrandLineDialog />

      <RenameRecordDialog />

      <SwapBrandDialog market={market} />

      <SwapOwnerDialog market={market} />

      <SwapCategory5Dialog />
    </>
  );
};
