import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import type { BrandsEntity } from 'src/types/entity/Brands';
import { getBrandsSearchResults } from './helpers';
import type { Suggestion } from './types';

interface HookParams {
  brands: BrandWithOwnerName[];
  brandLine: DenormalizedBrandLine | null;
  selectedSuggestion: Suggestion | null;
  onSearchComplete: (suggestions: Suggestion[]) => void;
}

const MIN_CHARACTERS_FOR_SEARCH = 2;
const MAX_RESULTS_TO_SHOW = 10;

export const DEBOUNCE_DELAY_MS = 200;

export interface BrandWithOwnerName extends BrandsEntity {
  ownerName: string;
}

export const useSuggestionsSearch = ({
  brands,
  brandLine,
  selectedSuggestion,
  onSearchComplete,
}: HookParams) => {
  const noSearchResultsCopy = useRef('');
  const [search, setSearch] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestion[]>([]);

  const clearFilteredSuggestionsAndNoSearchResultsCopy = () => {
    setFilteredSuggestions([]);
    noSearchResultsCopy.current = '';
  };

  const getSearchSuggestions = (): Suggestion[] =>
    getBrandsSearchResults({ brands, search, selectedRow: brandLine });

  const debouncedHandleSearchResults = useDebouncedCallback(
    () => {
      if (search.length < MIN_CHARACTERS_FOR_SEARCH) {
        clearFilteredSuggestionsAndNoSearchResultsCopy();
        onSearchComplete([]);
        return;
      }

      const searchResults = getSearchSuggestions().slice(0, MAX_RESULTS_TO_SHOW);

      setFilteredSuggestions(searchResults);

      if (searchResults.length === 0) {
        noSearchResultsCopy.current = `No brand found for "${search}"`;
      }

      onSearchComplete(searchResults);
    },
    DEBOUNCE_DELAY_MS,
    { trailing: true }
  );

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
    debouncedHandleSearchResults();
  };

  return {
    search,
    filteredSuggestions,
    noSearchResultsCopy: noSearchResultsCopy.current,
    selectedSuggestion,
    handleSearch,
  };
};
