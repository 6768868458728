import { useEffect, useSyncExternalStore } from 'react';

import type { Entity } from '../../types/Entity';
import { SyncContext } from '../classes/sync-context';
import { type EntityReview, OnlineState, type SyncContextState, type Transaction } from '../types';

export type UseSyncContext = ReturnType<typeof useSyncContext>;

export const useSyncContext = () => {
  let timer: NodeJS.Timer | undefined;

  const syncContext = SyncContext.Build();

  return {
    /**
     * To enqueue a create entity transaction.
     *
     * @param entityType  Entity Type (brandSales, brandLines), matches with indexed db table name.
     * @param entity  Entity(Brand sale, Brand lines, etc.) payload.
     * @param actionType  An option parameter that describes action type for onTransactionCreated listeners.
     * @function useSyncContext
     */
    createEntity: async (entityType: string, entity: Entity, actionType?: string) =>
      syncContext.queueManager.createEntity(entityType, entity, actionType),

    /**
     * To enqueue an update entity transaction.
     *
     * @param entityType  Entity Type (brandSales, brandLines), matches with indexed db table name.
     * @param entityGUID  Entity's guid.
     * @param changes  Entity(Brand sale, Brand lines, etc.) partial payload.
     * @param isLocalChanges Is updating only local indexed db and cache in memory.
     * @param actionType  An option parameter that describes action type for onTransactionCreated listeners.
     * @function useSyncContext
     */
    updateEntity: async (
      entityType: string,
      entityGUID: string,
      changes: Partial<Entity>,
      actionType?: string,
      isLocalChanges?: boolean
    ) => {
      if (isLocalChanges) {
        await syncContext.localDbManager.putEntity(changes, entityType);
        return syncContext.cachingManager.cacheEntity({ [entityType]: changes });
      }

      return syncContext.queueManager.updateEntity(entityType, entityGUID, changes, actionType);
    },

    /**
     * To enqueue a review entity transaction.
     *
     * @param entityType  Entity Type (brandSales, brandLines), matches with indexed db table name.
     * @param entityGUID  Entity's guid.
     * @param review  Entity review payload.
     * @param actionType  An option parameter that describes action type for onTransactionCreated listeners.
     * @function useSyncContext
     */
    reviewEntity: async (
      entityType: string,
      entityGUID: string,
      review: EntityReview,
      actionType?: string
    ) => syncContext.queueManager.reviewEntity(entityType, entityGUID, review, actionType),

    /**
     * To enqueue a delete entity transaction.
     *
     * @param entityType  Entity Type (brandSales, brandLines), matches with indexed db table name.
     * @param entityGUID  Entity's guid.
     * @param actionType  An option parameter that describes action type for onTransactionCreated listeners.
     * @function useSyncContext
     */
    deleteEntity: async (entityType: string, entityGUID: string, actionType?: string) =>
      syncContext.queueManager.deleteEntity(entityType, entityGUID, actionType),

    /**
     * To push queued transaction the server.
     *
     * @function useSyncContext
     */
    syncTransactions: () => syncContext.queueManager.syncTransactions(),

    /**
     * Retrieves all cached entity data.
     *
     * @function useSyncContext
     */
    getAllEntityData: () => syncContext.cachingManager.getAllEntityData(),

    /**
     * Retrieves all cached entity data.
     *
     * @function useSyncContext
     */
    getEntitiesLookup: () => syncContext.cachingManager.getEntitiesLookup(),

    /**
     * Returns last visitest market id.
     *
     * @function useSyncContext
     */
    getLastVisitedMarketId: () => syncContext.syncManager.getLastVisitedMarketId(),

    /**
     * Retrieves cached entity data.
     *
     * @param entityType  Entity Type (brandSales, brandLines), matches with indexed db table name.
     * @function useSyncContext
     */
    getEntities: (entityType: string) => syncContext.cachingManager.getEntities(entityType),

    /**
     * Retrieves an cached entity.
     *
     * @param entityType  Entity Type (brandSales, brandLines), matches with indexed db table name.
     * @param entityGUID  Entity GUID.
     * @function useSyncContext
     */
    getEntity: (entityType: string, entityGUID: string) =>
      syncContext.cachingManager.getEntity(entityType, entityGUID),

    /**
     * Returns last cached date.
     *
     * @function useSyncContext
     */
    getLastCachedDate: () => syncContext.cachingManager.getLastCachedDate(),

    /**
     * Syncing bootstrap, which should be called when user is signed in or page is refreshed.
     *
     * @function useSyncContext
     */
    connect: async (): Promise<{ error?: Error | undefined; status: SyncContextState }> =>
      syncContext.connect(),

    /**
     * Performs syncing.
     *
     * @function useSyncContext
     */
    syncData: async () => syncContext.syncManager.syncData(),

    /**
     * Syncing market data into the indexedDb and caching it.
     *
     * @function useSyncContext
     */
    cacheMarket: async (marketId: number) => syncContext.syncManager.cacheMarket(marketId),

    /**
     * Retrieves the number of records in each cached data and indexedDb.
     *
     * @function useSyncContext
     */
    statistics: async () => syncContext.statistics(),

    /**
     * Delete all data from the database.
     *
     * @function useSyncContext
     */
    resetLocalDatabase: async () => syncContext.resetLocalDatabase(),

    /**
     * Delete the database.
     *
     * @function useSyncContext
     */
    deleteDatabase: async () => syncContext.deleteDatabase(),

    /**
     * Clearing all sync context data.
     *
     * @function useSyncContext
     */
    disconnect: async () => syncContext.disconnect(),

    /**
     * Close Db Connection, this function is being used only for demostrating the case when db closed.
     * DO NOT USE THIS FUNCTION FOR CLOSING DB DIRECTLY, INSTEAD OF THIS, disconnect() should be used....
     *
     * @function useSyncContext
     */
    closeDbConnection: () => {
      syncContext.localDbManager.closeDbConnection();
    },

    /**
     * Notify observers application disconnect with syncing module.
     *
     * @param observer  Callback function that is called when application disconnect with syncing module.
     * @function useSyncContext
     */
    onDisconnect: (observer: (data: boolean) => void) => {
      return syncContext.onDisconnect.subscribe(observer);
    },

    /**
     * Retreives cached market ids.
     *
     * @function useSyncContext
     */
    cachedMarketIds: syncContext.syncManager.getCachedMarketIds(),

    /**
     * Notify observers when market data has changed.
     *
     * @param observer  Callback function that is called when market data has changed.
     * @function useSyncContext
     */
    onMarketDataChanged: (observer: (data: Entity[]) => void) => {
      return syncContext.syncManager.onMarketDataChange.subscribe(observer);
    },

    /**
     * Check if the entity type is market query.
     *
     * @function useSyncContext
     */
    isMarketQuery: (entityType: string): boolean => syncContext.isMarketQuery(entityType),

    /**
     * Notify observers about recieving new entities after delta sync
     *
     * @param observer  Callback function that is called when any entities received from backend api or other tabs.
     * @function useSyncContext
     */
    onEntitiesReceived: (observer: (data: Record<string, Entity[]>) => void) => {
      const syncManagerObservation = syncContext.syncManager.onEntitiesReceived.subscribe(observer);

      const queueManagerObservation =
        syncContext.queueManager.onEntitiesReceived.subscribe(observer);

      return () => {
        syncManagerObservation();
        queueManagerObservation();
      };
    },

    /**
     * Notify observers about created transactions.
     *
     * @param observer  Callback function that is called when any transaction created.
     * @function useSyncContext
     */
    onTransactionCreated: (
      observer: (data: { entity: Entity; transaction: Transaction }) => void
    ) => {
      return syncContext.queueManager.onTransactionCreated.subscribe(observer);
    },

    /**
     * React hooks for listening syncing status and re-rendering the component accordingly.
     *
     * @function useSyncContext
     */
    useSyncStatus: () => {
      const cachingStoreCount = syncContext.cachingManager.cachingStoreCount();

      const transactionCount = useSyncExternalStore(
        syncContext.queueManager.onTransactionCountChange.subscribe,
        () => syncContext.queueManager.getTransactions().length
      );

      const status = useSyncExternalStore(
        syncContext.onSyncContextStateChange.subscribe,
        () => syncContext.status
      );

      const syncPercentage = useSyncExternalStore(
        syncContext.syncManager.onSyncPercentageChange.subscribe,
        () => syncContext.syncManager.syncingPercentage
      );

      return {
        syncPercentage,
        cachingStoreCount,
        transactionCount,
        status,
      };
    },

    /**
     * React hooks for doing async delta syncing.
     *
     * @function useSyncContext
     */
    useStartSyncingTimer: () => {
      const onlineStatus = useSyncExternalStore(
        syncContext.connectivity.onOnlineStatusChange.subscribe,
        () => syncContext.connectivity.onlineStatus
      );

      const isOnline = onlineStatus !== OnlineState.Offline;

      return useEffect(() => {
        if (isOnline) {
          timer = setInterval(() => {
            void (async () => {
              // before delta syncing, waiting transaction in queue should be tried to pushed first.
              await syncContext.queueManager.syncTransactions();
              await syncContext.syncManager.syncData();
              await syncContext.queueManager.checkTransactionLog();

              const lastTransactionLogPurge = localStorage.getItem('lastTransactionLogPurge');

              if (lastTransactionLogPurge !== new Date().toDateString()) {
                await syncContext.queueManager.purgeTransactionLog();
                localStorage.setItem('lastTransactionLogPurge', new Date().toDateString());
              }
            })();
          }, 15 * 60000); // do auto syncing every 15 minutes.
        } else {
          clearInterval(timer);
        }

        return () => {
          if (timer) {
            clearInterval(timer);
          }
        };
      }, [isOnline]);
    },

    /**
     * Retrieves all local DB tables and data.
     *
     * @function useSyncContext
     */
    getAllDatabaseData: async () => {
      const tables = Array.from(syncContext.localDbManager.entityStoreNames());

      const data = await Promise.all(
        tables.map(tableName => syncContext.localDbManager.getAllEntities(tableName))
      );

      return tables.reduce<Record<string, unknown>>((acc, tableName, index) => {
        acc[tableName] = data[index];
        return acc;
      }, {});
    },
  };
};
