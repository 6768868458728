import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const Heading = styled.div`
  font-size: 12pt;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  font-size: 18pt;
  font-weight: bold;
`;

export const ExcelIconContainer = styled.div`
  margin: auto;
  width: 50%;
  margin-top: 30px;
`;

export const CustomRow = styled(Row)`
  margin-top: 20px;
`;
