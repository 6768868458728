import { type FC, useContext, useLayoutEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { generatePath, useHref, useLinkClickHandler, useParams } from 'react-router-dom';

import { type FeatureFlag, isFeatureBehindFlag, isFeatureFlagSet } from 'src/feature-flags';
import NavStateContext from '../context/NavStateContext';
import { useMarket } from '../markets';
import type { Table } from '../views/types';

interface TablesNavigationItemProps {
  name: string;
  slug: string;
}

const TablesNavigationItem: FC<TablesNavigationItemProps> = ({ name, slug }) => {
  const { tableSlug } = useParams<'tableSlug'>();
  const { mostRecentSubPathsForTables } = useContext(NavStateContext);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { market } = useMarket();

  const base = generatePath('/:marketSlug/:tableSlug', {
    marketSlug: market.marketSlug,
    tableSlug: slug,
  });

  const to = mostRecentSubPathsForTables[base]
    ? `${base}/${mostRecentSubPathsForTables[base] ?? ''}`
    : base;

  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, { replace: false });

  useLayoutEffect(() => {
    setIsActive(tableSlug === slug ? true : false);
  }, [slug, tableSlug]);

  const isTableBehindFeatureFlag = isFeatureBehindFlag(slug as FeatureFlag);
  const hasFeatureFlagBeenSetInBrowser = isFeatureFlagSet(slug as FeatureFlag);

  if (isTableBehindFeatureFlag && !hasFeatureFlagBeenSetInBrowser) return null;

  return (
    <NavItem>
      <NavLink active={isActive} href={href} onClick={handleClick}>
        {name}
      </NavLink>
    </NavItem>
  );
};

interface TablesNavigationProps {
  tables: Table[];
}

export const TablesNavigation: FC<TablesNavigationProps> = ({ tables }) => {
  return (
    <Nav variant="tabs" className="mr-auto">
      {tables.map(table => (
        <TablesNavigationItem key={table.tableId} name={table.name} slug={table.slug} />
      ))}
    </Nav>
  );
};
