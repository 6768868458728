import { useNavigate } from 'react-router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useMarkets } from 'src/markets';
import { useSyncContext } from 'src/sync';
import { AppException } from 'src/types/AppException';
import { logOutCollectorLocalStorageItems } from 'src/utils/logging';

interface ErrorPageProps {
  error: Error;
}

const checkIfAppException = (error: Error) => error instanceof AppException;

const getAppExceptionMessage = (error: Error) =>
  error.message.replaceAll('AppException:', '').replaceAll('Uncaught', '');

export const ErrorPage = ({ error }: ErrorPageProps) => {
  const navigate = useNavigate();
  const markets = useMarkets();
  const { getLastVisitedMarketId } = useSyncContext();

  const handleTryAgainClick = () => {
    const market = markets.find(item => item.marketId === getLastVisitedMarketId());

    // if the error happens during syncing after switching the market in offline mode, push researcher back to last visited country.
    if (!navigator.onLine && market && !window.location.pathname.includes(market.marketSlug)) {
      navigate(`/${market.marketSlug}`, { replace: true });
    }

    navigate(0);
  };

  const isAppException = checkIfAppException(error);

  const title = isAppException ? getAppExceptionMessage(error) : 'An unknown error has occurred..';

  const context = !isAppException
    ? `We detected an exception and the dev team has been notified. \n We apologise for any inconvenience. Don't hesitate to reach out to us if this problem persists.`
    : undefined;

  logOutCollectorLocalStorageItems();
  return (
    <>
      <Dialog open>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {context && (
            <DialogContentText>
              {context.split('\n').map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </DialogContentText>
          )}
          {process.env.REACT_APP_MODE === 'development' && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="dev-panel-aria"
                id="dev-panel"
                style={{ backgroundColor: 'aliceblue' }}
              >
                {error.message}
              </AccordionSummary>
              <AccordionDetails>
                <code>{error.stack}</code>
              </AccordionDetails>
            </Accordion>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleTryAgainClick}>
            Reload Collector
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
