import { type FormEvent, type FormEventHandler, useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Auth } from 'aws-amplify';

import { config } from 'src/config';
import { Base, SigningInType } from './Base';

export const getRedirectDomain = () => {
  if (config.domain.includes('infrastructure-alb.production')) {
    // For now, redirect to: 'https://collectorv2.theiwsr.com'
    // Once v1 has been retired, we can revert to using 'https://collector.theiwsr.com'
    return 'https://collectorv2.theiwsr.com';
  }

  if (
    config.domain === 'https://collector.production.theiwsr.net' ||
    config.domain.indexOf('production') > 0
  ) {
    return 'https://collector.theiwsr.com';
  }

  return config.domain;
};

interface Props {
  onStateChange: (state: string, data?: unknown) => void;
  signInType?: SigningInType;
}

export const SignIn = ({ onStateChange, signInType }: Props) => {
  const [error, setError] = useState<{ message: string }>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [signingInType, setSigningInType] = useState(signInType ?? SigningInType.Office365);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (signingInType === SigningInType.PasswordAuth) {
      inputRef.current?.focus();
    }
  }, [signingInType]);

  const signIn = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (username.length === 0) {
      return setError({ message: 'Email is required' });
    }

    if (password.length === 0) {
      return setError({ message: 'Password is required' });
    }

    return Auth.signIn(username.toLowerCase(), password)
      .then((user: { challengeName: string; preferredMFA: string }) => {
        switch (user.challengeName) {
          case 'SOFTWARE_TOKEN_MFA':
            return onStateChange('confirmSignIn', user);
          case 'NEW_PASSWORD_REQUIRED':
            return onStateChange('requireNewPassword', user);
          case 'MFA_SETUP':
            return onStateChange('TOTPSetup', user);
          default:
            if (user.preferredMFA === 'NOMFA') {
              return onStateChange('TOTPSetup', user);
            }

            return onStateChange('signedIn', user);
        }
      })
      .catch(error => {
        const err = error as { code: string; message: string };

        switch (err.code) {
          case 'UserNotConfirmedException':
            return onStateChange('confirmSignUp', { username });
          case 'PasswordResetRequiredException':
            return onStateChange('forgotPassword', { username });
          default:
            return setError(err);
        }
      });
  };

  const header = (
    <Box sx={{ fontSize: '50px', fontWeight: 200, margin: '0 auto' }}>
      The IWSR
      <Box
        sx={{
          border: '3px solid #b32553',
          marginTop: '20px',
          marginBottom: '20px',
          padding: 0,
          width: '200px',
          display: 'block',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      />
    </Box>
  );

  const providerName = 'Office365';
  const redirectDomain = getRedirectDomain();

  const href =
    `https://${config.cognitoDomain}` +
    '/oauth2/authorize' +
    `?identity_provider=${providerName}` +
    `&redirect_uri=${redirectDomain}` +
    '&response_type=CODE' +
    `&client_id=${config.userPoolClientId}` +
    '&scope=aws.cognito.signin.user.admin email openid';

  const getLoginBody = () => {
    if (signingInType === SigningInType.PasswordAuth) {
      return (
        <>
          <p>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            Data will be cached locally to facilitate offline usage. If you are using a shared
            machine then please remember to log off to clear that data.
          </p>

          <Form.Group as={Form.Row}>
            <Form.Label column sm={3} htmlFor="signIn-Username">
              Email
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                id="signIn-Username"
                type="text"
                value={username}
                onChange={({ target }) => setUsername(target.value)}
                isInvalid={!!error}
                ref={inputRef}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Form.Row}>
            <Form.Label column sm={3} htmlFor="signIn-Password">
              Password
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                type="password"
                id="signIn-Password"
                value={password}
                onChange={({ target }) => setPassword(target.value)}
                isInvalid={!!error}
              />
            </Col>
          </Form.Group>
          <Box sx={{ textAlign: 'center', fontSize: '14px', margin: '10px' }}>
            Or you
            <Button
              onClick={() => setSigningInType(SigningInType.Office365)}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                border: 'transparent',
              }}
            >
              can sign in with Office365 instead
            </Button>
          </Box>
        </>
      );
    }

    const isProductionOrPreview = config.isProduction || config.isPreview;

    return (
      <>
        <p>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          Data will be cached locally to facilitate offline usage. If you are using a shared machine
          then please remember to log off to clear that data.
        </p>
        <Box sx={{ margin: 'auto', width: '50%' }}>
          <Button
            sx={{
              marginBottom: '10px',
              color: 'white',
              padding: '15px',
            }}
            style={{ color: 'white' }}
            variant="contained"
            href={href}
          >
            Login with Office365
          </Button>
        </Box>
        {!isProductionOrPreview && (
          <Box sx={{ textAlign: 'center', fontSize: '14px', margin: '10px' }}>
            Or you
            <Button
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                backgroundColor: 'transparent',
                border: 'transparent',
              }}
              onClick={() => setSigningInType(SigningInType.PasswordAuth)}
            >
              can sign in with an email and password instead
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <Base
      title={header}
      body={getLoginBody()}
      submitTxt="Sign In"
      onSubmit={signIn as FormEventHandler}
      cancelTxt="Forgot your password?"
      onCancel={() => onStateChange('forgotPassword')}
      error={error}
      signingInType={signingInType}
    />
  );
};
