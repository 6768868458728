import type { DenormalizedWineVarietalSaleData } from 'src/records/types/DenormalizedWineVarietalSaleData';
import type { WineVarietalOptionsEntity } from 'src/types/entity/WineVarietalOptions';
import type { OriginSelectionOption } from 'src/views/Pages/types/wineRegionAndVarietal';
import {
  isUniqueAndNotDeletedOption,
  sortOriginsAlpheticallyAsc,
} from 'src/views/Pages/utils/wineRegionAndVarietal';

export const getWineVarietalOptions = (
  wineVarietalOptions: WineVarietalOptionsEntity[],
  existingOptions: DenormalizedWineVarietalSaleData[]
) => {
  const options = wineVarietalOptions.reduce<OriginSelectionOption[]>((options, opt) => {
    if (!opt.isRegion && isUniqueAndNotDeletedOption(opt, existingOptions)) {
      return options.concat({
        value: opt.wineVarietalOptionId,
        label: opt.wineVarietalOption,
      });
    }

    return options;
  }, []);

  return options.sort(sortOriginsAlpheticallyAsc);
};
