import type { DenormalizedWineVarietalSaleData } from 'src/records/types/DenormalizedWineVarietalSaleData';
import type { OriginsEntity } from 'src/types/entity/Origins';
import type { WineVarietalOptionsEntity } from 'src/types/entity/WineVarietalOptions';
import type { OriginSelectionOption } from '../types/wineRegionAndVarietal';

export const sortOriginsAlpheticallyAsc = <T extends OriginSelectionOption>(optA: T, optB: T) => {
  if (optA.label < optB.label) {
    return -1;
  }

  if (optA.label > optB.label) {
    return 1;
  }

  return 0;
};

export const getOriginOptions = (origins: OriginsEntity[]) => {
  const originOptions = origins.map(({ originId, originName }) => ({
    value: originId,
    label: originName,
  }));

  return originOptions.sort(sortOriginsAlpheticallyAsc);
};

export const isUniqueAndNotDeletedOption = (
  varietalOption: WineVarietalOptionsEntity,
  existingOptions: DenormalizedWineVarietalSaleData[]
) => {
  const isUnique = existingOptions.every(
    opt => opt.wineVarietalOptionId !== varietalOption.wineVarietalOptionId
  );
  return isUnique && !varietalOption.isDeleted;
};

export const isOldWorld = (entity: DenormalizedWineVarietalSaleData | OriginsEntity) =>
  !isNewWorld(entity);

export const isNewWorld = (entity: DenormalizedWineVarietalSaleData | OriginsEntity) => {
  return entity.isNewWorld;
};
