import { COLLECTED_YEAR, FORECASTING_YEARS_MODIFIER } from 'src/constants';
import { type Market } from 'src/markets';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import type { DenormalizedOrganicWineSales } from 'src/records/types/DenormalizedOrganicWineSales';
import { type BrandSalesEntity } from 'src/types/entity/BrandSales';
import { type OrganicWineSalesEntity } from 'src/types/entity/OrganicWineSales';

export interface DenormalizedOrganicWineSalesWithPercentage extends DenormalizedOrganicWineSales {
  volume: Record<number, number>;
}

const YEARS_WITH_FORECAST_YEARS = COLLECTED_YEAR + FORECASTING_YEARS_MODIFIER;

export const calculateVolumes = (
  stillWineSaleVolumes: Record<string, number>,
  data: OrganicWineSalesEntity[]
): DenormalizedOrganicWineSalesWithPercentage[] => {
  const tempData = [...data] as DenormalizedOrganicWineSalesWithPercentage[];

  for (const entity of tempData) {
    for (let year = 2000; year <= YEARS_WITH_FORECAST_YEARS; year += 1) {
      const yearTotals = stillWineSaleVolumes[year] ?? 0;
      const yearValue = entity.percentage[year];
      const isNumericalYearValue = yearValue != null && !Number.isNaN(yearValue);

      if (isNumericalYearValue) {
        entity.volume = {
          ...entity.volume,
          [year]: yearValue * yearTotals,
        };
      }
    }
  }

  return tempData;
};

export const getStillWineTotals = (stillWines: DenormalizedBrandSales[]) => {
  const yearTotals: DenormalizedBrandSales['volume'] = {};

  for (const { volume } of stillWines) {
    for (let year = 2000; year <= YEARS_WITH_FORECAST_YEARS; year += 1) {
      const yearAmount = volume[year] ?? 0;

      if (!yearTotals[year]) {
        yearTotals[year] = yearAmount;
        continue;
      }

      yearTotals[year] += yearAmount;
    }
  }

  return yearTotals;
};

interface GetBrandSalesStillWineTotals {
  brandSaleEntities: BrandSalesEntity[];
  brandSales: DenormalizedBrandSales[];
  market: Market;
}

export const getUpdatedBrandSales = ({
  brandSales,
  brandSaleEntities,
  market,
}: GetBrandSalesStillWineTotals) => {
  return brandSales.reduce<DenormalizedBrandSales[]>((updatedBrandSaleRecords, brandSale) => {
    const affectedBrandSale = brandSaleEntities.find(
      bSale =>
        bSale.brandLineGUID === brandSale.brandLineGUID && bSale.countryId === market.marketId
    );

    if (affectedBrandSale) {
      return [...updatedBrandSaleRecords, { ...brandSale, ...affectedBrandSale }];
    }

    return [...updatedBrandSaleRecords, brandSale];
  }, []);
};
