import { type ForwardedRef, forwardRef, useImperativeHandle } from 'react';
import type { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';
import type { IFilterReactComp } from '@ag-grid-community/react';

export interface CustomSetFilterProps extends IFilterParams {
  values: number[] | string[];
}

/** CustomSetFilter looks similar to the usual agSetColumnFilter that is used for 'select' and 'entity' type fields
   but only show filter options that are passed in the `values` prop rather than showing all possible filter options for that field.

   https://web.archive.org/web/20220629130133/https://www.ag-grid.com/react-data-grid/component-filter/
*/
const CustomSetFilter = (props: CustomSetFilterProps, ref: ForwardedRef<IFilterReactComp>) => {
  useImperativeHandle(ref, () => ({
    doesFilterPass(params: IDoesFilterPassParams) {
      const field = props.colDef.field;
      if (!params.node.data || field === undefined) return true;

      let valueOfFieldForNode = (params.node.data as Record<string, number | string | boolean>)[
        field
      ];

      if (valueOfFieldForNode === undefined) return false;

      if (typeof valueOfFieldForNode === 'boolean') {
        valueOfFieldForNode = String(valueOfFieldForNode);
      }

      return [...props.values].includes(valueOfFieldForNode);
    },

    isFilterActive() {
      return true;
    },

    getModel() {
      return { value: props.values };
    },

    setModel() {
      return;
    },
  }));

  return (
    <>
      <div role="listbox" aria-label="Filter List" style={{ padding: '5px 0' }}>
        {props.values.map(value => {
          const id = `custom-filter-list-item-${value}`;
          const label = props.colDef.refData?.[value];
          return (
            <div
              key={id}
              role="option"
              tabIndex={-1}
              aria-selected="true"
              aria-checked="true"
              style={{ height: '24px', top: '24px' }}
            >
              <div className="ag-set-filter-item">
                <div
                  role="presentation"
                  className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field"
                >
                  <div
                    className="ag-input-field-label ag-label ag-checkbox-label"
                    id={`${id}-label`}
                  >
                    {label}
                  </div>
                  <div
                    className="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-checked ag-disabled"
                    role="presentation"
                  >
                    <input
                      className="ag-input-field-input ag-checkbox-input"
                      disabled
                      type="checkbox"
                      id={`${id}-input`}
                      aria-labelledby={`${id}-label`}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default forwardRef(CustomSetFilter);
