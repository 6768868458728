import { cloneElement } from 'react';

// @ts-expect-error: legacy code
export function calcOptionsLength(options) {
  // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-assignment
  options = options || [];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const head = options[0] || {};
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isGrouped = head.options !== undefined;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return isGrouped
    ? // @ts-expect-error: legacy code
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, , @typescript-eslint/restrict-plus-operands
      options.reduce((result, group) => result + group.options.length, 0)
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      options.length;
}

// @ts-expect-error: legacy code
export function flattenGroupedChildren(children) {
  // @ts-expect-error: legacy code
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  return children.reduce((result, child) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const {
      props: { children: nestedChildren = [] },
    } = child;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
    return [...result, cloneElement(child, { type: 'group' }, []), ...nestedChildren];
  }, []);
}

// @ts-expect-error: legacy code
// eslint-disable-next-line @typescript-eslint/no-shadow
function isFocused({ props: { isFocused } = {} } = {}) {
  return isFocused === true;
}

// @ts-expect-error: legacy code
export function getCurrentIndex(children) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  return Math.max(children.findIndex(isFocused), 0);
}

export function createGetHeight({
  // @ts-expect-error: legacy code
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  groupHeadingStyles,
  // @ts-expect-error: legacy code
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  noOptionsMsgStyles,
  // @ts-expect-error: legacy code
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  optionStyles,
  // @ts-expect-error: legacy code
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  loadingMsgStyles,
} = {}) {
  return function getHeight(child = {}) {
    const {
      // @ts-expect-error: legacy code
      props: {
        // @ts-expect-error: legacy code
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        type,
        // @ts-expect-error: legacy code
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        children,
        // @ts-expect-error: legacy code
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        inputValue,
        // @ts-expect-error: legacy code
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        selectProps: { noOptionsMessage, loadingMessage } = {},
      } = {},
    } = child;

    if (type === 'group') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { height = 25 } = groupHeadingStyles;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return height;
    }
    if (type === 'option') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { height = 35 } = optionStyles;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return height;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
    if (typeof noOptionsMessage === 'function' && children === noOptionsMessage({ inputValue })) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { height = 35 } = noOptionsMsgStyles;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return height;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
    if (typeof loadingMessage === 'function' && children === loadingMessage({ inputValue })) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { height = 35 } = loadingMsgStyles;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return height;
    }
    return 35;
  };
}

// @ts-expect-error: legacy code
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unsafe-return, @typescript-eslint/restrict-plus-operands
export const sum = (a, b) => a + b;
