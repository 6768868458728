import type { FC } from 'react';
import { Form } from 'react-bootstrap';

import { type TextColumn } from 'src/components/DataGrid/types';
import { TextInput } from 'src/components/TextInput';
import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import {
  buildDisplayNameBlurHandler,
  buildDisplayNameChangeHandler,
} from 'src/utils/createBrandLineFormHandlers';
import { useGetFieldOptions } from 'src/views/hooks';
import { Label, WarningDiv } from './styledComponents';

const CreateBrandLineDisplayNameSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  const brandLineDisplayNameFieldOptions = useGetFieldOptions(
    'brandLineDisplayName'
  ) as TextColumn['options'];

  if (!formState.category) return null;

  return (
    <Form.Group>
      <Label>Display name</Label>
      <TextInput
        allowedCharacters={brandLineDisplayNameFieldOptions?.allowedCharacters}
        value={formState.displayName}
        onChange={buildDisplayNameChangeHandler(
          dispatchFormAction,
          brandLineDisplayNameFieldOptions?.allowedCharacters
        )}
        maxLength={50}
        onBlur={buildDisplayNameBlurHandler(dispatchFormAction)}
        dataTestId="create_brandline_display_name_input"
      />

      {formState.errorMessages['displayName'] && (
        <WarningDiv>{formState.errorMessages['displayName']}</WarningDiv>
      )}
    </Form.Group>
  );
};

export default CreateBrandLineDisplayNameSection;
