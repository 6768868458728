import type { ChangeEvent, FocusEvent } from 'react';
import { Form } from 'react-bootstrap';

import { hasOnlyAllowedCharacters } from 'src/utils/hasOnlyAllowedCharacters';

interface TextInputProps {
  allowedCharacters?: string | undefined;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  onFocus?(e: FocusEvent<HTMLInputElement>): void;
  placeholder?: string;
  value: string;
}

export const TextInput = ({
  allowedCharacters,
  className,
  dataTestId,
  disabled,
  id,
  maxLength,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  value,
}: TextInputProps) => {
  const validateInputValue = (newValue: string): boolean => {
    if (allowedCharacters) {
      return hasOnlyAllowedCharacters(newValue, allowedCharacters);
    }
    return true;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (e.target.value && !validateInputValue(newValue.trim())) {
      return false;
    }
    onBlur?.(e as FocusEvent<HTMLInputElement>);
    onChange?.(e);
    return true;
  };

  return (
    <Form.Control
      className={className ?? ''}
      data-testid={dataTestId}
      disabled={Boolean(disabled)}
      id={id ?? ''}
      maxLength={maxLength}
      onChange={handleChange}
      onFocus={onFocus}
      placeholder={placeholder}
      type="text"
      value={value}
    />
  );
};
