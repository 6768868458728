import type { Market } from 'src/markets';
import type { Table } from 'src/views/types';

interface Props {
  /** @description - For ensuring grid is un/re-mounted when changing markets */
  market: Market;
  /** @description  For ensuring grid is un/re-mounted for filters and grouping options to correctly apply */
  rowGrouping?: string | undefined;
  table?: Table;
  /** @description - For ensuring grid is un/remounted when changing views */
  viewSlug?: string | undefined;
}

export const useDataGridUnmountKey = ({ market, rowGrouping, table, viewSlug }: Props) => {
  const key = [market.marketSlug, rowGrouping, table?.slug, viewSlug].filter(Boolean).join('/');

  return { key };
};
