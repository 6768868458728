import type { Market } from '../../markets';
import { useSyncContext } from '../../sync';
import type { Table } from '../types';

export function useTables(market: Market) {
  const { getEntities } = useSyncContext();
  const tables = getEntities('tables') as unknown as Table[];

  return tables
    .filter(table => table.marketId === market.marketId)
    .sort(
      ({ displayOrder }, { displayOrder: otherDisplayOrder }) => displayOrder - otherDisplayOrder
    );
}
