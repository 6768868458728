// Disable to prevent the "bootstrap/dist/css/bootstap.min.css" and "./css/main.css" from auto-sorting.
// eslint-disable-next-line simple-import-sort/imports
import { useEffect, useState } from 'react';
import { useParams, useRoutes } from 'react-router-dom';
import { setUser, wrapUseRoutes } from '@sentry/react';
import { CommandBar } from './components/CommandBar';
//  @TODO - remove these legacy imports
import { Authenticator } from 'aws-amplify-react';

// @TODO - move these imports underneath JS imports (bug currently stopping this)
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/main.css';
import { ChecksPage } from './checks';
import { CustomAmplify } from './components/Amplify';
import { SignedIn } from './components/Amplify/SignedIn';
import { HomePage } from './home';
import { MarketPage } from './markets';
import { TablePage } from './views';
import { BrandLinesTableViewPage } from './views/Pages/BrandLinesTableViewPage';
import { BrandSalesTableViewPage } from './views/Pages/BrandSalesTableViewPage';
import { EcommerceTableViewPage } from './views/Pages/EcommerceTableViewPage';
import { ExternalData } from './views/Pages/ExternalData';
import { ForecastsTableViewPage } from './views/Pages/ForecastsTableViewPage/ForecastsTableViewPage';
import { OnPremiseTableViewPage } from './views/Pages/OnPremiseTableViewPage/OnPremiseTableViewPage';
import { OrganicWineTableViewPage } from './views/Pages/OrganicWineTableViewPage';
import { WineColourTableViewPage } from './views/Pages/WineColourTableViewPage';
import { WineRegionTableViewPage } from './views/Pages/WineRegionTableViewPage';
import { WineVarietalTableViewPage } from './views/Pages/WineVarietalTableViewPage';
import { isFeatureFlagSet } from 'src/feature-flags';
import { AdminFeatureFlags } from './views/Pages/AdminFeatureFlags';
import { useAppSelector } from './store';
import LogOut from './components/LogOut';
import { AdminClearCachedMarkets } from './views/Pages/AdminClearCachedMarkets';
import { AdminExportLocalDB } from './views/Pages/AdminExportLocalDB';
import { UIVersionChecker } from './components/UIVersionChecker';

const useSentryRoutes = wrapUseRoutes(useRoutes);

export const App = () => {
  const user = useAppSelector(state => state.auth.user);
  const [error, setError] = useState<PromiseRejectionEvent | ErrorEvent | undefined>(undefined);

  if (error) {
    if (error instanceof PromiseRejectionEvent) {
      throw error.reason;
    } else if (error instanceof ErrorEvent) {
      throw Error(error.message);
    }
  }

  const routing = useSentryRoutes([
    { path: '/', element: <HomePage /> },
    { path: '/logout', element: <LogOut /> },
    { path: '/admin/clear-cached-markets', element: <AdminClearCachedMarkets /> },
    { path: '/admin/features', element: <AdminFeatureFlags /> },
    { path: '/admin/export-local-db', element: <AdminExportLocalDB /> },
    {
      path: '/:marketSlug',
      element: <MarketPage />,
      children: [
        {
          index: true,
          element: <TablePage />,
        },
        {
          path: 'checks',
          element: <ChecksPage />,
          children: [
            {
              index: true,
              element: <ChecksPage />,
            },
            {
              path: ':checkSlug',
              element: <ChecksPage />,
            },
          ],
        },
        {
          path: ':tableSlug',
          element: <TablePage />,
          children: [
            {
              index: true,
              element: <TableViewPageComponent />,
            },
            {
              path: ':viewSlug',
              element: <TableViewPageComponent />,
            },
          ],
        },
      ],
    },
    { path: '*', element: <p>Page not found.</p> },
  ]);

  useEffect(() => {
    // this line is observing their result of async calls which are called without waiting the result(using void)
    window.addEventListener('unhandledrejection', function (evt) {
      setError(evt);
    });

    return () => {
      window.removeEventListener('unhandledrejection', () =>
        console.log('unhandledrejection event is removed.')
      );
    };
  }, []);

  useEffect(() => {
    if (user) {
      setUser({ email: user.email });
    } else {
      setUser(null);
    }
  }, [user]);

  return (
    <>
      {/* @ts-expect-error: Missing 'children' prop on Authenticator component */}
      <Authenticator hideDefault>
        {/* @ts-expect-error: Missing prop assignment - props are presumably being passed down by Authenticated component */}
        <CustomAmplify />
        <SignedIn routing={routing} />
        <UIVersionChecker />
      </Authenticator>
      <CommandBar disabled={!isFeatureFlagSet('command-palette')} />
    </>
  );
};

const TableViewPageComponent = () => {
  const { tableSlug } = useParams<'tableSlug'>();

  if (tableSlug === 'brands') {
    return <BrandLinesTableViewPage />;
  } else if (tableSlug === 'sales') {
    return <BrandSalesTableViewPage />;
  } else if (tableSlug === 'forecasts') {
    return <ForecastsTableViewPage />;
  } else if (tableSlug === 'external-data') {
    return <ExternalData />;
  } else if (tableSlug === 'wine-colour') {
    return <WineColourTableViewPage />;
  } else if (tableSlug === 'organic-wine') {
    return <OrganicWineTableViewPage />;
  } else if (tableSlug === 'wine-varietal') {
    return <WineVarietalTableViewPage />;
  } else if (tableSlug === 'ecommerce') {
    return <EcommerceTableViewPage />;
  } else if (tableSlug === 'wine-region') {
    return <WineRegionTableViewPage />;
  } else if (tableSlug === 'on-premise') {
    return <OnPremiseTableViewPage />;
  }

  throw new Error('Page not found.');
};
