import { SigningInType } from './Base';
import { SignIn } from './SignIn';

interface Props {
  onStateChange: (state: string) => void;
}

export const SignInWithPasswordAuth = (props: Props) => {
  return <SignIn signInType={SigningInType.PasswordAuth} {...props} />;
};
