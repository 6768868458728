import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ModalHeader } from 'src/css/styled-components';
import type { RootState } from 'src/store';
import { hideAddModal } from 'src/store/features/brandLineAdd/actions';
import { ModalState } from 'src/utils/addBrandLineUtils';
import { useModalDimensions } from 'src/utils/utilHooks';
import CreateBrandLine from './CreateBrandLine';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { DivOverlay } from './styledComponents';

const ChooseBrandLineModal = ({
  modalState,
  closeModal,
  modalWidth,
  modalHeight,
}: {
  modalState: ModalState | undefined;
  closeModal: () => void;
  modalWidth: number;
  modalHeight: number;
}) => {
  switch (modalState) {
    case undefined:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    case ModalState.Step1:
      return <StepOne closeModal={closeModal} modalHeight={modalHeight} />;
    case ModalState.Step2:
      return <StepTwo closeModal={closeModal} />;
    case ModalState.Create:
      /* This is the one from Sales table Step One */
      return (
        <CreateBrandLine
          closeModal={closeModal}
          modalHeight={modalHeight}
          modalWidth={modalWidth}
        />
      );
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Invalid Modal State ${modalState}`);
  }
};

const AddBrandLineModal = () => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(hideAddModal());
  };

  const modalState = useSelector<RootState>(state => state.brandLineAdd.modalState);
  const { modalRef, modalWidth, modalHeight } = useModalDimensions(1000, 500);
  const modalClass = 'add-brandline-modal';
  const modalTitle = 'Add brand line';

  return (
    <DivOverlay ref={modalRef}>
      <Modal
        show
        centered
        animation={false}
        backdrop="static"
        size="xl"
        dialogClassName={modalClass}
      >
        <ModalHeader data-testid="brand_line_modal">{modalTitle}</ModalHeader>
        <ChooseBrandLineModal
          modalState={modalState as ModalState}
          closeModal={closeModal}
          modalHeight={modalHeight}
          modalWidth={modalWidth}
        />
      </Modal>
    </DivOverlay>
  );
};

export default AddBrandLineModal;
