import type { Column, Row } from 'src/components/DataGrid';
import type { Volume } from 'src/types/Entity';
import type { PinnedTotalItem } from '../views/Pages/types/GridAPI';

interface PinnedTopRowTotal extends PinnedTotalItem {
  brandLineDisplayName: string;
}

export const TopTotalsRow = (
  marketName: string,
  sectionName: string,
  columns: Column[],
  data: Row[]
): [PinnedTopRowTotal] | undefined => {
  const totalRow: [PinnedTopRowTotal] = [
    { volume: [], brandLineDisplayName: `${marketName}: ${sectionName}` },
  ];
  const totalRowVolumes: Record<string, unknown> = {};

  const columnsToTotal = columns.filter(
    (col: Column) => col.type === 'number' && (col.aggregable === undefined || col.aggregable)
  );

  columnsToTotal.forEach((col: Column) => {
    data.forEach((dataRow: Row) => {
      const prefix: string = col.field.split('.')[0] ?? '';
      const suffix: string = col.field.split('.')[1] ?? '';
      const volumes = dataRow[prefix] as Record<string, unknown>;
      if (prefix === 'volume') {
        const value = volumes[suffix] as keyof Row;
        if (totalRowVolumes[suffix] && !isNaN(value as unknown as number)) {
          totalRowVolumes[suffix] += volumes[suffix] as keyof Row;
        } else if (!totalRowVolumes[col.field] && !isNaN(value as unknown as number)) {
          totalRowVolumes[suffix] = volumes[suffix] as keyof Row;
        }
      }
    });
  });

  totalRow[0].volume = totalRowVolumes as Volume;

  return totalRow;
};
