import { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// @TODO TODO: - remove these legacy imports
import { Button, Modal, Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { set as setProperty } from 'dot-prop';
import { v4 as uuidv4 } from 'uuid';

import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import {
  DataGrid,
  type unsafe_GridApi,
  type unsafe_GridReadyEvent,
  type unsafe_NewValueParams,
  type unsafe_PostSortRowsParams,
  type unsafe_RowClassParams,
  type unsafe_RowNode,
} from 'src/components/DataGrid';
import { Dropdown } from 'src/components/Dropdown';
import { GroupingMenu } from 'src/components/GroupingMenu';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { Content, ModalHeader } from 'src/css/styled-components';
import { useDataGridUnmountKey } from 'src/hooks/useDataGridUnmountKey/useDataGridUnmountKey';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedWineVarietalSaleData } from 'src/records/types/DenormalizedWineVarietalSaleData';
import { denormalizeWineVarietalSaleDataUtil } from 'src/records/utils/denormalizers';
import { useSyncContext } from 'src/sync';
import type { OriginsEntity } from 'src/types/entity/Origins';
import type { WineVarietalOptionsEntity } from 'src/types/entity/WineVarietalOptions';
import type { WineVarietalSaleDataEntity } from 'src/types/entity/WineVarietalSaleData';
import type { StrictOmit } from 'src/types/MappedTypes';
import isDeepEqual from 'src/utils/isDeepEqual';
import { getBooleanValueFromString, isStringifiedBooleanValue } from 'src/utils/string';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import { useWineRegionAndVarietalContextMenu } from 'src/views/hooks/useWineRegionAndVarietalContextMenu/useWineRegionAndVarietalContextMenu';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import { pasteFromClipboard } from 'src/views/utils/grid/gridClipboardPasteUtils';
import { sortRowsWithValueToBottom } from 'src/views/utils/sorts/sortRowsWithValueToBottom';
import { PopupType } from '../common/types';
import type {
  OriginSelectionOption,
  OriginSelectionOptionsList,
} from '../types/wineRegionAndVarietal';
import { getOriginOptions, isOldWorld } from '../utils/wineRegionAndVarietal';
import { getWineVarietalOptions } from './helpers';

import 'src/css/grid.css';

const isExternalFilterPresent = () => true;

export const WineRegionTableViewPage: FC = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);
  const origins = useRecords<OriginsEntity>('origins');
  const originsData = useMemo(() => {
    return origins.filter(isOldWorld);
  }, [origins]);

  const wineVarietalOptionsData = useRecords<WineVarietalOptionsEntity>('wineVarietalOptions');

  const dataGridApiRef = useRef<unsafe_GridApi<DenormalizedWineVarietalSaleData>>();

  const SALES_UPDATE_DIRECTLY_ON_AG_GRID = 'SalesUpdateDirectlyOnAgGrid';

  const {
    updateEntity,
    deleteEntity,
    createEntity,
    onEntitiesReceived,
    onTransactionCreated,
    getEntitiesLookup,
  } = useSyncContext();
  const entitiesLookup = getEntitiesLookup();
  const updateAgGridData = (data: Record<string, DenormalizedWineVarietalSaleData[]>) => {
    // get only this market data from provider.
    const wineVarietalData =
      data[table.source]?.filter(item => item.countryId === market.marketId) ?? [];

    let selectedNode: unsafe_RowNode | undefined;

    wineVarietalData.forEach(wineVarietalEntity => {
      // extra control for skipping sales that does not belong to this market.
      if (wineVarietalEntity.countryId !== market.marketId) {
        return;
      }

      dataGridApiRef.current?.forEachNode(
        (node: unsafe_RowNode<DenormalizedWineVarietalSaleData>) => {
          if (node.data && node.data[getRowId()] === wineVarietalEntity[getRowId()]) {
            selectedNode = node;
            return;
          }
        }
      );

      if (selectedNode) {
        // if comming brand sale has isDeleted flag, then remove the item from the grid.
        if (wineVarietalEntity.isDeleted) {
          dataGridApiRef.current?.applyTransaction({ remove: [selectedNode.data] });
        } else {
          // update the existing ag grid wine colour sale item.
          selectedNode.data = denormalizeWineVarietalSaleDataUtil(entitiesLookup, {
            ...wineVarietalEntity,
          });
          dataGridApiRef.current?.applyTransaction({
            update: [selectedNode.data],
          });
        }
      } else {
        const dataToBeAdded: DenormalizedWineVarietalSaleData[] = [
          {
            ...denormalizeWineVarietalSaleDataUtil(entitiesLookup, {
              ...wineVarietalEntity,
            }),
          },
        ];

        dataGridApiRef.current?.forEachNode(
          (node: unsafe_RowNode<DenormalizedWineVarietalSaleData>) => {
            if (
              node.data &&
              node.data.wineVarietalOption === 'Other Regions' &&
              node.data.originId === wineVarietalEntity.originId
            ) {
              dataToBeAdded.push({
                ...denormalizeWineVarietalSaleDataUtil(entitiesLookup, {
                  ...node.data,
                }),
              });
              dataGridApiRef.current?.applyTransaction({ remove: [node.data] });
              return;
            }
          }
        );

        // if coming brand sale does not exist, add it to the grid.
        dataGridApiRef.current?.applyTransaction({
          add: dataToBeAdded,
        });
      }
    });
  };

  useEffect(() => {
    // When data received from other tabs or received from delta sync, update AG-GRID data without re-rendering.
    return onEntitiesReceived(data => {
      updateAgGridData(data as Record<string, DenormalizedWineVarietalSaleData[]>);
    });
  });

  useEffect(() => {
    // After any transaction created, update AG-GRID data without re-rendering.
    return onTransactionCreated(({ entity, transaction }) => {
      // in order to avoid updating brand sale volumes twice when any record is updated.
      if (transaction.actionType !== SALES_UPDATE_DIRECTLY_ON_AG_GRID) {
        updateAgGridData({
          [transaction.entityType]: [entity as DenormalizedWineVarietalSaleData],
        });
      }
    });
  });

  const originOptions = useRef<OriginSelectionOptionsList>([
    { options: getOriginOptions(originsData) },
  ]);

  const [wineVarietalOptions, setWineVarietalOptions] = useState<OriginSelectionOptionsList>([
    { options: getWineVarietalOptions(wineVarietalOptionsData, []) },
  ]);
  const [selectedOriginId, setSelectedOriginId] = useState<string>();
  const [selectedWineVarietalOptionId, setSelectedWineVarietalOptionId] = useState<
    string | undefined
  >(undefined);

  const { viewSlug } = useParams<'viewSlug'>();
  const view = useMemo(
    () => views.find(view => view.slug === viewSlug),
    // Don't depend on views. Each time we do useRecords we filter on all views
    // so receive a new object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewSlug]
  );
  const groupingColumn = useMemo(
    () => (view?.leafField ? { leafField: view.leafField } : {}),
    [view?.leafField]
  );

  const fields = useMemo(() => {
    return table.fields;
  }, [table.fields]);

  const section = useMemo(() => {
    return sections.find(section => section.id === view?.sectionId);
    // Don't depend on sections, we get a new object each time with useRecords
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view?.sectionId]);

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const wineVarietalSaleData = useRecords<DenormalizedWineVarietalSaleData>(
    table.source,
    market.marketId
  );
  const gridData = useMemo(() => {
    return wineVarietalSaleData.filter(isOldWorld);
  }, [wineVarietalSaleData]);

  const { convertFieldsToColumns } = useColumns();

  const { columns, columnGrouping } = useMemo(
    () =>
      convertFieldsToColumns(fields, {
        periodiseByRows: false,
        lockedView: Boolean(view?.locked),
      }),
    // do not put convertFieldsToColumns in this dependency list as it causes re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, view?.name, view?.locked]
  );
  const {
    handleColumnOrderChange,
    handleColumnVisibilityChange,
    handleColumnWidthsChange,
    handlePinnedColumnsChange,
    handleRowGroupingChange,
    state,
  } = useGridViewState(table);

  const { key } = useDataGridUnmountKey({
    market,
    table,
    viewSlug,
    rowGrouping: state.rowGrouping?.join(''),
  });

  const pinnedColumns = useMemo(
    () => state.pinnedColumns ?? { left: view?.pinnedColumns ?? [] },
    [view?.pinnedColumns, state]
  );

  const handleCellValueChange = useCallback(
    ({
      column,
      data,
      newValue,
      oldValue,
    }: unsafe_NewValueParams<DenormalizedWineVarietalSaleData>) => {
      const hasDifferentValue = !isDeepEqual(oldValue, newValue);

      if (hasDifferentValue) {
        const entityGuid = String(data[table.primaryField]);
        const payloadValue = isStringifiedBooleanValue(newValue)
          ? getBooleanValueFromString(newValue)
          : (newValue as unknown);
        const payload = setProperty({}, column.getColId(), payloadValue);

        void updateEntity(table.source, entityGuid, payload, SALES_UPDATE_DIRECTLY_ON_AG_GRID);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.name, viewSlug]
  );

  const postSortRows = useCallback(
    ({ nodes }: unsafe_PostSortRowsParams<DenormalizedWineVarietalSaleData>) => {
      sortRowsWithValueToBottom<string>(nodes, 'wineVarietalOption', 'Other Regions');
    },
    []
  );

  const doesExternalFilterPass = () => true;

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const handleOriginOptionSelection = (selectedOriginId: number) => {
    const existingWineRegions: DenormalizedWineVarietalSaleData[] = [];

    if (dataGridApiRef.current) {
      dataGridApiRef.current.forEachNode(({ group, data }) => {
        const rowEntity = data as DenormalizedWineVarietalSaleData | null;
        if (group || rowEntity == null) {
          return;
        }

        existingWineRegions.push(rowEntity);
      });

      setSelectedOriginId(selectedOriginId.toString());
      setWineVarietalOptions([
        { options: getWineVarietalOptions(wineVarietalOptionsData, existingWineRegions) },
      ]);
    }
  };

  const handleSelectWineVarietalOption = ({ value }: OriginSelectionOption) => {
    setSelectedWineVarietalOptionId(value.toString());
  };

  const contextMenu = useWineRegionAndVarietalContextMenu({
    canRemoveRecords: table.canRemoveRecords,
    canWriteRecords: table.canWriteRecords,
    onOriginOptionSelection: handleOriginOptionSelection,
    table: 'wineRegion',
  });

  const handleGridReady = useCallback(
    (params: unsafe_GridReadyEvent) => {
      dataGridApiRef.current = params.api;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [section?.name, gridData, viewSlug]
  );

  const getRowClass = useCallback((params: unsafe_RowClassParams) => {
    if (params.node.group) {
      return `row-group-${params.node.level}`;
    }
    return '';
  }, []);

  const deleteWineVarietal = () => {
    const entityGuid = contextMenu.popupConfiguration?.selectedItem[getRowId()] as string;
    if (entityGuid) {
      void deleteEntity(table.source, entityGuid);
    }

    contextMenu.handleClearPopupConfiguration();
  };

  const addWineVarietal = () => {
    if (selectedOriginId && selectedWineVarietalOptionId) {
      const newVarietal: StrictOmit<WineVarietalSaleDataEntity, 'isDeleted' | 'lastUpdatedDate'> = {
        wineVarietalGUID: `${uuidv4().toUpperCase()}`,
        countryId: market.marketId,
        originId: parseInt(selectedOriginId),
        wineVarietalOptionId: parseInt(selectedWineVarietalOptionId),
        volume: {},
      };

      void createEntity(table.source, newVarietal);
    }

    contextMenu.handleClearPopupConfiguration();
    setSelectedOriginId(undefined);
    setSelectedWineVarietalOptionId(undefined);
  };

  if (!viewSlug) return <p>Loading.</p>;

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            allTableFields={fields}
            columnVisibility={state.columnVisibility}
            pinnedColumns={state.pinnedColumns}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnPinnedChange={handlePinnedColumnsChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            disableChangingVisibility={!!view.locked}
            disableChangingOrder={!table.canChangeColumnOrder}
          />
          <GroupingMenu
            columns={fields}
            disabled={!table.canChangeRowGrouping || (view.locked ?? false)}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            onRowGroupingChange={handleRowGroupingChange}
          />
        </Nav>
      </Navbar>

      <Container id="viewContainer">
        {views.length > 1 && (
          <StyledSidebar sidebarOpen data-testid="sidebar">
            <SidebarHeader sidebarOpen>
              <SidebarTitle>Options</SidebarTitle>
            </SidebarHeader>
            <SidebarBody sidebarOpen>
              {sections.length > 0 && (
                <ViewSectionSwitcher currentSection={section} currentView={view} />
              )}
              <ViewSwitcher currentSection={section} currentView={view} />
            </SidebarBody>
          </StyledSidebar>
        )}
        <Content>
          <DataGrid<DenormalizedWineVarietalSaleData>
            debug
            key={key}
            aggregation={view.aggregation}
            canChangeColumnOrder={table.canChangeColumnOrder}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={table.canChangeRowGrouping}
            columnGrouping={columnGrouping}
            columnOrder={state.columnOrder}
            columns={columns}
            columnVisibility={state.columnVisibility}
            columnWidths={state.columnWidths}
            filter={view.filter ?? []}
            getRowId={getRowId}
            groupingColumn={groupingColumn}
            onCellValueChange={handleCellValueChange}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnWidthChange={handleColumnWidthsChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onRowGroupingChange={handleRowGroupingChange}
            onGridReady={handleGridReady}
            pinnedColumns={pinnedColumns}
            getRowClass={getRowClass}
            rows={gridData}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            sort={view.sort}
            postSortRows={postSortRows}
            getContextMenuItems={contextMenu.getContextMenuActions}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            sendToClipboard={sendToClipboard}
            processDataFromClipboard={pasteFromClipboard}
            enterNavigatesVerticallyAfterEdit
            enterNavigatesVertically
          />
        </Content>

        <Modal
          show={contextMenu.popupConfiguration?.popupType === PopupType.DeleteWarning}
          centered
          animation={false}
          backdrop="static"
        >
          <ModalHeader data-testid="wine_Region_modal">
            <>
              Delete region: {contextMenu.popupConfiguration?.selectedItem['originName']} -
              {contextMenu.popupConfiguration?.selectedItem['wineVarietalOption']}
            </>
          </ModalHeader>
          <Modal.Body>You can not delete this region because it has volume data</Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="link"
              onClick={contextMenu.handleClearPopupConfiguration}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={contextMenu.popupConfiguration?.popupType === PopupType.DeleteConfirmation}
          centered
          animation={false}
          backdrop="static"
        >
          <ModalHeader data-testid="wine_Region_modal">
            <>
              Delete region: {contextMenu.popupConfiguration?.selectedItem.originName} -
              {contextMenu.popupConfiguration?.selectedItem.wineVarietalOption}
            </>
          </ModalHeader>
          <Modal.Body>
            {' '}
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <>Are you sure you want to delete this row?</>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="link"
              onClick={contextMenu.handleClearPopupConfiguration}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" onClick={deleteWineVarietal}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={contextMenu.popupConfiguration?.popupType === PopupType.AddNewItem}
          centered
          animation={false}
          backdrop="static"
        >
          <ModalHeader data-testid="wine_Region_modal">Add new region</ModalHeader>
          <Modal.Body>
            <Dropdown
              data-testid="add_new_varietal_origin"
              options={originOptions.current}
              selected={selectedOriginId}
              placeholder="Select origin"
              onSelect={(data: OriginSelectionOption) => handleOriginOptionSelection(data.value)}
            />
            <br />
            <Dropdown
              data-testid="add_new_varietal_varietal"
              options={wineVarietalOptions}
              selected={selectedWineVarietalOptionId}
              placeholder="Select region"
              onSelect={handleSelectWineVarietalOption}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              variant="link"
              onClick={contextMenu.handleClearPopupConfiguration}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={addWineVarietal}
              disabled={!selectedWineVarietalOptionId}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};
