import type { ChangeEvent, FocusEvent } from 'react';
import { Form } from 'react-bootstrap';

import {
  CharacterSetEnum,
  NumberEntryEnum,
  NumberFormat,
  type TextEntryEnum,
  UserNumberEntry,
  UserTextEntry,
} from '../shared';

const EXTENDED_CHAR_REGEX =
  /^[A-Za-zÀ-ÖØ-öø-ÿa-zA-Z0-9-']+(\s{0,1}[A-Za-zÀ-ÖØ-öø-ÿa-zA-Z0-9-()'/%&._\b?:!+,#$* ])*$/;
const LIMITED_CHAR_REGEX = /^\s{0,1}[a-zA-Z0-9-()'/%&.\b?:!+,#$* ]*$/;

interface TextInputProps {
  textEntryEnum?: `${TextEntryEnum}`;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  onFocus?(e: FocusEvent<HTMLInputElement>): void;
  value?: string;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  type?: 'text';
}

export const ValidTextInput = (props: TextInputProps) => {
  const { textEntryEnum, ...formControlProps } = props;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { onBlur, onChange } = formControlProps;
  // @ts-expect-error: legacy code
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const { maxLength, characterSet } = UserTextEntry[`${textEntryEnum}`];

  const validateInputValue = (newValue: string): boolean => {
    // patternOfAccentedChars
    if (characterSet === CharacterSetEnum.EXTENDED) {
      return EXTENDED_CHAR_REGEX.test(newValue.trim());
    }

    // patternOfLatinChars
    if (characterSet === CharacterSetEnum.LIMITED) {
      return LIMITED_CHAR_REGEX.test(newValue.trim());
    }

    return true;
  };

  // @ts-expect-error: legacy code
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    const newValue = e.target.value;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    if (e.target.value && !validateInputValue(newValue.trim())) {
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    onBlur && onBlur(e as FocusEvent<HTMLInputElement>);
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    onChange && onChange(e);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return <Form.Control {...formControlProps} onChange={handleChange} maxLength={maxLength} />;
};

export const isNumericInputValid = (
  usersInput: string,
  numberEntryEnum: NumberEntryEnum
): boolean => {
  const {
    // @ts-expect-error: legacy code
    biggerThan,
    // @ts-expect-error: legacy code
    lessThan,
    // @ts-expect-error: legacy code
    biggerEqualThan,
    // @ts-expect-error: legacy code
    lessEqualThan,
    // @ts-expect-error: legacy code
    allowEstimates,
    // @ts-expect-error: legacy code
    format,
    // @ts-expect-error: legacy code
    allowNegatives,
  } = UserNumberEntry[numberEntryEnum];

  const isSingleOccurenceInString = (character: string): boolean =>
    usersInput.split('').reduce((acc, el) => (el === character ? acc + 1 : acc), 0) === 1;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (usersInput === undefined) return true;
  let checkValue: string | number = usersInput;

  if (checkValue && typeof checkValue === 'string' && format === NumberFormat.PERCENTAGE) {
    checkValue = checkValue.replace('%', '');
  }

  if (checkValue && typeof checkValue === 'string' && format === NumberFormat.PERCENTAGENEGOK) {
    checkValue = checkValue.replace('-', '');
  }

  // check if user typed . (dot) first, then prepend it with zero
  if (
    checkValue &&
    typeof checkValue === 'string' &&
    usersInput.startsWith('.') &&
    isSingleOccurenceInString('.')
  ) {
    checkValue = `0${checkValue}`;
  }

  if (
    allowEstimates &&
    typeof checkValue === 'string' &&
    usersInput.startsWith('~') &&
    isSingleOccurenceInString('~') &&
    usersInput.length >= 1
  ) {
    checkValue = usersInput.substring(1);
  }

  if (
    allowEstimates &&
    typeof checkValue === 'string' &&
    usersInput.startsWith('?') &&
    isSingleOccurenceInString('?') &&
    usersInput.length >= 1
  ) {
    checkValue = usersInput.substring(1);
  }

  if (
    allowNegatives &&
    typeof checkValue === 'string' &&
    usersInput.startsWith('-') &&
    isSingleOccurenceInString('-') &&
    usersInput.length >= 1
  ) {
    checkValue = usersInput.substring(1);
  }

  let equalCheck = false;
  if (
    typeof checkValue === 'string' &&
    numberEntryEnum === NumberEntryEnum.Volume &&
    (usersInput.toUpperCase().startsWith('=T') || usersInput.toUpperCase().startsWith('=L')) &&
    usersInput.length > 2
  ) {
    checkValue = usersInput.substring(2);
    equalCheck = true;
  }

  if (numberEntryEnum === NumberEntryEnum.Volume && Math.expm1(1e-10) === parseFloat(checkValue))
    return true;
  if (
    numberEntryEnum === NumberEntryEnum.Volume &&
    checkValue &&
    checkValue.toString().toLocaleLowerCase() === 'min'
  )
    return true;

  // check if string starts with special characters
  if (
    typeof checkValue === 'string' &&
    (usersInput.startsWith('~') || usersInput.startsWith('?') || usersInput.startsWith('-')) &&
    usersInput.length > 1 &&
    Number.isNaN(parseFloat(checkValue))
  ) {
    return false;
  }

  if (
    typeof checkValue === 'string' &&
    !usersInput.startsWith('~') &&
    !usersInput.startsWith('?') &&
    !usersInput.startsWith('-') &&
    Number.isNaN(parseFloat(checkValue))
  ) {
    return false;
  }

  if (numberEntryEnum === NumberEntryEnum.Volume) {
    if (!Number(checkValue) && !equalCheck && Number(checkValue) !== 0) return false;
  }

  checkValue = parseFloat(checkValue);
  if (format === NumberFormat.WITHTWODECIMALPLACES) {
    checkValue = Math.round(checkValue * 100) / 100;
  }

  if (format === NumberFormat.WITHTHREEDECIMALPLACES) {
    checkValue = Math.round(checkValue * 1000) / 1000;
  }

  // check for price will happen onBlur, this is to avoid the problem when you cannot type 0, ie for 0.75
  if (numberEntryEnum !== NumberEntryEnum.Price) {
    if (biggerEqualThan != null && checkValue < biggerEqualThan) return false;
    if (biggerThan != null && checkValue <= biggerThan) return false;
  }
  if (lessThan != null && checkValue >= lessThan) return false;
  if (lessEqualThan != null && checkValue > lessEqualThan) return false;

  if (numberEntryEnum === NumberEntryEnum.PercentageNegOK) {
    if (parseFloat(usersInput) < -100) return false;
  }

  return true;
};

interface NumberFieldProps {
  numberEntryEnum: NumberEntryEnum;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  onFocus?(e: FocusEvent<HTMLInputElement>): void;
  name?: string;
  isInvalid?: boolean;
  isValid?: boolean;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  dataTestId?: string;
}

export const ValidNumberField = (props: NumberFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { onBlur, onChange, numberEntryEnum, dataTestId, ...inputProps } = props;

  // @ts-expect-error: legacy code
  const { format } = UserNumberEntry[numberEntryEnum];
  const getValidationRegexPattern = () => {
    if (format === NumberFormat.INTEGER) {
      return /^\s?[0-9]*$/;
    }

    if (format === NumberFormat.PERCENTAGE) {
      return /^\s?[0-9.,]*$/;
    }

    if (format === NumberFormat.PERCENTAGENEGOK) {
      return /^\s?[-]?[0-9.,]*$/;
    }

    return /^\s?[0-9-.,?~]*$/;
  };

  const validateInputValue = (newValue: string): boolean =>
    getValidationRegexPattern().test(newValue.trim()) &&
    isNumericInputValid(newValue, numberEntryEnum);

  // @ts-expect-error: legacy code
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    const newValue = e.target.value;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
    if (e.target.value && !validateInputValue(newValue.trim())) {
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    onBlur && onBlur(e as FocusEvent<HTMLInputElement>);
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    onChange && onChange(e);
  };

  return (
    <Form.Control
      {...inputProps}
      onBlur={onBlur}
      onChange={handleChange}
      autoComplete="off"
      data-testId={dataTestId}
    />
  );
};
