import React from 'react';

export function useWhatPropsChanged(props: Record<string, unknown>, enable: boolean) {
  const previous = React.useRef(props);

  React.useEffect(() => {
    if (process.env.REACT_APP_MODE === 'development' && enable) {
      const changed = Object.entries(props).reduce<Record<string, unknown>>((a, [key, prop]) => {
        if (previous.current[key] === prop) return a;
        return {
          ...a,
          [key]: {
            previous: previous.current[key],
            next: prop,
          },
        };
      }, {});

      if (Object.keys(changed).length > 0) {
        console.group('Props that changed');
        console.log(changed);
        console.groupEnd();
      }

      previous.current = props;
    }
  }, [props, enable]);
}
