import { useCallback, useEffect, useMemo } from 'react';
// @TODO - remove these legacy imports
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import { DataGrid, type unsafe_GetContextMenuItemsParams } from 'src/components/DataGrid';
import { GroupingMenu } from 'src/components/GroupingMenu';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { Content } from 'src/css/styled-components';
import { useRecords } from 'src/records/hooks/useRecords';
import type { Entity } from 'src/types/Entity';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import { absoluteChange } from 'src/views/utils';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import createContextMenu from '../common/getContextMenuItems';

import 'src/css/grid.css';

export const ExternalData = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);

  const { viewSlug } = useParams<'viewSlug'>();
  const view = useMemo(() => views.find(view => view.slug === viewSlug), [views, viewSlug]);

  const section = useMemo(
    () => sections.find(section => section.id === view?.sectionId),
    [sections, view]
  );

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const gridData = useRecords(table.source, market.marketId);

  const { convertFieldsToColumns } = useColumns();

  const { columns, columnGrouping } = useMemo(
    () => convertFieldsToColumns(table.fields, { periodiseByRows: false }),
    // Exclude convertFieldsToColumns from list to prevent redraw
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.fields]
  );

  const {
    handleColumnOrderChange,
    handleColumnVisibilityChange,
    handleColumnWidthsChange,
    handlePinnedColumnsChange,
    handleRowGroupingChange,
    state,
  } = useGridViewState(table);

  const formulaFunctions = useMemo(() => ({ absoluteChange }), []);

  const groupingColumn = useMemo(
    () => (view?.leafField ? { leafField: view.leafField } : {}),
    [view?.leafField]
  );

  const pinnedColumns = useMemo(
    () => state.pinnedColumns ?? { left: view?.pinnedColumns ?? [] },
    [view, state]
  );

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const getContextMenuItems = useCallback(
    (params: unsafe_GetContextMenuItemsParams<Entity>) =>
      createContextMenu(params, table.canRemoveRecords),
    [table.canRemoveRecords]
  );

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            allTableFields={table.fields}
            columnVisibility={state.columnVisibility}
            pinnedColumns={state.pinnedColumns}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnPinnedChange={handlePinnedColumnsChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            disableChangingVisibility={!table.canChangeColumnVisibility}
            disableChangingOrder={!table.canChangeColumnOrder}
          />
          <GroupingMenu
            columns={table.fields}
            disabled={!table.canChangeRowGrouping || view.locked}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            onRowGroupingChange={handleRowGroupingChange}
          />
        </Nav>
      </Navbar>

      <Container id="viewContainer">
        {views.length > 1 && (
          <StyledSidebar sidebarOpen data-testid="sidebar">
            <SidebarHeader sidebarOpen>
              <SidebarTitle>Options</SidebarTitle>
            </SidebarHeader>
            <SidebarBody sidebarOpen>
              {sections.length > 0 && (
                <ViewSectionSwitcher currentSection={section} currentView={view} />
              )}
              <ViewSwitcher currentSection={section} currentView={view} />
            </SidebarBody>
          </StyledSidebar>
        )}
        <Content>
          <DataGrid<Entity>
            debug
            aggregation={view.aggregation}
            canChangeColumnOrder={table.canChangeColumnOrder}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={table.canChangeRowGrouping}
            columnGrouping={columnGrouping}
            columnOrder={state.columnOrder}
            columns={columns}
            columnVisibility={state.columnVisibility}
            columnWidths={state.columnWidths}
            filter={view.filter}
            formulaFunctions={formulaFunctions}
            getRowId={getRowId}
            groupingColumn={groupingColumn}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnVisibilityChange={handleColumnVisibilityChange}
            onColumnWidthChange={handleColumnWidthsChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            onRowGroupingChange={handleRowGroupingChange}
            pinnedColumns={pinnedColumns}
            rows={gridData}
            rowGrouping={view.locked ? view.rowGrouping : state.rowGrouping}
            sort={view.sort}
            getContextMenuItems={getContextMenuItems}
            sendToClipboard={sendToClipboard}
          />
        </Content>
      </Container>
    </>
  );
};
