import { type FC, useCallback, useMemo } from 'react';
// @TODO - remove these legacy imports
import { FormGroup } from 'react-bootstrap';
import type { OptionTypeBase } from 'react-select';

import type { Column } from 'src/components/DataGrid';
import { Dropdown } from './Dropdown';

interface GroupingMenuItemProps {
  columns: Column[];
  current?: Column | undefined;
  disabled?: boolean | undefined;
  index: number;
  onChange: (column: Column | null, index: number) => void;
}

export const GroupingMenuItem: FC<GroupingMenuItemProps> = ({
  columns,
  current,
  disabled,
  index,
  onChange,
}) => {
  const handleSelect = useCallback(
    (option?: OptionTypeBase) => {
      if (option) {
        const selectedField = columns.find(col => col.field === option['value']);
        selectedField && onChange(selectedField, index);
      } else {
        onChange(null, index);
      }
    },
    [columns, index, onChange]
  );

  const options = useMemo(() => {
    const options = [];

    if (current) options.push({ label: current.headerName, value: current.field });

    columns.forEach(({ field, headerName }) => {
      options.push({
        label: headerName,
        value: field,
      });
    });

    return options;
  }, [columns, current]);

  return (
    <FormGroup
      // @TODO - move this into actual css files
      style={{
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
        marginLeft: '8px',
        marginRight: '8px',
        width: '250px',
      }}
      id="grouping-menu-select"
    >
      <Dropdown
        isClearable
        isDisabled={disabled ?? false}
        options={[{ options }]}
        placeholder="Select column"
        selected={current?.field}
        onSelect={handleSelect}
      />
    </FormGroup>
  );
};
