import { type MutableRefObject, useEffect, useRef } from 'react';

import type { DataGridProps } from '../../DataGrid';
import type { GridOptions, Row } from '../../types';
import { useGridLogger } from '../utils';

export const useGridInitialisation = <R extends Row>(
  initialOptions: GridOptions<R>,
  props: Pick<DataGridProps<R>, 'debug'>
): MutableRefObject<GridOptions<R>> => {
  const gridRef = useRef(initialOptions);

  const { debug = false } = props;

  useEffect(() => {
    gridRef.current.debug = debug;
  }, [debug]);

  const logger = useGridLogger(gridRef, 'useGridInitialisation');

  useEffect(() => {
    return () => {
      logger.log('Unmounting');
    };
  }, [logger]);

  return gridRef;
};
