import type { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildAgeStyleHandler } from 'src/utils/createBrandLineFormHandlers';
import { mapNumericsToValues, mapNumericToValue } from 'src/utils/createBrandLineFormMappers';
import { Label, WarningDiv } from './styledComponents';

const CreateBrandLineAgeStyleSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  if (!formState.category) return null;
  if (!formState.ageStyleVisible) return null;

  return (
    <Form.Group>
      <Label>Age Style</Label>
      <Select
        options={mapNumericsToValues(formState.ageStyleOptions)}
        value={mapNumericToValue(formState.ageStyle)}
        onChange={buildAgeStyleHandler(dispatchFormAction, formState)}
        isDisabled={formState.ageStyleDisabled || formState.attributesDisabled}
        id="create_brand_line_age_style_dropdown"
      />
      {formState.errorMessages['ageStyle'] && (
        <WarningDiv>{formState.errorMessages['ageStyle']}</WarningDiv>
      )}
    </Form.Group>
  );
};

export default CreateBrandLineAgeStyleSection;
