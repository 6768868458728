import type { RecordKind } from 'src/store/features//types/BrandLineDialog';
import type { BrandsEntity } from 'src/types/entity/Brands';
import type { Record, Records } from './types';

/*
  "_" prefix instructs Webpack's ESLint to not report the parameter as unused,
  which is likely happening from it not understanding type predicates:
  https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
*/
const isBrands = (recordKind: RecordKind | null, _records: Record[]): _records is BrandsEntity[] =>
  recordKind === 'brand';

export const getExistingRecordNames = (recordKind: RecordKind | null, records: Records) => {
  if (isBrands(recordKind, records)) {
    return records.map(record => record.brandName.toLowerCase());
  }

  return records.map(record => record.ownerName.toLowerCase());
};
