import styled from 'styled-components';

import type { LookUp } from '../shared';
import { smSizeWindow } from '../utils/appUtils';

interface StyleProps {
  sidebarOpen: boolean;
  theme: LookUp;
}

const sidebarWidth = smSizeWindow ? `${window.innerWidth}px` : '250px';
const sidebarBtnWidth = '32px';

export const StyledSidebar = styled.aside`
  min-width: ${sidebarWidth};
  max-width: ${sidebarWidth};
  border-top: solid 1px lightgrey;
  height: 100%;
  transition: all 0.3s ease;
  margin-left: ${({ sidebarOpen }: StyleProps) => {
    if (!sidebarOpen) return `calc(${sidebarBtnWidth} - ${sidebarWidth})`;
    return undefined;
  }};
  background-color: ${({ theme }) => theme.midGrey};
  overflow: auto;

  @media print {
    display: none;
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  text-transform: uppercase;
  padding-left: 10px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.midGrey};
  border-bottom: solid 1px lightgrey;
  filter: ${({ sidebarOpen }: StyleProps) => (sidebarOpen ? 'brightness(90%)' : 'none')};
`;

export const SidebarTitle = styled.div`
  margin: auto 0;
`;

export const SidebarBody = styled.div`
  padding: 10px;
  visibility: ${({ sidebarOpen }: StyleProps) => !sidebarOpen && 'hidden'};
`;
