import type { FC } from 'react';

import FilterSelectFormGroup from 'src/components/Reusable/FilterSelectFormGroup';
import type { BrandOwnerList } from 'src/shared';
import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildBrandOwnerHandler } from 'src/utils/createBrandLineFormHandlers';
import type { SelectItem } from 'src/utils/fiterSelectUtils';

const CreateBrandLineBrandOwnerSection: FC<
  CreateBrandLineSectionProps & {
    ownerOptions: SelectItem[];
    owners: BrandOwnerList;
  }
> = ({ dispatchFormAction, formState, ownerOptions, owners }) => {
  if (!formState.category) return null;

  return (
    <FilterSelectFormGroup
      property="brandOwner"
      label="Brand owner"
      options={ownerOptions}
      onSelect={buildBrandOwnerHandler(dispatchFormAction)}
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      length={owners ? owners.length : -1}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      selected={formState.brandOwner}
      disabled={formState.brandOwnerDisabled}
      formState={formState}
      dataTestId="create_brand_line_brand_owner"
    />
  );
};

export default CreateBrandLineBrandOwnerSection;
