import { type FormEvent, useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import { Base } from './Base';

interface Props {
  onStateChange: (state: string, data?: unknown) => void;
  authData: { username: string };
}

export const ConfirmSignIn = ({ onStateChange, authData: user }: Props) => {
  const [error, setError] = useState<Error | null>(null);
  const [token, setToken] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    Auth.confirmSignIn(user, token, 'SOFTWARE_TOKEN_MFA')
      .then(() => onStateChange('signedIn', user))
      .catch(setError);
  };

  const handleCancel = () => {
    onStateChange('signIn');
  };

  const body = (
    <>
      <p>
        Use the authenticator app on your smartphone to get a time-based one-time (TOTP) token to
        sign in.
      </p>
      <Form.Group as={Form.Row}>
        <Form.Label column sm={3} htmlFor="confirmSignIn-Token">
          Token
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            id="confirmSignIn-Token"
            value={token}
            type="text"
            onChange={({ target }) => setToken(target.value)}
            ref={inputRef}
          />
        </Col>
      </Form.Group>
    </>
  );

  return (
    <Base
      title="Enter security token"
      body={body}
      submitTxt="Verify"
      onSubmit={handleSubmit}
      cancelTxt="Back to sign in"
      onCancel={handleCancel}
      error={error}
    />
  );
};
