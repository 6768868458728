import { API, Auth } from 'aws-amplify';

import { filterUndefinedValuesDeeply } from 'src/utils/funcUtils';
import { API_NAME } from '../constants';
import type {
  ApiResponse,
  DoDeleteRequestParams,
  DoPostRequestParams,
  DoPutRequestParams,
  RequestInitParams,
} from '../types';

const getAccessToken = async () => {
  let session = await Auth.currentSession();

  // the value of Date.now() is 1424941329632 while jwt.exp is indicating 1424984529 so jtw exp date should be multiple by 1000.
  let expiration = session.getAccessToken().getExpiration() * 1000;

  // in order to be sure that API is not called with out of date token, expire date is set 10 mins earlier.
  expiration = expiration - 1000 * 10 * 60;

  if (expiration < Date.now()) {
    for (const key in localStorage) {
      if (key.endsWith('.accessToken')) {
        localStorage.removeItem(key);
      }
    }
    session = await Auth.currentSession();
  }

  return session.getAccessToken().getJwtToken();
};

const getRequestInit = async (params: RequestInitParams) => {
  const accessToken = await getAccessToken();

  const {
    transactionId,
    requestPayload,
    queryStringParameters,
    timeoutInMilliseconds,
    modifiedSince,
  } = params;

  // Always set a timeout to prevent your application from hanging indefinitely.
  // The database connection timeout in backend project is 15 seconds, and the operations in the API functions typically take less than a second.
  // However, it's a good practice to add a buffer, so the default client timeout is set to 20 seconds.
  // The lines below are necessary to test offline-first scenarios and network issues. For the test,
  // request-timeout can be set to 1 ms to simulate an API timeout when calling the health endpoint.

  const requesTimeout = localStorage.getItem('request-timeout');
  const timeout =
    requesTimeout && !isNaN(Number(requesTimeout))
      ? parseInt(requesTimeout)
      : timeoutInMilliseconds ?? 20 * 1000;

  return filterUndefinedValuesDeeply({
    headers: {
      Authorization: accessToken,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'X-If-Modified-Since': modifiedSince,
      'X-Transaction-Id': transactionId,
      'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'X-Local-DateTime': new Date().toLocaleString(),
    },
    body: requestPayload,
    response: true,
    queryStringParameters,
    timeout,
  });
};

export const doFetchRequest = async ({
  path,
  modifiedSince,
  queryStringParameters,
  timeoutInMilliseconds,
}: {
  path: string;
  modifiedSince?: string | undefined;
  queryStringParameters?: Record<string, string> | undefined;
  timeoutInMilliseconds?: number | undefined;
}): Promise<ApiResponse> => {
  const result = (await API.get(
    API_NAME,
    `/${path}`,
    await getRequestInit({
      modifiedSince,
      queryStringParameters,
      timeoutInMilliseconds,
    })
  )) as ApiResponse;

  return result;
};

export const doPostRequest = async ({
  path,
  transactionId,
  requestPayload,
}: DoPostRequestParams): Promise<ApiResponse> => {
  const result = (await API.post(
    API_NAME,
    `/${path}`,
    await getRequestInit({
      requestPayload,
      transactionId,
    })
  )) as ApiResponse;

  return result;
};

export const doPatchRequest = async ({
  path,
  transactionId,
  requestPayload,
}: DoPostRequestParams): Promise<ApiResponse> => {
  const result = (await API.patch(
    API_NAME,
    `/${path}`,
    await getRequestInit({
      requestPayload,
      transactionId,
    })
  )) as ApiResponse;

  return result;
};

export const doPutRequest = async ({
  path,
  transactionId,
  requestPayload,
}: DoPutRequestParams): Promise<ApiResponse> => {
  const result = (await API.put(
    API_NAME,
    `/${path}`,
    await getRequestInit({
      requestPayload,
      transactionId,
    })
  )) as ApiResponse;

  return result;
};

export const doDeleteRequest = async ({
  path,
  transactionId,
}: DoDeleteRequestParams): Promise<ApiResponse> =>
  (await API.del(API_NAME, `/${path}`, await getRequestInit({ transactionId }))) as ApiResponse;
