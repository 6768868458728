import {
  faCheckCircle,
  faExclamationTriangle,
  faPause,
  faQuestionCircle,
  faSpinner,
  type IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css, keyframes } from 'styled-components';

import type { ValidationCheck } from '../types';
import { validationCheckColor } from '../utils';

const spinnerKeyframes = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const StyledIcon = styled.div<{
  status: ValidationCheck['checkStatus'];
  selected: boolean;
  large: boolean;
}>`
  width: ${({ large }) => (large ? '43px' : 'auto')}
  margin: ${({ large }) => (large ? '5px 15px' : 'inherit')}
  svg {
    color: ${params => validationCheckColor(params)};
    animation: ${({ status }) =>
      status === 'In Progress'
        ? css`
            ${spinnerKeyframes} 2s linear infinite;
          `
        : 'none'};
  }
`;

export const ValidationIcon = ({
  status,
  selected = false,
  large = false,
  className,
}: {
  status: ValidationCheck['checkStatus'];
  selected?: boolean;
  large?: boolean;
  className?: string;
}) => {
  const icon: IconDefinition | null = {
    Success: faCheckCircle,
    Warning: faExclamationTriangle,
    'In Progress': faSpinner,
    Unknown: faQuestionCircle,
    Suspended: faPause,
    NotRun: faQuestionCircle,
    None: null,
  }[status];

  return (
    <StyledIcon status={status} selected={!!selected} className={className} large={large}>
      {icon && <FontAwesomeIcon icon={icon} size={large ? '2x' : '1x'} />}
    </StyledIcon>
  );
};
