import type { Dispatch } from 'react';
import { useCallback } from 'react';
import type { ValueType } from 'react-select';

import type { Brand, Origin, OriginList } from '../shared';
import type { AgeStylesEntity } from '../types/entity/AgeStyles';
import type { AlcoholicStrengthsEntity } from '../types/entity/AlcoholicStrengths';
import type { MaltRegionsEntity } from '../types/entity/MaltRegions';
import type { FlatCategoryItem } from '../views/types';
import { isRuleEnabled, isRuleFixedValue, isRuleWildcard } from './categoryRules';
import { getCategoryRules } from './categoryUtils';
import {
  emptyOption,
  mapAgeStyleValue,
  mapAgeStyleValues,
  mapAlcoholicStrengthValue,
  mapAlcoholicStrengthValues,
  mapMaltRegionValues,
  mapValueToNumeric,
  type OptionValue,
} from './createBrandLineFormMappers';
import type { CreateBrandLineAction, CreateBrandLineState } from './createBrandLineReducer';
import { hasOnlyAllowedCharacters } from './hasOnlyAllowedCharacters';

export const buildCategoryFormStatePayload = (
  category: FlatCategoryItem,
  origins: OriginList,
  alcoholicStrengths: AlcoholicStrengthsEntity[],
  ageStyles: AgeStylesEntity[],
  maltRegions: MaltRegionsEntity[]
): Partial<CreateBrandLineState> => {
  const categoryRules = getCategoryRules(category.id.toString());

  const maltRegionOptions = mapMaltRegionValues(maltRegions);

  /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
  const selectedOrigin: Origin[] = origins.filter((o: any) => o.originId === category.originId);

  const originPayload =
    // @ts-expect-error: legacy code
    selectedOrigin.length > 0 && Number(selectedOrigin[0].originId) !== 0
      ? {
          // @ts-expect-error: legacy code
          origin: selectedOrigin[0].originName,
          originDisabled: true,
        }
      : {
          origin: '',
          originDisabled: false,
        };

  const isFlavoured = (label: string) => label === 'Flavoured';
  const isCraft = (label: string) => label === 'Craft';
  const isBLE = (label: string) => label === 'Brand Line Extension';
  const isRTS = (label: string) => label === 'RTS';

  const ageStyle =
    categoryRules.ageStyle.length > 0 && categoryRules.ageStyle[0]
      ? mapAgeStyleValue(categoryRules.ageStyle[0], ageStyles)
      : emptyOption;

  // @ts-expect-error: legacy code
  return {
    category,
    ageStyleDisabled: isRuleFixedValue(categoryRules, 'ageStyle'),
    ageStyle,
    ageStyleOptions: mapAgeStyleValues(categoryRules.ageStyle, ageStyles),
    ageInYearsVisible: isRuleWildcard(categoryRules, 'ageInYears') && ageStyle.text === 'Aged',
    ageInYearsAvailable: isRuleWildcard(categoryRules, 'ageInYears'),
    flavouredVisible: isRuleEnabled(categoryRules, 'flavoured'),
    flavouredDisabled: isRuleFixedValue(categoryRules, 'flavoured'),
    // @ts-expect-error: legacy code
    flavoured: categoryRules.flavoured.length > 0 ? isFlavoured(categoryRules.flavoured[0]) : false,
    flavouredOptions: categoryRules.flavoured.map(text => ({ value: isFlavoured(text), text })),
    craftVisible: isRuleEnabled(categoryRules, 'craft'),
    // @ts-expect-error: legacy code
    craft: categoryRules.craft.length > 0 ? isCraft(categoryRules.craft[0]) : false,
    craftOptions: categoryRules.craft.map(text => ({ value: isCraft(text), text })),
    alcoholicStrengthVisible: isRuleEnabled(categoryRules, 'alcoholicStrength'),
    alcoholicStrengthDisabled: isRuleFixedValue(categoryRules, 'alcoholicStrength'),
    alcoholicStrength:
      categoryRules.alcoholicStrength.length > 0
        ? mapAlcoholicStrengthValue(categoryRules.alcoholicStrength[0]!, alcoholicStrengths)
        : emptyOption,
    alcoholicStrengthOptions: mapAlcoholicStrengthValues(
      categoryRules.alcoholicStrength,
      alcoholicStrengths
    ),
    bleVisible: isRuleEnabled(categoryRules, 'brandLineExtension'),
    isBLE:
      categoryRules.brandLineExtension.length > 0
        ? isBLE(categoryRules.brandLineExtension[0]!)
        : false,
    bleOptions: categoryRules.brandLineExtension.map(text => ({ value: isBLE(text), text })),
    rtsVisible: isRuleEnabled(categoryRules, 'rts'),
    isRTS: categoryRules.rts.length > 0 ? isBLE(categoryRules.rts[0]!) : false,
    rtsOptions: categoryRules.rts.map(text => ({ value: isRTS(text), text })),
    maltRegionVisible: isRuleWildcard(categoryRules, 'maltScotchRegion'),
    maltRegion: isRuleWildcard(categoryRules, 'maltScotchRegion')
      ? maltRegionOptions[0]
      : emptyOption,
    ...originPayload,
  };
};

export const buildCategoryHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>,
  origins: OriginList,
  alcoholicStrengths: AlcoholicStrengthsEntity[],
  ageStyles: AgeStylesEntity[],
  maltRegions: MaltRegionsEntity[]
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    (selectedCategory: FlatCategoryItem) => {
      dispatchFormAction({
        type: 'update',
        payload: buildCategoryFormStatePayload(
          selectedCategory,
          origins,
          alcoholicStrengths,
          ageStyles,
          maltRegions
        ),
      });
    },
    [dispatchFormAction, origins, alcoholicStrengths, ageStyles, maltRegions]
  );

export const buildBrandLineNameChangeHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
      const newValue = e.target.value;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (!/^\s?[a-zA-Z0-9-()'/%&._\b?:!+,#$ ]*$/.test(newValue.trim())) {
        return false;
      }
      dispatchFormAction({
        type: 'update',
        payload: {
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
          brandLineName: newValue.trimLeft().replace(/\s+/g, ' '),
        },
      });

      return true;
    },
    [dispatchFormAction]
  );

export const buildBrandLineNameBlurHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
          brandLineName: e.target.value.trim(),
        },
      });
    },
    [dispatchFormAction]
  );

export const buildDisplayNameChangeHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>,
  allowedCharacters?: string | undefined
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (newValue) {
        const trimmedNewValue = newValue.trim();

        if (allowedCharacters && !hasOnlyAllowedCharacters(trimmedNewValue, allowedCharacters)) {
          return false;
        }
      }
      dispatchFormAction({
        type: 'update',
        payload: {
          displayName: newValue.trimStart().replace(/\s+/g, ' '),
        },
      });

      return true;
    },
    [dispatchFormAction, allowedCharacters]
  );

export const buildBrandOrOwnerNameChangeHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  e: any
) => {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
  const newValue = e.target.value;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  if (!/^\s?[a-zA-Z0-9-()'/%&._\b?:!+,#$ ]*$/.test(newValue.trim())) {
    return false;
  }
  dispatchFormAction({
    type: 'update',
    payload: {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
      modalBrandOrOwner: newValue.trimLeft().replace(/\s+/g, ' '),
    },
  });

  return true;
};

export const buildDisplayNameBlurHandler = (dispatchFormAction: Dispatch<CreateBrandLineAction>) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
          displayName: e.target.value.trim(),
        },
      });
    },
    [dispatchFormAction]
  );

export const buildBrandLineHandler = (dispatchFormAction: Dispatch<CreateBrandLineAction>) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    (item: ValueType<OptionValue, false>) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          brandLine: item,
        },
      });
    },
    [dispatchFormAction]
  );

export const buildBrandNameHandler = (dispatchFormAction: Dispatch<CreateBrandLineAction>) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    (item: ValueType<OptionValue, false>) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          brandName: item?.label,
          brandGUID: item?.value,
        },
      });
    },
    [dispatchFormAction]
  );

export const buildSelectStringValueHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>,
  ruleField: keyof CreateBrandLineState
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    (item: ValueType<OptionValue, false>) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          [ruleField]: item?.value ?? '',
        },
      });
    },
    [dispatchFormAction, ruleField]
  );

export const buildSelectBooleanValueHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>,
  ruleField: keyof CreateBrandLineState
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    (item: ValueType<OptionValue, false>) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          [ruleField]: item?.value === true.toString(),
        },
      });
    },
    [dispatchFormAction, ruleField]
  );

export const buildBrandOwnerHandler = (dispatchFormAction: Dispatch<CreateBrandLineAction>) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          brandOwner: item,
        },
      });
    },
    [dispatchFormAction]
  );

export const buildBrandLineOwnerHandler = (dispatchFormAction: Dispatch<CreateBrandLineAction>) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
          ownerGUID: item.value,
          /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
          ownerName: item.label,
        },
      });
    },
    [dispatchFormAction]
  );

export const buildParentBrandHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ownerOptions: any,
  brands: Brand[]
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const payloadIsNew = item.isNew
        ? {
            isNewParentBrand: true,
            brandOwner: undefined,
          }
        : {
            isNewParentBrand: false,
          };

      const brandName: Brand | undefined = brands.find(
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
        (brand: any) => brand.brandGUID === item.value
      );

      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any */
      const brandNameOwner: any | undefined = ownerOptions.find(
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
        (owner: any) => owner.value === brandName?.ownerGUID
      );

      const payloadBrandName = brandName
        ? {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            brandOwner: brandNameOwner,
          }
        : {};

      dispatchFormAction({
        type: 'update',
        payload: {
          ...payloadIsNew,
          ...payloadBrandName,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          parentBrand: item,
          isNewParentBrandConfirmed: false,
          brandOwnerDisabled: true,
        },
      });
    },
    [dispatchFormAction, ownerOptions, brands]
  );

export const buildAgeStyleHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>,
  formState: CreateBrandLineState
) => {
  const { ageInYearsAvailable } = formState;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (item: ValueType<OptionValue, false>) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          ageStyle: mapValueToNumeric(item),
          ageInYears: 0,
          ageInYearsVisible: ageInYearsAvailable && item?.value !== '0' && item?.value !== '1',
        },
      });
    },
    [dispatchFormAction, ageInYearsAvailable]
  );
};

export const buildAgeInYearsHandler =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (dispatchFormAction: Dispatch<CreateBrandLineAction>) => (event: any) => {
    dispatchFormAction({
      type: 'update',
      payload: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        ageInYears: event.target.value === '' ? 0 : parseInt(event.target.value, 10),
      },
    });
  };

export const buildNumericValuesHandler = (
  dispatchFormAction: Dispatch<CreateBrandLineAction>,
  ruleField: keyof CreateBrandLineState
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCallback(
    (item: ValueType<OptionValue, false>) => {
      dispatchFormAction({
        type: 'update',
        payload: {
          [ruleField]: mapValueToNumeric(item),
        },
      });
    },
    [dispatchFormAction, ruleField]
  );
