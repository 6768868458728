import { useMemo } from 'react';
import Select from 'react-select';

import { baseDropdownStyles } from 'src/css/react-select-styles';
import type { FlatCategoryItem } from 'src/views/types';
import CategoryOptionComponent from './CategoryOptionComponent';
import { SELECT_CATEGORY_PLACEHOLDER } from './constants';

interface Props {
  categories: FlatCategoryItem[];
  selected: FlatCategoryItem | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelectCategory: (item: any) => void;
  id: string;
}

const getNestedCategories = (categories: FlatCategoryItem[], parentId = 0, level = 1) => {
  if (!Array.isArray(categories)) {
    return [];
  }
  const nestedCategories = [];
  const dataToParse = categories.filter(item => item.level === level && item.parentId === parentId);

  for (const row of dataToParse) {
    const category: FlatCategoryItem = {
      name: row.name,
      id: row.id,
      level: row.level,
      parentId: row.parentId,
      originId: row.originId,
      forecastByOrigin: row.forecastByOrigin,
      displayOrder: row.displayOrder,
      legacyId: row.id.toString(),
      value: `${row.level || 'all'}_${row.id}`,
    };

    if (level < 5) {
      const subCategories = getNestedCategories(categories, row.id, level + 1);
      category.subCategories = subCategories;
    }
    nestedCategories.push(category);
  }

  return nestedCategories;
};

const getFlatCategories = (categories: FlatCategoryItem[]): FlatCategoryItem[] => {
  const reducer =
    (level: number, parents: FlatCategoryItem[]) =>
    (acc: FlatCategoryItem[], val: FlatCategoryItem): FlatCategoryItem[] => {
      const option: FlatCategoryItem = {
        id: val.id,
        value: `${val.level}_${val.id}`,
        label: `${val.name} (${level})`,
        name: `${val.name}`,
        level,
        isDisabled: level !== 5,
        displayOrder: val.displayOrder,
        originId: val.originId,
        parents,
        forecastByOrigin: val.forecastByOrigin,
        parentId: val.parentId,
        legacyId: val.id.toString(),
      };
      acc.push(option);
      const newParents = [...parents, option];
      if (val.subCategories) return val.subCategories.reduce(reducer(level + 1, newParents), acc);
      return acc;
    };

  return categories.reduce(reducer(1, []), []);
};

const CategoryDropdown = ({ categories, selected, handleSelectCategory, id }: Props) => {
  const options: FlatCategoryItem[] = useMemo(() => {
    const nestedCategories = getNestedCategories(categories, 0, 1);
    return getFlatCategories(nestedCategories);
  }, [categories]);

  return (
    <Select
      placeholder={SELECT_CATEGORY_PLACEHOLDER}
      options={options}
      isDisabled={!options}
      components={{ Option: CategoryOptionComponent }}
      onChange={handleSelectCategory}
      value={options.find(_ => _.value === `${selected?.level ?? 'all'}_${selected?.id ?? 0}`)}
      styles={baseDropdownStyles}
      id={id}
    />
  );
};

export default CategoryDropdown;
