import { type FC, useCallback, useMemo } from 'react';
// @TODO - remove these legacy imports
import { FormGroup, FormLabel } from 'react-bootstrap';
import { generatePath, useNavigate } from 'react-router-dom';
import type { OptionTypeBase } from 'react-select';

import { DropdownHeader, SectionDropdownOption } from '../css/styled-components';
import { useTable, useViews } from '../views/hooks';
import type { View, ViewSection } from '../views/types';
import { Dropdown } from './Dropdown';

interface ViewSectionSwitcherProps {
  currentSection?: ViewSection | undefined;
  currentView?: View | undefined;
}

export const ViewSectionSwitcher: FC<ViewSectionSwitcherProps> = ({
  currentSection,
  currentView,
}) => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);

  const handleViewSectionChange = useCallback<(viewSection: ViewSection) => void>(
    ({ id }) => {
      const view = views.filter(view => view.sectionId === id)[0];

      if (!view) throw new Error('No views can be found.');

      if (view.slug === currentView?.slug) {
        return;
      }

      navigate(
        generatePath('/:marketSlug/:tableSlug/:viewSlug', {
          marketSlug: market.marketSlug,
          tableSlug: table.slug,
          viewSlug: view.slug,
        }),
        {
          replace: false,
        }
      );
    },
    [views, currentView?.slug, navigate, market.marketSlug, table.slug]
  );

  const handleSelect = useCallback(
    (option: OptionTypeBase) => {
      const selectedSection = sections.find(section => section.id === option['value']);
      selectedSection && handleViewSectionChange(selectedSection);
    },
    [handleViewSectionChange, sections]
  );

  const options = useMemo(() => {
    return sections
      .map(({ id, name, parentId, displayOrder }) => ({
        category1Id: parentId,
        label: name,
        level: parentId ? 2 : 1,
        sectionId: id,
        value: id,
        displayOrder,
      }))
      .sort(
        ({ displayOrder }, { displayOrder: otherDisplayOrder }) => displayOrder - otherDisplayOrder
      );
  }, [sections]);

  return (
    <FormGroup>
      <FormLabel id="viewSectionSwitcher">
        <DropdownHeader>Section</DropdownHeader>
      </FormLabel>
      <Dropdown
        ariaLabelledBy="viewSectionSwitcher"
        option={SectionDropdownOption}
        options={[{ options }]}
        placeholder="Select section"
        selected={currentSection?.id}
        onSelect={handleSelect}
      />
    </FormGroup>
  );
};
