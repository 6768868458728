import type { NumberColumn } from 'src/components/DataGrid/types';
import type { Market } from 'src/markets';
import type { Field } from 'src/views/types';

const NUMBER_COLUMN_DEFAULT_PRECISION = 2;

export const getPrecisionFromVolume = (fields: Field[]) => {
  const selectedVolume = fields.find(field => field.field === 'volume') as NumberColumn;
  return selectedVolume.options?.precision ?? NUMBER_COLUMN_DEFAULT_PRECISION;
};

type MarketPermissions = Pick<Market, 'isMaintain' | 'isAdmin' | 'isWrite'>;

export const hasMarketEditingPermissions = ({ isMaintain, isAdmin, isWrite }: MarketPermissions) =>
  isMaintain || isAdmin || isWrite;
