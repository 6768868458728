import uniqBy from 'lodash/uniqBy';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';

const MAX_BRAND_LINES_TO_SHOW = 20;

export const getExactMatch = (search: string, brandLines: DenormalizedBrandLine[]) =>
  brandLines.filter(brandLine => brandLine.brandLineName.toLowerCase() === search.toLowerCase());

export const getContainsMatch = (search: string, brandLines: DenormalizedBrandLine[]) =>
  brandLines.filter(brandLine =>
    brandLine.brandLineName.toLowerCase().includes(search.toLowerCase())
  );

interface GetSearchResultsParams {
  search: string;
  brandLines: DenormalizedBrandLine[];
  brandSale: DenormalizedBrandSales | null;
}

export const getSearchResults = ({ brandLines, brandSale, search }: GetSearchResultsParams) => {
  if (brandLines.length && brandSale && search) {
    const filteredbrandLines = [
      ...getExactMatch(search, brandLines),
      ...getContainsMatch(search, brandLines),
    ].filter(({ brandLineGUID }) => brandLineGUID !== brandSale.brandLineGUID);

    const dedupedFilteredBrandLines = uniqBy(filteredbrandLines, 'brandLineGUID');
    return dedupedFilteredBrandLines.slice(0, MAX_BRAND_LINES_TO_SHOW);
  }

  return [];
};
