import type { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove as dndKitArrayMove } from '@dnd-kit/sortable';

export const removeAtIndex = (columns: string[], index: number) => {
  return [...columns.slice(0, index), ...columns.slice(index + 1)];
};

export const insertAtIndex = (
  columns: string[],
  index: number,
  activeId: UniqueIdentifier
): string[] => {
  const returnRow: string[] = [
    ...columns.slice(0, index),
    activeId,
    ...columns.slice(index),
  ] as string[];
  return returnRow;
};

export const arrayMove = (columns: string[], oldIndex: number, newIndex: number) => {
  return dndKitArrayMove(columns, oldIndex, newIndex);
};
