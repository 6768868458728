import { type HTMLAttributes, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete, {
  type AutocompleteInputChangeReason,
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

import { RecordDialog } from 'src/components/RecordDialog/RecordDialog';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import { useAppDispatch, useAppSelector } from 'src/store';
import { swapBrandLineDialogActions } from 'src/store/features/swapBrandLineDialog/slice';
import { useSyncContext } from 'src/sync/hooks';
import { BrandLineDetailsList } from './components/BrandLineDetailsList';
import { BrandLineSearch } from './components/BrandLineSearch';
import { useBrandLineSearch } from './hooks/useBrandLineSearch';
import * as styles from './styles/swapBrandLineDialog';

const COMPONENT_PROPS: AutocompleteProps<
  DenormalizedBrandLine,
  false,
  false,
  true
>['componentsProps'] = {
  popper: {
    disablePortal: true,
  },
};

export const SwapBrandLineDialog = () => {
  const syncContext = useSyncContext();
  const dispatch = useAppDispatch();
  const [modalState, setModalState] = useState<'swapping' | 'confirming'>('swapping');
  const isOpen = useAppSelector(state => state.swapBrandLineDialog.isOpen);
  const brandSale = useAppSelector(state => state.swapBrandLineDialog.brandSale);
  const brandLines = useRecords<DenormalizedBrandLine>('brandLines');

  const {
    filteredList,
    noSearchResultsCopy,
    search,
    selection,
    handleSearch,
    handleSelection,
    resetHookState,
  } = useBrandLineSearch({
    brandSale,
    brandLines,
  });

  const clearState = () => {
    setModalState('swapping');
    resetHookState();
  };

  const handleClose = () => {
    clearState();
    dispatch(swapBrandLineDialogActions.close());
  };

  const handleSubmit = () => {
    if (modalState === 'swapping') {
      setModalState('confirming');
      return;
    }

    handleSwap();
  };

  const handleInputChange = (newValue: string, reason: AutocompleteInputChangeReason) => {
    // reset comes through when the user selects an autocomplete option.
    if (reason === 'reset') {
      return;
    }

    handleSearch(newValue);
  };

  const isDialogClosed = !isOpen || brandSale == null;

  if (isDialogClosed) {
    return null;
  }

  const updateEntity = () => {
    void syncContext.updateEntity('brandSales', brandSale.saleGUID, {
      brandLineGUID: selection!.brandLineGUID,
    });
    return;
  };

  const handleSwap = () => {
    updateEntity();
    handleClose();
  };

  const renderInput = (inputProps: AutocompleteRenderInputParams) => (
    <TextField
      {...inputProps}
      InputLabelProps={{ className: '' }}
      placeholder={searchPlaceholderCopy}
      size="small"
      InputProps={{
        ...inputProps.InputProps,
        startAdornment: <SearchIcon />,
      }}
    />
  );

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, brandLine: DenormalizedBrandLine) => (
    <BrandLineSearch
      {...props}
      brandLine={brandLine}
      selectedOption={selection}
      onChange={() => handleSelection(brandLine)}
    />
  );

  const swapCopy = `Swap brand line`;
  const searchPlaceholderCopy = `Search brand lines...`;
  const findCopy = `Find a brand line to swap with`;
  const ariaLabel = `swap-brand-line-search`;
  const isFilteredListOpen = filteredList.length > 0 || noSearchResultsCopy.length > 0;

  return (
    <RecordDialog
      disabled={!selection}
      open={isOpen}
      onClose={modalState !== 'confirming' ? handleClose : () => setModalState('swapping')}
      onSubmit={modalState !== 'confirming' ? handleSubmit : handleSwap}
      submitButtonText={swapCopy}
      title={swapCopy}
    >
      {modalState !== 'confirming' && (
        <DialogContent dividers>
          <BrandLineDetailsList brandSale={brandSale} />

          <FormControl fullWidth>
            <FormLabel htmlFor={ariaLabel} sx={styles.findToSwapWith} focused={false}>
              <p></p>
              {findCopy}
            </FormLabel>
            <Autocomplete
              componentsProps={COMPONENT_PROPS}
              disableCloseOnSelect
              disablePortal
              filterOptions={opts => opts}
              getOptionLabel={opt => opt.brandLineName}
              id={ariaLabel}
              inputValue={search}
              noOptionsText={noSearchResultsCopy}
              onChange={(_e, newValue) => handleSelection(newValue)}
              onInputChange={(_e, newValue, reason) => handleInputChange(newValue, reason)}
              open={isFilteredListOpen}
              options={filteredList}
              popupIcon={null}
              renderInput={renderInput}
              renderOption={renderOption}
              value={selection}
            />
          </FormControl>
        </DialogContent>
      )}
      {modalState === 'confirming' && (
        <DialogContent dividers>
          <BrandLineDetailsList brandSale={brandSale} selection={selection} />
        </DialogContent>
      )}
    </RecordDialog>
  );
};
