// From https://github.com/jacobworrel/react-windowed-select

import { forwardRef, useMemo } from 'react';
import Creatable from 'react-select/creatable';

import MenuList from './MenuList';
import { calcOptionsLength } from './utils';

/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-explicit-any */
function WindowedSelect({ windowThreshold = 10, ...passedProps }, ref: any) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const optionsLength = useMemo(
    // @ts-expect-error: legacy code
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => calcOptionsLength(passedProps.options),
    // @ts-expect-error: legacy code
    [passedProps.options]
  );
  const isWindowed = optionsLength >= windowThreshold;

  return (
    <Creatable
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...passedProps}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      components={{
        // @ts-expect-error: legacy code
        ...passedProps.components,
        ...(isWindowed ? { MenuList } : {}),
      }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ref={ref}
    />
  );
}

export default forwardRef(WindowedSelect);
