import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import MaterialTooltip from '@mui/material/Tooltip';

import { SPACING } from 'src/theme/spacing';

interface Props {
  children: ReactNode;
  title: string;
}

export const Tooltip = ({ children, title }: Props) => {
  return (
    <MaterialTooltip
      title={title}
      sx={theme => ({
        borderRadius: '8px',
        border: `0.66px solid black`,
        padding: SPACING['300'],
        background: theme.palette.background.paper,
        boxShadow: '0px 7px 24px 0px rgba(0, 0, 0, 0.15)',
      })}
    >
      <Box>{children}</Box>
    </MaterialTooltip>
  );
};
