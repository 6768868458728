import type { Market } from '../../shared';
import { getFixedNumber, getStrippedString, getVolumeDecimalPlaces, minVolume } from '../gridUtils';

export const isMin = (valueString: string) => /^mi?n?$/i.test(valueString);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseVolume = (newValue: string, context: any): number | null => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (newValue === undefined) return null;

  const valueString = getStrippedString(newValue);
  if (valueString === '') return null; // Number('') returns 0 which we don't want

  if (isMin(valueString)) return minVolume;

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  const num: number = Number(valueString);
  if (num > 0 && num <= minVolume) return minVolume;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const decimalPlaces = getVolumeDecimalPlaces(context?.market as Market);
  return getFixedNumber(newValue, decimalPlaces);
};
