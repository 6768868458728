import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface Payload {
  marketName: string;
  path: string;
}

interface ChecksState {
  markets: Record<string, string | null>;
}

const initialState: ChecksState = {
  markets: {},
};

export const {
  actions: checksActions,
  reducer: checksReducer,
  name: checksSliceName,
} = createSlice({
  name: 'checks',
  initialState,
  reducers: {
    setPath(state, { payload }: PayloadAction<Payload>) {
      state.markets[payload.marketName] = payload.path;
    },
  },
});
