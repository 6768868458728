import { type MutableRefObject } from 'react';

import type {
  unsafe_ColDef,
  unsafe_GridApi,
  unsafe_ProcessCellForExportParams,
  unsafe_ProcessRowGroupForExportParams,
  unsafe_ValueFormatterParams,
} from 'src/components/DataGrid';
import type { Value, ValueFormatterParams } from 'src/types/valueFormatterTypes';
import { isNil } from 'src/utils/funcUtils';

interface CellCallbackParams extends Omit<unsafe_ProcessCellForExportParams, 'value' | 'column'> {
  value: Value;
  column: {
    colDef: unsafe_ColDef;
  };
}

interface Props {
  apiRef: MutableRefObject<unsafe_GridApi | undefined>;
  setLoading: (arg: boolean) => void;
}

interface RowGroupColumn {
  colDef: unsafe_ColDef;
}

export const exportToExcel = ({ apiRef, setLoading }: Props) => {
  setLoading(true);
  setTimeout(() => {
    if (apiRef.current) {
      apiRef.current.exportDataAsExcel({
        processCellCallback: (params: unsafe_ProcessCellForExportParams): string => {
          const { value } = params as unknown as CellCallbackParams;
          if (isNil(value)) return '';
          const {
            column: {
              colDef: { valueFormatter },
            },
          } = params as unknown as CellCallbackParams;
          if (typeof valueFormatter === 'function') {
            const valueFormatterParams: Partial<ValueFormatterParams> = {
              ...params,
              colDef: params.column.getColDef(),
            };
            return valueFormatter(valueFormatterParams as unsafe_ValueFormatterParams);
          }
          return value as string;
        },
        processRowGroupCallback: (params: unsafe_ProcessRowGroupForExportParams) => {
          const { node } = params;
          const { key, rowGroupColumn, level } = node;
          if (!key) return '';

          const { colDef } = rowGroupColumn as unknown as RowGroupColumn;
          const { refData } = colDef;
          if (!refData) return key;

          const groupName = refData[key] ?? key;

          return `${'> '.repeat(level + 1)} ${groupName}`;
        },
      });
    }
  }, 1000);
  setTimeout(() => {
    setLoading(false);
  }, 1000);
};
