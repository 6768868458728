import { Amplify } from 'aws-amplify';

import { API_NAME } from './sync';

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code',
      },
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_USERPOOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: API_NAME,
          endpoint: '/api',
        },
      ],
    },
    aws_appsync_region: process.env.REACT_APP_COGNITO_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  });
};
