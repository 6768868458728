import Box from '@mui/material/Box';

import { Tooltip } from 'src/components/Tooltips/Tooltip';
import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import { type BaseColumn } from '../types';
import { Description } from './Description';

interface Props {
  data: DenormalizedBrandLine | undefined;
  tooltipOptions: BaseColumn['tooltipOptions'] | undefined;
}

export const CellTooltip = ({ data, tooltipOptions }: Props) => {
  if (!data || !tooltipOptions?.fields) {
    // Workaround: AG Grid logs an error when component doesn't return an HTML element.
    return <div style={{ display: 'none' }}></div>;
  }

  return (
    <Tooltip title="Cell tooltip">
      <Box>
        {tooltipOptions.fields.map(({ field, friendlyName }) => (
          <Description
            key={field}
            label={friendlyName}
            value={data[field as keyof DenormalizedBrandLine]}
          />
        ))}
      </Box>
    </Tooltip>
  );
};
