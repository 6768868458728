import { useCallback, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useModalDimension = (modalRef: any, initialDimension: number, dimensionName: string) => {
  const [dimension, setDimension] = useState(initialDimension);

  const getDimension = useCallback(
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access */
    () => modalRef?.current?.[dimensionName] || initialDimension,
    [dimensionName, initialDimension, modalRef]
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const updateDimension = useCallback(() => setDimension(getDimension()), [getDimension]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setDimension(getDimension());

    window.addEventListener('resize', updateDimension);
    return () => window.removeEventListener('resize', updateDimension);
  }, [getDimension, updateDimension]);

  return dimension;
};

export const useModalDimensions = (initialWidth: number, initialHeight: number) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const modalWidth = useModalDimension(modalRef, initialWidth, 'clientWidth');
  const modalHeight = useModalDimension(modalRef, initialHeight, 'clientHeight');

  return { modalRef, modalWidth, modalHeight };
};

export const useScrollIntoView = (isSelected: boolean) => {
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (isSelected) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      ref?.current?.scrollIntoView?.();
    }
  }, [isSelected]);

  return ref;
};
