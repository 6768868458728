import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const Label = styled(Form.Label)`
  font-weight: bold;
`;

interface ThProps {
  width?: string;
}

export const Th = styled.th<ThProps>`
  width: ${({ width }) => width ?? '20%'};
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.midPurple};
  color: white;
  z-index: 1;
`;

export const WarningDiv = styled.small`
  color: red;
`;

export const IconButton = styled.button`
  border: none;
  margin-right: 2px;
  background-color: transparent;
  color: ${({ theme }) => theme.blue};
`;

export const Heading = styled.h3`
  font-size: 12pt;
  font-weight: bold;
  margin-top: 12px;
`;

export const ErrorDiv = styled.div`
  color: red;
  font-size: 10pt;
`;

export const DivOverlay = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const ButtonDiv = styled.div`
  padding-left: 8px;
`;

export const selectStyles = {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any */
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
};
