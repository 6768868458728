export enum CharacterSetEnum {
  LIMITED = 'limited',
  EXTENDED = 'extended',
  FULL = 'full',
}

export enum TextEntryEnum {
  BrandName = 'brandName',
  BrandLineName = 'brandLineName',
  OwnerName = 'ownerName',
  OriginName = 'originName',
  CoverQuote = 'coverQuote',
  Extra = 'extra',
  Region = 'region',
  Variental = 'variental',
  Distributor = 'distributor',
  ApprovalReason = 'approvalReason',
}

export interface TextEntryItem {
  key: string;
  maxLength: number;
  characterSet: CharacterSetEnum;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface TextEntryItemList {
  [key: string]: TextEntryItem;
}

export const UserTextEntry: TextEntryItemList = {
  [`${TextEntryEnum.BrandName}`]: {
    key: TextEntryEnum.BrandName,
    maxLength: 50,
    characterSet: CharacterSetEnum.EXTENDED,
  },
  [`${TextEntryEnum.BrandLineName}`]: {
    key: TextEntryEnum.BrandLineName,
    maxLength: 50,
    characterSet: CharacterSetEnum.EXTENDED,
  },
  [`${TextEntryEnum.OwnerName}`]: {
    key: TextEntryEnum.OwnerName,
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [`${TextEntryEnum.OriginName}`]: {
    key: TextEntryEnum.OriginName,
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [`${TextEntryEnum.CoverQuote}`]: {
    key: TextEntryEnum.CoverQuote,
    maxLength: 140,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [`${TextEntryEnum.Extra}`]: {
    key: TextEntryEnum.Extra,
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [`${TextEntryEnum.Region}`]: {
    key: TextEntryEnum.Region,
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [`${TextEntryEnum.Variental}`]: {
    key: TextEntryEnum.Variental,
    maxLength: 50,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [`${TextEntryEnum.Distributor}`]: {
    key: TextEntryEnum.Distributor,
    maxLength: 100,
    characterSet: CharacterSetEnum.LIMITED,
  },
  [`${TextEntryEnum.ApprovalReason}`]: {
    key: TextEntryEnum.Distributor,
    maxLength: 140,
    characterSet: CharacterSetEnum.LIMITED,
  },
};

export enum NumberFormat {
  PERCENTAGE = 'percentange',
  CURRENCY = 'currency',
  INTEGER = 'integer',
  WITHTWODECIMALPLACES = 'with two decimal places',
  WITHTHREEDECIMALPLACES = 'with three decimal places',
  PERCENTAGENEGOK = 'percentage with optional negative',
}

interface NumberEntryItem {
  key: string;
  biggerThan?: number;
  biggerEqualThan?: number;
  lessThan?: number;
  lessEqualThan?: number;
  allowEstimates?: true;
  format: NumberFormat;
  allowNegatives?: boolean;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
interface NumberEntryItemList {
  [key: string]: NumberEntryItem;
}

export enum NumberEntryEnum {
  Price = 'price',
  DutyFreePrice = 'duty free prize',
  Volume = 'volume',
  RetailValue = 'retail value',
  BottleSize = 'bottle size',
  Age = 'age',
  ContainerSize = 'container size',
  PercentageNegOK = 'percentage with optional negative',
}

export const UserNumberEntry: NumberEntryItemList = {
  [`${NumberEntryEnum.Price}`]: {
    key: NumberEntryEnum.Price,
    biggerThan: 0,
    format: NumberFormat.WITHTWODECIMALPLACES,
    allowEstimates: true,
  },
  [`${NumberEntryEnum.DutyFreePrice}`]: {
    key: NumberEntryEnum.DutyFreePrice,
    biggerThan: 0,
    lessThan: 10000000000,
    format: NumberFormat.WITHTHREEDECIMALPLACES,
    allowEstimates: true,
  },
  [`${NumberEntryEnum.Volume}`]: {
    key: NumberEntryEnum.Volume,
    biggerEqualThan: 0,
    lessThan: 10000000,
    format: NumberFormat.WITHTWODECIMALPLACES,
  },
  [`${NumberEntryEnum.RetailValue}`]: {
    key: NumberEntryEnum.RetailValue,
    biggerEqualThan: 0,
    lessThan: 10000000000,
    format: NumberFormat.WITHTWODECIMALPLACES,
  },
  [`${NumberEntryEnum.BottleSize}`]: {
    key: NumberEntryEnum.BottleSize,
    biggerEqualThan: 1,
    lessEqualThan: 3000,
    format: NumberFormat.WITHTWODECIMALPLACES,
  },
  [`${NumberEntryEnum.Age}`]: {
    key: NumberEntryEnum.Age,
    biggerEqualThan: 0,
    lessEqualThan: 100,
    format: NumberFormat.INTEGER,
  },
  [`${NumberEntryEnum.ContainerSize}`]: {
    key: NumberEntryEnum.ContainerSize,
    biggerEqualThan: 1,
    lessThan: 10000000,
    format: NumberFormat.WITHTWODECIMALPLACES,
  },
  [`${NumberEntryEnum.PercentageNegOK}`]: {
    key: NumberEntryEnum.PercentageNegOK,
    // biggerThan:-101,
    // lessThan: 101,
    biggerEqualThan: 0,
    lessEqualThan: 999,
    allowNegatives: true,
    format: NumberFormat.PERCENTAGENEGOK,
  },
};
