import type { RowNode } from '@ag-grid-community/core';
import { path } from 'ramda';

import type { LookUp, YearPriceFormat } from '../shared';
import { Market, YearDataTypes } from '../shared';

export type RowGroups = string[] | undefined;

export const TOTAL_ROW_ID = 'total';
export const REMAINDER_ROW_ID = 'remainder';

export const BRAND_LINE_DISPLAY_NAME_COL_ID = 'brandLineDisplayName';
export const PRICE_BAND_COL_ID = 'priceBandName';

export const minVolume = 1e-10;

interface ComparatorParams {
  valueA: string | number;
  valueB: string | number;
  nodeA: RowNode;
  nodeB: RowNode;
  isInverted: boolean;
}

type Comparator = (params: ComparatorParams) => number;

type ComparatorCallback = (
  valueA: string | number,
  valueB: string | number,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean
) => number;

export const comparatorParamsWrapper =
  (fn: Comparator): ComparatorCallback =>
  (valueA, valueB, nodeA, nodeB, isInverted) =>
    fn({
      valueA,
      valueB,
      nodeA,
      nodeB,
      isInverted,
    });

export const getNumberOrZero = (value: number | string | undefined | null): number => {
  const result = Number(value ?? 0);
  return result;
};

// get the number as a string, ignoring comma separators
export const getStrippedString = (value: string | number | undefined | null) => {
  if (value === null || typeof value === 'undefined') return '';
  return value.toString().replace(/[,]/g, '');
};

export const getFixedNumber = (value: string, decimalPlaces: number) => {
  const valueString = getStrippedString(value);
  let fixedValueString: string = getNumberOrZero(valueString).toFixed(decimalPlaces);

  // Fix for -0.001 being returned as -0 (Javascript has support for signed zeros)
  // eslint-disable-next-line no-compare-neg-zero
  if (Number(fixedValueString) === -0) {
    fixedValueString = '0';
  }
  return getNumberOrZero(fixedValueString);
};

export const getVolumeDecimalPlaces = (market: Market) => (market === Market.df ? 3 : 2);

export const parseYearColId = (colId: string): string[] => {
  if (!colId) return [];
  const [year, suffix] = colId.split('_');
  // @ts-expect-error: legacy code
  if (!/^[0-9]{4}$/.test(year)) return [];

  const yearProperties: LookUp = {
    on: YearDataTypes.onPremisePercent,
    price: YearDataTypes.price,
    cl: YearDataTypes.containerSize,
    externalDataVolume: YearDataTypes.externalDataVolume,
    externalDataComment: YearDataTypes.externalDataComment,
    externalDataIsUsed: YearDataTypes.externalDataIsUsed,
  };
  const property = suffix ? yearProperties[suffix] : YearDataTypes.volume;
  // @ts-expect-error: legacy code
  return [year, property];
};

export const invalidCharacters = [
  'à',
  'á',
  'â',
  'ã',
  'ä',
  'å',
  'æ',
  'ç',
  'è',
  'é',
  'ê',
  'ë',
  'ì',
  'í',
  'î',
  'ï',
  'ð',
  'ñ',
  'ò',
  'ó',
  'ô',
  'õ',
  'ö',
  '÷',
  'ø',
  'ù',
  'ú',
  'û',
  'ü',
  'ý',
  'þ',
  'ÿ',
  ',',
  '|',
];

export const hasInvalidCharacter = (value: string) =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  invalidCharacters.some(character => value?.includes(character));

export enum VolumeType {
  SALE = 'sale',
  SALE_READ_ONLY = 'saleReadOnly',
  SALE_PREVIOUS = 'salePreviousCycle',
  STILL_WINE = 'stillWine',
}

export const getPriceDecimalPlaces = (
  year: string,
  priceFormat: YearPriceFormat | null | undefined
) => {
  if (!priceFormat) return 2;

  const yearDecimalPlace: number | undefined = path([year, 'decimalPlaces'], priceFormat);

  if (yearDecimalPlace === undefined) {
    const yearsArray = Object.keys(priceFormat).map(Number);

    const lastYearWithPriceFormat = yearsArray
      .reduce((acc, item) => (item > acc ? item : acc), 0)
      .toString();

    const lastYearDecimalPlace: number | undefined = path(
      [lastYearWithPriceFormat, 'decimalPlaces'],
      priceFormat
    );
    if (lastYearDecimalPlace === undefined) return 2;

    return lastYearDecimalPlace;
  }

  return yearDecimalPlace;
};
