import type React from 'react';
import { createContext, type ReactNode, useState } from 'react';

interface NavStateContextInterface {
  mostRecentSubPathsForTables: Record<string, string>;
  setMostRecentSubPathsForTables: (value: Record<string, string>) => void;
}

const NavStateContext = createContext<NavStateContextInterface>({
  mostRecentSubPathsForTables: {},
  setMostRecentSubPathsForTables: () => undefined,
});

interface NavStateContextProviderProps {
  children: ReactNode;
}

export const NavStateContextProvider: React.FC<NavStateContextProviderProps> = ({ children }) => {
  const [mostRecentSubPathsForTables, setMostRecentSubPathsForTables] = useState<
    Record<string, string>
  >({});

  const value = {
    mostRecentSubPathsForTables,
    setMostRecentSubPathsForTables,
  };

  return <NavStateContext.Provider value={value}>{children}</NavStateContext.Provider>;
};

export default NavStateContext;
