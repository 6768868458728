import type { CSSProperties, MutableRefObject } from 'react';
import { useState } from 'react';
import { Button, FormGroup, NavDropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { unsafe_GridApi } from 'src/components/DataGrid';
import ModalType from 'src/components/ModalDialog/ModalType';
import { setModalDialogType } from 'src/store/features/modalDialog/actions';
import { exportToExcel } from './exportToExcel';

interface Props {
  apiRef: MutableRefObject<unsafe_GridApi | undefined>;
  sheets?: boolean;
}

const Loading = ({ styles }: { styles?: CSSProperties }) => (
  <FontAwesomeIcon
    icon={faSpinner}
    className="fa-spin fa-pulse fa-lg"
    style={{ marginLeft: '6px', ...styles }}
  />
);

export const ExportReportTrigger = ({ apiRef, sheets = false }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleExportSheets = () => dispatch(setModalDialogType(ModalType.EXPORT_REPORT, {}));
  const handleExportToExcel = () => exportToExcel({ apiRef, setLoading });

  return (
    <>
      {!sheets && (
        <Button
          className="nav-link"
          onClick={handleExportToExcel}
          style={{ backgroundColor: 'transparent', border: '0' }}
        >
          {loading ? <Loading /> : 'Export'}
        </Button>
      )}
      {sheets && (
        <FormGroup style={{ margin: '0 8px 16px 0', height: '20px', width: '200px' }}>
          {loading ? (
            <Loading styles={{ marginLeft: '8px', marginTop: '8px' }} />
          ) : (
            <NavDropdown title="Export" id="status-dropdown" alignRight>
              <NavDropdown.Item onClick={handleExportToExcel}>Export current view</NavDropdown.Item>
              <NavDropdown.Item onClick={handleExportSheets}>Export sheets</NavDropdown.Item>
            </NavDropdown>
          )}
        </FormGroup>
      )}
    </>
  );
};
