import CloseIcon from '@mui/icons-material/Close';
import { FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

import { useFormValidation } from 'src/hooks/useFormValidation/useFormValidation';
import { useAppDispatch, useAppSelector } from 'src/store';
import { reviewBrandLineDialogActions } from 'src/store/features/reviewBrandLineDialog/slice';
import { useSyncContext } from 'src/sync';
import { BrandLinesDetailsList } from './components/BrandLineDetailsList';

export enum ApprovalStatus {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = 2,
  REFERRED = 3,
}

const MAX_CHAR_LIMIT = 500;

interface ReviewBrandLineDialogFormData {
  status: string;
  comment: string;
}

const initialFormData: ReviewBrandLineDialogFormData = {
  status: '',
  comment: '',
};

export const ReviewBrandLineDialog = () => {
  const dispatch = useAppDispatch();
  const { brandLine, isOpen } = useAppSelector(state => state.reviewBrandLineDialog);

  const { errors, isDirty, form, handleInputChange, resetForm, handleSubmit } =
    useFormValidation<ReviewBrandLineDialogFormData>(initialFormData, {
      comment: {
        validate: {
          msg: 'Comment is required',
          validate: ({ comment, status }) => {
            if (status !== '' && (Number(status) as ApprovalStatus) !== ApprovalStatus.APPROVED) {
              return comment.trim() !== '';
            }

            return true;
          },
        },
        maxLength: {
          msg: 'Comment must be maximum 500 characters long',
          maxLength: MAX_CHAR_LIMIT,
        },
      },
      status: {
        required: {
          msg: 'Status is required',
          required: true,
        },
      },
    });

  const handleClose = () => {
    resetForm();
    dispatch(reviewBrandLineDialogActions.close());
  };

  const { reviewEntity } = useSyncContext();

  const handleFormSubmit = () => {
    if (brandLine) {
      void reviewEntity('brandLines', brandLine.brandLineGUID, {
        approvalStatus: Number(form.status),
        approvalComment: form.comment,
      });
    }
    handleClose();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle>
        Review brand line
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {brandLine && <BrandLinesDetailsList brandLine={brandLine} />}

        <FormControl fullWidth>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FormLabel htmlFor="status-select" sx={{ flex: 1 }} error={Boolean(errors.status)}>
              Status
            </FormLabel>
            <Select
              id="status-select"
              name="status"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              error={Boolean(errors.status)}
              value={form.status}
              onChange={handleInputChange}
              fullWidth
              sx={{ flex: 1 }}
            >
              <MenuItem value={String(ApprovalStatus.APPROVED)}>Approved</MenuItem>
              <MenuItem value={String(ApprovalStatus.REJECTED)}>Rejected</MenuItem>
              <MenuItem value={String(ApprovalStatus.REFERRED)}>Referred</MenuItem>
            </Select>
            {Boolean(errors.status) && <FormHelperText error>{errors.status}</FormHelperText>}
          </Box>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel htmlFor="comment-input" error={Boolean(errors.comment)}>
            Comment
          </FormLabel>
          <OutlinedInput
            id="comment-input"
            type="text"
            multiline
            rows={3}
            value={form.comment}
            name="comment"
            onChange={handleInputChange}
            onBlur={handleInputChange}
            error={Boolean(errors.comment)}
            fullWidth
          />
          {Boolean(errors.comment) && <FormHelperText error>{errors.comment}</FormHelperText>}
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSubmit(handleFormSubmit)}
          disabled={!isDirty || Object.keys(errors).length > 0}
        >
          Review brand line
        </Button>
      </DialogActions>
    </Dialog>
  );
};
