import * as Sentry from '@sentry/react';
import {
  deleteDB,
  type IDBPDatabase,
  type IDBPTransaction,
  openDB,
  type StoreKey,
  type StoreValue,
} from 'idb';

import { config } from '../../config';
import type { Entity } from '../../types/Entity';
import { entityConfiguration, objectStores } from '../config';
import type { LocalDBSchema } from '../types';

export class LocalDbManager {
  private database: IDBPDatabase<LocalDBSchema> | undefined;

  private readonly version = entityConfiguration.schemaVersion;

  private readonly AUDIT_ID = 'auditId';
  private readonly TRANSACTION_ID = 'transactionId';
  private readonly VERIFIED_DATE = 'verifiedDate';
  private readonly TRANSACTION_LOG = '_transactionLog';
  private readonly INDEX_BY_AUDIT_ID = '_index[auditId]';
  private readonly INDEX_BY_TRANSACTION_ID = '_index[transactionId]';
  private readonly INDEX_BY_VERIFIED_DATE = '_index[verifiedDate]';

  constructor(private readonly name: string) {}

  /**
   * Close the connection once all running transactions have finished.
   */
  close(reason: string) {
    try {
      config.loggingEnabled && console.debug(`Closing database due to ${reason}`);
      this.database?.close();
    } catch (err) {
      Sentry.captureException(
        `Exception occurs while closing database! ${reason} ${JSON.stringify(err)}`
      );
    }
    this.database = undefined;
  }

  /**
   * Close only db connection.
   */
  closeDbConnection() {
    this.database?.close();
  }

  /**
   * Delete the database.
   */
  async deleteDatabase() {
    if (this.database) this.database.close();
    config.loggingEnabled && console.debug('Delete local database', { name: this.name });
    await deleteDB(this.name);
  }

  /**
   * Delete all data from the database.
   */
  async resetLocalDatabase() {
    const dbConnection = await this.getDbConnection();

    config.loggingEnabled && console.debug('Resetting database', { name: this.name });
    try {
      await dbConnection.clear('_meta');

      for (const objectStore of objectStores) {
        await dbConnection.clear(objectStore[0] as 'entity');
      }
    } catch (error) {
      console.error('Error resetting database', error);
    }
  }

  /**
   * Retrieve all entity metadata.
   */
  async getAllMetadata() {
    const dbConnection = await this.getDbConnection();
    return dbConnection.transaction('_meta').store.getAll();
  }

  /**
   * Retrieve all entity metadata.
   */
  async getMetadata(entityType: string) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.transaction('_meta').store.get(entityType);
  }

  /**
   * Delete entity metadata.
   */
  async deleteMetaData(entityType: string) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.delete('_meta', entityType);
  }

  /**
   * Put a entity meta in the database.
   */
  async putMetadata(tx: StoreValue<LocalDBSchema, '_meta'>) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.put('_meta', tx, tx.entityType);
  }

  /**
   * Update a partial entity meta in the database.
   */
  async updateMetadata(entityType: string, tx: Partial<StoreValue<LocalDBSchema, '_meta'>>) {
    const dbConnection = await this.getDbConnection();
    const existingMetaData = await this.getMetadata(entityType);

    if (existingMetaData) {
      await dbConnection.put('_meta', { ...existingMetaData, ...tx }, entityType);
    }
  }

  /**
   * Retrieve all transactions.
   */
  async getAllTransactions() {
    const dbConnection = await this.getDbConnection();
    return dbConnection.transaction('_transactions').store.getAll();
  }

  /**
   * Put a transaction in the database.
   */
  async putTransaction(tx: StoreValue<LocalDBSchema, '_transactions'>) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.put('_transactions', tx);
  }

  /**
   * Deletes a transaction matching the given key.
   */
  async deleteTransaction(key: StoreKey<LocalDBSchema, '_transactions'> | IDBKeyRange) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.delete('_transactions', key);
  }

  /**
   * Retrieve all transaction logs.
   */
  async getAllTransactionLogs() {
    const dbConnection = await this.getDbConnection();
    return dbConnection.transaction(this.TRANSACTION_LOG).store.getAll();
  }

  /**
   * Retrieve all unverified transaction logs.
   */
  async getAllUnverifiedTransactionLogs() {
    const dbConnection = await this.getDbConnection();
    return await dbConnection.getAllFromIndex(
      this.TRANSACTION_LOG,
      this.INDEX_BY_AUDIT_ID,
      IDBKeyRange.only(0)
    );
  }

  async getTransactionLogsVerifiedOnOrBeforeDate(purgeDate: Date) {
    const dbConnection = await this.getDbConnection();
    return await dbConnection.getAllFromIndex(
      this.TRANSACTION_LOG,
      this.INDEX_BY_VERIFIED_DATE,
      IDBKeyRange.upperBound(purgeDate)
    );
  }

  /**
   * Get a transaction log from the database.
   */
  async getTransactionLog(txId: string) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.getFromIndex(this.TRANSACTION_LOG, this.INDEX_BY_TRANSACTION_ID, txId);
  }

  /**
   * Put a transaction log in the database.
   */
  async putTransactionLog(tx: StoreValue<LocalDBSchema, '_transactionLog'>) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.put(this.TRANSACTION_LOG, tx);
  }

  /**
   * Deletes a transaction log matching the given key.
   */
  async deleteTransactionLog(key: StoreKey<LocalDBSchema, '_transactionLog'> | IDBKeyRange) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.delete(this.TRANSACTION_LOG, key);
  }

  /**
   * Retrieve all transactions.
   */
  async getAllEntities(entityType: string) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.transaction(entityType as 'entity').store.getAll();
  }

  /**
   * Clear all entıty's data
   */
  async clearEntities(entityType: string) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.clear(entityType as 'entity');
  }

  /**
   * Put a entity data in the database.
   */
  async putEntity(tx: StoreValue<LocalDBSchema, 'entity'>, entityType: string) {
    const dbConnection = await this.getDbConnection();

    const transaction = dbConnection.transaction(entityType as 'entity', 'readwrite');
    await this.getPutEntityActionType(transaction, entityType, tx);
    await transaction.done;
  }

  /**
   * Put a entities data in the database.
   */
  async putEntities(tx: StoreValue<LocalDBSchema, 'entity'>[], entityType: string) {
    const dbConnection = await this.getDbConnection();

    const transaction = dbConnection.transaction(entityType as 'entity', 'readwrite');

    let promiseDataAction: (Promise<void> | Promise<IDBValidKey>)[] = [];

    promiseDataAction = tx.map(item => this.getPutEntityActionType(transaction, entityType, item));

    await Promise.all(promiseDataAction);
    await transaction.done;
  }

  /**
   * Retrieves the number of records.
   */
  async countEntities(entityType: string) {
    const dbConnection = await this.getDbConnection();
    return dbConnection.count(entityType as 'entity');
  }

  entityStoreNames() {
    if (!this.database) throw new Error('Trying to access closed database');
    return this.database.objectStoreNames;
  }

  getKeyPath(entityType: string) {
    const entitySchema = entityConfiguration.entities[entityType];
    if (!entitySchema) throw new Error(`Unexpected entity name: ${entityType}`);

    return entitySchema.keyPath;
  }

  /**
   * Retrieves the number of records in each indexed db.
   */
  async statistics() {
    const dbConnection = await this.getDbConnection();
    const stats: Record<string, number> = {};

    for (const key of dbConnection.objectStoreNames) {
      const storeName = key as string;

      if (
        storeName !== '_meta' &&
        storeName !== '_transactions' &&
        storeName !== this.TRANSACTION_LOG
      ) {
        stats[storeName] = await this.countEntities(storeName);
      }
    }

    return stats;
  }

  async open() {
    config.loggingEnabled &&
      console.debug(`Using database ${this.name} with schema version ${this.version}`);
    this.database = await openDB<LocalDBSchema>(this.name, this.version, {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      upgrade: (db, _oldVersion, _newVersion, _tx) => {
        !db.objectStoreNames.contains('_meta') && db.createObjectStore('_meta');
        !db.objectStoreNames.contains('_transactions') &&
          db.createObjectStore('_transactions', { keyPath: 'id', autoIncrement: true });

        if (!db.objectStoreNames.contains(this.TRANSACTION_LOG)) {
          const store = db.createObjectStore(this.TRANSACTION_LOG, {
            keyPath: 'id',
            autoIncrement: true,
          });
          store.createIndex(this.INDEX_BY_AUDIT_ID, this.AUDIT_ID, { unique: false });
          store.createIndex(this.INDEX_BY_TRANSACTION_ID, this.TRANSACTION_ID, { unique: true });
          store.createIndex(this.INDEX_BY_VERIFIED_DATE, this.VERIFIED_DATE, { unique: false });
        }

        for (const key in entityConfiguration.entities) {
          const entitySchema = entityConfiguration.entities[key];

          if (entitySchema) {
            // new entity type added, create new object store.
            if (!db.objectStoreNames.contains(key as 'entity')) {
              db.createObjectStore(key as 'entity', {
                keyPath: entitySchema.keyPath,
              });
            } else {
              const entityObjectStore = _tx.objectStore(key as 'entity');
              // if existing entity types keypath changed, delete the object store and recreate it again.
              if (entitySchema.keyPath !== entityObjectStore.keyPath) {
                db.deleteObjectStore(key as 'entity');

                db.createObjectStore(key as 'entity', {
                  keyPath: entitySchema.keyPath,
                });

                void this.deleteMetaData(key);
              }
            }
          }
        }

        for (const key of db.objectStoreNames) {
          const storeName = key as string;
          if (
            !entityConfiguration.entities[storeName] &&
            storeName !== '_meta' &&
            storeName !== '_transactions' &&
            storeName !== this.TRANSACTION_LOG
          ) {
            db.deleteObjectStore(storeName as 'entity');
          }
        }

        config.loggingEnabled && console.debug(`Upgrading database ${this.name}`);
      },
      blocking: (
        currentVersion: number,
        blockedVersion: number | null,
        event: IDBVersionChangeEvent
      ) => {
        this.close(
          `Database connection is blocking. Current Version: ${currentVersion}, Blocked Version : ${
            blockedVersion ?? ''
          }, Event:  ${JSON.stringify(event)}`
        );
      },
      blocked: (
        currentVersion: number,
        blockedVersion: number | null,
        event: IDBVersionChangeEvent
      ) => {
        this.close(
          `Database connection is blocked. Current Version: ${currentVersion}, Blocked Version : ${
            blockedVersion ?? ''
          }, Event:  ${JSON.stringify(event)}`
        );
      },
      terminated: () => {
        this.close('Database connection terminated.');
      },
    });
  }

  private async getDbConnection() {
    if (!this.database) {
      await this.open();
    }

    const dbConnection = this.database;
    if (!dbConnection) {
      throw Error('Db connection can not created!');
    }

    return dbConnection;
  }

  private getPutEntityActionType(
    tx: IDBPTransaction<LocalDBSchema, ['entity'], 'readwrite'>,
    entityType: string,
    item: Entity
  ): Promise<void> | Promise<IDBValidKey> {
    if (item['isDeleted']) {
      const collectorEntity = entityConfiguration.entities[entityType];

      if (collectorEntity) {
        const guid = item[collectorEntity.keyPath] as string;
        return tx.store.delete(guid);
      }
    }

    return tx.store.put(item);
  }
}
