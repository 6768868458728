import type { FormEvent } from 'react';
import { Alert, Container, Form, Modal } from 'react-bootstrap';

import { Button, ModalHeader } from 'src/css/styled-components';

const defaultErrorMsg = 'Incorrect username or password.';

const MAP_ENTRIES = [
  ['Your password must have a minimum of 8 characters', /password.*length.*greater/i],
  ['Your password must have a minimum of 8 characters', /password not long enough/i],
  ['Token must contain only numbers 0-9', /userCode.*failed.*satisfy.*\[0-9\]/i],
  ['Token must contain a total of 6 characters', /userCode.*length.*(greater|less).*6/i],
  [defaultErrorMsg, /Username\/client id combination not found./i],
  ['Incorrect MFA token, please try again', /Code.*mismatch.*fail.*/i],
  [defaultErrorMsg, /.*validation errors? detected.*'userName' failed to satisfy constraint.*/i],
  [defaultErrorMsg, /user does not exist/i],
] as const;

export enum SigningInType {
  None = 0,
  Office365 = 1,
  PasswordAuth = 2,
}

const customMessageMap = (message: string) => {
  const match = MAP_ENTRIES.filter(entry => entry[1].test(message));

  if (match[0] == null) {
    return message;
  }

  return match[0][0];
};

// @ts-expect-error: legacy code
// eslint-disable-next-line react/prop-types
export function PasswordHelp({ id }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  return <p id={id}>Your password must have a minimum of 8 characters</p>;
}

interface Props {
  body: React.ReactNode;
  cancelTxt: string;
  error: { message: string } | undefined | null;
  signingInType?: SigningInType;
  submitTxt: string;
  title: JSX.Element | string;
  onCancel: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

export const Base = ({
  body,
  cancelTxt,
  error,
  signingInType = SigningInType.None,
  submitTxt,
  title,
  onCancel,
  onSubmit,
}: Props) => {
  return (
    <Modal show centered animation={false} backdrop="static">
      <ModalHeader>{title}</ModalHeader>
      {/* eslint-disable-next-line react/prop-types, @typescript-eslint/no-unsafe-member-access */}
      {error && <Alert variant="danger">{customMessageMap(error.message)}</Alert>}
      <Container>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <Form onSubmit={onSubmit}>
          <Modal.Body>{body}</Modal.Body>
          <Modal.Footer>
            {(() => {
              if (signingInType !== SigningInType.Office365) {
                return (
                  <>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                    <Button type="button" variant="link" onClick={onCancel}>
                      {cancelTxt}
                    </Button>
                    <Button type="submit" variant="primary">
                      {submitTxt}
                    </Button>
                  </>
                );
              }
              return null;
            })()}
          </Modal.Footer>
        </Form>
      </Container>
    </Modal>
  );
};
