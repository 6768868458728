import type { FC, ReactNode } from 'react';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// @TODO - remove these legacy imports
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';

interface SortableMenuItemProps {
  children: ReactNode;
  disabled?: boolean | undefined;
  id: UniqueIdentifier;
  dataTestid?: string;
}

export const SortableMenuItem: FC<SortableMenuItemProps> = ({
  children,
  disabled = false,
  id,
  dataTestid,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    disabled,
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    // @TODO - move these into actual css files
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  };

  return (
    <Box ref={setNodeRef} sx={style} data-testid={dataTestid}>
      {children}
      <FontAwesomeIcon
        color="lightgrey"
        icon={faGripVertical}
        // @TODO - move this into actual css files
        style={{
          marginLeft: '8px',
          marginRight: '8px',
          outline: 'none',
          opacity: disabled ? 0 : 1,
        }}
        {...listeners}
        {...attributes}
      />
    </Box>
  );
};
