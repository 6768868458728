import type { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { OriginList } from 'src/shared';
import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildSelectStringValueHandler } from 'src/utils/createBrandLineFormHandlers';
import { Label, WarningDiv } from './styledComponents';

const CreateBrandLineOriginSection: FC<
  CreateBrandLineSectionProps & {
    origins: OriginList;
  }
> = ({ dispatchFormAction, formState, origins }) => {
  if (!formState.category) return null;
  return (
    <Form.Group>
      <Label>Origin</Label>
      <Select
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        options={origins?.map(_ => ({ label: _.originName, value: _.originName }))}
        onChange={buildSelectStringValueHandler(dispatchFormAction, 'origin')}
        value={{ label: formState.origin, value: formState.origin }}
        isDisabled={formState.originDisabled}
        id="create_brandline_origin_dropdown"
      />
      {formState.errorMessages['origin'] && (
        <WarningDiv>{formState.errorMessages['origin']}</WarningDiv>
      )}
    </Form.Group>
  );
};

export default CreateBrandLineOriginSection;
