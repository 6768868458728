import { v4 as uuidv4 } from 'uuid';

import { logError } from 'src/utils/logging';
import { doPostRequest } from '../../sync/functions/request';
import { type ApiError, type ApiResponse, HttpStatusCode } from '../../sync/types';

const callTriggerCheckRunAPI = async (marketId: number, checkId: number): Promise<ApiResponse> => {
  try {
    return await doPostRequest({
      path: `checks/${checkId}?marketId=${marketId}`,
      transactionId: uuidv4(),
    });
  } catch (err) {
    const apiError = err as ApiError;

    if (apiError.isAxiosError && apiError.response) {
      return {
        status: apiError.response.status,
        data: apiError.response.data,
        headers: apiError.response.headers,
      };
    } else {
      return {
        status: HttpStatusCode.ServiceNotAvailable,
        data: apiError.response?.data,
        headers: apiError.response?.headers ?? {},
      };
    }
  }
};

export const CHECK_FAIL_ERROR_MESSAGE = 'Triggering of check run failed!';

export const useTriggerCheckRun = () => {
  return {
    triggerCheckRun: async (
      marketId: number,
      checkId: number,
      checkName: string | undefined = ''
    ): Promise<void> => {
      // Call the API to trigger a check run on the server
      const triggerResponse = await callTriggerCheckRunAPI(marketId, checkId);
      if (triggerResponse.status !== HttpStatusCode.SuccessCreated) {
        logError(`${CHECK_FAIL_ERROR_MESSAGE} "${checkName}"`, {
          marketId,
          checkId,
          checkName,
        });
      }
    },
  };
};
