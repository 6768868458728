import type { YearPriceFormat } from '../../shared';
import { getFixedNumber, getPriceDecimalPlaces } from '../gridUtils';
import { isNewYearValueValid } from './yearValues';

const getPriceValueNumber = (
  year: string,
  newValue: string,
  priceFormat: YearPriceFormat | null | undefined
): number => {
  const decimalPlaces = getPriceDecimalPlaces(year, priceFormat);
  return getFixedNumber(newValue, decimalPlaces);
};

interface NewPriceValue {
  price: number | null;
  isPriceEstimated: boolean;
  priceValid: boolean;
}

export const getNewPriceValue = (
  colId: string,
  newValue: string,
  priceFormat: YearPriceFormat | null | undefined = undefined
): NewPriceValue => {
  if (!newValue) return { price: null, isPriceEstimated: false, priceValid: false };
  const isPriceEstimated =
    newValue.toString().startsWith('~') || newValue.toString().startsWith('--');

  const stringValue: string = isPriceEstimated
    ? newValue.replace('~', '').replace('--', '')
    : newValue;

  const valueNumber: number = getPriceValueNumber(colId, stringValue, priceFormat);

  const priceValid = isNewYearValueValid(colId, valueNumber);
  if (!priceValid) {
    return { price: null, isPriceEstimated, priceValid };
  }
  // return price as null if valueNumber is zero (zero if newValue is '' or undefined)
  return { price: valueNumber || null, isPriceEstimated, priceValid: true };
};
