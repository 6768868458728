import { type FC, useCallback, useMemo } from 'react';
// @TODO - remove these legacy imports
import { FormGroup, FormLabel } from 'react-bootstrap';
import { generatePath, useNavigate } from 'react-router-dom';
import type { OptionTypeBase } from 'react-select';

import { DropdownHeader } from '../css/styled-components';
import { useTable, useViews } from '../views/hooks';
import type { View, ViewSection } from '../views/types';
import { Dropdown } from './Dropdown';

interface ViewSwitcherProps {
  currentSection?: ViewSection | undefined;
  currentView?: View | undefined;
}

export const ViewSwitcher: FC<ViewSwitcherProps> = ({ currentSection, currentView }) => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { views } = useViews(table);

  const viewsInSection = useMemo(
    () =>
      views
        .filter(view => view.sectionId === currentSection?.id)
        .sort((compareA, compareB) => (compareA.displayOrder > compareB.displayOrder ? 1 : -1)),
    [currentSection, views]
  );

  const handleSelect = useCallback(
    (option: OptionTypeBase) => {
      const selectedView = viewsInSection.find(view => view.slug === option['value']);
      if (selectedView && selectedView.slug !== currentView?.slug) {
        navigate(
          generatePath('/:marketSlug/:tableSlug/:viewSlug', {
            marketSlug: market.marketSlug,
            tableSlug: table.slug,
            viewSlug: selectedView.slug,
          }),
          {
            replace: false,
          }
        );
      }
    },
    [currentView?.slug, market.marketSlug, navigate, table.slug, viewsInSection]
  );

  const options = useMemo(() => {
    return viewsInSection.map(({ name, slug }) => ({
      label: name,
      value: slug,
    }));
  }, [viewsInSection]);

  return (
    <FormGroup>
      <FormLabel id="viewSwitcher">
        <DropdownHeader>View</DropdownHeader>
      </FormLabel>
      <Dropdown
        ariaLabelledBy="viewSwitcher"
        options={[{ options }]}
        placeholder="Select view"
        selected={currentView?.slug}
        onSelect={handleSelect}
      />
    </FormGroup>
  );
};
