import Box from '@mui/material/Box';
import Typography, { type TypographyOwnProps } from '@mui/material/Typography';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';

const listItemProps: TypographyOwnProps = { color: 'text.secondary', variant: 'subtitle2' };

export const BrandLinesDetailsList = ({ brandLine }: { brandLine: DenormalizedBrandLine }) => {
  return (
    <Box>
      <Typography color="text.primary" variant="subtitle2">
        {brandLine.brandLineName}
      </Typography>
      <Typography {...listItemProps}>Display name: {brandLine.brandLineDisplayName}</Typography>
      <Typography {...listItemProps}>Category 5: {brandLine.category5Name}</Typography>
      <Typography {...listItemProps}>Brand: {brandLine.brandName}</Typography>
      <Typography {...listItemProps}>Owner: {brandLine.ownerName}</Typography>
      <Typography {...listItemProps}>Origin: {brandLine.originName}</Typography>
    </Box>
  );
};
