import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';

interface Props {
  open: boolean;
  brandGUID: string;
  onClose: () => void;
  onCancel: () => void;
  onSubmit: () => void;
}

const swapOwnerCopy = 'Swap owner';

export const SwapConfirmationDialog = ({ open, brandGUID, onCancel, onClose, onSubmit }: Props) => {
  const brandLines = useRecords<DenormalizedBrandLine>('brandLines');

  const getContentText = () => {
    const numberOfBrandLines = brandLines.filter(bl => bl.brandGUID === brandGUID).length;
    return `This will affect ${numberOfBrandLines} brand line${numberOfBrandLines > 1 ? 's' : ''}`;
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle fontWeight={600}>{`${swapOwnerCopy}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText>{getContentText()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          {swapOwnerCopy}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
