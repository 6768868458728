interface Observable<T> {
  notify(data: T): void;
  subscribe: (observer: (data: T) => void) => () => void;
}

export function createObservable<T>(): Observable<T> {
  const observers = new Set<(data: T) => void>();

  return {
    /**
     * Notify all observers.
     */
    notify(data: T) {
      observers.forEach(observer => observer(data));
    },
    /**
     * Add observer to the list.
     */
    subscribe(observer) {
      observers.add(observer);
      return () => observers.delete(observer);
    },
  };
}
