import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';

import { useAuth } from '../../account';
// @TODO - remove these legacy imports
import { CentralContainer } from '../../components/Container';

interface BootstrapErrorPageProps {
  error: Error;
}

export const BootstrapErrorPage = ({ error }: BootstrapErrorPageProps) => {
  const { logOut } = useAuth();

  return (
    <CentralContainer>
      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-4" size="3x" />
      <div>
        <h3>Error loading your data</h3>
        <p>{error.message}</p>
        <>
          <Button onClick={() => window.location.reload()}>Try again</Button>
          &nbsp;
          <Button onClick={() => logOut()}>Log out</Button>
        </>
      </div>
    </CentralContainer>
  );
};
