import { v4 as uuidv4 } from 'uuid';

import getAppEnv from './getAppEnv';

const breakpoints = {
  sm: 576,
  md: 768,
};

export const smSizeWindow: boolean = window.innerWidth <= breakpoints.sm;

export const mdSizeWindow: boolean =
  breakpoints.sm < window.innerWidth && window.innerWidth <= breakpoints.md;

// uuid v4 with hyphens removed to fit into 32 character column in SQL server
export const newGUID = () => uuidv4().replace(/-/g, '');

export const isProductionEnv = getAppEnv() === 'production';
