import type { MutableRefObject } from 'react';
import { type GridApi } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { GridOptions, Row } from '../../types';
import { createColumnDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useColumnsFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  gridApiRef: MutableRefObject<GridApi<R> | undefined>,
  props: Pick<
    DataGridProps<R>,
    | 'columns'
    | 'filter'
    | 'filterIsViewDefined'
    | 'formulaFunctions'
    | 'canChangeColumnVisibility'
    | 'canChangeColumnOrder'
    | 'rowSelection'
  >
) => {
  const logger = useGridLogger(gridRef, 'useColumnsFeature');

  const {
    columns,
    filter,
    filterIsViewDefined,
    formulaFunctions = {},
    canChangeColumnVisibility = true,
    canChangeColumnOrder = true,
    rowSelection,
  } = props;

  useInitialiseState(() => {
    const colDefs = columns.map(column =>
      createColumnDef<R>(column, {
        canChangeColumnVisibility,
        canChangeColumnOrder,
        filter,
        filterIsViewDefined,
        checkboxSelectionOnPrimaryColumn: rowSelection === 'multiple',
      })
    );

    gridRef.current.columnDefs = colDefs;
    gridRef.current.context.formulaFunctions = formulaFunctions;
  });

  useEffectAfterFirstRender(() => {
    logger.log(`Columns have changed, new length ${columns.length}`);

    const colDefs = columns.map(column =>
      createColumnDef<R>(column, {
        canChangeColumnVisibility,
        canChangeColumnOrder,
        filter,
        filterIsViewDefined,
        checkboxSelectionOnPrimaryColumn: rowSelection === 'multiple',
      })
    );

    gridApiRef.current?.setGridOption('columnDefs', colDefs);
  }, [columns, canChangeColumnVisibility, canChangeColumnOrder]);

  useEffectAfterFirstRender(() => {
    logger.log('Updating formula functions');

    gridRef.current.context.formulaFunctions = formulaFunctions;
  }, [formulaFunctions]);
};
