import { type HTMLAttributes } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreSharp';
import Autocomplete, { type AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { colors as theme } from 'src/css/theme';
import { type Option } from '../types';

interface MuiDropdownProps {
  placeholder: string;
  options: Option[];
  selected: string | undefined;
  handleChange: (event: React.SyntheticEvent, option: Option) => void;
}

const faded = 'hsl(0, 0%, 90%)';
const darker = 'hsl(0, 0%, 20%)';

const getGroupName = (option: Option) => `${option.isAssigned ? 'Assigned' : 'Other'} markets`;

const renderPopupIcon = () => (
  <ExpandMoreIcon
    sx={{
      width: 24,
      height: 24,
      fill: faded,
      '&:hover': { fill: darker },
      '&:active': { fill: theme.midGrey },
    }}
  />
);

const renderInput = (props: AutocompleteRenderInputParams, placeholder: string) => (
  <TextField
    {...props}
    InputLabelProps={{ className: '' }}
    size="small"
    variant="outlined"
    fullWidth
    placeholder={placeholder}
    sx={{
      bgcolor: theme.darkGrey,
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        color: theme.white,
        fontSize: '1rem',
        fontWeight: 400,
      },
      '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
        paddingBottom: '7.5px',
      },
    }}
  />
);

const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: Option) => (
  <Box
    component="li"
    data-testid={getGroupName(option)}
    sx={{
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      columnGap: '1rem',
    }}
    {...props}
  >
    <FontAwesomeIcon icon={faArrowRight} size="sm" opacity={0.5} />
    {option.label}
  </Box>
);

const MarketDropdown = (props: MuiDropdownProps) => {
  const { options, placeholder, selected, handleChange } = props;

  const getSelectedItem = (): Option =>
    selected
      ? options.find(option => option.value === selected.toLowerCase()) ?? options[0]!
      : options[0]!;

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '12.5rem',
        marginRight: '1rem',
        marginTop: '-1rem',
        paddingTop: 0,
        marginBottom: 0,
        maxHeight: '2.625rem',
        height: '100%',
      }}
    >
      <Autocomplete
        disableClearable
        disablePortal
        selectOnFocus
        blurOnSelect
        autoHighlight
        id="market-selector-combo"
        options={options}
        renderInput={params => renderInput(params, placeholder)}
        onChange={handleChange}
        value={getSelectedItem()}
        groupBy={option => getGroupName(option)}
        renderOption={renderOption}
        popupIcon={renderPopupIcon()}
        sx={{
          top: 0,
          opacity: 1.0,
          '& .MuiFormControl-root': {
            borderRadius: '0.25rem',
            borderWidth: '1px',
            borderStyle: 'solid',
            border: 'none',
            marginBottom: 0,
          },
        }}
      />
    </Box>
  );
};

export default MarketDropdown;
