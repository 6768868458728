import { memo, useEffect, useState } from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const CentralContainer = styled.div`
  width: 100%;
  height: 89vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerComponent = ({ showImmediately = false }: { showImmediately?: boolean }) => {
  const [showSpinner, setShowSpinner] = useState(!!showImmediately);

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), 1);
    return () => clearTimeout(timer);
  });

  if (!showSpinner) return null;
  return (
    <CentralContainer>
      <FontAwesomeIcon icon={faSpinner} className="fa-3x fa-spin" />
    </CentralContainer>
  );
};

export const Spinner = memo(SpinnerComponent);
