import { type FC, useEffect } from 'react';
import { Box } from '@mui/material';
import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  useKBar,
  useMatches,
} from 'kbar';

import { ResultItem } from './ResultItem';
import * as styles from './CommandBar.styles';

interface CommandBarProps {
  disabled?: boolean;
}
export const CommandBar: FC<CommandBarProps> = ({ disabled = false }) => {
  const { query } = useKBar();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    query.disable(disabled);
  }, [disabled, query]);

  return (
    <KBarPortal>
      <KBarPositioner style={styles.Positioner}>
        <KBarAnimator style={styles.Animator}>
          <KBarSearch
            autoCapitalize="off"
            autoCorrect="off"
            style={styles.Search}
            data-testid="kbar-search"
            placeholder="type here you fuckwit!"
          />
          <CommandBarResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

const CommandBarResults: FC = () => {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) => {
        return typeof item === 'string' ? (
          <Box sx={styles.Header}>{item}</Box>
        ) : (
          <ResultItem action={item} active={active} currentRootActionId={rootActionId} />
        );
      }}
    />
  );
};
