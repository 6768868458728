type TextEntry = 'brandName' | 'ownerName' | 'distributor';
export type RecordType = '' | 'Brand Line' | 'Brand' | 'Distributor' | 'Owner';

type TextEntryItems = {
  [TKey in TextEntry]: {
    key: TKey;
    maxLength: number;
    characterSet: 'limited' | 'extended' | 'full';
  };
};

const UserTextEntry: TextEntryItems = {
  ['brandName']: {
    key: 'brandName',
    maxLength: 50,
    characterSet: 'extended',
  },
  ['ownerName']: {
    key: 'ownerName',
    maxLength: 50,
    characterSet: 'limited',
  },
  ['distributor']: {
    key: 'distributor',
    maxLength: 100,
    characterSet: 'limited',
  },
};

const EXTENDED_CHAR_REGEX =
  /^[A-Za-zÀ-ÖØ-öø-ÿa-zA-Z0-9-']+(\s{0,1}[A-Za-zÀ-ÖØ-öø-ÿa-zA-Z0-9-()'/%&._\b?:!+,#$* ])*$/;
const LIMITED_CHAR_REGEX = /^\s{0,1}[a-zA-Z0-9-()'/%&.\b?:!+,#$* ]*$/;

export const isTextInputValid = (newValue: string, textEntryEnum: TextEntry): boolean => {
  const { maxLength, characterSet } = UserTextEntry[textEntryEnum];

  if (typeof newValue === 'string' && newValue.length > maxLength) {
    return false;
  }

  // patternOfAccentedChars
  if (typeof newValue === 'string' && characterSet === 'extended') {
    return EXTENDED_CHAR_REGEX.test(newValue.trim());
  }

  // patternOfLatinChars
  if (typeof newValue === 'string' && characterSet === 'limited') {
    return LIMITED_CHAR_REGEX.test(newValue.trim());
  }
  return true;
};
