import type { FC, PropsWithChildren } from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import {
  useHref,
  useLinkClickHandler,
  useMatch,
  useParams,
  useResolvedPath,
} from 'react-router-dom';

interface TablesNavigationItemProps extends PropsWithChildren {
  to: string;
}

const MarketNavigationItem: FC<TablesNavigationItemProps> = ({ children, to }) => {
  const { tableSlug } = useParams<'tableSlug'>();
  const { checkSlug } = useParams<'checkSlug'>();

  const path = useResolvedPath(to);
  const match = useMatch({ path: path.pathname });

  const href = useHref(path);
  const handleClick = useLinkClickHandler(path, { replace: false });

  const isActive =
    to === 'checks'
      ? // checklist doesn't have a checkSlug
        match !== null || Boolean(checkSlug)
      : Boolean(tableSlug);

  return (
    <NavItem>
      <NavLink active={isActive} href={href} onClick={handleClick}>
        {children}
      </NavLink>
    </NavItem>
  );
};

export const MarketNavigation: FC = () => {
  return (
    <Nav variant="pills" className="mr-auto">
      <MarketNavigationItem to=".">Data</MarketNavigationItem>
      <MarketNavigationItem to="checks">Checks</MarketNavigationItem>
    </Nav>
  );
};
