import type { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildSelectBooleanValueHandler } from 'src/utils/createBrandLineFormHandlers';
import { findBooleanOption } from 'src/utils/createBrandLineFormMappers';
import { Label } from './styledComponents';

const CreateBrandLineFlavouredSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  if (!formState.category) return null;
  if (!formState.flavouredVisible) return null;
  return (
    <Form.Group>
      <Label>Flavoured</Label>
      <Select
        options={formState.flavouredOptions.map(_ => ({
          label: _.text,
          value: _.value.toString(),
        }))}
        value={{
          label: findBooleanOption(formState.flavouredOptions, formState.flavoured),
          value: formState.flavoured.toString(),
        }}
        onChange={buildSelectBooleanValueHandler(dispatchFormAction, 'flavoured')}
        isDisabled={formState.flavouredDisabled || formState.attributesDisabled}
        data-testid="create_brand_line_flavoured_dropdown"
        id="create_brand_line_flavoured_dropdown"
      />
    </Form.Group>
  );
};

export default CreateBrandLineFlavouredSection;
