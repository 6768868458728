import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';

interface SwapBrandLineDialogState {
  brandSale: DenormalizedBrandSales | null;
  isOpen: boolean;
}

const initialState: SwapBrandLineDialogState = {
  brandSale: null,
  isOpen: false,
};

export const {
  actions: swapBrandLineDialogActions,
  reducer: swapBrandLineDialogReducer,
  name: swapBrandLineDialogSliceName,
} = createSlice({
  name: 'swapBrandLineDialog',
  initialState,
  reducers: {
    open(state, action: PayloadAction<DenormalizedBrandSales>) {
      state.brandSale = cloneDeep(action.payload);
      state.isOpen = true;
    },
    close() {
      return initialState;
    },
  },
});
