import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';

interface SwapCategory5DialogState {
  brandLine: DenormalizedBrandLine | undefined;
  isOpen: boolean;
}

const initialState: SwapCategory5DialogState = {
  brandLine: undefined,
  isOpen: false,
};

export const { actions: swapCategory5DialogActions, reducer: swapCategory5DialogReducer } =
  createSlice({
    name: 'swapCategory5Dialog',
    initialState,
    reducers: {
      open(state, action: PayloadAction<DenormalizedBrandLine>) {
        state.brandLine = action.payload;
        state.isOpen = true;
      },
      close() {
        return initialState;
      },
    },
  });
