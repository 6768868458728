import { useCallback, useMemo } from 'react';

import { useSyncContext } from 'src/sync';
import type { Entity, EntityType } from 'src/types/Entity';
import { type OrganicWineSalesEntity } from 'src/types/entity/OrganicWineSales';
import {
  denormalizeBrandLine as denormalizeBrandLineUtil,
  denormalizeEcommerceDataUtil,
  denormalizeOnPremisesSalesDataUtil,
  denormalizeOrganicWineDataUtil,
  denormalizeWineVarietalSaleDataUtil,
  getDenormalizedExternalData,
  getDenormalizedSaleData,
} from '../utils/denormalizers';

export const useRecords = <T = Entity>(
  entityType: EntityType | '',
  marketId?: number,
  refreshOnLastCachedDateChange?: boolean
): T[] => {
  const { getEntities, isMarketQuery, getEntitiesLookup, getLastCachedDate } = useSyncContext();
  const entityRecords = getEntities(entityType);
  const entitiesLookup = getEntitiesLookup();
  const lastCachedDate = getLastCachedDate();

  const denormalizeBrandLine = useCallback(
    (brandLine: Entity) => denormalizeBrandLineUtil(entitiesLookup, brandLine),
    [entitiesLookup]
  );

  const denormalizeSale = useCallback(
    (brandSale: Entity) => getDenormalizedSaleData(brandSale, entitiesLookup),
    [entitiesLookup]
  );

  const denormalizeExternalData = useCallback(
    (externalData: Entity) => getDenormalizedExternalData(externalData, entitiesLookup),
    [entitiesLookup]
  );

  const denormalizeWineVarietalSaleData = useCallback(
    (wineVarietalSaleData: Entity) =>
      denormalizeWineVarietalSaleDataUtil(entitiesLookup, wineVarietalSaleData),
    [entitiesLookup]
  );

  const denormalizeOrganicWineData = useCallback(
    (organicWine: OrganicWineSalesEntity) => denormalizeOrganicWineDataUtil(organicWine),
    []
  );

  const denormalizeEcommerceData = useCallback(
    (ecommerceData: Entity) => denormalizeEcommerceDataUtil(entitiesLookup, ecommerceData),
    [entitiesLookup]
  );

  const denormalizeOnPremiseSalesData = useCallback(
    (onPremiseSalesData: Entity) =>
      denormalizeOnPremisesSalesDataUtil(entitiesLookup, onPremiseSalesData),
    [entitiesLookup]
  );

  const data = useMemo(() => {
    const records = isMarketQuery(entityType)
      ? entityRecords.filter(item => (item['countryId'] ?? item['marketId']) === marketId)
      : entityRecords;

    if (entityType === 'brandLines') {
      return records.map(denormalizeBrandLine);
    }

    if (entityType === 'brandSales' || entityType === 'previousForecast') {
      return records.map(denormalizeSale);
    }

    if (entityType === 'externalData') {
      return records.map(denormalizeExternalData);
    }

    if (entityType === 'wineVarietalSales' || entityType === 'wineRegionSales') {
      return records.map(denormalizeWineVarietalSaleData);
    }

    if (entityType === 'organicWineSales') {
      return (records as unknown as OrganicWineSalesEntity[]).map(denormalizeOrganicWineData);
    }

    if (entityType === 'ecommerceSales') {
      return records.map(denormalizeEcommerceData);
    }

    if (entityType === 'onPremiseSales') {
      return records.map(denormalizeOnPremiseSalesData);
    }

    return records;
    // Do not put entityRecords in this dependency list otherwise the memoisation recalculates every time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, marketId, ...(refreshOnLastCachedDateChange ? [lastCachedDate] : [])]);

  return data as T[];
};
