import type { HTMLAttributes } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';

import type { DistributorsEntity } from 'src/types/entity/Distributors';
import * as styles from '../styles/distributorSearch';

interface Props extends HTMLAttributes<HTMLLIElement> {
  distributorName: string;
  selectedOption: DistributorsEntity | null;
  onChange: () => void;
}

export const DistributorSearch = ({
  distributorName,
  selectedOption,
  onChange,
  ...liElementProps
}: Props) => {
  const isChecked = () => {
    return distributorName === selectedOption?.distributorName;
  };

  return (
    <li {...liElementProps} style={styles.optionLiContainer}>
      <Box sx={styles.optionWrapper}>
        <Radio size="small" value={distributorName} checked={isChecked()} onChange={onChange} />
      </Box>
      <Typography variant="body1" fontWeight={600} sx={styles.distributorName}>
        {distributorName}
      </Typography>
    </li>
  );
};
