import type { Volume } from 'src/types/Entity';
import { PopupType } from 'src/views/Pages/common/types';
import { ContextMenuActionType, type GridContextMenuItem } from 'src/views/utils/contextmenu';
import type { Params, PopupConfigurationSetter, Table } from './types';

const COPY = {
  add: {
    wineRegion: 'Add wine region',
    wineVarietal: 'Add wine varietal',
  },
  remove: {
    wineRegion: 'Remove wine region',
    wineVarietal: 'Remove wine varietal',
  },
};

export const hasEmptyVolume = (volume: Volume) => {
  const hasVolumeValues = Object.values(volume).some(value => Boolean(value));
  return !hasVolumeValues;
};

interface GetContextMenuItemsParams {
  canWriteRecords?: boolean | undefined;
  canRemoveRecords?: boolean | undefined;
  params: Params;
  table: Table;
  setPopupConfiguration: PopupConfigurationSetter;
  onOriginOptionSelection: (selectedOriginId: number) => void;
}

export const getContextMenuItems = ({
  canWriteRecords,
  canRemoveRecords,
  params,
  table,
  setPopupConfiguration,
  onOriginOptionSelection,
}: GetContextMenuItemsParams): GridContextMenuItem[] => {
  if (params.node?.group) {
    return [];
  }

  return [
    canWriteRecords && {
      name: COPY.add[table],
      action: () => {
        if (params.node?.data) {
          onOriginOptionSelection(params.node.data.originId);
          setPopupConfiguration({
            popupType: PopupType.AddNewItem,
            selectedItem: params.node.data,
          });
        }
      },
      actionType: ContextMenuActionType.ADD,
      tooltip: COPY.add[table],
      isMainMenuItem: true,
    },
    canRemoveRecords && {
      name: COPY.remove[table],
      action: () => {
        if (params.node?.data) {
          const popupType = hasEmptyVolume(params.node.data.volume)
            ? PopupType.DeleteConfirmation
            : PopupType.DeleteWarning;

          setPopupConfiguration({
            popupType,
            selectedItem: params.node.data,
          });
        }
      },
      actionType: ContextMenuActionType.DELETE,
      tooltip: COPY.remove[table],
    },
  ].filter(Boolean) as GridContextMenuItem[];
};
