import type { HTMLAttributes } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import * as styles from '../styles/searchSuggestion.styles';

interface Props extends HTMLAttributes<HTMLLIElement> {
  brandName: string;
  ownerName: string;
  checked: boolean;
  onChange: () => void;
}

export const SearchSuggestion = ({
  brandName,
  ownerName,
  checked,
  onChange,
  ...liElementProps
}: Props) => {
  return (
    <li {...liElementProps} style={styles.optionLiContainer}>
      <Box sx={styles.optionWrapper}>
        <Radio size="small" value={brandName} checked={checked} onChange={onChange} />
        <Box sx={styles.recordContext}>
          <>
            <Typography variant="body1" fontWeight={600} sx={styles.optionBrandName}>
              {brandName}
            </Typography>

            <Typography variant="caption">Owner: {ownerName}</Typography>
          </>
        </Box>
      </Box>
    </li>
  );
};
