import type { FC, PropsWithChildren } from 'react';
import { KBarProvider } from 'kbar';

export interface CommandBarProviderProps {
  shortcut?: string;
}

export const CommandBarProvider: FC<PropsWithChildren<CommandBarProviderProps>> = ({
  children,
  shortcut,
}: PropsWithChildren<CommandBarProviderProps>) => (
  <KBarProvider actions={[]} options={{ toggleShortcut: shortcut ?? '$mod+k' }}>
    {children}
  </KBarProvider>
);
