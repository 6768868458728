import type { unsafe_RowNode } from 'src/components/DataGrid';
import type { Entity } from '../../../types/Entity';

export function sortRowsWithValueToBottom<T>(
  nodes: unsafe_RowNode<Entity>[] = [],
  attribute: string,
  value: T
): void {
  let nextInsertPos = 0;
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i]?.data?.[attribute] !== value) {
      const node = nodes.splice(i, 1)[0];
      if (node) {
        nodes.splice(nextInsertPos, 0, node);
        nextInsertPos++;
      }
    }
  }
}
