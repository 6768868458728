import { useCallback, useMemo, useState } from 'react';
// @TODO - remove these legacy imports
import { NavDropdown } from 'react-bootstrap';
import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Typography from '@mui/material/Typography';

import type { Column, RowGroupingModel } from 'src/components/DataGrid';
import { Button } from '../css/styled-components';
import { GroupingMenuItem } from './GroupingMenuItem';
import { SortableMenuItem } from './SortableMenuItem';

interface GroupingMenuProps {
  columns: Column[];
  disabled?: boolean | undefined;
  rowGrouping: RowGroupingModel | undefined;
  onRowGroupingChange: (model: RowGroupingModel) => void;
}

export const GROUPING_UNAVAILABLE = 'Grouping unavailable';

export const GroupingMenu = ({
  columns,
  disabled,
  rowGrouping = [],
  onRowGroupingChange,
}: GroupingMenuProps) => {
  const groupedColumns = useMemo<Column[]>(() => {
    const groupedColumns: Column[] = [];

    rowGrouping.forEach(field => {
      const column = columns.find(col => col.field === field);
      if (column && column.groupable === true) {
        groupedColumns.push(column);
      }
    });

    return groupedColumns;
  }, [columns, rowGrouping]);

  const availableColumns = useMemo<Column[]>(() => {
    return columns
      .filter(col => col.groupable === true && !rowGrouping.includes(col.field))
      .sort((colA, colB) => (colA.headerName > colB.headerName ? 1 : -1));
  }, [columns, rowGrouping]);

  const [addGroupActive, setAddGroupActive] = useState(false);

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const oldIndex = groupedColumns.findIndex(col => col.field === active.id);
        const newIndex = groupedColumns.findIndex(col => col.field === over?.id);

        onRowGroupingChange(arrayMove(groupedColumns, oldIndex, newIndex).map(col => col.field));
      }
    },
    [groupedColumns, onRowGroupingChange]
  );

  const handleChange = useCallback(
    (column: Column | null, index: number) => {
      const model: RowGroupingModel = [...rowGrouping];
      model.splice(index, 1)[0];
      if (column) {
        model.splice(index, 0, column.field);
      }
      onRowGroupingChange(model);
    },
    [onRowGroupingChange, rowGrouping]
  );

  const toggleAddGroupActive = useCallback(
    () => setAddGroupActive(addGroupActive => !addGroupActive),
    []
  );

  const handleAdd = useCallback(
    (column: Column | null) => {
      if (column) {
        onRowGroupingChange([...rowGrouping, column.field]);
      }
      toggleAddGroupActive();
    },
    [onRowGroupingChange, rowGrouping, toggleAddGroupActive]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <NavDropdown title="Group" id="groupingMenu">
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext items={rowGrouping} strategy={verticalListSortingStrategy}>
          {groupedColumns.length > 0 &&
            groupedColumns.map((col, index) => (
              <SortableMenuItem key={col.field} disabled={disabled} id={col.field}>
                <GroupingMenuItem
                  columns={availableColumns}
                  current={col}
                  disabled={disabled}
                  index={index}
                  onChange={handleChange}
                />
              </SortableMenuItem>
            ))}
        </SortableContext>
      </DndContext>
      {availableColumns.length > 0 && (
        <>
          {addGroupActive && !disabled && (
            <GroupingMenuItem
              columns={availableColumns}
              index={rowGrouping.length}
              onChange={handleAdd}
            />
          )}
          <Button
            aria-label="Add group"
            disabled={disabled ?? false}
            onClick={toggleAddGroupActive}
            title="Add group"
            style={{ marginBottom: '0.5rem', marginTop: '0.5rem', marginLeft: '8px' }}
            variant="secondary"
          >
            +
          </Button>
        </>
      )}
      {availableColumns.length === 0 && groupedColumns.length === 0 && (
        <Typography variant="subtitle2" textAlign="center">
          {GROUPING_UNAVAILABLE}
        </Typography>
      )}
    </NavDropdown>
  );
};
