import { components } from 'react-select';
import styled from 'styled-components';

import type { LookUp } from '../../../shared';
import { useScrollIntoView } from '../../../utils/utilHooks';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type OptionProps = {
  level: number;
  isSelected: boolean;
  theme: LookUp;
};
const StyledOption = styled.div`
  display: flex;
  padding-left: ${({ level }: OptionProps) => `${(level - 1) * 10}px`};
  font-size: ${({ level }: OptionProps) => (level === 1 ? '12pt' : '11pt')};
  background-color: ${({ isSelected, theme }: OptionProps) => {
    // @ts-expect-error: legacy code
    return isSelected ? theme.darkBlue : theme.white;
  }};
  &:hover {
    background-color: ${({ isSelected }: OptionProps) => !isSelected && '#DEEBFF'};
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CategoryOptionComponent = (props: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data, isSelected } = props;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const ref = useScrollIntoView(isSelected);
  return (
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    <StyledOption level={data.level} isSelected={isSelected}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...props} innerRef={ref} />
    </StyledOption>
  );
};

export default CategoryOptionComponent;
