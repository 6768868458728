import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import type { DistributorsEntity } from 'src/types/entity/Distributors';
import { getSearchResults } from './helpers';

interface HookParams {
  distributors: DistributorsEntity[];
  brandSale: DenormalizedBrandSales | null;
  selection: DistributorsEntity | null;
  setSelection: (selection: DistributorsEntity | null) => void;
}

const MIN_CHARACTERS_FOR_SEARCH = 2;

export const DEBOUNCE_DELAY_MS = 200;

export const useDistributorSearch = ({
  brandSale,
  distributors,
  selection,
  setSelection,
}: HookParams) => {
  const noSearchResultsCopy = useRef('');
  const [search, setSearch] = useState('');

  const [filteredList, setFilteredList] = useState<DistributorsEntity[]>([]);

  const hasSelectionInSearchResults = (filteredList: DistributorsEntity[]) => {
    return filteredList.some(fbl => fbl.distributorGUID === selection?.distributorGUID);
  };

  const clearFilteredListAndNoSearchResultsCopy = () => {
    setFilteredList([]);
    noSearchResultsCopy.current = '';
  };

  const debouncedHandleSearchResults = useDebouncedCallback(
    () => {
      if (search.length < MIN_CHARACTERS_FOR_SEARCH) {
        clearFilteredListAndNoSearchResultsCopy();
        return;
      }

      const searchResults = getSearchResults({
        distributors,
        brandSale,
        search,
      });
      setFilteredList(searchResults);

      if (searchResults.length === 0) {
        noSearchResultsCopy.current = `No distributors found for "${search}"`;
      }

      if (!hasSelectionInSearchResults(searchResults)) {
        setSelection(null);
      }
    },
    DEBOUNCE_DELAY_MS,
    { trailing: true }
  );

  const handleSearch = (searchValue: string) => {
    setSearch(searchValue);
    debouncedHandleSearchResults();
  };

  const handleSelection = (selection: DistributorsEntity | null) => {
    setSelection(selection);
  };

  /*
    Necessary workaround for clearing state, because the dialog does not get unmounted when closed.
    TODO: Consider conditionally rendering the component in "BrandSalesTableViewPage" with the "isOpen" Redux store state to guarantee it's unmounted when closing;
    only thing is that doing so appears to cause a delay when opening the dialog, potentially due to re-renders of the "BrandSalesTableViewPage".
  */
  const resetHookState = () => {
    setFilteredList([]);
    setSelection(null);
    setSearch('');
    noSearchResultsCopy.current = '';
  };

  return {
    search,
    filteredList,
    noSearchResultsCopy: noSearchResultsCopy.current,
    selection,
    handleSearch,
    handleSelection,
    resetHookState,
  };
};
