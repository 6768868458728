import { useCallback, useEffect, useMemo } from 'react';
import { type Action, useRegisterActions } from 'kbar';
import useLocalStorageState from 'use-local-storage-state';

import type {
  ColumnOrderModel,
  ColumnVisibilityModel,
  ColumnWidthModel,
  FilterModel,
  PinnedColumnsModel,
  RowGroupingModel,
} from 'src/components/DataGrid';
import { randomId } from 'src/utils/string';
import type { GridViewState, NumbersOfGroupedColumnsToShowModel, Table } from 'src/views/types';

const VERSION = 1;

export const useGridViewState = (table: Table) => {
  const [state, setState] = useLocalStorageState<GridViewState>(`GridViewState.${table.tableId}`, {
    defaultValue: { _version: VERSION },
  });

  useEffect(() => {
    if (state._version === undefined || state._version < VERSION) {
      // do any necessary migration here

      if (table.name === 'sales') {
        setState(prevState => {
          delete prevState.rowGrouping;
          return { ...prevState, _version: VERSION };
        });
      } else {
        setState(prevState => ({ ...prevState, _version: VERSION }));
      }
    }
  }, [state, setState, table.name]);

  useEffect(() => {
    if (!state.columnOrder || Object.keys(state.columnOrder).length === 0) {
      setState(prevState => ({
        ...prevState,
        columnOrder: table.fields.map(col => col.field),
      }));
    }
    if (!state.columnVisibility || Object.keys(state.columnVisibility).length === 0) {
      setState(prevState => ({
        ...prevState,
        columnVisibility: table.fields.reduce<Record<string, boolean>>((acc, col) => {
          // eslint-disable-next-line no-param-reassign
          acc[col.field] = col.visible ?? true;
          return acc;
        }, {}),
      }));
    }
  }, [setState, state, table.fields]);

  const handleColumnOrderChange = useCallback(
    (model: ColumnOrderModel) => setState(prevState => ({ ...prevState, columnOrder: model })),
    [setState]
  );

  const handleColumnVisibilityChange = useCallback(
    (model: ColumnVisibilityModel) =>
      setState(prevState => ({ ...prevState, columnVisibility: model })),

    [setState]
  );

  const handleFilterChange = useCallback(
    (model: FilterModel) => setState(prevState => ({ ...prevState, filter: model })),
    [setState]
  );

  const handleColumnWidthsChange = useCallback(
    (model: ColumnWidthModel) => setState(prevState => ({ ...prevState, columnWidths: model })),
    [setState]
  );

  const handlePinnedColumnsChange = useCallback(
    (model: PinnedColumnsModel) => setState(prevState => ({ ...prevState, pinnedColumns: model })),
    [setState]
  );

  const handleRowGroupingChange = useCallback(
    (model: RowGroupingModel) => setState(prevState => ({ ...prevState, rowGrouping: model })),
    [setState]
  );

  const handleNumbersOfGroupedColumnsToShowChange = useCallback(
    (model: NumbersOfGroupedColumnsToShowModel) =>
      setState(prevState => ({ ...prevState, numbersOfGroupedColumnsToShow: model })),
    [setState]
  );

  const stateActions = useMemo<Action[]>(
    () => [
      {
        id: randomId(),
        name: 'Reset view',
        section: 'Developer',
        perform: () => setState({ _version: VERSION }),
      },
    ],
    [setState]
  );

  useRegisterActions(stateActions);

  return {
    handleColumnOrderChange,
    handleColumnVisibilityChange,
    handleColumnWidthsChange,
    handlePinnedColumnsChange,
    handleRowGroupingChange,
    handleFilterChange,
    handleNumbersOfGroupedColumnsToShowChange,
    state,
  };
};
