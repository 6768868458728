import type { unsafe_GetContextMenuItemsParams } from 'src/components/DataGrid';
import { createBrandLineDialogActions } from 'src/store/features/createBrandLineDialog/slice';
import type { Entity } from 'src/types/Entity';
import { ContextMenuActionType, type GridContextMenuItem } from 'src/views/utils/contextmenu';

interface GetContextMenuItemsParams {
  canWriteRecords: boolean | undefined;
  params: unsafe_GetContextMenuItemsParams<Entity>;
  dispatch: (args: unknown) => void;
  onRenameAction: () => void;
  onSwapAction: () => void;
  onSwapCategory5Action: () => void;
}

export const getContextMenuItems = ({
  canWriteRecords,
  params,
  onRenameAction,
  onSwapAction,
  onSwapCategory5Action,
  dispatch,
}: GetContextMenuItemsParams) => {
  const gridContextMenuItems: GridContextMenuItem[] = [];

  if (canWriteRecords) {
    gridContextMenuItems.push({
      name: 'Create brand line',
      action: () => {
        dispatch(createBrandLineDialogActions.open());
      },
      actionType: ContextMenuActionType.ADD,
      tooltip: 'Create brand line',
      isMainMenuItem: true,
    });
  }

  if (canWriteRecords && !params.node?.group) {
    const chosenColumn = params.column?.getColDef().headerName?.toLowerCase() ?? '';

    if (['brand', 'owner'].includes(chosenColumn)) {
      gridContextMenuItems.push({
        name: `Rename ${chosenColumn}`,
        actionType: ContextMenuActionType.EDIT,
        action: onRenameAction,
        tooltip: `Rename ${chosenColumn}`,
      });

      gridContextMenuItems.push({
        name: `Swap ${chosenColumn}`,
        actionType: ContextMenuActionType.SWAP,
        action: onSwapAction,
        tooltip: `Swap ${chosenColumn}`,
      });
    }

    if (chosenColumn === 'category 5') {
      gridContextMenuItems.push({
        name: `Swap Category 5`,
        actionType: ContextMenuActionType.SWAP,
        action: onSwapCategory5Action,
        tooltip: `Swap Category 5`,
      });
    }
  }

  return gridContextMenuItems;
};
