import type { CategoryRules } from './categoryRules';
import { categoriesRules, standardRules } from './categoryRules';

export const getCategoryRules = (id: string): CategoryRules => {
  const categoryRules = categoriesRules.find(_ => _.id.toString() === id);
  if (!categoryRules) {
    console.warn(`Cannot find rule for category with id ${id}`);
  }
  return categoryRules ?? standardRules;
};
