export const config = {
  apiUrl: '/api',
  build: '0',
  cognitoDomain: process.env.REACT_APP_COGNITO_DOMAIN,
  cognitoRegion: process.env.REACT_APP_COGNITO_REGION,
  domain: window.location.origin,
  isPreview: window.location.host === 'collector-infrastructure-alb.preview.theiwsr.net',
  isProduction: window.location.hostname === 'collectorv2.theiwsr.com',
  loggingEnabled: false,
  userPoolClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  version: '0.0.0',
};
