import { type FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { set as setProperty } from 'dot-prop';

import { ColumnVisibilityMenu } from 'src/components/ColumnVisibilityMenu';
import { Container } from 'src/components/Container';
import {
  DataGrid,
  type unsafe_GridApi,
  type unsafe_GridReadyEvent,
  type unsafe_NewValueParams,
  type unsafe_RowClassParams,
  type unsafe_RowNode,
} from 'src/components/DataGrid';
import { GroupingMenu } from 'src/components/GroupingMenu';
import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from 'src/components/Sidebar';
import { ViewSectionSwitcher } from 'src/components/ViewSectionSwitcher';
import { ViewSwitcher } from 'src/components/ViewSwitcher';
import { Content } from 'src/css/styled-components';
import { useDataGridUnmountKey } from 'src/hooks/useDataGridUnmountKey/useDataGridUnmountKey';
import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type DenormalizedOnPremiseSales } from 'src/records/types/DenormalizedOnPremiseSales';
import { denormalizeOnPremisesSalesDataUtil } from 'src/records/utils/denormalizers';
import { useSyncContext } from 'src/sync/hooks/useSyncContext';
import { type Entity, type EntityType } from 'src/types/Entity';
import { type BrandLinesEntity } from 'src/types/entity/BrandLines';
import { type BrandSalesEntity } from 'src/types/entity/BrandSales';
import { type OnPremiseSalesEntity } from 'src/types/entity/OnPremiseSales';
import isDeepEqual from 'src/utils/isDeepEqual';
import { useGridViewState, useTable, useTitle, useViews } from 'src/views/hooks';
import { useColumns } from 'src/views/hooks/useColumns';
import {
  absoluteChange,
  compoundAnnualGrowthRate,
  onPremiseCompoundAnnualGrowthRate,
  onPremiseRelativeChange,
  relativeChange,
} from 'src/views/utils';
import { sendToClipboard } from 'src/views/utils/grid/gridClipboardCopyUtils';
import { pasteFromClipboard } from 'src/views/utils/grid/gridClipboardPasteUtils';
import { ON_PREMISE_COLUMN_VISIBILITY } from './constants';
import {
  calculateOnPremise,
  calculateOnPremiseGridData,
  getSegmentsForBrandLinesEntities,
  getSegmentsForUpdatedSalesEntities,
  getUpdatedBrandSales,
  getUpdatedBrandSalesFromBrandLines,
} from './helpers';
import { getOnPremiseVolumeTotals, getOnPremiseWeightedAverage } from './onPremiseWeightedAverage';

import 'src/css/grid.css';

const UPDATE_DIRECTLY_ON_AG_GRID = 'UPDATE_DIRECTLY_ON_AG_GRID';

export const OnPremiseTableViewPage: FC = () => {
  const navigate = useNavigate();
  const { market, table } = useTable();
  const { sections, views } = useViews(table);
  const { viewSlug } = useParams<'viewSlug'>();
  const { key } = useDataGridUnmountKey({ market, table, viewSlug });

  const { convertFieldsToColumns } = useColumns();

  const formulaFunctions = useMemo(
    () => ({
      compoundAnnualGrowthRate,
      relativeChange,
      absoluteChange,
      onPremiseRelativeChange,
      onPremiseCompoundAnnualGrowthRate,
    }),
    []
  );

  const aggregationFunctions = useMemo(
    () => ({ getOnPremiseWeightedAverage, getOnPremiseVolumeTotals }),
    []
  );

  const view = useMemo(
    () => views.find(view => view.slug === viewSlug),
    // Don't depend on views. Each time we do useRecords we filter on all views
    // so receive a new object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewSlug]
  );
  const section = useMemo(() => {
    return sections.find(section => section.id === view?.sectionId);
    // Don't depend on sections, we get a new object each time with useRecords
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view?.sectionId]);

  const onPremiseSales = useRecords<DenormalizedOnPremiseSales>(table.source, market.marketId);
  const brandSales = useRef(useRecords<DenormalizedBrandSales>('brandSales', market.marketId));

  const dataGridApiRef = useRef<unsafe_GridApi<DenormalizedOnPremiseSales> | null>(null);
  const { onEntitiesReceived, getEntitiesLookup, updateEntity } = useSyncContext();

  const { columns, columnGrouping } = useMemo(
    () => {
      const { columns, columnGrouping } = convertFieldsToColumns(table.fields, {
        periodiseByRows: false,
        lockedView: Boolean(view?.locked),
      });
      return {
        columns,
        columnGrouping: [
          ...columnGrouping,
          {
            groupId: 'onPremise',
            headerName: 'Volume - On-Premise (Change)',
            children: [{ field: 'onPremise.1yrChange' }, { field: 'onPremise.5yrCAGR' }],
          },
          {
            groupId: 'allChannels',
            headerName: 'Volume - All Channels (Change)',
            children: [{ field: 'allChannels.1yrChange' }, { field: 'allChannels.5yrCAGR' }],
          },
        ],
      };
    },
    // do not put convertFieldsToColumns in this dependency list as it causes re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.fields, view?.locked]
  );

  let gridData = calculateOnPremiseGridData(onPremiseSales, brandSales.current);

  const { handleColumnWidthsChange, handleNumbersOfGroupedColumnsToShowChange, state } =
    useGridViewState(table);

  const groupingColumn = useMemo(
    () => (view?.leafField ? { leafField: view.leafField } : {}),
    [view?.leafField]
  );

  const updateGridRowNodeWithSegment = (
    gridRow: DenormalizedOnPremiseSales,
    segment: DenormalizedOnPremiseSales
  ) => {
    const rowId = getRowId() as keyof DenormalizedOnPremiseSales;

    dataGridApiRef.current?.forEachNode((node: unsafe_RowNode<DenormalizedOnPremiseSales>) => {
      const isNodeMatchingUpdatedSegment = node.data?.[rowId] === segment[rowId];

      if (isNodeMatchingUpdatedSegment) {
        node.data = { ...node.data, ...gridRow };
        dataGridApiRef.current?.applyTransaction({
          update: [node.data],
        });
        return;
      }
    });
  };

  const updateAgGridWithBrandLinesTableData = (brandLineEntities: BrandLinesEntity[]) => {
    const updatedBrandSales = getUpdatedBrandSalesFromBrandLines(
      brandSales.current,
      brandLineEntities
    );
    const segmentsForExistingBrandSales = getSegmentsForBrandLinesEntities(
      onPremiseSales,
      brandSales.current,
      brandLineEntities
    );
    const segmentsForUpdatedBrandSales = getSegmentsForBrandLinesEntities(
      onPremiseSales,
      updatedBrandSales,
      brandLineEntities
    );

    brandSales.current = updatedBrandSales;
    gridData = calculateOnPremiseGridData(onPremiseSales, brandSales.current);

    const rowId = getRowId() as keyof OnPremiseSalesEntity;

    gridData.forEach(gridRow => {
      const newSegmentGridRow = segmentsForUpdatedBrandSales.find(
        segment => segment[rowId] === gridRow[rowId]
      );

      if (newSegmentGridRow) {
        updateGridRowNodeWithSegment(gridRow, newSegmentGridRow);
      }

      const currentMatchingGridRow = segmentsForExistingBrandSales.find(
        segment => segment[rowId] === gridRow[rowId]
      );

      if (currentMatchingGridRow) {
        updateGridRowNodeWithSegment(gridRow, currentMatchingGridRow);
      }
    });
  };

  const updateAgGridWithSalesTableData = (brandSalesEntities: BrandSalesEntity[]) => {
    const entitiesLookup = getEntitiesLookup();
    const updatedBrandSales = getUpdatedBrandSales({
      brandSalesEntities,
      brandSales: brandSales.current,
      entitiesLookup,
    });

    const segmentsForExistingBrandSales = getSegmentsForUpdatedSalesEntities(
      onPremiseSales,
      brandSales.current,
      brandSalesEntities
    );
    const segmentsForUpdatedBrandSales = getSegmentsForUpdatedSalesEntities(
      onPremiseSales,
      updatedBrandSales,
      brandSalesEntities
    );

    brandSales.current = updatedBrandSales;
    gridData = calculateOnPremiseGridData(onPremiseSales, brandSales.current);

    const rowId = getRowId() as keyof OnPremiseSalesEntity;

    gridData.forEach(gridRow => {
      const newSegmentGridRow = segmentsForUpdatedBrandSales.find(
        segment => segment[rowId] === gridRow[rowId]
      );

      if (newSegmentGridRow) {
        updateGridRowNodeWithSegment(gridRow, newSegmentGridRow);
      }

      const currentMatchingGridRow = segmentsForExistingBrandSales.find(
        segment => segment[rowId] === gridRow[rowId]
      );

      if (currentMatchingGridRow) {
        updateGridRowNodeWithSegment(gridRow, currentMatchingGridRow);
      }
    });
  };

  const updateAgGridWithNewOnPremiseSales = (onPremiseSalesEntities: Entity[]) => {
    const entitiesLookup = getEntitiesLookup();
    onPremiseSalesEntities.forEach(newRecord => {
      const currentMatchingGridRow = gridData.find(
        segment => segment.onPremiseGUID === newRecord['onPremiseGUID']
      );

      const newOnPremiseSales = denormalizeOnPremisesSalesDataUtil(
        entitiesLookup,
        newRecord
      ) as DenormalizedOnPremiseSales;

      // control for skipping premise sale data that does not belong to this market.
      if (newOnPremiseSales.countryId !== market.marketId) {
        return;
      }

      if (currentMatchingGridRow) {
        updateGridRowNodeWithSegment(newOnPremiseSales, currentMatchingGridRow);
      } else {
        dataGridApiRef.current?.applyTransaction({
          add: [{ ...newOnPremiseSales }],
        });

        onPremiseSales.push({ ...newOnPremiseSales });
      }
    });
    gridData = calculateOnPremiseGridData(onPremiseSales, brandSales.current);
  };

  useEffect(() => {
    return onEntitiesReceived(data => {
      const typedData = data as Partial<Record<EntityType, Entity[]>>;

      if (typedData.brandLines) {
        updateAgGridWithBrandLinesTableData(typedData.brandLines as unknown as BrandLinesEntity[]);
      }

      if (typedData.brandSales?.length) {
        updateAgGridWithSalesTableData(typedData.brandSales as unknown as BrandSalesEntity[]);
      }

      if (typedData.onPremiseSales?.length) {
        updateAgGridWithNewOnPremiseSales(typedData.onPremiseSales);
      }
    });
  });

  useEffect(() => {
    if (!viewSlug) {
      if (!views[0]) throw new Error('No views can be found.');

      navigate(views[0].slug, { replace: true });
    }
  }, [navigate, views, viewSlug]);

  useTitle(`${market.marketName}: ${table.name} - Collector`);

  const handleCellValueChange = useCallback(
    ({
      column,
      data,
      node,
      newValue,
      oldValue,
    }: unsafe_NewValueParams<DenormalizedOnPremiseSales>) => {
      const areDifferentValues = !isDeepEqual(oldValue, newValue);

      if (areDifferentValues && node?.data) {
        const entityGuid = String(data[table.primaryField]);
        const payload = setProperty({}, column.getColId(), newValue);

        const calculatedOnPremisData = calculateOnPremise(node.data, brandSales.current);

        node.setData(calculatedOnPremisData);

        void updateEntity(table.source, entityGuid, payload, UPDATE_DIRECTLY_ON_AG_GRID);
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.name, viewSlug]
  );

  const getRowId = useCallback(() => table.primaryField, [table.primaryField]);

  const getRowClass = useCallback((params: unsafe_RowClassParams) => {
    if (params.node.group) {
      return `row-group-${params.node.level}`;
    }
    return '';
  }, []);

  const handleGridReady = useCallback(
    (params: unsafe_GridReadyEvent<DenormalizedOnPremiseSales>) => {
      dataGridApiRef.current = params.api;
    },
    []
  );

  if (!viewSlug) return <p>Loading.</p>;

  if (!view) return <p>View not found.</p>;

  return (
    <>
      <Navbar bg="light" expand="sm" id="viewBar" className="py-0">
        <Nav>
          <ColumnVisibilityMenu
            columnOrder={state.columnOrder}
            pinnedColumns={{}}
            allTableFields={table.fields}
            columnVisibility={ON_PREMISE_COLUMN_VISIBILITY}
            onColumnOrderChange={() => ({})}
            onColumnVisibilityChange={() => ({})}
            onColumnPinnedChange={() => ({})}
            numbersOfGroupedColumnsToShow={state.numbersOfGroupedColumnsToShow}
            onNumbersOfGroupedColumnsToShowChange={handleNumbersOfGroupedColumnsToShowChange}
            enableNumbersOfGroupedColumnsToShowFeature
            disableChangingVisibility
            disableChangingOrder
          />
          <GroupingMenu
            columns={table.fields}
            disabled
            rowGrouping={view.rowGrouping}
            onRowGroupingChange={() => ({})}
          />
        </Nav>
      </Navbar>

      <Container id="viewContainer">
        {views.length > 1 && (
          <StyledSidebar sidebarOpen data-testid="sidebar">
            <SidebarHeader sidebarOpen>
              <SidebarTitle>Options</SidebarTitle>
            </SidebarHeader>
            <SidebarBody sidebarOpen>
              {sections.length > 0 && (
                <ViewSectionSwitcher currentSection={section} currentView={view} />
              )}
              <ViewSwitcher currentSection={section} currentView={view} />
            </SidebarBody>
          </StyledSidebar>
        )}
        <Content>
          <DataGrid<DenormalizedOnPremiseSales>
            debug
            key={key}
            aggregation={{
              ...view.aggregation,
              onPremisePercent: 'getOnPremiseWeightedAverage',
              onPremiseVolume: 'getOnPremiseVolumeTotals',
            }}
            aggregationFunctions={aggregationFunctions}
            canChangeColumnOrder={false}
            canChangeColumnVisibility={false}
            canChangeRowGrouping={false}
            columnGrouping={columnGrouping}
            columns={columns}
            columnVisibility={ON_PREMISE_COLUMN_VISIBILITY}
            numbersOfGroupedColumnsToShow={state.numbersOfGroupedColumnsToShow}
            columnWidths={state.columnWidths}
            filter={view.filter ?? []}
            filterIsViewDefined
            getRowId={getRowId}
            groupingColumn={groupingColumn}
            onCellValueChange={handleCellValueChange}
            onColumnWidthChange={handleColumnWidthsChange}
            getRowClass={getRowClass}
            rows={gridData}
            rowGrouping={view.rowGrouping}
            sort={view.sort}
            onGridReady={handleGridReady}
            sendToClipboard={sendToClipboard}
            processDataFromClipboard={pasteFromClipboard}
            enterNavigatesVerticallyAfterEdit
            enterNavigatesVertically
            formulaFunctions={formulaFunctions}
          />
        </Content>
      </Container>
    </>
  );
};
