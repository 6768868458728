import { createSubcategoriesTree } from 'src/components/BrandSales/AddBrandLine/utils/stepOne';
import type { unsafe_GetContextMenuItemsParams, unsafe_GridApi } from 'src/components/DataGrid';
import type { Entity } from 'src/types/Entity';
import type { CategoriesEntity } from 'src/types/entity/Categories';
import {
  ContextMenuActionType,
  createContextMenu,
  type GridContextMenuItem,
} from 'src/views/utils/contextmenu';
import { type PopupConfiguration, PopupType } from '../common/types';
import {
  getCategoriesNotInGrid,
  getGroupedCategories,
  type GroupedOption,
} from '../utils/ecommerce';

const getContextMenuItems = (
  params: unsafe_GetContextMenuItemsParams<Entity>,
  canWriteRecords: boolean | undefined,
  canRemoveRecords: boolean | undefined,
  categories: CategoriesEntity[],
  setEcommerceCategoriesOptions: (args: GroupedOption[]) => void,
  setPopupConfiguration: (args: PopupConfiguration | undefined) => void,
  dataGridApiRef: React.MutableRefObject<unsafe_GridApi | undefined>
) => {
  const dataInAgGrid: Entity[] = [];

  if (!params.node?.data) {
    return [];
  }

  dataGridApiRef.current?.forEachNode((node: { data?: Entity }) => {
    if (node.data) {
      dataInAgGrid.push(node.data);
    }
  });

  const { options = [] } = createSubcategoriesTree(categories);
  const notExistingCategories = getCategoriesNotInGrid(options, dataInAgGrid);
  const groupedCategories = getGroupedCategories(notExistingCategories);

  let gridContextMenuItems: GridContextMenuItem[] = [];

  if (canWriteRecords) {
    gridContextMenuItems = [
      {
        name: 'Add category 2',
        action: () => {
          if (params.node?.data && dataGridApiRef.current) {
            setEcommerceCategoriesOptions(groupedCategories);

            setPopupConfiguration({
              popupType: PopupType.AddNewItem,
              selectedItem: params.node.data,
            });
          }
        },
        actionType: ContextMenuActionType.ADD,
        disabled: notExistingCategories.length === 0,
        tooltip: 'Add category 2',
        isMainMenuItem: true,
      },
    ];
  }

  if (canRemoveRecords) {
    gridContextMenuItems.push({
      name: 'Remove category 2',
      action: () => {
        if (params.node?.data) {
          let canBeDeleted = true;
          const retailValues = params.node.data['retailValue'] as Record<
            string,
            undefined | number
          >;

          for (const [, value] of Object.entries(retailValues)) {
            if (value) {
              canBeDeleted = false;
              break;
            }
          }

          if (canBeDeleted) {
            setPopupConfiguration({
              popupType: PopupType.DeleteConfirmation,
              selectedItem: params.node.data,
            });
          } else {
            setPopupConfiguration({
              popupType: PopupType.DeleteWarning,
              selectedItem: params.node.data,
            });
          }
        }
      },
      actionType: ContextMenuActionType.DELETE,
      tooltip: 'Remove category 2',
    });
  }

  return createContextMenu(canRemoveRecords, params, gridContextMenuItems);
};

export default getContextMenuItems;
