import { useCallback, useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

import type { RowGroupingModel } from 'src/components/DataGrid';
import type { View } from 'src/views/types';

const VERSION = 1;

export interface GridViewLevelState {
  /** The row grouping model of the grid. */
  rowGrouping?: RowGroupingModel;

  /** The 'schema version' of this bit of state. Useful in migrating previous persisted data to new structures. */
  _version: number;
}

export const useGridViewLevelState = (view: View | undefined) => {
  const [viewLevelState, setViewLevelState] = useLocalStorageState<GridViewLevelState>(
    `GridViewLevelState.${view ? view.id : 'noview'}`,
    {
      defaultValue: {
        _version: VERSION,
      },
    }
  );

  useEffect(() => {
    if (viewLevelState._version < VERSION) {
      // do any necessary migration here
      setViewLevelState(prevState => ({
        ...prevState,
        _version: VERSION,
      }));
    }
  }, [viewLevelState, setViewLevelState]);

  useEffect(() => {
    localStorage.removeItem(`GridViewLevelState.noview`);
  }, [view]);

  const handleRowGroupingChange = useCallback(
    (model: RowGroupingModel) =>
      setViewLevelState(prevState => ({ ...prevState, rowGrouping: model })),
    [setViewLevelState]
  );

  return {
    handleRowGroupingChange,
    viewLevelState,
  };
};
