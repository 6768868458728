import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import type { Action, ActionSection } from 'kbar';
import { useRegisterActions } from 'kbar';

import type { Market } from 'src/markets/types';
import { randomId } from 'src/utils/string';
import { getAssignedMarkets, getUnassignedMarkets } from '../utils';
import { useMarkets } from './useMarkets';

const openMarketId = randomId();

export const useMarketActions = () => {
  const navigate = useNavigate();
  const markets = useMarkets();

  const getMarketActions = useCallback(
    ({
      markets,
      shortcutPrefix,
      parent,
      section,
    }: {
      markets: Market[];
      shortcutPrefix?: string;
      parent: string;
      section: ActionSection;
    }) => {
      const actions: Action[] = [];

      markets.forEach((market, index) => {
        actions.push({
          id: randomId(),
          parent,
          name: market.marketName,
          section,
          ...(shortcutPrefix && { shortcut: [shortcutPrefix, `${index + 1}`] }),
          icon: 'faArrowRight',
          perform: () => {
            navigate(generatePath('/:marketSlug', { marketSlug: market.marketSlug }));
          },
        });
      });
      return actions;
    },
    [navigate]
  );

  const assignedMarketActions = useMemo(
    () =>
      markets.length
        ? getMarketActions({
            markets: getAssignedMarkets(markets),
            shortcutPrefix: 'a',
            parent: openMarketId,
            section: 'Assigned markets',
          })
        : [],
    [markets, getMarketActions]
  );

  const otherMarketActions = useMemo(
    () =>
      markets.length
        ? getMarketActions({
            markets: getUnassignedMarkets(markets),
            parent: openMarketId,
            section: 'Other markets',
          })
        : [],
    [markets, getMarketActions]
  );

  const rootMarketAction = useMemo(
    () =>
      markets.length
        ? {
            id: openMarketId,
            name: 'Open market…',
            shortcut: ['o', 'm'],
            keywords: 'open market',
            section: 'Markets',
            priority: 9,
          }
        : null,
    [markets]
  );

  useRegisterActions(
    [rootMarketAction, ...assignedMarketActions, ...otherMarketActions].filter(Boolean) as Action[],
    [assignedMarketActions, otherMarketActions]
  );
};
