import { type DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type DenormalizedOnPremiseSales } from 'src/records/types/DenormalizedOnPremiseSales';
import { getDenormalizedSaleData } from 'src/records/utils/denormalizers';
import { type EntitiesLookup } from 'src/sync/types';
import { type Entity, type Volume } from 'src/types/Entity';
import { type BrandLinesEntity } from 'src/types/entity/BrandLines';
import { type BrandSalesEntity } from 'src/types/entity/BrandSales';
import { type PriceBand } from 'src/types/entity/PriceBands';
import { type Brand } from 'src/types/MappedTypes';

const getAggregatedHistoricalVolumesForSegment = (
  segment: DenormalizedOnPremiseSales,
  brandSales: DenormalizedBrandSales[]
): Volume => {
  const salesForSegment = getBrandSalesForMatchingBrandSale(brandSales, segment);

  return salesForSegment.reduce<Volume>((acc, sale) => {
    const updatedAcc = { ...acc };
    Object.keys(sale.volume).forEach(key => {
      const year = parseInt(key);
      const volumeValue = sale.volume[`${year}`];

      if (volumeValue != null && !isNaN(volumeValue)) {
        if (updatedAcc[`${year}`] != null) {
          updatedAcc[`${year}`] += volumeValue;
        } else {
          updatedAcc[`${year}`] = volumeValue;
        }
      }
    });
    return updatedAcc;
  }, {});
};

const getBrandSalesForMatchingBrandSale = (
  brandSales: DenormalizedBrandSales[],
  segment: DenormalizedOnPremiseSales
) => {
  return brandSales.filter(brandSale => {
    const isCategory5IdMatch = segment.category5Id === brandSale.category5Id;
    const isPriceBandIdMatch = segment.priceBandId === brandSale.priceBandId;
    const forecastKind = segment.forecastByOrigin ? 'originId' : 'isLocal';
    const isOriginOrLocal = segment[forecastKind] === brandSale[forecastKind];

    return isCategory5IdMatch && isPriceBandIdMatch && isOriginOrLocal;
  });
};

export const calculateOnPremise = (
  onPremiseSale: DenormalizedOnPremiseSales,
  brandSales: DenormalizedBrandSales[]
) => {
  const aggregatedHistoricalVolumes = getAggregatedHistoricalVolumesForSegment(
    onPremiseSale,
    brandSales
  );

  const { onPremisePercent } = onPremiseSale;
  const onPremiseVolume: Record<number, number> = {};

  for (const [key, percent] of Object.entries(onPremisePercent ?? {})) {
    const useYear = Number(key);
    const { [useYear]: aggVolume = 0 } = aggregatedHistoricalVolumes;
    onPremiseVolume[useYear] = percent && aggVolume ? aggVolume * percent : 0;
  }

  return {
    ...onPremiseSale,
    onPremiseVolume: { ...onPremiseVolume },
    allChannelsVolume: { ...aggregatedHistoricalVolumes },
  };
};

export const calculateOnPremiseGridData = (
  onPremiseSales: DenormalizedOnPremiseSales[],
  brandSales: DenormalizedBrandSales[]
) => {
  return onPremiseSales.map(onPremiseSale => calculateOnPremise(onPremiseSale, brandSales));
};

const getSegmentsForBrandSale = (
  entity: Brand<BrandSalesEntity, 'brandSale'> | Brand<BrandLinesEntity, 'brandLine'>,
  onPremiseSales: DenormalizedOnPremiseSales[],
  brandSales: DenormalizedBrandSales[]
): DenormalizedOnPremiseSales[] | null => {
  const updatedBrandSaleForEntity = brandSales.find(brandSale => {
    if (entity.__type === 'brandSale') {
      return brandSale.saleGUID === entity.saleGUID;
    }

    return brandSale.brandLineGUID === entity.brandLineGUID;
  });

  if (updatedBrandSaleForEntity == null) {
    return null;
  }

  return onPremiseSales.filter(segment => {
    const isCategory5IdMatch = segment.category5Id === updatedBrandSaleForEntity.category5Id;
    const isPriceBandIdMatch = segment.priceBandId === updatedBrandSaleForEntity.priceBandId;
    const forecastKind = segment.forecastByOrigin ? 'originId' : 'isLocal';
    const isOriginOrLocal = segment[forecastKind] === updatedBrandSaleForEntity[forecastKind];

    return isCategory5IdMatch && isPriceBandIdMatch && isOriginOrLocal;
  });
};

export const getSegmentsForUpdatedSalesEntities = (
  onPremiseSales: DenormalizedOnPremiseSales[],
  brandSales: DenormalizedBrandSales[],
  sales: BrandSalesEntity[]
): DenormalizedOnPremiseSales[] => {
  return sales.reduce<DenormalizedOnPremiseSales[]>((listOfUpdatedSegments, saleEntity) => {
    const segments = getSegmentsForBrandSale(
      { ...saleEntity, __type: 'brandSale' },
      onPremiseSales,
      brandSales
    );

    if (segments) {
      return [...listOfUpdatedSegments, ...segments];
    }

    return listOfUpdatedSegments;
  }, []);
};

export const getSegmentsForBrandLinesEntities = (
  onPremiseSales: DenormalizedOnPremiseSales[],
  brandSales: DenormalizedBrandSales[],
  brandLines: BrandLinesEntity[]
): DenormalizedOnPremiseSales[] => {
  return brandLines.reduce<DenormalizedOnPremiseSales[]>((listOfUpdatedSegments, brandLine) => {
    const segments = getSegmentsForBrandSale(
      { ...brandLine, __type: 'brandLine' },
      onPremiseSales,
      brandSales
    );

    if (segments) {
      return [...listOfUpdatedSegments, ...segments];
    }

    return listOfUpdatedSegments;
  }, []);
};

type PriceBandIdNameMap = Record<number, PriceBand>;

interface GetBrandSaleWithBrandSaleEntityUpdateParams {
  brandSale: DenormalizedBrandSales;
  brandSalesEntity: BrandSalesEntity;
  priceBandIdNameMap: PriceBandIdNameMap;
}

const getBrandSaleWithBrandSaleEntityUpdate = ({
  brandSale,
  brandSalesEntity,
  priceBandIdNameMap,
}: GetBrandSaleWithBrandSaleEntityUpdateParams): DenormalizedBrandSales => {
  const isPriceBandChange = brandSale.priceBandId !== brandSalesEntity.priceBandId;
  const priceBandName = isPriceBandChange
    ? priceBandIdNameMap[brandSalesEntity.priceBandId]!
    : brandSale.priceBandName;

  return { ...brandSale, ...brandSalesEntity, priceBandName };
};

interface GetUpdatedBrandSalesParams {
  brandSales: DenormalizedBrandSales[];
  brandSalesEntities: BrandSalesEntity[];
  entitiesLookup: EntitiesLookup;
}

export const getUpdatedBrandSales = ({
  brandSalesEntities,
  brandSales,
  entitiesLookup,
}: GetUpdatedBrandSalesParams) => {
  const priceBandIdNameMap = brandSales.reduce<PriceBandIdNameMap>((map, brandSale) => {
    return { ...map, [brandSale.priceBandId]: brandSale.priceBandName };
  }, {});

  const updatedBrandSales = brandSales.reduce<DenormalizedBrandSales[]>(
    (updatedSales, brandSale) => {
      const brandSalesEntity = brandSalesEntities.find(
        bSale => bSale.saleGUID === brandSale.saleGUID
      );

      if (brandSalesEntity?.isDeleted) {
        return updatedSales;
      }

      if (brandSalesEntity) {
        const updatedBrandSale = getBrandSaleWithBrandSaleEntityUpdate({
          brandSale,
          brandSalesEntity,
          priceBandIdNameMap,
        });

        return updatedSales.concat(updatedBrandSale);
      }

      return updatedSales.concat(brandSale);
    },
    []
  );

  // TODO: Consider optimising to reduce iterations - brandSales is huge.
  const newEntities = brandSalesEntities.filter(entity =>
    brandSales.every(bs => bs.saleGUID !== entity.saleGUID)
  );

  if (newEntities.length) {
    const denormalizedEntities = newEntities.map(entity =>
      getDenormalizedSaleData(entity as unknown as Entity, entitiesLookup)
    ) as DenormalizedBrandSales[];

    return updatedBrandSales.concat(denormalizedEntities);
  }

  return updatedBrandSales;
};

const getChangedKeyValuePair = (
  brandSales: DenormalizedBrandSales[],
  brandLine: BrandLinesEntity,
  brandSale: DenormalizedBrandSales
) => {
  const isOriginChange = isBrandLineOriginChanged(brandSale, brandLine);
  const changedProperty: keyof BrandLinesEntity = isOriginChange ? 'originId' : 'category5Id';

  const key: keyof DenormalizedBrandSales = isOriginChange ? 'originName' : 'category5Id';
  const value = brandSales.find(bs => bs[changedProperty] === brandLine[changedProperty])?.[key];

  return { key, value };
};

export const getUpdatedBrandSalesFromBrandLines = (
  brandSales: DenormalizedBrandSales[],
  brandLines: BrandLinesEntity[]
): DenormalizedBrandSales[] => {
  return brandSales.map(brandSale => {
    const brandLineToUpdateBrandSaleWith = brandLines.find(brandLine => {
      const hasChangedCategory5 = isBrandLineCategory5Changed(brandSale, brandLine);
      const hasChangedOrigin = isBrandLineOriginChanged(brandSale, brandLine);

      return hasChangedCategory5 || hasChangedOrigin;
    });

    if (brandLineToUpdateBrandSaleWith) {
      const { key, value } = getChangedKeyValuePair(
        brandSales,
        brandLineToUpdateBrandSaleWith,
        brandSale
      );
      const updatedBrandSale = { ...brandSale, ...brandLineToUpdateBrandSaleWith, [key]: value };

      return updatedBrandSale;
    }
    return brandSale;
  });
};

export const isBrandLineCategory5Changed = (
  brandSale: DenormalizedBrandSales,
  brandLine: BrandLinesEntity
) => {
  const hasIdenticalBrandLineGuid = brandSale.brandLineGUID === brandLine.brandLineGUID;
  const hasChangedCategory5Id = brandSale.category5Id !== brandLine.category5Id;

  return hasIdenticalBrandLineGuid && hasChangedCategory5Id;
};

export const isBrandLineOriginChanged = (
  brandSale: DenormalizedBrandSales,
  brandLine: BrandLinesEntity
) => {
  const hasIdenticalBrandLineGuid = brandSale.brandLineGUID === brandLine.brandLineGUID;
  const hasChangedOriginId = brandSale.originId !== brandLine.originId;

  return hasIdenticalBrandLineGuid && hasChangedOriginId;
};
