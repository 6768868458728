import * as XLSX from '@sheet/core';
import { saveAs } from 'file-saver';
import { groupBy } from 'ramda';

export const exportToCSV = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonData: any,
  fileName: string,
  reportTypeLabel: string,
  reportTypeValue: string,
  forceDownload: boolean,
  currentCountryName: string,
  currentYear: number,
  numberOfDecimalPlaces: number
) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const numberFormat = `0.${new Array(numberOfDecimalPlaces).fill('0').join('')}`;

  const workSheet: XLSX.WorkSheet = {};
  const colInfoArr: XLSX.ColInfo[] = [];
  const rolInfoArr: XLSX.ColInfo[] = [];
  const mergeRangeArr: XLSX.Range[] = [];

  // Excel file has 11 columns, column width are defined below.
  for (let i = 0; i <= 11; i += 1) {
    const colInfo: XLSX.ColInfo = {};
    // if (i < 4) { colInfo.width = 18; } else { colInfo.width = 15; }

    switch (i) {
      case 0:
        colInfo.width = 20;
        break;
      case 1:
        colInfo.width = 15;
        break;
      case 2:
        colInfo.width = 20;
        break;
      case 3:
        colInfo.width = 11;
        break;
      case 8:
        colInfo.width = 8;
        break;
      case 9:
        colInfo.width = 12;
        break;
      default:
        colInfo.width = 7;
    }
    colInfoArr.push(colInfo);
  }
  workSheet['!cols'] = colInfoArr;

  // Height of first 9 rows are defined below
  for (let i = 0; i <= 9; i += 1) {
    const rolInfo: XLSX.RowInfo = {};
    rolInfo.hpt = 13;
    rolInfoArr.push(rolInfo);
  }
  workSheet['!rows'] = rolInfoArr;

  // Merging 6 columns of rows 2,3
  for (let i = 2; i <= 3; i += 1) {
    const range: XLSX.Range = {
      s: {
        c: 0,
        r: i,
      },
      e: {
        c: 6,
        r: i,
      },
    };
    mergeRangeArr.push(range);
  }
  workSheet['!merges'] = mergeRangeArr;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const byCategory2Name = groupBy((tempJsonData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { category2Name } = tempJsonData;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return category2Name;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  }, jsonData);

  /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access */
  workSheet['!ref'] = `A1:K${20 + jsonData.length + Object.keys(byCategory2Name).length}`;

  const generalStyling = {
    color: { rgb: '000000' },
    name: 'Open Sans',
    sz: 8,
  };

  // Putting fixed labels and their dynamic values.
  // @ts-expect-error: legacy code
  workSheet.A1 = {
    t: 's',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    v: `Consumption Data for: ${currentCountryName}`,
    s: {
      ...generalStyling,
      bold: true,
      italic: true,
    },
  };

  // @ts-expect-error: legacy code
  workSheet.A2 = {
    t: 's',
    v: `Date Created: ${new Date().toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })}`,
    s: {
      ...generalStyling,
      italic: true,
    },
  };

  // @ts-expect-error: legacy code
  workSheet.A3 = {
    t: 's',
    v: 'Volume figures shown in 000s HL for Beer and Cider and 000s 9-litre cases for Wines, Spirits and RTDs',
    s: {
      ...generalStyling,
      italic: true,
    },
  };
  // @ts-expect-error: legacy code
  workSheet.A4 = {
    t: 's',
    v: 'Prices shown per bottle in the local currency. Bottle sizes are shown in cl.',
    s: {
      ...generalStyling,
      italic: true,
    },
  };
  // @ts-expect-error: legacy code
  workSheet.A5 = {
    t: 's',
    v: 'Source: The IWSR',
    s: {
      ...generalStyling,
      italic: true,
    },
  };

  // @ts-expect-error: legacy code
  workSheet.A7 = {
    t: 's',
    v: 'Country:',
    s: {
      ...generalStyling,
      italic: true,
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    },
  };

  // @ts-expect-error: legacy code
  workSheet.B7 = {
    t: 's',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    v: `${currentCountryName}`,
    s: {
      ...generalStyling,
      italic: true,
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
    },
  };

  // @ts-expect-error: legacy code
  workSheet.C7 = {
    t: 's',
    v: '',
    s: {
      left: { style: 'thin' },
    },
  };

  // @ts-expect-error: legacy code
  workSheet.A8 = {
    t: 's',
    v: `${reportTypeLabel}`,
    s: {
      ...generalStyling,
      italic: true,
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
    },
  };

  // @ts-expect-error: legacy code
  workSheet.B8 = {
    t: 's',
    v: `${reportTypeValue}`,
    s: {
      ...generalStyling,
      italic: true,
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
    },
  };

  // @ts-expect-error: legacy code
  workSheet.C8 = {
    t: 's',
    v: '',
    s: {
      left: { style: 'thin' },
    },
  };

  // @ts-expect-error: legacy code
  workSheet.A10 = {
    t: 's',
    v: 'Category',
    s: {
      ...generalStyling,
      fgColor: { rgb: 0x000000 },
      color: { rgb: 0xffffff },
      bold: true,
    },
  };
  // @ts-expect-error: legacy code
  workSheet.B10 = {
    t: 's',
    v: 'Detail',
    s: {
      ...generalStyling,
      fgColor: { rgb: 0x000000 },
      color: { rgb: 0xffffff },
      bold: true,
    },
  };
  // @ts-expect-error: legacy code
  workSheet.C10 = {
    t: 's',
    v: 'Brand Line',
    s: {
      ...generalStyling,
      fgColor: { rgb: 0x000000 },
      color: { rgb: 0xffffff },
      bold: true,
    },
  };
  // @ts-expect-error: legacy code
  workSheet.D10 = {
    t: 's',
    v: 'Price Band',
    s: {
      ...generalStyling,
      fgColor: { rgb: 0x000000 },
      color: { rgb: 0xffffff },
      bold: true,
    },
  };

  // Creating dynamic headers with using currentYear,
  // Like vol 2016, vol 2017...
  for (let y: number = currentYear - 3; y <= currentYear; y += 1) {
    workSheet[`${String.fromCharCode(72 - (currentYear - y))}10`] = {
      t: 's',
      v: `vol ${y}`,
      s: {
        ...generalStyling,
        fgColor: { rgb: 0x000000 },
        color: { rgb: 0xffffff },
        bold: true,
      },
    };
  }

  // @ts-expect-error: legacy code
  workSheet.I10 = {
    t: 's',
    v: `Price ${currentYear}`,
    s: {
      ...generalStyling,
      fgColor: { rgb: 0x000000 },
      color: { rgb: 0xffffff },
      bold: true,
    },
  };
  // @ts-expect-error: legacy code
  workSheet.J10 = {
    t: 's',
    v: `Bottle Size ${currentYear}`,
    s: {
      ...generalStyling,
      fgColor: { rgb: 0x000000 },
      color: { rgb: 0xffffff },
      bold: true,
    },
  };

  // Creating the table's content dynamicly
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  let i: number = 11;
  let categoryName = '';

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  jsonData.forEach(
    (sale: {
      category2Name: string;
      brandLineName: string;
      priceBandName: string;
      volume: Record<string, number>;
      price: Record<string, number | { isEstimated: boolean; value: number }>;
      containerSize: Record<string, number>;
      detail: string;
    }) => {
      if (categoryName !== sale.category2Name) {
        i += 1;
        categoryName = sale.category2Name;
        workSheet[`A${i}`] = {
          t: 's',
          v: categoryName,
          s: {
            ...generalStyling,
            fgColor: { rgb: 0x000000 },
            color: { rgb: 0xffffff },
            bold: true,
          },
        };
      }

      workSheet[`B${i}`] = {
        t: 's',
        v: sale.detail,
        s: {
          ...generalStyling,
          bold: true,
        },
      };
      workSheet[`C${i}`] = {
        t: 's',
        v: sale.brandLineName,
        s: {
          ...generalStyling,
        },
      };
      workSheet[`D${i}`] = {
        t: 's',
        v: sale.priceBandName,
        s: {
          ...generalStyling,
        },
      };

      const getYearVolume = (year: number) => {
        const volume: number | undefined = sale.volume[`${year}`];

        if (volume === undefined) {
          return undefined;
        }

        // eslint-disable-next-line no-restricted-properties
        if (volume === 10 ** -10) {
          return volume;
        }

        return volume.toFixed(numberOfDecimalPlaces);
      };

      const getCurrentYearSale = () => {
        const currentYearSale: number | { isEstimated: boolean; value: number } | undefined =
          sale.price[`${currentYear}`];

        if (currentYearSale === undefined) {
          return undefined;
        }

        // @TODO Should we add ~ if estimated?
        const value = typeof currentYearSale === 'object' ? currentYearSale.value : currentYearSale;

        // eslint-disable-next-line no-restricted-properties
        if (value === 10 ** -10) {
          return value;
        }

        return value.toFixed(numberOfDecimalPlaces);
      };

      for (let y: number = currentYear - 3; y <= currentYear; y += 1) {
        const volumeOfYear = getYearVolume(y);

        if (volumeOfYear) {
          workSheet[`${String.fromCharCode(72 - (currentYear - y))}${i}`] = {
            t: 'n',
            v: `${volumeOfYear}`,
            s: {
              ...generalStyling,
            },
            z: numberFormat,
          };
        }
      }

      const currentYearSale = getCurrentYearSale();

      if (currentYearSale) {
        workSheet[`I${i}`] = {
          t: 'n',
          v: `${currentYearSale}`,
          s: {
            ...generalStyling,
          },
          z: numberFormat,
        };
      }

      workSheet[`J${i}`] = {
        t: 'n',
        v: `${(sale.containerSize[`${currentYear}`] ?? 0).toFixed(2)}`,
        s: {
          ...generalStyling,
        },
        z: numberFormat,
      };

      i += 1;
    }
  );

  const wb: XLSX.WorkBook = {
    Sheets: { data: workSheet },
    SheetNames: ['data'],
  };

  const writingOptions: XLSX.WritingOptions = {};

  writingOptions.bookType = 'xlsx';
  writingOptions.type = 'array';
  writingOptions.cellStyles = true;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const excelBuffer = XLSX.write(wb, writingOptions);
  const blobData = new Blob([excelBuffer], { type: fileType });

  // force downloads for individual files
  if (forceDownload) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return saveAs(blobData, fileName);
  }

  // return data for zip file
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    fileName,
    blobData,
  };
};
