import type { FC } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import type { ActionImpl } from 'kbar';

import * as styles from '../CommandBar.styles';

// faArrowRight is the only icon currently catered for. Note that the icon prop on the action
// can actually pass through a React component. However, the only actions requiring icons
// emanate from the useMarketActions hook (in which you shouldn't include a React component).

interface Props {
  action: ActionImpl;
}

export const ActionIcon: FC<Props> = ({ action }) => {
  if (typeof action.icon === 'string') {
    if (action.icon !== 'faArrowRight') return null;

    return (
      <Box sx={styles.IconContainer}>
        <FontAwesomeIcon icon={faArrowRight} size="sm" opacity={0.5} />
      </Box>
    );
  }

  return <Box sx={styles.IconContainer}>{action.icon}</Box>;
};
