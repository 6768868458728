import { createSelector } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { type BrandLine } from 'src/shared/types';
import type { RootState } from 'src/store';

const selectBrandLineAdd = (state: RootState) => state.brandLineAdd.step1?.selected ?? [];

export const selectDuplicateBrandLineGUIDS = (brandLines: BrandLine[]) => {
  return brandLines.reduce<string[]>((duplicates, brandLine) => {
    if (duplicates.includes(brandLine.brandLineGUID)) {
      return duplicates;
    }

    const count = brandLines.filter(bl => bl.brandLineGUID === brandLine.brandLineGUID).length;

    if (count > 1) {
      return [...duplicates, brandLine.brandLineGUID];
    }

    return duplicates;
  }, []);
};

export interface BrandLineWithUniqueId extends BrandLine {
  uniqueId?: string;
}

export const selectSelectedBrandLinesWithUniqueIds = createSelector(
  selectBrandLineAdd,
  (brandLines): BrandLineWithUniqueId[] => {
    const duplicateBrandLineGUIDS = selectDuplicateBrandLineGUIDS(brandLines);

    return brandLines.map((brandLine): BrandLineWithUniqueId => {
      const hasDuplicate = duplicateBrandLineGUIDS.includes(brandLine.brandLineGUID);

      if (hasDuplicate) {
        return {
          ...brandLine,
          uniqueId: uuidv4(),
        };
      }

      return brandLine;
    });
  }
);
