import { type ChangeEvent, useState } from 'react';
import { Col, Form, Modal } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Label } from 'src/components/BrandSales/AddBrandLine/styledComponents';
import { Dropdown } from 'src/components/Dropdown';
import type { DropdownOptions } from 'src/components/Dropdown/types';
import { ValidNumberField } from 'src/components/UserEnteries';
import { Button, CircularButton, ModalHeader } from 'src/css/styled-components';
import { NumberEntryEnum } from 'src/shared';

type CalculationType = 'Exponential' | 'Straight-Line';

export interface CalculationConfirmation {
  calculation: CalculationType;
  finalVolume: string;
  growthRate: string;
}

interface Props {
  onCloseModal: () => void;
  onConfirmCalculation: (confirmationValue: CalculationConfirmation) => void;
}

interface CalculationTypeOption {
  value: string;
  label: CalculationType | '';
}

const applyGrowthCalculationCopy = 'Apply growth Calculation';

const calculationOptions: DropdownOptions<CalculationType> = [
  {
    options: [
      { value: '0', label: 'Exponential' },
      { value: '1', label: 'Straight-Line' },
    ],
  },
];

export const ApplyGrowthCalculationModal = ({ onCloseModal, onConfirmCalculation }: Props) => {
  const [finalVolume, setFinalVolume] = useState('');
  const [growthRate, setGrowthRate] = useState('');
  const [calculation, setCalculation] = useState<CalculationTypeOption>({ value: '-1', label: '' });

  const handleSelectSection = (e: CalculationTypeOption) => {
    setCalculation(e);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (calculation.label === 'Exponential') {
      setGrowthRate(e.target.value);
      return;
    }

    if (calculation.label === 'Straight-Line') {
      setFinalVolume(e.target.value);
      return;
    }
  };

  return (
    <Modal show centered size="lg">
      <ModalHeader>
        {applyGrowthCalculationCopy}
        <CircularButton type="button" variant="link" onClick={onCloseModal}>
          <FontAwesomeIcon icon={faTimes} />
        </CircularButton>
      </ModalHeader>

      <Modal.Body>
        <Form.Row>
          <Col md={6}>
            <Form.Group>
              <Label>Calculation</Label>
              <Dropdown
                placeholder="Select section"
                options={calculationOptions}
                onSelect={handleSelectSection}
                selected=""
              />
            </Form.Group>
          </Col>

          {calculation.label === 'Straight-Line' && (
            <Col md={6}>
              <Form.Group>
                <Label>Final volume (optional)</Label>
                <ValidNumberField
                  numberEntryEnum={NumberEntryEnum.Volume}
                  value={finalVolume}
                  onChange={handleChange}
                  dataTestId="growth-calculation-value"
                />
              </Form.Group>
            </Col>
          )}

          {calculation.label === 'Exponential' && (
            <Col md={6}>
              <Form.Group>
                <Label>Growth rate (%)</Label>
                <ValidNumberField
                  numberEntryEnum={NumberEntryEnum.PercentageNegOK}
                  value={growthRate}
                  onChange={handleChange}
                  dataTestId="growth-calculation-value"
                />
              </Form.Group>
            </Col>
          )}
        </Form.Row>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="link" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            onConfirmCalculation({
              calculation: calculation.label as CalculationType,
              finalVolume,
              growthRate,
            });
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
