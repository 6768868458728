import { type FC, useCallback, useMemo } from 'react';
import { Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom';
import { suspend } from 'suspend-react';

import { AccountMenu } from 'src/account';
import { StyledNavbar, StyledNavToggle } from 'src/css/styled-components';
import { MarketNavigation, MarketSwitcher } from 'src/markets/components';
import { useMarketActions, useMarkets, useNavigateActions } from 'src/markets/hooks';
import Logo from 'src/markets/pages/iwsr-gradient-32.png';
import type { Market } from 'src/markets/types';
import { SyncStatus, useSyncContext } from 'src/sync';
import { mdSizeWindow } from 'src/utils/appUtils';
import { getAssignedMarketIds } from '../utils';

const cacheMarketUUID = Symbol();

export const MarketPage: FC = () => {
  const navigate = useNavigate();
  const markets = useMarkets();

  const { cacheMarket } = useSyncContext();
  const { marketSlug } = useParams<'marketSlug'>();

  const market = useMemo(
    () => markets.find(market => market.marketSlug === marketSlug),
    [markets, marketSlug]
  );

  const assignedMarketIds = useMemo(() => getAssignedMarketIds(markets), [markets]);

  const handleChange = useCallback<(market: Market) => void>(
    ({ marketSlug }) => {
      navigate(generatePath('/:marketSlug', { marketSlug }), {
        replace: false,
      });
    },
    [navigate]
  );

  useNavigateActions();
  useMarketActions();

  if (!market) return <p>Market not found</p>;

  suspend(async () => await cacheMarket(market.marketId), [cacheMarketUUID, market.marketId]);

  return (
    <>
      <StyledNavbar className="topBar" variant="dark" expand="sm" id="navMain" navopen="true">
        <NavbarBrand>
          <img src={Logo} alt="iwsr-collector-logo" />
          &nbsp;
          <strong hidden={mdSizeWindow}>IWSR Collector</strong>
        </NavbarBrand>
        <StyledNavToggle main={1} aria-controls="iwsr-navbar" />
        <Navbar.Collapse id="iwsr-navbar">
          <MarketSwitcher
            current={market}
            markets={markets}
            assignedMarketIds={assignedMarketIds}
            onChange={handleChange}
          />
          <MarketNavigation />
          <Nav>
            <SyncStatus />
            <AccountMenu />
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
      <Outlet context={{ market }} />
    </>
  );
};
