import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface User {
  email: string;
  id: string;
  name: string;
}

interface AuthState {
  user: null | User;
}

const initialState: AuthState = {
  user: null,
};

export const {
  actions: authActions,
  reducer: authReducer,
  name: authSliceName,
} = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<User | null>) {
      state.user = payload;
    },
    close() {
      return initialState;
    },
  },
});
