import type { AdditionalWineList, CountryList, PriceBandList, Sale, SaleList } from '../shared';
import type { FlatCategoryItem } from '../views/types';

export enum ValidationStatus {
  beforeValidation = 'Before Validation',
  success = 'Success',
  fail = 'Fail',
  validating = 'Validating',
}

export enum ValidationEnum {
  checklist = 'checklist',
  addingUp = 'addingUp',
  prices = 'prices',
  onOffPremise = 'onOffPremise',
  priceBands = 'priceBands',
  priceBandsUnsegment = 'priceBandsUnsegment',
  localOrImported = 'localOrImported',
  oneYearForecasts = 'oneYearForecasts',
  valueForecasts = 'valueForecasts',
  unapprovedBrands = 'unapprovedBrands',
  missingForecast = 'missingForecast',
  missingForecastVolumes = 'missingForecastVolumes',
  all = 'all',
}

// validation reducer

export interface ValidationItemState {
  status?: ValidationStatus;
  timestamp?: number;
  addingUpResult?: AddingUpResult;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface ValidationState {
  [validationEnum: string]: ValidationItemState;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface ValidationChecklist {
  [countryId: string]: ValidationState;
}

export interface ValidationReducer {
  activeValidation: ValidationEnum;
  validationChecklist: ValidationChecklist;
}

// validation input

interface SaleValidation extends Sale {
  approval?: string;
  comment?: string;
}

export interface ValidationFunctionParams {
  sales: SaleValidation[];
  stillWine: AdditionalWineList;
  currentYear: number | undefined;
  segmentYear: number | undefined;
  priceBands: PriceBandList | undefined;
  countries: CountryList | undefined;
  forecasts: SaleList;
  marketType: string;
  brandLineApprovals?: BrandLineApproval[];
  categories: FlatCategoryItem[];
  validatedSales: Sale[];
  isLocked: boolean;
}

/*
    Let Sales be the set of sales for a given country
    where the category, priceband and (localorimported or countryoforigin)
    be the group of sales to aggregate the total sales for the current year
*/
/*
    Let forecasts be the set of forecasts for a given country
    Where the category, priceband and (localorimported or countryoforigin)
    be the group of forecasts where the aggregate of forecast volumes for each year > current year is undefined AND
    the corresponding sales aggregate for the same group has an aggregated value for the current year that is non zero
*/

/*
    UNION(
    SELECT TotalSales FROM SALES
    INNER JOIN Cat5Table ON SALES.Cat5
    WHERE Cat5Table.ForcastByOrigin = FALSE
    GROUP BY CATEGORY, PRICEBAND, LOCALORIMPORTED

    SELECT TotalSales FROM SALES
    INNER JOIN Cat5Table ON SALES.Cat5
    WHERE Cat5Table.ForcastByOrigin = TRUE
    GROUP BY CATEGORY, PRICEBAND, COUNTRYOFORIGIN
    )
*/

export const initialValidationParams: ValidationFunctionParams = {
  sales: [],
  stillWine: [],
  currentYear: undefined,
  segmentYear: undefined,
  priceBands: undefined,
  countries: undefined,
  forecasts: [],
  marketType: '',
  categories: [],
  validatedSales: [],
  isLocked: true,
};

// validation output

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface AddingUpResult {
  // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
  [section: string]: {
    [originKey: string]: {
      failedYears?: number[];
      failedYearsData?: {
        year: number;
        brandSaleTotal: number;
        amountEntered: number;
      }[];
    };
  };
}

interface ValidationFunctionResult {
  isInvalid: boolean;
  addingUpResult?: AddingUpResult;
}

export interface SaleResult {
  sale: Sale;
  isInvalid: boolean;
}

interface BrandLineApproval {
  brandLineGUID: string;
  status: number;
  comment: string;
}

// validation functions

export type ValidationFunction = (
  params: ValidationFunctionParams
) => (sale?: SaleValidation, forecasts?: Sale[]) => ValidationFunctionResult;
