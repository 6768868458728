import { useOutletContext } from 'react-router-dom';

import type { Market } from '../types';

interface MarketContext {
  market: Market;
}

export function useMarket() {
  return useOutletContext<MarketContext>();
}
