import type { BrandLine, Sale } from '../../../../shared';

const getDuplicatedBrandLineGUIDs = (sectionBrandLines: Sale[], selectedBrandLines: BrandLine[]) =>
  selectedBrandLines.reduce(
    (acc: string[], { brandLineGUID: selectedBrandLineGUID }: BrandLine) => {
      const newAcc: string[] = [...acc];
      const brandLineExists = !!sectionBrandLines.find(
        ({ brandLineGUID }) => brandLineGUID === selectedBrandLineGUID
      );
      if (brandLineExists) {
        newAcc.push(selectedBrandLineGUID);
      }
      return newAcc;
    },
    []
  );

export default getDuplicatedBrandLineGUIDs;
