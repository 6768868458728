import 'styled-components';
import type { LookUp } from '../shared';

declare module 'styled-components' {
  interface DefaultTheme {
    white: string;
    black: string;
    lightGrey: string;
    midGrey: string;
    darkGrey: string;
    midPurple: string;
    darkPurple: string;
    red: string;
    pink: string;
    green: string;
    darkGreen: string;
    blue: string;
    darkBlue: string;
    yellow: string;
    darkYellow: string;
  }
}

const theme: LookUp = {
  white: '#fff',
  black: '#333',
  lightGrey: '#f2f2f2',
  midGrey: '#e5e5e8;',
  darkGrey: 'grey',
  midPurple: '#302e60',
  darkPurple: '#17163c',
  red: '#b32553',
  pink: '#F7C2E6',
  green: '#B5E992',
  darkGreen: '#155724',
  blue: '#84ADFD',
  darkBlue: '#2684FF',
  yellow: '#FFD334',
  darkYellow: '#EFC324',
};

type ColorName =
  | 'white'
  | 'black'
  | 'lightGrey'
  | 'midGrey'
  | 'darkGrey'
  | 'midPurple'
  | 'darkPurple'
  | 'red'
  | 'pink'
  | 'green'
  | 'darkGreen'
  | 'blue'
  | 'darkBlue'
  | 'yellow'
  | 'darkYellow';

// Due to the linting restriction of not being able to non-null assert,
// added a default empty string to each line. Will never happen.
export const colors: Record<ColorName, string> = {
  white: theme['white'] ?? '',
  black: theme['black'] ?? '',
  lightGrey: theme['lightGrey'] ?? '',
  midGrey: theme['midGrey'] ?? '',
  darkGrey: theme['darkGrey'] ?? '',
  midPurple: theme['midPurple'] ?? '',
  darkPurple: theme['darkPurple'] ?? '',
  red: theme['red'] ?? '',
  pink: theme['pink'] ?? '',
  green: theme['green'] ?? '',
  darkGreen: theme['darkGreen'] ?? '',
  blue: theme['blue'] ?? '',
  darkBlue: theme['darkBlue'] ?? '',
  yellow: theme['yellow'] ?? '',
  darkYellow: theme['darkYellow'] ?? '',
};

// ************************************

type BtnProps = Record<string, { color: string; backgroundColor: string }>;

export const btn: BtnProps = {
  primary: {
    color: '#fff',
    backgroundColor: 'rgb(48, 46, 96)',
  },
  secondary: {
    color: '#fff',
    backgroundColor: colors.darkGrey,
  },
  link: {
    color: 'rgb(48, 46, 96)',
    backgroundColor: 'transparent',
  },
};

export const btnHover: BtnProps = {
  primary: {
    color: 'rgb(48, 46, 96)',
    backgroundColor: '#fff',
  },
  secondary: {
    color: '#fff',
    backgroundColor: '#e5e5e8',
  },
  link: {
    color: 'rgb(98, 96, 146)',
    backgroundColor: 'transparent',
  },
};

export const btnDisabled: BtnProps = {
  primary: {
    color: '#ddd',
    backgroundColor: 'transparent',
  },
  secondary: {
    color: '#fff',
    backgroundColor: 'rgb(230, 230, 230);',
  },
  link: {
    color: '#aaa',
    backgroundColor: 'transparent',
  },
};

export const btnBorder: LookUp = {
  primary: '2px solid #302e60',
  secondary: '1px solid transparent',
  link: 'none',
};

export const btnTextTransform: LookUp = {
  primary: 'none',
  secondary: 'none',
  link: 'none',
};

export default theme;
