import { type FormEvent, useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
// @ts-expect-error: missing DefinitelyTyped types
import QRCode from 'qrcode.react';

import { isProductionEnv } from 'src/utils/appUtils';
import { handleSignOut } from 'src/utils/navUtils';
import { CentralContainer } from '../Container';
import { Spinner } from '../Spinner';
import { Base } from './Base';

interface Error {
  message: string;
}

const ErrorMsg = ({ error }: { error: Error }) => error.message;

const QRCodeCard = ({ user }: { user: { username: string } }) => {
  const [qrCode, setQRCode] = useState<string>();
  const [error, setError] = useState<{ message: string } | undefined>();

  const getQRCode = () =>
    Auth.setupTOTP(user)
      .then(data => {
        const tcode =
          'otpauth://totp/TheIWSR:' + user.username + '?secret=' + data + '&issuer=TheIWSR';
        setQRCode(tcode);
      })
      .catch(setError);

  const loading = !qrCode && !error;

  if (loading) {
    getQRCode() as unknown as VoidFunction;
  }

  return (
    <CentralContainer>
      {loading && <Spinner />}
      {error && <ErrorMsg error={error} />}
      {qrCode && <QRCode value={qrCode} />}
    </CentralContainer>
  );
};

interface Props {
  onStateChange: (state: string, data?: unknown) => void;
  authData: { username: string };
}

export const TOTPSetup = ({ onStateChange, authData: user }: Props) => {
  const [token, setToken] = useState('');
  const [error, setError] = useState();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const verifyToken = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    Auth.verifyTotpToken(user, token)
      .then(() => Auth.setPreferredMFA(user, 'TOTP'))
      .then(() => onStateChange('signedIn', user))
      .catch(setError);
  };

  const body = (
    <>
      <p>
        Use the 2FA app on your smartphone (Authy or Google Authenticator) to scan the QR code and
        enter the token it gives you below.
      </p>
      <QRCodeCard user={user} />
      <br />
      <Form.Group as={Form.Row}>
        <Form.Label column sm={2} htmlFor="securitySetup-Token">
          Token
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            id="securitySetup-Token"
            value={token}
            type="text"
            onChange={({ target }) => setToken(target.value)}
            isInvalid={!!error}
            ref={inputRef}
          />
        </Col>
      </Form.Group>
    </>
  );

  const cancelTxt = !isProductionEnv ? 'Not now' : 'Back to sign in';

  const handleCancel = !isProductionEnv ? () => onStateChange('signedIn', user) : handleSignOut;

  return (
    <Base
      title="Setup MFA"
      body={body}
      submitTxt="Verify"
      onSubmit={verifyToken}
      cancelTxt={cancelTxt}
      onCancel={handleCancel}
      error={error}
    />
  );
};
