import styled from 'styled-components';

export const CentralContainer = styled.div`
  width: 100%;
  height: 89vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContainer = styled.main`
  display: flex;
  margin: 0;
  padding: 0;
  overflow: hidden;
  @media print {
    height: auto;
    display: block;
    overflow: visible;
  }
  height: ${({ heightToRemove }: { heightToRemove: number }) =>
    `calc(100vh - ${heightToRemove}px)`};
`;

export const Container = ({
  id,
  children,
  hasOnlyMainNav,
}: React.PropsWithChildren<{
  id: string;
  hasOnlyMainNav?: boolean;
}>) => {
  const MAIN_NAV_HEIGHT = 59;
  const SUB_NAV_HEIGHT = 41;
  const VIEW_BAR_HEIGHT = 40;

  let heightToRemove = MAIN_NAV_HEIGHT + SUB_NAV_HEIGHT + VIEW_BAR_HEIGHT;

  if (hasOnlyMainNav) heightToRemove = MAIN_NAV_HEIGHT;

  return (
    <StyledContainer id={id} heightToRemove={heightToRemove}>
      {children}
    </StyledContainer>
  );
};
