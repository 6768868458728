import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { ModalHeader } from '../../css/styled-components';

const ConfirmationDialog = ({
  title,
  body,
  closeModal,
  confirmationCallback,
}: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  title: String;
  // eslint-disable-next-line @typescript-eslint/ban-types
  body: String;
  // eslint-disable-next-line @typescript-eslint/ban-types
  closeModal: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  confirmationCallback: Function;
}) => {
  const confirm = () => {
    confirmationCallback();
    closeModal();
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    <Modal show centered onHide={() => closeModal()}>
      <ModalHeader>
        <Modal.Title>{title}</Modal.Title>
      </ModalHeader>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
        <Button variant="secondary" onClick={() => closeModal()}>
          Close
        </Button>
        <Button variant="primary" onClick={confirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
  const { title, body, confirmationCallback } = state.modalDialog.properties;

  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    title,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    body,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    confirmationCallback,
  };
};

export default connect(mapStateToProps)(ConfirmationDialog);
