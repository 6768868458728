import { type MutableRefObject, useRef } from 'react';

import type { GridOptions, Row } from '../../types';

interface Logger {
  log: (args: unknown) => void;
}

export const useGridLogger = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  name: string
): Logger => {
  const logger = useRef<Logger | null>(null);

  if (logger.current) {
    return logger.current;
  }

  const newLogger = {
    log: (...args: unknown[]) =>
      // If DataGrid has `debug=true` logs will be visible at 'Info' level.
      // If not in `debug`, logs can only be seen at 'Verbose' level
      gridRef.current.debug
        ? console.log(name, args.join(' '))
        : console.debug(name, args.join(' ')),
  };
  logger.current = newLogger;

  return newLogger;
};
