import type { FlatCategoryItem } from '../types';

interface CreateBrandLineNameParams {
  categories: FlatCategoryItem[];
  category5Id: number;
  displayName: string;
  originName: string;
}

const MAX_LENGTH = 50;

const createBrandLineName = ({
  categories,
  category5Id,
  displayName,
  originName,
}: CreateBrandLineNameParams) => {
  const category5 = categories.find(({ id, level }) => id === category5Id && level === 5);

  if (!category5?.namingPatternBrandLine) return displayName;

  const brandLineName = category5.namingPatternBrandLine
    .replaceAll('{brandLineDisplayName}', displayName)
    .replaceAll('{originName}', originName)
    .trim()
    .substring(0, MAX_LENGTH)
    .trim();

  return brandLineName;
};

export default createBrandLineName;
