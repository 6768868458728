import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'src/store';
import { swapCategory5DialogActions } from 'src/store/features/swapCategory5Dialog/slice';
import { useSyncContext } from 'src/sync';
import Category5Autocomplete from './components/Category5Autocomplete';

export const SwapCategory5Dialog = () => {
  const dispatch = useAppDispatch();
  const { brandLine, isOpen } = useAppSelector(state => state.swapCategory5Dialog);

  const handleClose = () => {
    dispatch(swapCategory5DialogActions.close());
  };

  const [newCategory5Id, setNewCategory5Id] = useState<number | undefined>();

  const { updateEntity } = useSyncContext();

  const handleFormSubmit = () => {
    if (brandLine) {
      void updateEntity('brandLines', brandLine.brandLineGUID, {
        category5Id: newCategory5Id,
      });
    }
    handleClose();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        Swap Category 5
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          Current Category 5: <strong>{brandLine?.category5Name}</strong>
        </Typography>

        <Box sx={{ mt: 2, mb: 8 }}>
          <Category5Autocomplete onChange={value => setNewCategory5Id(value)} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleFormSubmit}>
          Swap
        </Button>
      </DialogActions>
    </Dialog>
  );
};
