import { type FillOperationParams } from 'src/components/DataGrid';
import { type DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { BrandLineTypes } from 'src/shared';
import { type Entity } from 'src/types/Entity';

export const fillOperationHandler = (
  params: FillOperationParams,
  distributors: Entity[],
  updateEntity: (
    entityType: string,
    entityGUID: string,
    changes: Partial<Entity>,
    actionType?: string | undefined,
    isLocalChanges?: boolean | undefined
  ) => Promise<void>
): unknown => {
  const { column, initialValues, rowNode } = params;
  if (column.getColId() === 'distributorName' && params.currentCellValue !== initialValues[0]) {
    const matchingDistributors = distributors.filter(
      ({ distributorName }) => distributorName === initialValues[0]
    );

    if (matchingDistributors.length === 1 && matchingDistributors[0]) {
      const rowNodeToUpdateData = rowNode.data as DenormalizedBrandSales | undefined;

      const sourceDistributor = matchingDistributors[0];
      if (rowNodeToUpdateData && rowNodeToUpdateData.brandLineTypeId !== BrandLineTypes.OTHER) {
        const saleGUID = rowNodeToUpdateData.saleGUID;
        const { distributorGUID, distributorName } = sourceDistributor;

        void updateEntity('brandSales', saleGUID, { distributorGUID, distributorName });
      }
    }

    if (matchingDistributors.length > 1) {
      console.error('More than one Distributor with the same name', matchingDistributors);
    }
  }

  return params.currentCellValue;
};
