import { suspend } from 'suspend-react';

import { BootstrapErrorPage, SyncContextState, useSyncContext } from 'src/sync';

const connectUUID = Symbol();

interface Props {
  authState?: string;
  routing: React.ReactElement | null;
}

export const SignedIn = ({ authState, routing }: Props) => {
  const { connect, useStartSyncingTimer } = useSyncContext();

  // TODO: proper solution in order to not inject authState to useStartSyncingTime hook,
  // this line should be out of SignedIn component!
  if (authState !== 'signedIn') return null;

  // @TODO - move this into <App /> when we've sorted out auth
  const { error, status } = suspend(async () => await connect(), [connectUUID]);

  if (status === SyncContextState.Available) {
    // because of using hook, it should not be under conditional statement,
    // so authState is injected in order to cause re-rendered when state is change.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useStartSyncingTimer();
  }

  switch (status) {
    case SyncContextState.BootstrapError: {
      return <BootstrapErrorPage error={error ?? new Error('Unknown error')} />;
    }

    case SyncContextState.Available: {
      return routing;
    }

    default: {
      return null;
    }
  }
};
