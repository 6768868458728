import { createSlice } from '@reduxjs/toolkit';

interface CreateBrandLineDialogState {
  isOpen: boolean;
}

const initialState: CreateBrandLineDialogState = {
  isOpen: false,
};

export const {
  actions: createBrandLineDialogActions,
  reducer: createBrandLineDialogReducer,
  name: createBrandLineDialogSliceName,
} = createSlice({
  name: 'createBrandLineDialog',
  initialState,
  reducers: {
    open(state) {
      state.isOpen = true;
    },
    close() {
      return initialState;
    },
  },
});
