import { type MutableRefObject, useCallback, useRef } from 'react';
import type {
  FilterChangedEvent,
  GridApi,
  NumberFilterModel,
  ProvidedFilterModel,
  SetFilterModel,
  TextFilterModel,
} from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import { COLUMN_TYPES } from '../../options';
import {
  type Column,
  type FilterItem,
  type FilterModel,
  FilterOperator,
  type GridOptions,
  type Row,
} from '../../types';
import { useEffectAfterFirstRender, useInitialiseState } from '../utils';

const buildFilteringCriteria = (item: FilterItem, column?: Column): ProvidedFilterModel => {
  if (!column || column.filterable === false) return {};

  const filterOperator = COLUMN_TYPES[column.type]?.filterParams.filterOptions.find(
    operator => operator === item.operator
  );
  if (!filterOperator) {
    throw new TypeError(`Unrecognised filter operator ${item.operator}`);
  }

  switch (item.operator) {
    case FilterOperator.IsAnyOf:
      return {
        type: 'set',
        values: item.value,
      } as SetFilterModel;

    default:
      return {
        filter: item.value,
        filterType: 'number',
        type: item.operator,
      } as NumberFilterModel | TextFilterModel;
  }
};

type AgGridFilterModel = NumberFilterModel | SetFilterModel | TextFilterModel;

export const useFilteringFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  _gridApiRef: MutableRefObject<GridApi<R> | undefined>,
  props: Pick<DataGridProps<R>, 'columns' | 'filter' | 'onFilterChange'>
) => {
  const { columns, filter = [], onFilterChange } = props;
  const originalFilters = useRef<FilterModel>(props.filter ?? []);
  const hasActiveFilters = useRef(false);

  const handleFilterChange = useCallback(
    (e: FilterChangedEvent<R>) => {
      const filterModel: FilterModel = [];
      Object.entries<AgGridFilterModel>(e.api.getFilterModel()).forEach(([column, item], index) => {
        switch (item.filterType) {
          case 'set':
            filterModel[index] = {
              field: column,
              operator: FilterOperator.IsAnyOf,
              value: item.values,
            };
            break;

          case 'number':
          case 'text':
            filterModel[index] = {
              field: column,
              operator: item.type as FilterOperator,
              value: item.filter,
            };
            break;
        }
      });

      onFilterChange?.(filterModel);
      e.api.refreshCells({ suppressFlash: true });

      if (filterModel.length > originalFilters.current.length) {
        e.api.refreshClientSideRowModel('group');
        hasActiveFilters.current = true;
        return;
      }

      if (hasActiveFilters.current) {
        e.api.refreshClientSideRowModel('group');
        hasActiveFilters.current = false;
      }
    },
    [onFilterChange]
  );

  useInitialiseState(() => {
    const filteringCriteria: Record<string, ProvidedFilterModel | null> = {};

    filter.forEach(item => {
      filteringCriteria[item.field] = buildFilteringCriteria(
        item,
        columns.find(col => col.field === item.field)
      );
    });
    gridRef.current.onFilterChanged = handleFilterChange;

    // @TODO - use onGridReady instead (requires registry)
    gridRef.current.onFirstDataRendered = e => {
      e.api.setFilterModel(filteringCriteria);
      gridRef.current.onFilterChanged = handleFilterChange;
    };
  });

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onFilterChange` is not supported.');
  }, [onFilterChange]);
};
