import { useState } from 'react';

import type { DenormalizedWineVarietalSaleData } from 'src/records/types/DenormalizedWineVarietalSaleData';
import type { PopupConfiguration } from 'src/views/Pages/common/types';
import { createContextMenu } from 'src/views/utils/contextmenu';
import { getContextMenuItems } from './helpers';
import type { Params, Table } from './types';

interface HookArgs {
  canWriteRecords?: boolean | undefined;
  canRemoveRecords?: boolean | undefined;
  table: Table;
  onOriginOptionSelection: (selectedOriginId: number) => void;
}

export type WineRegionVarietalPopupConfiguration =
  PopupConfiguration<DenormalizedWineVarietalSaleData>;

export const useWineRegionAndVarietalContextMenu = ({
  canWriteRecords,
  canRemoveRecords,
  table,
  onOriginOptionSelection: handleOriginOptionSelection,
}: HookArgs) => {
  const [popupConfiguration, setPopupConfiguration] =
    useState<WineRegionVarietalPopupConfiguration | null>(null);

  const getContextMenuActions = (params: Params) => {
    const actions = getContextMenuItems({
      params,
      canWriteRecords,
      canRemoveRecords,
      setPopupConfiguration,
      onOriginOptionSelection: handleOriginOptionSelection,
      table,
    });

    return createContextMenu(canRemoveRecords, params, actions);
  };

  const handleClearPopupConfiguration = () => {
    setPopupConfiguration(null);
  };

  return {
    popupConfiguration,
    getContextMenuActions,
    handleClearPopupConfiguration,
  };
};
