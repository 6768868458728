export const toSentenceCase = (value: string) => {
  return `${value.slice(0, 1).toUpperCase()}${value.slice(1).toLowerCase()}`;
};

type StringifiedBoolean = 'true' | 'false';

export const isStringifiedBooleanValue = (value: unknown): value is StringifiedBoolean =>
  value === 'true' || value === 'false';

export const getBooleanValueFromString = (value: StringifiedBoolean) =>
  String(value).toLowerCase() === 'true' ? true : false;

export const randomId = () => Math.random().toString(36).substring(2, 9);
