import { type ChangeEvent, type FormEvent, useCallback, useRef, useState } from 'react';
import type { SelectChangeEvent } from '@mui/material/Select';

import type { Dictionary } from 'src/types/MappedTypes';
import type { Errors, Validations } from './types';

export const useFormValidation = <
  TForm extends Dictionary<TForm>,
  TValidations extends Partial<Validations<TForm>> = Partial<Validations<TForm>>,
>(
  initialForm: TForm,
  validations?: TValidations
) => {
  const errors = useRef<Partial<Errors<TValidations>>>({});
  const [form, setForm] = useState(initialForm);
  // eslint-disable-next-line react/hook-use-state
  const [, updateFn] = useState(0);
  const isDirty = useRef(false);

  const getValidation = (name: keyof TForm, updatedForm: TForm) => {
    const value = updatedForm[name] as string;
    const { required, maxLength, validate } = validations?.[name] ?? {};

    if (required && value.trim().length === 0) {
      return { error: required.msg };
    }

    if (maxLength && value.length > maxLength.maxLength) {
      return { error: maxLength.msg };
    }

    if (validate) {
      const validateResult = validate.validate(updatedForm);
      const isStringError = typeof validateResult === 'string';

      if (!validateResult || isStringError) {
        const error = isStringError ? validateResult : validate.msg;

        return { error };
      }
    }

    return { error: null };
  };

  const removeErrorFromState = (name: keyof TForm) => {
    const { [name]: _errorToOmit, ...restErrors } = errors.current;
    errors.current = restErrors as typeof errors.current;
  };

  const setErrorInState = (name: keyof TForm, error: string) => {
    errors.current = { ...errors.current, [name]: error };
  };

  const validateInput = (name: keyof TForm, updatedForm: TForm) => {
    const { error: validationError } = getValidation(name, updatedForm);

    if (validationError) {
      setErrorInState(name, validationError);
      return;
    }

    removeErrorFromState(name);
  };

  const clearErrors = () => {
    errors.current = {};
  };

  const validateForm = (formToValidate: TForm) => {
    Object.entries<string>(formToValidate).forEach(([name]) => {
      validateInput(name as keyof TForm, formToValidate);
    });
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    isDirty.current = true;
    const { name, value } = e.target;
    const updatedForm: TForm = { ...form, [name]: value };

    setForm(() => updatedForm);
    validateForm(updatedForm);
  };

  const isValidForm = () => {
    if (!validations) {
      return true;
    }

    validateForm(form);
    return Object.keys(errors.current).length === 0;
  };

  const handleSubmit = (callback: () => void, e?: FormEvent) => {
    e?.preventDefault();

    if (!isValidForm()) {
      // Force re-render to show errors.
      updateFn(val => val + 1);
      return;
    }

    callback();
  };

  const setFormValue = useCallback(({ name, value }: { name: keyof TForm; value: string }) => {
    setForm(currentForm => ({ ...currentForm, [name]: value }));
  }, []);

  const resetForm = () => {
    setForm(initialForm);
    clearErrors();
    isDirty.current = false;
  };

  return {
    form,
    errors: errors.current,
    isDirty: isDirty.current,
    handleInputChange,
    handleSubmit,
    resetForm,
    setFormValue,
    validateInput,
  };
};
