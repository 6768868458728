import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { useSyncContext } from '../sync';
import { CentralContainer } from './Container';
import { Spinner } from './Spinner';

const LogOut = () => {
  const { disconnect } = useSyncContext();
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      // clear cached data, reset database data except transaction table and close database connection.
      await disconnect();

      // react amplify sign out.
      await Auth.signOut();

      navigate('/');
    })();
  }, [disconnect, navigate]);

  return (
    <CentralContainer>
      <Spinner />
    </CentralContainer>
  );
};

export default LogOut;
