import { type InitialGroupOrderComparatorParams } from '@ag-grid-community/core';

export const initialGroupOrderComparator = ({
  nodeA,
  nodeB,
}: InitialGroupOrderComparatorParams) => {
  const columnDef = nodeA.rowGroupColumn?.getColDef();
  const isDescending = false; // Always false for now.
  return columnDef?.comparator?.(nodeA.key, nodeB.key, nodeA, nodeB, isDescending) ?? 0;
};
