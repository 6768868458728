import { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { SyncContextState, useSyncContext } from 'src/sync';
import { ModalHeader } from '../css/styled-components';

const CURRENT_UI_VERSION = 'current-ui-version';

interface UIVersion {
  revision: string;
}

const getUIStorageVersion = () => localStorage.getItem(CURRENT_UI_VERSION);

const setUIStorageVersion = (value: string) => {
  window.localStorage.setItem(CURRENT_UI_VERSION, value);
};

const isUIVersion = (data: unknown): data is UIVersion =>
  typeof data === 'object' &&
  data !== null &&
  'revision' in data &&
  typeof (data as UIVersion).revision === 'string';

const fetchUIVersionJson = async (): Promise<UIVersion | undefined> => {
  try {
    const response = await fetch('/version.json');

    if (!response.ok) {
      console.error('Failed to fetch version data.');
      return;
    }

    if (!response.headers.get('content-type')?.includes('application/json')) {
      console.error('Response is not JSON');
      return;
    }

    const data: unknown = await response.json();

    if (!isUIVersion(data)) {
      console.error('Invalid data: Missing "revision" field');
      return;
    }

    return data;
  } catch (error) {
    console.error('Error fetching version:', error);
    return;
  }
};

export const UIVersionChecker = () => {
  const [isVersionMismatch, setIsVersionMismatch] = useState<boolean>(false);
  const [uiVersion, setUIVersion] = useState<UIVersion | undefined>();
  const { useSyncStatus } = useSyncContext();
  const { status } = useSyncStatus();

  const isAppAvailable = useCallback(
    () => status === SyncContextState.Available && navigator.onLine,
    [status]
  );

  const checkForUIVersionUpdate = useCallback(async () => {
    if (!isAppAvailable()) return;

    const currentUIVersion = getUIStorageVersion();
    const fetchedUIVersion = await fetchUIVersionJson();

    if (fetchedUIVersion && currentUIVersion !== fetchedUIVersion.revision) {
      setUIVersion(fetchedUIVersion);
      setIsVersionMismatch(true);
    }
  }, [isAppAvailable]);

  const initializeUIVersion = useCallback(async () => {
    if (!isAppAvailable()) return;

    const currentUIVersion = getUIStorageVersion();
    const fetchedUIVersion = await fetchUIVersionJson();

    if (!fetchedUIVersion) return;

    if (!currentUIVersion || currentUIVersion !== fetchedUIVersion.revision) {
      setUIStorageVersion(fetchedUIVersion.revision);
    }
  }, [isAppAvailable]);

  useEffect(() => {
    if (isVersionMismatch) return;

    void initializeUIVersion();

    const intervalId = setInterval(() => {
      void checkForUIVersionUpdate();
    }, 20 * 60000);

    return () => clearInterval(intervalId);
  }, [isVersionMismatch, checkForUIVersionUpdate, initializeUIVersion]);

  return (
    <>
      {isVersionMismatch && uiVersion && (
        <Modal show centered>
          <ModalHeader>
            <Modal.Title>A New Version is Available</Modal.Title>
          </ModalHeader>
          <Modal.Body>
            Your application is outdated. Please refresh the page to update to the latest version.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setUIStorageVersion(uiVersion.revision);
                window.location.reload();
              }}
            >
              Refresh
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
