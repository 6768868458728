import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { pxToRem } from 'src/theme/utils';

interface Props {
  label: string;
  value: string | number | boolean | null | undefined;
}

export const Description = (props: Props) => {
  if (!props.value) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        rowGap: pxToRem('4px'),
        columnGap: pxToRem('40px'),
      }}
    >
      <Typography component="span" sx={{ fontSize: 14, fontWeight: 400 }}>
        {props.label}:
      </Typography>
      <Typography component="span" sx={{ fontSize: 14, fontWeight: 400 }}>
        {props.value}
      </Typography>
    </Box>
  );
};
