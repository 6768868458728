import type { FormulaFunction } from 'src/components/DataGrid';

export const absoluteChange: FormulaFunction = (startValue: unknown, endValue: unknown) => {
  const config = {
    startValue: !isNaN(startValue as number) ? (startValue as number) : 0,
    endValue: !isNaN(endValue as number) ? (endValue as number) : 0,
  };

  const absoluteChange = config.endValue - config.startValue;

  if (isNaN(absoluteChange)) return undefined;
  if (!isFinite(absoluteChange)) return undefined;
  if (absoluteChange === 0) return undefined;

  return absoluteChange;
};
