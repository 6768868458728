import { isNumericInputValid } from '../../components/UserEnteries';
import { NumberEntryEnum, YearDataTypes } from '../../shared';
import { parseYearColId } from '../gridUtils';

// ********** CONTAINER SIZE **********
const containerSizeMin = 0; // +ve only & !zero
const isNewContainerSizeValueValid = (newValue: number) =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  newValue > containerSizeMin || newValue === null;

// ********** ON PREMISE PERCENT **********
const percentageMin = 0;
const percentageMax = 100; // percentage only

const isNewPercentageValueValid = (newValue: number) =>
  newValue >= percentageMin && newValue <= percentageMax;

export const isNewYearValueValid = (
  columnId: string | number,
  newValue: number | null
): boolean => {
  const colId = columnId.toString();
  if (colId.includes(YearDataTypes.externalDataComment)) return true;
  if (newValue == null) return true;

  const isNumberValid = isNumericInputValid(newValue.toString(), NumberEntryEnum.Volume); // !NaN && +ve
  if (!isNumberValid) return false;

  const [, property] = parseYearColId(colId);
  // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style, @typescript-eslint/ban-types
  const validationFunctionLookUp: { [index: string]: Function } = {
    [YearDataTypes.onPremisePercent]: isNewPercentageValueValid,
    [YearDataTypes.containerSize]: isNewContainerSizeValueValid,
    // validation for volume/etc just not negative - handled standard validation
  };

  // @ts-expect-error: legacy code
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const validationFunction = validationFunctionLookUp[property];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!validationFunction) return true;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  return validationFunction(newValue);
};
