import { useMemo, useState } from 'react';
import { Col, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Box from '@mui/material/Box';

import CategoryDropdown from 'src/components/BrandSales/AddBrandLine/CategoryDropdown';
import { Label } from 'src/components/BrandSales/AddBrandLine/styledComponents';
import { Button, ModalHeader } from 'src/css/styled-components';
import { useMarket } from 'src/markets';
import { useRecords } from 'src/records/hooks/useRecords';
import { type DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type DenormalizedOnPremiseSales } from 'src/records/types/DenormalizedOnPremiseSales';
import type { OriginsEntity } from 'src/types/entity/Origins';
import type { PriceBandsEntity } from 'src/types/entity/PriceBands';
import { useEnsureForecastAndOtherExist } from 'src/views/hooks/useEnsureForecastAndOtherExist';
import type { FlatCategoryItem } from 'src/views/types';

interface AddForecastProps {
  closeModal: () => void;
}

interface PageState {
  canSave: boolean;
  errorMessage: string;
  forecastByOrigin?: boolean | null;
  selectedCategory5?: FlatCategoryItem | undefined;
  selectedOriginOption?: { label: string; value: string } | undefined;
  selectedLocalImportOption?: { label: string; value: string } | undefined;
  selectedPriceBandOption?: { label: string; value: string };
}

const AddForecast = ({ closeModal }: AddForecastProps) => {
  const {
    market: { marketId, demonym },
  } = useMarket();

  const brandSales = useRecords<DenormalizedBrandSales>('brandSales', marketId);
  const onPremiseSales = useRecords<DenormalizedOnPremiseSales>('onPremiseSales', marketId);
  const origins = useRecords<OriginsEntity>('origins');
  const priceBands = useRecords<PriceBandsEntity>('priceBands');
  const categories = useRecords<FlatCategoryItem>('categories');

  const { ensureForecastAndOtherExist } = useEnsureForecastAndOtherExist(
    brandSales,
    onPremiseSales
  );

  const [pageState, setPageState] = useState<PageState>({
    canSave: false,
    errorMessage: '',
    forecastByOrigin: true,
  });

  const originOptions = useMemo(
    () => (originId: string) => {
      return origins
        .map(item => ({
          value: String(item.originId),
          label: item.originName,
        }))
        .filter(item => item.value === originId || originId === '0')
        .sort((firstItem, secondItem) => (firstItem.label > secondItem.label ? 1 : -1));
    },
    [origins]
  );

  const localOrImportedOptions = useMemo(
    () =>
      (originId: number | undefined): { label: string; value: string }[] => {
        const options = [
          {
            value: 'Local',
            label: 'Local',
          },
          {
            value: 'Imported',
            label: 'Imported',
          },
        ];

        if (!originId) {
          return options;
        }

        if (origins.find(o => o.originId === originId)?.originName === demonym) {
          return [
            {
              value: 'Local',
              label: 'Local',
            },
          ];
        }

        return [
          {
            value: 'Imported',
            label: 'Imported',
          },
        ];
      },
    [demonym, origins]
  );

  const priceBandOptions = useMemo(() => {
    return priceBands
      .sort((firstItem, secondItem) => (firstItem.displayOrder > secondItem.displayOrder ? 1 : -1))
      .map(priceBand => ({
        label: String(priceBand.priceBandName),
        value: String(priceBand.priceBandId),
      }));
  }, [priceBands]);

  const saveChanges = () => {
    const category5Id = pageState.selectedCategory5?.id;
    const priceBandId = pageState.selectedPriceBandOption?.value;
    const isLocal = pageState.selectedLocalImportOption?.value === 'Local';
    const originId =
      pageState.selectedOriginOption?.value ?? String(pageState.selectedCategory5?.originId);

    if (category5Id != null && priceBandId != null) {
      ensureForecastAndOtherExist({
        category5Id,
        priceBandId: parseInt(priceBandId),
        isLocal,
        originId: parseInt(originId),
      });

      closeModal();
    } else {
      setPageState({
        ...pageState,
        errorMessage: 'Please select category 5 and price band',
      });
    }
  };

  const handleSelectCategory = (selectedCategory5: FlatCategoryItem) => {
    let selectedOriginOption: { value: string; label: string } | undefined;
    let selectedLocalImportOption: { label: string; value: string } | undefined;

    if (selectedCategory5.forecastByOrigin) {
      selectedOriginOption = originOptions(String(selectedCategory5.originId))[0];
    } else {
      selectedLocalImportOption = localOrImportedOptions(selectedCategory5.originId)[0];
    }

    setPageState({
      ...pageState,
      forecastByOrigin: selectedCategory5.forecastByOrigin,
      selectedCategory5,
      selectedOriginOption,
      selectedLocalImportOption,
    });
  };

  const handleSelectOrigin = (
    selectedItem: { label: string; value: string } | null | undefined
  ) => {
    if (selectedItem != null) {
      setPageState({
        ...pageState,
        selectedOriginOption: selectedItem,
      });
    }
  };

  const handleSelectLocalOrImported = (
    selectedItem: { label: string; value: string } | null | undefined
  ) => {
    if (selectedItem != null) {
      setPageState({
        ...pageState,
        selectedLocalImportOption: selectedItem,
      });
    }
  };

  const handleSelectPriceBand = (
    selectedItem: { label: string; value: string } | null | undefined
  ) => {
    if (selectedItem != null) {
      setPageState({
        ...pageState,
        selectedPriceBandOption: selectedItem,
        canSave: true,
      });
    }
  };

  return (
    <Modal show centered onHide={() => closeModal()} size="xl">
      <ModalHeader>
        <Modal.Title>Add Forecast Line</Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <Form.Row>
          <Col>
            <Form.Group>
              <Label>Category</Label>
              <CategoryDropdown
                categories={categories}
                handleSelectCategory={handleSelectCategory}
                selected={pageState.selectedCategory5}
                id="add_forecast_category_dropdown"
              />
            </Form.Group>
          </Col>
          {pageState.forecastByOrigin && (
            <Col>
              <Form.Group>
                <Label>Origin</Label>
                <Select
                  options={originOptions(String(pageState.selectedCategory5?.originId ?? '0'))}
                  value={pageState.selectedOriginOption}
                  onChange={handleSelectOrigin}
                  isDisabled={!pageState.selectedCategory5}
                  placeholder="Select origin"
                />
              </Form.Group>
            </Col>
          )}
          {!pageState.forecastByOrigin && (
            <Col>
              <Form.Group>
                <Label>Local Or Imported</Label>
                <Select
                  options={localOrImportedOptions(pageState.selectedCategory5?.originId)}
                  value={pageState.selectedLocalImportOption}
                  onChange={handleSelectLocalOrImported}
                  isDisabled={!pageState.selectedCategory5}
                  placeholder="Select local imported"
                />
              </Form.Group>
            </Col>
          )}

          <Col>
            <Form.Group>
              <Label>Price Band</Label>
              <Select
                options={priceBandOptions}
                value={pageState.selectedPriceBandOption}
                onChange={handleSelectPriceBand}
                isDisabled={!pageState.selectedLocalImportOption && !pageState.selectedOriginOption}
                placeholder="Select price band"
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Box
          component="span"
          sx={{ display: pageState.errorMessage !== '' ? 'block' : 'none', color: 'red' }}
        >
          {pageState.errorMessage}
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="link" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={saveChanges} disabled={!pageState.canSave}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddForecast;
