import { type MutableRefObject, useCallback } from 'react';
import type { ColumnState, GridApi, SortChangedEvent } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { ColDef, ColGroupDef, GridOptions, Row, SortModel } from '../../types';
import { isColGroupDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useSortingFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  gridApiRef: MutableRefObject<GridApi<R> | undefined>,
  props: Pick<DataGridProps<R>, 'columns' | 'onSortChange' | 'sort'>
) => {
  const logger = useGridLogger(gridRef, 'useSortingFeature');

  const { columns, onSortChange, sort = [] } = props;

  const handleSortChange = useCallback(
    (e: SortChangedEvent) => {
      const sortModel: SortModel = [];

      e.api.getColumnState().forEach(col => {
        if (!col.sort || col.sortIndex === undefined || col.sortIndex === null) return;
        sortModel[col.sortIndex] = { field: col.colId, sort: col.sort };
      });

      console.log('sortModel', sortModel);

      onSortChange?.(sortModel);
    },
    [onSortChange]
  );

  useInitialiseState(() => {
    sort.forEach((item, index) => {
      let colDef = gridRef.current.columnDefs?.find(
        col => !isColGroupDef<R>(col) && col.field === item.field
      ) as ColDef<R> | undefined;

      if (!colDef) {
        const colGroupDef = gridRef.current.columnDefs?.find(
          col => isColGroupDef<R>(col) && col.children.some(col => col.field === item.field)
        ) as ColGroupDef<R> | undefined;

        colDef = colGroupDef?.children.find(col => col.field === item.field);
      }

      if (colDef) {
        colDef.sort = item.sort;
        colDef.sortIndex = index;
      }
    });

    gridRef.current.onSortChanged = handleSortChange;
  });

  useEffectAfterFirstRender(() => {
    logger.log('Setting sort model');

    const sortCriteria: ColumnState[] = [];

    sort.forEach((item, index) => {
      const column = columns.find(col => col.field === item.field);

      if (column) {
        sortCriteria.push({
          colId: column.field,
          sort: item.sort,
          sortIndex: index,
        });
      }
    });

    gridApiRef.current?.applyColumnState({
      state: sortCriteria,
      defaultState: {
        sort: null,
        sortIndex: null,
      },
    });
  }, [columns, sort]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onSortChange` is not supported.');
  }, [onSortChange]);
};
