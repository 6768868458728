import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { v4 as uuidv4 } from 'uuid';

import { useFormValidation } from 'src/hooks/useFormValidation/useFormValidation';
import type { Market } from 'src/markets/types';
import { useSyncContext } from 'src/sync/hooks/useSyncContext';
import type { BrandsEntity } from 'src/types/entity/Brands';
import type { StrictOmit } from 'src/types/MappedTypes';
import * as styles from '../styles/createBrandDialog.styles';

export type NewBrand = StrictOmit<
  BrandsEntity,
  'approvalComment' | 'lastUpdatedDate' | 'approvalStatus'
>;

interface Props {
  defaultValue: string;
  market: Market;
  open: boolean;
  brands: BrandsEntity[];
  onCancel: () => void;
  onClose: () => void;
  onSubmit: (newBrand: NewBrand) => void;
}

interface Form {
  brandName: string;
}

const createBrandCopy = 'Create brand';

export const CreateBrandDialog = ({
  defaultValue,
  market,
  open,
  brands,
  onCancel,
  onClose,
  onSubmit,
}: Props) => {
  const { errors, form, handleInputChange, handleSubmit } = useFormValidation<Form>(
    {
      brandName: defaultValue,
    },
    {
      brandName: {
        required: {
          msg: 'Brand name is required',
          required: true,
        },
        validate: {
          msg: 'Brand name has already been taken',
          validate: updatedForm => {
            const isNotExistingBrand = brands.every(
              brand => brand.brandName.toLowerCase() !== updatedForm.brandName.toLowerCase()
            );

            return isNotExistingBrand;
          },
        },
      },
    }
  );

  const { createEntity } = useSyncContext();

  const handleFormSubmit = () => {
    const newBrand: NewBrand = {
      createdIn: market.marketId,
      brandName: form.brandName,
      brandDisplayName: form.brandName,
      brandGUID: uuidv4(),
      ownerGUID: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA', //checkme
    };

    void createEntity('brands', newBrand);

    onSubmit(newBrand);
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle fontWeight={600}>{createBrandCopy}</DialogTitle>
      <DialogContent dividers>
        <div>
          <FormControl fullWidth>
            <FormLabel htmlFor="brandName" sx={styles.formLabel}>
              <Typography variant="body1">
                {'Name'} - <Typography variant="caption">name of the brand</Typography>
              </Typography>
            </FormLabel>
            <TextField
              id="brandName"
              name="brandName"
              value={form.brandName}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              error={Boolean(errors.brandName)}
              helperText={errors.brandName}
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSubmit(handleFormSubmit)}
          disabled={Object.keys(errors).length > 0}
        >
          {createBrandCopy}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
