import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';

interface OpenDialogPayload {
  brandLine: DenormalizedBrandLine;
}

interface SwapOwnerDialogState {
  brandLine: DenormalizedBrandLine | null;
  isOpen: boolean;
}

const initialState: SwapOwnerDialogState = {
  isOpen: false,
  brandLine: null,
};

export const {
  actions: swapOwnerDialogActions,
  reducer: swapOwnerDialogReducer,
  name: swapOwnerDialogSliceName,
} = createSlice({
  name: 'swapOwnerDialog',
  initialState,
  reducers: {
    open(state, { payload }: PayloadAction<OpenDialogPayload>) {
      state.isOpen = true;
      state.brandLine = payload.brandLine;
    },
    close() {
      return initialState;
    },
  },
});
