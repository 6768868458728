import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Container } from 'src/components/Container';
import { Content } from 'src/css/styled-components';
import { useRecords } from 'src/records/hooks/useRecords';
import { useAppDispatch, useAppSelector } from 'src/store';
import { checksActions } from 'src/store/features/checks/slice';
import { useSyncContext } from 'src/sync';
import { useTitle } from 'src/views/hooks';
import { useTable } from 'src/views/hooks/useTable';
import { CheckHeader } from '../components/CheckHeader';
import { ChecksSidebar } from '../components/ChecksSidebar';
import { useTriggerCheckRun } from '../hooks/useTriggerCheckRun';
import type { ValidationCheck } from '../types';
import { CheckErrorDetails } from './CheckErrorDetails';

const ALL_CHECKS_STATUS_ID = 0;

interface GetGeneratedSlugPath {
  marketSlug: string;
  selectedCheckSlug: string | undefined;
}

const getGeneratedSlugPath = ({ marketSlug, selectedCheckSlug }: GetGeneratedSlugPath) => {
  return !selectedCheckSlug
    ? generatePath('/:marketSlug/checks', {
        marketSlug,
      })
    : generatePath('/:marketSlug/checks/:checkSlug', {
        marketSlug,
        checkSlug: selectedCheckSlug,
      });
};

export const ChecksPage = () => {
  const navigate = useNavigate();
  const { syncData, syncTransactions } = useSyncContext();
  const { checkSlug } = useParams<'checkSlug'>();
  const {
    market: { marketId, marketSlug, marketName },
  } = useTable();
  const { triggerCheckRun } = useTriggerCheckRun();
  const dispatch = useAppDispatch();
  const checksPath = useAppSelector(state => state.checks.markets[marketName]);
  const [checkErrorDetailsKey, setCheckErrorDetailsKey] = useState(Date.now().toString());

  // An array of checkIds that are in progress. We keep this state in the UI
  // to override the status in indexdb to reflect us calling the API to run
  // the check on the server.
  const [runningChecks, setRunningChecks] = useState<ValidationCheck['checkId'][]>([]);

  useEffect(() => {
    if (checksPath != null) {
      navigate(checksPath, { replace: false });
    }
    // Empty dependency array, so this only runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedCheckChange = useCallback(
    (selectedCheckSlug?: string) => {
      if (selectedCheckSlug === checkSlug) {
        // Already here, no need to redirect
        return;
      }

      const generatedPath = getGeneratedSlugPath({ marketSlug, selectedCheckSlug });

      navigate(generatedPath, { replace: false });
      dispatch(checksActions.setPath({ marketName, path: generatedPath }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marketSlug, navigate, checkSlug]
  );

  const checks = useRecords('checks', marketId, true) as unknown as ValidationCheck[];
  const checksList = useMemo((): ValidationCheck[] => {
    if (checks.length === 0) {
      return [];
    }

    const initialCheck: ValidationCheck = {
      checkStatusId: ALL_CHECKS_STATUS_ID,
      checkId: ALL_CHECKS_STATUS_ID,
      checkSlug: undefined,
      checkName: 'Summary',
      checkStatus: 'None' as const,
      marketId,
      description: 'Checks',
      colDefs: '[]',
      colDefsGrouping: '[]',
      isUnfixable: false,
      entityType: 'brandSales',
    };

    const checksWithCheckStatus = checks.map(check => ({
      ...check,
      checkStatus: runningChecks.includes(check.checkId) ? 'In Progress' : check.checkStatus,
    }));

    return [initialCheck, ...checksWithCheckStatus];
  }, [checks, marketId, runningChecks]);

  const checkToShow = checksList.find(c => c.checkSlug === checkSlug);

  let title = `${marketName}: Checks - Collector`;

  if (checkToShow?.checkName && checkToShow.checkName !== 'Checklist') {
    title = `${marketName}: ${checkToShow.checkName} - Checks - Collector`;
  }

  useTitle(title);

  const handleValidate = useCallback(
    async (checkId: ValidationCheck['checkId']) => {
      // The checks we'll change to in progress on the UI in this case.
      // Once the api is done, we stop overriding these.
      const checksToAdd =
        checkId === ALL_CHECKS_STATUS_ID ? checks.map(({ checkId }) => checkId) : [checkId];

      setRunningChecks(runningChecks => [...runningChecks, ...checksToAdd]);

      // First make sure to sync all pending transactions on the outbox
      // If we're not online, the buttons should be disabled or not there
      await syncTransactions();

      // Force sync to be run before running validation checks
      await syncData();

      // Update the state so UI will stop showing in progress by removing
      // one instance (the first only) of any check we added (not all)
      // If we click to validate a  check, then validate all, we would like
      // to wait for the second run to finish
      if (checkId === ALL_CHECKS_STATUS_ID) {
        // do not call trigger check run stored procedure with run all flag, it is causing time out, therefore call the triggers for each checks individually.
        // instead of using Promise.all, Promise.allSettled is used. Because, while running all checks in once, we do not want to throw error message
        // it should only be appeared on when the check is ran individually.
        await Promise.allSettled(
          checksList
            .filter(item => item.checkId !== ALL_CHECKS_STATUS_ID)
            .map(item => triggerCheckRun(marketId, item.checkId, item.checkName))
        );
      } else {
        await triggerCheckRun(
          marketId,
          checkId,
          checksList.find(item => item.checkId === checkId)?.checkName
        );
      }

      // Force sync to be run after running validation checks
      await syncData();

      setRunningChecks(runningChecks =>
        runningChecks.filter(
          (cId, i) => !checksToAdd.includes(cId) || i !== runningChecks.findIndex(id => id === cId)
        )
      );

      // after running delta syncing in order fetch new records in CheckErrorDetails component
      // it should be rerendered again, therefore key is replaced with lastsyncdate.
      setCheckErrorDetailsKey(Date.now().toString());
    },
    [checks, marketId, triggerCheckRun, syncData, checksList, syncTransactions]
  );

  return (
    <Container id="checksContainer" hasOnlyMainNav>
      <ChecksSidebar
        checks={checksList}
        selectedCheckSlug={checkSlug}
        onSelect={handleSelectedCheckChange}
      />
      <Content>
        {!checkToShow && 'Check not found.'}

        {checkToShow?.checkId === ALL_CHECKS_STATUS_ID &&
          checksList.map(check => (
            <CheckHeader
              key={`${check.checkStatusId}-${checkErrorDetailsKey}`}
              check={check}
              onValidate={(checkId: ValidationCheck['checkId']) => void handleValidate(checkId)}
              onClick={handleSelectedCheckChange}
              isSummaryPage
            />
          ))}

        {checkToShow && checkToShow.checkId !== ALL_CHECKS_STATUS_ID && (
          <CheckErrorDetails
            key={checkErrorDetailsKey}
            check={checkToShow}
            onValidate={(checkId: ValidationCheck['checkId']) => void handleValidate(checkId)}
          />
        )}
      </Content>
    </Container>
  );
};
