import { pxToRem } from 'src/theme/utils';

export const brandLineName = {
  marginBottom: pxToRem('6px'),
  fontWeight: 600,
};

export const container = {
  display: 'flex',
  flexFlow: 'row nowrap',
};

export const leftCol = {
  flex: 1,
  maxWidth: '6.5rem',
};

export const rightCol = {
  flex: 1,
};

export const preposition = {
  marginTop: '20px',
  fontWeight: 600,
  fontSize: '1.25rem',
};
