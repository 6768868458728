import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import type { BrandsEntity } from 'src/types/entity/Brands';
import type { OwnersEntity } from 'src/types/entity/Owners';
import type { Suggestion } from './types';

export interface BrandWithOwnerName extends BrandsEntity {
  ownerName: string;
}

interface GetOwnersSearchResultsParam {
  owners: OwnersEntity[];
  search: string;
  selectedRow: DenormalizedBrandLine | null;
}

interface GetBrandsSearchResultsParam {
  brands: BrandWithOwnerName[];
  search: string;
  selectedRow: DenormalizedBrandLine | null;
}

const isSearchMatchForName = (search: string, name: string) =>
  name.toLowerCase().includes(search.toLowerCase());

const getOwnersMatchingSearch =
  (search: string, selectedRow: DenormalizedBrandLine | null) =>
  (owner: OwnersEntity): boolean => {
    const isOwnerSearchMatch = isSearchMatchForName(search, owner.ownerName);
    const isNotTheSelectedOwner = owner.ownerName !== selectedRow?.ownerName;
    return isOwnerSearchMatch && isNotTheSelectedOwner;
  };

const getBrandLinesMatchingSearch =
  (search: string, selectedRow: DenormalizedBrandLine | null) =>
  (brand: BrandWithOwnerName): boolean => {
    const isBrandSearchMatch = isSearchMatchForName(search, brand.brandName);
    const isNotTheSelectedBrandLine = brand.brandName !== selectedRow?.brandName;
    return isBrandSearchMatch && isNotTheSelectedBrandLine;
  };

export const getOwnersSearchResults = ({
  owners,
  search,
  selectedRow,
}: GetOwnersSearchResultsParam): Suggestion[] => {
  if (!owners.length) {
    return [];
  }

  const filteredOwners = owners.filter(getOwnersMatchingSearch(search, selectedRow));
  return filteredOwners.map(({ ownerName, ownerGUID }) => ({
    ownerName,
    ownerGUID,
  }));
};

export const getBrandsSearchResults = ({
  brands,
  search,
  selectedRow,
}: GetBrandsSearchResultsParam): Suggestion[] => {
  if (!brands.length) {
    return [];
  }

  const filteredBrands = brands.filter(getBrandLinesMatchingSearch(search, selectedRow));
  return filteredBrands.map(({ brandName, brandGUID, ownerGUID, ownerName }) => ({
    brandName,
    brandGUID,
    ownerName,
    ownerGUID,
  }));
};
