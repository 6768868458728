import type ModalType from 'src/components/ModalDialog/ModalType';

export const SET_SHOW_MODAL_DIALOG = 'SET_SHOW_MODAL_DIALOG';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setModalDialogType = (modalType: ModalType, props?: any) => {
  const content = {
    title: undefined,
    body: undefined,
    confirmationCallback: () => null,
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { title, body, confirmationCallback } = props || content;
  return {
    type: SET_SHOW_MODAL_DIALOG,
    modalType,
    properties: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      title,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      body,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      confirmationCallback,
    },
  };
};
