import { HttpStatusCode, OnlineState } from '../types';
import { createObservable } from './observable';
import { doFetchRequest } from './request';

export function createConnectivityDetector() {
  let onlineStatus = OnlineState.Online;
  const onOnlineStatusChange = createObservable<OnlineState>();

  const handleOnlineChange = () => {
    onlineStatus = navigator.onLine ? OnlineState.Online : OnlineState.Offline;
    onOnlineStatusChange.notify(onlineStatus);
  };

  window.addEventListener('offline', handleOnlineChange);
  window.addEventListener('online', handleOnlineChange);

  return {
    get onlineStatus() {
      return onlineStatus;
    },
    get onOnlineStatusChange() {
      return onOnlineStatusChange;
    },
  };
}

/**
 * Checking if application is healthy and available for the request
 */
export async function isApiServiceAvailable(): Promise<boolean> {
  try {
    // The health service endpoint should return a response within 3 seconds;
    // if it does not, there may be an issue with the API.
    const response = await doFetchRequest({ path: 'health.json', timeoutInMilliseconds: 3000 });

    return response.status === HttpStatusCode.SuccessOK;
  } catch (err) {
    return false;
  }
}
