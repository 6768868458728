import { useCallback, useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { Box, List, ListItem, Switch } from '@mui/material';

import { ACTIVE_FEATURE_FLAGS } from 'src/feature-flags';

export const AdminFeatureFlags = () => {
  const [flags, setFlags] = useState<Record<string, boolean>>({});

  const getKnownFeatureFlagsFromLocalStorage = () => {
    return ACTIVE_FEATURE_FLAGS.reduce<Record<string, boolean>>((acc, flag) => {
      acc[flag] = localStorage.getItem(flag) === 'true';
      return acc;
    }, {});
  };

  const syncStateWithLocalStorage = useCallback(() => {
    setFlags(getKnownFeatureFlagsFromLocalStorage());
  }, []);

  const localStorageEventHandler = useCallback(() => {
    syncStateWithLocalStorage();
  }, [syncStateWithLocalStorage]);

  useEffect(() => {
    syncStateWithLocalStorage();
    window.addEventListener('storage', localStorageEventHandler, false);
  }, [localStorageEventHandler, syncStateWithLocalStorage]);

  return (
    <Box sx={{ m: 4 }}>
      <h1>Feature flags</h1>
      <List>
        {Object.keys(flags).map(flag => (
          <ListItem key={flag}>
            <FormLabel>
              {flag}
              <Switch
                checked={Boolean(flags[flag])}
                onChange={event => {
                  const checked = event.target.checked;
                  if (checked) {
                    localStorage.setItem(flag, 'true');
                  } else {
                    localStorage.removeItem(flag);
                  }
                  setFlags({ ...flags, [flag]: checked });
                }}
              />
            </FormLabel>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
