import type React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { COLOURS } from './colours';
import { BASE_FONT_SIZE } from './font';
import { pxToRem } from './utils';

interface ThemeProviderProps {
  children: React.ReactNode;
}

// We could use this to help build the theme https://zenoo.github.io/mui-theme-creator/
const theme = createTheme({
  palette: {
    primary: {
      main: COLOURS['--colour-bg-primary'],
    },
    secondary: {
      main: '#111111',
    },
    background: {
      paper: COLOURS['--colour-bg-surface'],
    },
  },
  typography: {
    htmlFontSize: BASE_FONT_SIZE,
    body1: {
      fontSize: pxToRem('14px'),
    },
    caption: {
      color: COLOURS['--colour-text-subdued'],
      fontSize: pxToRem('14px'),
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            color: 'white',
            '&.Mui-disabled': {
              backgroundColor: COLOURS['--colour-bg-primary-disabled'],
              color: 'white',
            },
          },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          padding: pxToRem('16px'),
          alignItems: 'center',
          '& .MuiIconButton-root': {
            position: 'absolute',
            right: 16,
            top: 8,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: pxToRem('16px'),
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black',
          fontSize: pxToRem('14px'),
          fontWeight: 600,
          marginBottom: pxToRem('4px'),
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: pxToRem('16px'),
            color: '#8F8F8F',
          },
          '& + .MuiAutocomplete-popper': {
            position: 'relative !important',
            transform: 'none !important',
          },
          '& + .MuiAutocomplete-popper .MuiPaper-root': {
            boxShadow: 'none',
            '& .MuiAutocomplete-noOptions': {
              paddingLeft: 0,
            },
          },
        },
      },
    },
  },
});

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
