import { getFeatureFlag } from 'src/feature-flags';

export const FORECAST_VIEW_COLUMN_VISIBILITY = {
  category1Id: false,
  category2Id: false,
  category3Id: false,
  category4Id: false,
  category5Id: false,
  isLocal: false,
  originId: false,
  modelledForecastApproved: Boolean(getFeatureFlag('forecast-plan-b')),
} as const;
