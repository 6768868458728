import type { FC } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { WarningDiv } from './styledComponents';

const CreateBrandLineParentBrandCreateNew: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => (
  <>
    <FormControlLabel
      control={
        <Checkbox
          data-testid="brandCreateConfirmCheckBox"
          onChange={e => {
            dispatchFormAction({
              type: 'update',
              payload: {
                isNewParentBrandConfirmed: e.target.checked,
                brandOwnerDisabled: !e.target.checked,
              },
            });
          }}
        />
      }
      label="I confirm that I want to create a new parent brand."
      labelPlacement="end"
      style={{ textTransform: 'capitalize' }}
    />

    {formState.errorMessages['checkboxConfirmation'] && (
      <WarningDiv>{formState.errorMessages['checkboxConfirmation']}</WarningDiv>
    )}
  </>
);

export default CreateBrandLineParentBrandCreateNew;
