import { isApiServiceAvailable } from 'src/sync/functions';

const isApiAccessible = async () => navigator.onLine && (await isApiServiceAvailable());

const registerServiceWorkerIfApiAccessible = async () => {
  try {
    const registration = await navigator.serviceWorker.register(
      `${window.location.origin}/serviceWorker.js`,
      { scope: '/' }
    );

    if (await isApiAccessible()) {
      await registration.update();

      navigator.serviceWorker.controller?.postMessage('REUPDATE_ASSETS');
    }

    console.log('Service worker is installed.');
  } catch (error) {
    console.error('Service worker registration failed:', error);
  }
};

export const initServiceWorker = () => {
  const isProduction = process.env.REACT_APP_MODE !== 'development';

  if (isProduction && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      void registerServiceWorkerIfApiAccessible();
    });
  }
};
