import { type DependencyList, type EffectCallback, useEffect, useRef } from 'react';

export const useEffectAfterFirstRender = (
  callback: EffectCallback,
  deps?: DependencyList
): void => {
  const isFirstRender = useRef<boolean>(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
