import { Auth } from 'aws-amplify';

import { config } from '../config';

export const handleSignOut = () => {
  localStorage.clear();

  Auth.signOut()
    .then(() => {
      window.location.href = `${config.domain}`;
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    .catch(err => console.error('Error logging out', { err }));
};
