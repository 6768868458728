import type { FormulaFunction } from 'src/components/DataGrid';

export const onPremiseCompoundAnnualGrowthRate: FormulaFunction = (
  startPercentage: unknown,
  startVolume: unknown,
  endPercentage: unknown,
  endVolume: unknown,
  duration: unknown
) => {
  const config = {
    startPercentage: !isNaN(startPercentage as number) ? (startPercentage as number) : 0,
    startVolume: !isNaN(startVolume as number) ? (startVolume as number) : 0,
    endPercentage: !isNaN(endPercentage as number) ? (endPercentage as number) : 0,
    endVolume: !isNaN(endVolume as number) ? (endVolume as number) : 0,
    duration: !isNaN(duration as number) ? (duration as number) : 0,
  };

  const startValue = config.startPercentage * config.startVolume;
  const endValue = config.endPercentage * config.endVolume;

  const exponent: number = 1 / config.duration;
  const cagr: number = (endValue / startValue) ** exponent - 1;

  const percentage: number = cagr;

  if (isNaN(percentage)) return undefined;
  if (!isFinite(percentage)) return undefined;
  if (percentage === 0) return 0.00001;
  if (endPercentage == null || startPercentage == null) return undefined;

  return percentage;
};
