import type { FC } from 'react';
import { FormCheck } from 'react-bootstrap';
import type { UniqueIdentifier } from '@dnd-kit/core';

import type { Column, ColumnVisibilityModel } from 'src/components/DataGrid';
import { SortableMenuItem } from './SortableMenuItem';

interface DragOverlayItemProps {
  id: UniqueIdentifier;
  disableChangingVisibility: boolean;
  columnVisibility: ColumnVisibilityModel | undefined;
  allTableFields: Column[];
}

const DragOverlayItem: FC<DragOverlayItemProps> = ({
  id,
  disableChangingVisibility,
  columnVisibility = {},
  allTableFields,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const item: Column = allTableFields.filter((column: Column) => column.field === id)[0]!;
  return (
    <SortableMenuItem
      key={id}
      id={id}
      disabled={false}
      dataTestid={`column-visibility-menu-item-overlay`}
    >
      <FormCheck
        defaultChecked={
          (columnVisibility[item.field] ?? item.visible !== false) || item.hideable === false
        }
        className="ml-2 my-2"
        disabled={disableChangingVisibility || item.hideable === false}
        label={item.headerName}
        name={item.field}
      />
    </SortableMenuItem>
  );
};

export default DragOverlayItem;
