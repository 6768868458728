import type { Entity } from 'src/types/Entity';

export enum PopupType {
  DeleteConfirmation,
  DeleteWarning,
  AddNewItem,
}

export interface PopupConfiguration<T extends Entity = Entity> {
  selectedItem: T;
  popupType: PopupType;
}
