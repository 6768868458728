import { Suspense } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { ThemeProvider as LegacyStyledThemeProvider } from 'styled-components';

import theme from 'src/css/theme';
import { App } from './App';
import { CommandBarProvider } from './components/CommandBar/CommandBarProvider';
import { Spinner } from './components/Spinner';
import { configureAmplify } from './configureAmplify';
import { configureSentry } from './configureSentry';
import { NavStateContextProvider } from './context/NavStateContext';
import { store } from './store';
import { ThemeProvider } from './theme/ThemeProvider';
import { initServiceWorker } from './utils/serviceWorkerUtils';
import { ErrorPage } from './views/Pages/ErrorPage';

configureAmplify();
configureSentry();
initServiceWorker();

const container = document.getElementById('root');

if (container) {
  const root = ReactDOMClient.createRoot(container);
  root.render(
    <BrowserRouter>
      <CommandBarProvider>
        <ReduxProvider store={store}>
          <ThemeProvider>
            {/* @ts-expect-error: legacy code */}
            <LegacyStyledThemeProvider theme={theme}>
              <ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
                <Suspense fallback={<Spinner />}>
                  <NavStateContextProvider>
                    <App />
                  </NavStateContextProvider>
                </Suspense>
              </ErrorBoundary>
            </LegacyStyledThemeProvider>
          </ThemeProvider>
        </ReduxProvider>
      </CommandBarProvider>
    </BrowserRouter>
  );
}
