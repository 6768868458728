import { type MutableRefObject, useCallback } from 'react';
import type { ColumnResizedEvent, ColumnState, GridApi } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { ColGroupDef, GridOptions, Row } from '../../types';
import { isColGroupDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useColumnWidthsFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  gridApiRef: MutableRefObject<GridApi<R> | undefined>,
  props: Pick<DataGridProps<R>, 'columns' | 'columnWidths' | 'onColumnWidthChange'>
) => {
  const logger = useGridLogger(gridRef, 'useColumnWidthsFeature');

  const { columns, columnWidths = {}, onColumnWidthChange } = props;

  const handleColumnWidthChange = useCallback(
    (e: ColumnResizedEvent) => {
      e.finished &&
        onColumnWidthChange?.(
          e.api
            .getAllGridColumns()
            .reduce((acc, col) => ({ ...acc, [col.getId()]: col.getActualWidth() }), {})
        );
    },
    [onColumnWidthChange]
  );

  useInitialiseState(() => {
    Object.entries(columnWidths).forEach(([field, width]) => {
      let colDef = gridRef.current.columnDefs?.find(
        col => !isColGroupDef<R>(col) && col.field === field
      );

      if (!colDef) {
        const colGroupDef = gridRef.current.columnDefs?.find(
          col => isColGroupDef<R>(col) && col.children.some(col => col.field === field)
        ) as ColGroupDef<R> | undefined;

        colDef = colGroupDef?.children.find(col => col.field === field);
      }

      if (!colDef || isColGroupDef(colDef)) return;

      const column = columns.find(col => col.field === field);

      if (width && column && column.resizable !== false) {
        colDef.width = Number(width);
      }
    });

    gridRef.current.onColumnResized = handleColumnWidthChange;
  });

  useEffectAfterFirstRender(() => {
    logger.log('Updating column widths');

    // Augment passed columnWidths with initialWidth or a default 100.
    // We usually expect column widths passed to be of all columns, but if
    // they don't reset any field which is not specified.
    const widthCriteria: ColumnState[] = columns.map(({ field, initialWidth, resizable }) => {
      const fallback = initialWidth ?? 100;
      const width = resizable !== false ? columnWidths[field] ?? fallback : fallback;
      return { colId: field, width };
    });

    gridApiRef.current?.applyColumnState({
      state: widthCriteria,
    });
  }, [columns, columnWidths]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onColumnWidthChange` is not supported.');
  }, [handleColumnWidthChange]);
};
