import {
  type ChangeEventHandler,
  type FormEventHandler,
  type MutableRefObject,
  useState,
} from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Clear from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import { type unsafe_GridApi, type unsafe_RowNode } from 'src/components/DataGrid';
import { type DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';

interface Props {
  /**  The Grid API ref. */
  dataGridApiRef: MutableRefObject<unsafe_GridApi | undefined>;

  /**  Each searchable field defines which property in the data object to search and the corresponding column ID to highlight. */
  searchableFields: { property: string; column: string }[];
}
interface Result {
  rowIndex: number;
  column: string;
}

export const GridSearch = ({ dataGridApiRef, searchableFields }: Props) => {
  const [results, setResults] = useState<Result[]>([]);
  const [currentResultIndex, setCurrentResultIndex] = useState(-1);

  const showResults = currentResultIndex > -1;

  const handleSearch: ChangeEventHandler<HTMLInputElement> = event => {
    clearHighlight();

    const searchTerm = event.target.value;
    if (!searchTerm) {
      setCurrentResultIndex(-1);
      setResults([]);
      return;
    }

    const matches: Result[] = [];
    dataGridApiRef.current?.forEachNodeAfterFilterAndSort(rowNode => {
      const row = rowNode as unsafe_RowNode<DenormalizedBrandLine>;

      if (row.data) {
        searchableFields.forEach(({ property, column }) => {
          const fieldMatchesSearchTerm = String(row.data?.[property as keyof DenormalizedBrandLine])
            .toLowerCase()
            .includes(searchTerm.toLowerCase());

          if (fieldMatchesSearchTerm) {
            if (rowNode.rowIndex != null) matches.push({ rowIndex: rowNode.rowIndex, column });
          }
        });
      }
    });

    setResults(matches);
    setCurrentResultIndex(0);

    if (matches.length) {
      highlight(matches[0]);
    }
  };

  const handleSubmit: FormEventHandler = event => {
    event.preventDefault();
    clearHighlight();

    if (results.length) {
      if (!showResults) {
        highlight(results[currentResultIndex]);
      } else if (currentResultIndex < results.length) {
        setCurrentResultIndex(currentResultIndex + 1);
        highlight(results[currentResultIndex + 1]);
      }
    }
  };

  const handleReset: FormEventHandler = () => {
    setCurrentResultIndex(-1);
    setResults([]);
    clearHighlight();
  };

  const highlight = (result: Result | undefined) => {
    if (result) {
      dataGridApiRef.current?.ensureIndexVisible(result.rowIndex, 'middle');
      dataGridApiRef.current?.ensureColumnVisible(result.column);
      dataGridApiRef.current?.setGridOption('getRowStyle', params =>
        params.node.rowIndex === result.rowIndex ? { backgroundColor: 'orange' } : {}
      );
      dataGridApiRef.current?.redrawRows({
        rowNodes: [dataGridApiRef.current.getDisplayedRowAtIndex(result.rowIndex)!],
      });

      setTimeout(() => {
        try {
          dataGridApiRef.current?.flashCells({
            rowNodes: [dataGridApiRef.current.getDisplayedRowAtIndex(result.rowIndex)!],
            columns: [result.column],
          });
        } catch (err) {
          // ignore
        }
      }, 100);
    }
  };

  const clearHighlight = () => {
    dataGridApiRef.current?.setGridOption('getRowStyle', () => ({}));
    dataGridApiRef.current?.redrawRows();
  };

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <TextField
        onInput={handleSearch}
        placeholder="Search..."
        size="small"
        sx={{ width: '300px', m: 0, mt: 0.25 }}
        autoComplete="off"
        InputProps={{
          endAdornment: showResults ? (
            <InputAdornment position="end">
              <small>
                {results.length > 0 ? `${currentResultIndex + 1} / ${results.length}` : '0 / 0'}
              </small>
            </InputAdornment>
          ) : null,
        }}
      />
      {showResults && (
        <>
          <IconButton
            aria-label="previous result"
            disabled={currentResultIndex === 0}
            onClick={() => {
              if (currentResultIndex > 0) {
                setCurrentResultIndex(currentResultIndex - 1);
                highlight(results[currentResultIndex - 1]);
              }
            }}
          >
            <ChevronLeft sx={{ transform: 'rotate(90deg)' }} />
          </IconButton>
          <IconButton
            type="submit"
            aria-label="next result"
            disabled={currentResultIndex >= results.length - 1}
          >
            <ChevronLeft sx={{ transform: 'rotate(-90deg)' }} />
          </IconButton>
          <IconButton aria-label="clear search" type="reset">
            <Clear />
          </IconButton>
        </>
      )}
    </form>
  );
};
