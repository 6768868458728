import styled from 'styled-components';

import { SidebarBody, SidebarHeader, SidebarTitle, StyledSidebar } from '../../components/Sidebar';
import type { ValidationCheck } from '../types';
import { ValidationIcon } from './ValidationIcon';

const LIST_ITEM_PADDING = 10;

const StyledListItem = styled.li<{ selected: boolean }>`
  display: flex;
  border-top: 1px solid grey;
  padding: ${LIST_ITEM_PADDING}px;
  margin: 0px;
  color: ${({ theme, selected }) => (selected ? theme.white : 'currentcolor')};
  background-color: ${({ theme, selected }) => (selected ? theme.blue : 'inherit')};
  cursor: pointer;
`;

const StyledLabel = styled.div`
  flex-grow: 1;
`;

const StyledOrderedList = styled.ol`
  list-style-type: none;
  padding: 0px;
  margin: -${LIST_ITEM_PADDING}px -${LIST_ITEM_PADDING}px 0px;
  border-bottom: 1px solid grey;
`;

interface SideBarValidationCheckProps {
  check: ValidationCheck;
  selected?: boolean;
  onClick: () => void;
}

const SidebarValidationCheck = ({
  check: { checkName, checkStatus },
  selected = false,
  onClick,
}: SideBarValidationCheckProps) => {
  return (
    <StyledListItem
      selected={!!selected}
      onClick={onClick}
      data-testid={selected ? 'selected-check' : 'unselected-check'}
    >
      <StyledLabel>{checkName}</StyledLabel>
      <ValidationIcon status={checkStatus} selected={selected} />
    </StyledListItem>
  );
};

interface Props {
  checks: ValidationCheck[];
  selectedCheckSlug: string | undefined;
  onSelect: (checkSlug: string | undefined) => void;
}

export const ChecksSidebar = ({ checks, selectedCheckSlug, onSelect }: Props) => (
  <StyledSidebar sidebarOpen data-testid="checks-sidebar">
    <SidebarHeader sidebarOpen>
      <SidebarTitle>VALIDATE</SidebarTitle>
    </SidebarHeader>
    <SidebarBody sidebarOpen>
      <StyledOrderedList>
        {checks.map(check => (
          <SidebarValidationCheck
            key={check.checkStatusId}
            check={check}
            selected={selectedCheckSlug === check.checkSlug}
            onClick={() => onSelect(check.checkSlug)}
            data-testid="check-item"
          />
        ))}
      </StyledOrderedList>
    </SidebarBody>
  </StyledSidebar>
);
