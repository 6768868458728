import { type HTMLAttributes, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete, {
  type AutocompleteInputChangeReason,
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { RecordDialog } from 'src/components/RecordDialog/RecordDialog';
import type { Market } from 'src/markets';
import { useRecords } from 'src/records/hooks/useRecords';
import { useAppDispatch, useAppSelector } from 'src/store';
import { swapOwnerDialogActions } from 'src/store/features/swapOwnerDialog/slice';
import { useSyncContext } from 'src/sync/hooks';
import type { OwnersEntity } from 'src/types/entity/Owners';
import { CreateRecordDialog, type NewOwner } from './components/CreateOwnerDialog';
import { SearchSuggestion } from './components/SearchSuggestion';
import { SwapConfirmationDialog } from './components/SwapConfirmationDialog';
import type { Suggestion } from './hooks/types';
import { useSuggestionsSearch } from './hooks/useSuggestionsSearch';
import * as styles from './styles/swapOwnerDialog.styles';

const COMPONENT_PROPS: AutocompleteProps<Suggestion, false, false, true>['componentsProps'] = {
  popper: {
    disablePortal: true,
  },
};

const swapOwnerCopy = 'Swap owner';
const searchAriaLabel = 'swap-owner-search';

const hasSelectedSuggestionInSearchResults = (
  filteredSuggestions: Suggestion[],
  selectedSuggestion: Suggestion | null
) => {
  const hasSuggestionInSearchResults = filteredSuggestions.some(
    fs => fs.ownerGUID === selectedSuggestion?.ownerGUID
  );

  return filteredSuggestions.length && hasSuggestionInSearchResults;
};

interface Props {
  market: Market;
}

export const SwapOwnerDialogComponent = ({ market }: Props) => {
  const syncContext = useSyncContext();
  const dispatch = useAppDispatch();
  const [modalState, setModalState] = useState<'swapping' | 'confirming-swap' | 'creating'>(
    'swapping'
  );

  const { brandLine } = useAppSelector(state => state.swapOwnerDialog);
  const ownerRecords = useRecords<OwnersEntity>('owners');

  const [owners, setOwners] = useState<OwnersEntity[]>([]);

  /*
    The useEffect allows the UI to render snappily while setting owners in state.
  */

  useEffect(() => {
    setOwners(ownerRecords);
  }, [ownerRecords]);

  const [selectedSuggestion, setSelectedSuggestion] = useState<Suggestion | null>(null);

  const handleOnSearchComplete = (searchResults: Suggestion[]) => {
    if (!hasSelectedSuggestionInSearchResults(searchResults, selectedSuggestion)) {
      setSelectedSuggestion(null);
    }
  };

  const { filteredSuggestions, noSearchResultsCopy, search, handleSearch } = useSuggestionsSearch({
    brandLine,
    selectedSuggestion,
    owners,
    onSearchComplete: handleOnSearchComplete,
  });

  const handleClose = () => {
    dispatch(swapOwnerDialogActions.close());
  };

  const handleSubmit = () => {
    if (modalState === 'swapping') {
      setModalState('confirming-swap');
      return;
    }

    handleSwap();
  };

  const handleInputChange = (newValue: string, reason: AutocompleteInputChangeReason) => {
    // 'reset' is the reason that comes through when the user selects an autocomplete option.
    if (reason === 'reset') {
      return;
    }

    handleSearch(newValue);
  };

  if (brandLine === null) return null;

  const updateEntity = () => {
    void syncContext.updateEntity('brands', brandLine.brandGUID, {
      ownerGUID: selectedSuggestion!.ownerGUID,
    });
  };

  const handleSwap = () => {
    updateEntity();
    handleClose();
  };

  if (modalState === 'confirming-swap') {
    return (
      <SwapConfirmationDialog
        open
        brandGUID={brandLine.brandGUID}
        onClose={handleClose}
        onCancel={() => setModalState('swapping')}
        onSubmit={handleSwap}
      />
    );
  }

  if (modalState === 'creating') {
    const processNewRecord = (newOwner: NewOwner) => {
      setModalState('swapping');
      setOwners([...owners, newOwner as OwnersEntity]);
      setSelectedSuggestion(newOwner);
      handleSearch(newOwner.ownerName);
    };

    return (
      <CreateRecordDialog
        owners={owners}
        onCancel={() => setModalState('swapping')}
        onSubmit={processNewRecord}
        open
        onClose={handleClose}
        defaultValue={search}
        market={market}
      />
    );
  }

  const renderInput = (inputProps: AutocompleteRenderInputParams) => (
    <TextField
      {...inputProps}
      InputLabelProps={{ className: '' }}
      placeholder={'Search owners...'}
      size="small"
      InputProps={{
        ...inputProps.InputProps,
        startAdornment: <SearchIcon />,
      }}
    />
  );

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, suggestion: Suggestion) => {
    const isChecked = selectedSuggestion?.ownerGUID === suggestion.ownerGUID;

    return (
      <SearchSuggestion
        {...props}
        brandName={suggestion.brandName ?? ''}
        ownerName={suggestion.ownerName}
        checked={isChecked}
        onChange={() => setSelectedSuggestion(suggestion)}
      />
    );
  };

  const isSuggestionsSectionOpen = filteredSuggestions.length > 0 || noSearchResultsCopy.length > 0;

  return (
    <RecordDialog
      disabled={!selectedSuggestion}
      open
      onClose={handleClose}
      onSubmit={handleSubmit}
      submitButtonText={swapOwnerCopy}
      title={swapOwnerCopy}
    >
      <DialogContent dividers>
        <Typography variant="body1" fontWeight={600}>
          {brandLine.ownerName}
        </Typography>

        <FormControl fullWidth>
          <FormLabel htmlFor={searchAriaLabel} sx={styles.findToSwapWith} focused={false}>
            Find an owner to swap with
          </FormLabel>
          <Autocomplete
            componentsProps={COMPONENT_PROPS}
            disableCloseOnSelect
            disablePortal
            filterOptions={opts => opts}
            getOptionLabel={opt => opt.ownerName}
            id={searchAriaLabel}
            inputValue={search}
            noOptionsText={noSearchResultsCopy}
            onInputChange={(_e, newValue, reason) => handleInputChange(newValue, reason)}
            open={isSuggestionsSectionOpen}
            options={filteredSuggestions}
            popupIcon={null}
            renderInput={renderInput}
            renderOption={renderOption}
            onChange={(_, newValue) => setSelectedSuggestion(newValue)}
            value={selectedSuggestion}
          />
        </FormControl>
        {search.length > 0 && filteredSuggestions.length === 0 && (
          <FormControl>
            <Button
              variant="text"
              startIcon={<AddIcon color="disabled" />}
              color="secondary"
              onClick={() => setModalState('creating')}
            >
              Create owner
            </Button>
          </FormControl>
        )}
      </DialogContent>
    </RecordDialog>
  );
};

export const SwapOwnerDialog = (props: Props) => {
  const isOpen = useAppSelector(state => state.swapOwnerDialog.isOpen);
  return isOpen ? <SwapOwnerDialogComponent {...props} /> : null;
};
