import { useEffect, useState } from 'react';

import { useSyncContext } from '../../sync/hooks/useSyncContext';
import type { Entity } from '../../types/Entity';
import type { Market } from '../types';

export function useMarkets() {
  const { getEntities, onMarketDataChanged } = useSyncContext();

  const [markets, setMarkets] = useState<Market[]>(
    () => getEntities('markets') as unknown as Market[]
  );

  useEffect(() => {
    return onMarketDataChanged((data: Entity[]) => {
      setMarkets(data as unknown as Market[]);
    });
  });

  // @TODO - use displayOrder instead
  return markets.sort((a, b) => {
    const nameA = a.marketName.toUpperCase();
    const nameB = b.marketName.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
}
