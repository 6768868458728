import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  FormLabel,
  TextField,
} from '@mui/material';

import { useRecords } from 'src/records/hooks/useRecords';
import { type FlatCategoryItem } from 'src/views/types';

const getParentsForCategory5 = (categories: FlatCategoryItem[], category5Id: number) => {
  const category5 = categories.find(item => item.id === category5Id && item.level === 5);
  const category4 = categories.find(item => item.id === category5?.parentId && item.level === 4);
  const category3 = categories.find(item => item.id === category4?.parentId && item.level === 3);
  const category2 = categories.find(item => item.id === category3?.parentId && item.level === 2);
  const category1 = categories.find(item => item.id === category2?.parentId && item.level === 1);

  return [category1, category2, category3, category4];
};

const SEPERATOR = ' → ';

export default function Category5Autocomplete({
  onChange,
}: {
  onChange: (newValue: number | undefined) => void;
}) {
  const categories = useRecords<FlatCategoryItem>('categories');
  const category5s = categories.filter(item => item.level === 5);

  const options = category5s
    .map(item => ({
      ...item,
      group: getParentsForCategory5(categories, item.id)
        .map(item => item?.name)
        .join(SEPERATOR),
    }))
    .sort((a, b) => a.group.localeCompare(b.group));

  return (
    <Autocomplete
      id="category5-autocomplete"
      options={options}
      groupBy={option => option.group}
      getOptionKey={option => option.id}
      getOptionLabel={option => `${option.group} → ${option.name}`}
      renderOption={(props, option) => {
        return <li {...props}>{option.name}</li>;
      }}
      isOptionEqualToValue={(option, value) => option.level === 5 && option.id === value.id}
      onChange={(_event, value) => onChange(value?.id)}
      renderInput={(inputProps: AutocompleteRenderInputParams) => (
        <>
          <FormLabel htmlFor="category5-autocomplete">Find a Category 5 to change to</FormLabel>
          <TextField
            {...inputProps}
            inputProps={{
              ...inputProps.inputProps,
              value: String(inputProps.inputProps.value).split('→').toReversed()[0],
            }}
            InputLabelProps={{}}
            placeholder="Select a Category 5"
            size="small"
          />
        </>
      )}
    />
  );
}
