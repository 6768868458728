// import { style, styleVariants } from '@vanilla-extract/css';
import { colors as theme } from 'src/css/theme';

export const Animator = {
  backgroundColor: 'rgb(252 252 252)',
  borderRadius: '8px',
  boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)',
  color: 'rgb(28 28 29)',
  maxWidth: '720px',
  overflow: 'hidden',
  width: '100%',
};

export const Header = {
  fontSize: '12px',
  opacity: 0.5,
  padding: '8px 16px',
  textDecoration: 'uppercase',
};

const BaseItem = {
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'system-ui',
  justifyContent: 'space-between',
  padding: '12px 16px',
};

export const OuterContainer = {
  default: {
    ...BaseItem,
    ...{
      background: 'transparent',
    },
  },
  selected: {
    ...BaseItem,
    ...{
      background: '#eee',
    },
  },
};

export const Positioner = {
  zIndex: '1000',
};

export const Search = {
  background: 'none',
  border: 'none',
  fontSize: '18px',
  outline: 'none',
  padding: '20px',
  width: '100%',
};

export const InnerContainer = {
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'center',
  fontSize: 14,
};

export const IconContainer = {
  marginLeft: '0.5rem',
  paddingRight: 0,
};

export const KbdContainer = {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '0.25rem',
};

export const Kbd = {
  padding: '0.25rem 0.375rem',
  background: 'rgba(0 0 0 / .1)',
  borderRadius: '0.25rem',
  fontSize: 14,
  color: theme.darkGrey,
};

export const RightAngle = {
  paddingLeft: '0.625rem',
  paddingRight: '0.625rem',
  fontSize: '1rem',
  fontWeight: 600,
  color: '#858585',
};
