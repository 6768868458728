import type { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildSelectBooleanValueHandler } from 'src/utils/createBrandLineFormHandlers';
import { findBooleanOption } from 'src/utils/createBrandLineFormMappers';
import { Label } from './styledComponents';

const CreateBrandLineCraftSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  if (!formState.category) return null;

  return (
    <Form.Group>
      <Label>Craft</Label>
      <Select
        options={formState.craftOptions.map(_ => ({ label: _.text, value: _.value.toString() }))}
        value={{
          label: findBooleanOption(formState.craftOptions, formState.craft),
          value: formState.craft.toString(),
        }}
        onChange={buildSelectBooleanValueHandler(dispatchFormAction, 'craft')}
        isDisabled={formState.attributesDisabled}
        data-testid="create_brand_line_craft_dropdown"
        id="create_brand_line_craft_dropdown"
      />
    </Form.Group>
  );
};

export default CreateBrandLineCraftSection;
