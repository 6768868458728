import type { unsafe_RowNode } from 'src/components/DataGrid';
import { BrandLineTypes } from '../../../shared/types';
import type { Entity } from '../../../types/Entity';

export const sortUnknownRows = (nodes: unsafe_RowNode<Entity>[]) => {
  let nextInsertPos = 0;

  for (let i = 0; i < nodes.length; i++) {
    let brandLineType: BrandLineTypes = BrandLineTypes.BRANDED;

    const groupNodes = nodes[i];

    if (groupNodes) {
      if (groupNodes.data !== undefined) {
        brandLineType = groupNodes.data['brandLineTypeId'] as BrandLineTypes;
      }
    }

    if (brandLineType === BrandLineTypes.BRANDED) {
      const node = nodes.splice(i, 1)[0];
      if (node) {
        nodes.splice(nextInsertPos, 0, node);
        nextInsertPos++;
      }
    }
  }
};
