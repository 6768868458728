import { FORECASTING_YEARS_MODIFIER } from 'src/constants';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { getArrayFromRange } from 'src/utils/array';
import { BrandLineTypes } from '../../../shared';

interface CalculatingRows {
  brandSales: DenormalizedBrandSales[];
  forecastBrandSale: DenormalizedBrandSales;
}

export type Volume = DenormalizedBrandSales['volume'];

const getMatchingNonForecastBrandSales = ({ brandSales, forecastBrandSale }: CalculatingRows) => {
  return brandSales.filter(brandSale => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (brandSale.brandLineTypeId === BrandLineTypes.FORECAST) {
      return false;
    }

    const isCategory5IdMatch = forecastBrandSale.category5Id === brandSale.category5Id;
    const isPriceBandIdMatch = forecastBrandSale.priceBandId === brandSale.priceBandId;
    const forecastKind = forecastBrandSale.forecastByOrigin ? 'originId' : 'isLocal';
    const isOriginOrLocal = forecastBrandSale[forecastKind] === brandSale[forecastKind];

    return isCategory5IdMatch && isPriceBandIdMatch && isOriginOrLocal;
  });
};

export const calculateForecasts = (
  brandSales: DenormalizedBrandSales[]
): DenormalizedBrandSales[] => {
  return brandSales.reduce<DenormalizedBrandSales[]>((forecastBrandSales, brandSale) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (brandSale.brandLineTypeId !== BrandLineTypes.FORECAST) {
      return forecastBrandSales;
    }
    const forecastBrandSale = brandSale;

    const nonForecastBrandSales = getMatchingNonForecastBrandSales({
      brandSales,
      forecastBrandSale,
    });

    const aggregatedHistoricalVolumes = nonForecastBrandSales.reduce<Volume>((acc, sale) => {
      const updatedAcc = { ...acc };
      Object.keys(sale.volume).forEach(key => {
        const year = parseInt(key);
        const volumeValue = sale.volume[`${year}`];

        if (volumeValue != null && !isNaN(volumeValue)) {
          if (updatedAcc[`${year}`] != null) {
            updatedAcc[`${year}`] += volumeValue;
          } else {
            updatedAcc[`${year}`] = volumeValue;
          }
        }
      });
      return updatedAcc;
    }, {});

    return forecastBrandSales.concat({
      ...forecastBrandSale,
      volume: { ...aggregatedHistoricalVolumes, ...forecastBrandSale.volume },
    });
  }, []);
};

export interface ForecastChartValue {
  year: number;
  value: number;
}

interface ExponentialForecastCalculation {
  growthRate: string;
  currentYear: number;
  selectedNode: DenormalizedBrandSales;
}

export const roundGeneratedForecast = (value: number) => {
  if (value < 10) {
    return parseFloat(value.toFixed(2));
  } else if (value >= 10 && value <= 1000) {
    return parseFloat(value.toFixed(1));
  }

  return parseFloat(value.toFixed(0));
};

export const calculateExponentialForecast = ({
  growthRate,
  currentYear,
  selectedNode,
}: ExponentialForecastCalculation): ForecastChartValue[] => {
  const startValue = selectedNode.volume[`${currentYear}`];
  const growthCalculation = 1 + Number(growthRate) / 100;

  if (!startValue) {
    return [];
  }

  return getArrayFromRange(1, FORECASTING_YEARS_MODIFIER).map(
    (elementValue): ForecastChartValue => {
      return {
        year: currentYear + elementValue,
        value: roundGeneratedForecast(startValue * growthCalculation ** elementValue),
      };
    }
  );
};

interface StraightLineForecastCalculation {
  finalValue: string | undefined;
  currentYear: number;
  selectedNode: DenormalizedBrandSales;
}

export const calculateStraightLineForecast = ({
  finalValue,
  currentYear,
  selectedNode,
}: StraightLineForecastCalculation): ForecastChartValue[] => {
  const figuresToReturn: ForecastChartValue[] = [];

  const startValue = selectedNode.volume[`${currentYear}`];
  const historicValue = selectedNode.volume[`${currentYear - 4}`];

  if (startValue == null || historicValue == null) return [];

  const historicTime = 4;

  if (finalValue) {
    const step = (Number(finalValue) - startValue) / FORECASTING_YEARS_MODIFIER;
    for (let i = 1; i <= FORECASTING_YEARS_MODIFIER; i += 1) {
      figuresToReturn.push({
        year: currentYear + i,
        value: roundGeneratedForecast(startValue + step * i),
      });
    }
  } else {
    const step = (startValue - historicValue) / historicTime;
    for (let i = 1; i <= FORECASTING_YEARS_MODIFIER; i += 1) {
      figuresToReturn.push({
        year: currentYear + i,
        value: roundGeneratedForecast(startValue + step * i),
      });
    }
  }

  return figuresToReturn;
};

export type CalculationType = 'Exponential' | 'Straight-Line';

interface AutomaticForecastsCalculation {
  calculationType: CalculationType;
  finalValue: string | undefined;
  growthRate: string;
  currentYear: number;
  selectedNode: DenormalizedBrandSales | undefined;
}

export const calculateAutomaticForecasts = ({
  calculationType,
  finalValue,
  growthRate,
  currentYear,
  selectedNode,
}: AutomaticForecastsCalculation): ForecastChartValue[] => {
  if (selectedNode == null) {
    return [];
  }

  if (calculationType === 'Exponential') {
    return calculateExponentialForecast({ growthRate, currentYear, selectedNode });
  }

  // TODO: VERIFY IF "calculationType" CAN BE ANY VALUE OTHER THAN "Exponential" OR "Straight-Line"
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (calculationType === 'Straight-Line') {
    return calculateStraightLineForecast({ finalValue, currentYear, selectedNode });
  }

  return [];
};
