import type * as CSS from 'csstype';

import { smSizeWindow } from '../utils/appUtils';
import { colors as theme } from './theme';

interface Option {
  isDisabled: boolean;
  isSelected: boolean;
  isFocused: boolean;
}

interface BaseDropdownStyles {
  container: (base: CSS.Properties) => CSS.Properties;
  option: (base: CSS.Properties, { isDisabled }: Pick<Option, 'isDisabled'>) => CSS.Properties;
  menu: (base: CSS.Properties) => CSS.Properties;
  menuList: (base: CSS.Properties) => CSS.Properties;
  group: (base: CSS.Properties) => CSS.Properties;
  groupHeading: (base: CSS.Properties) => CSS.Properties;
}

interface NavDropdownStyles extends Omit<BaseDropdownStyles, 'option' | 'groupHeading'> {
  control: (base: CSS.Properties, { isDisabled }: Pick<Option, 'isDisabled'>) => CSS.Properties;
  option: (
    base: CSS.Properties,
    { isSelected, isFocused }: Omit<Option, 'isDisabled'>
  ) => CSS.Properties;
  singleValue: (base: CSS.Properties) => CSS.Properties;
  dropdownIndicator: (base: CSS.Properties) => CSS.Properties;
  placeholder: (base: CSS.Properties) => CSS.Properties;
  input: (base: CSS.Properties) => CSS.Properties;
}

export const baseDropdownStyles: BaseDropdownStyles = {
  container: base => ({
    ...base,
    width: '100%',
  }),
  option: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? 'grey' : 'black',
    textAlign: 'left',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
  }),
  menu: base => ({ ...base, zIndex: 1000 }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  group: base => ({
    ...base,
    padding: 0,
  }),
  groupHeading: base => ({
    ...base,
    margin: 0,
  }),
};

export const navDropdownStyles: NavDropdownStyles = {
  container: base => ({
    ...base,
    outline: 'none',
    minWidth: '170px',
    padding: smSizeWindow ? '0.5rem 0' : undefined,
    marginRight: '1rem',
  }),
  control: (base, { isDisabled }) => ({
    ...base,
    border: 'none',
    opacity: isDisabled ? '.75' : 1.0,
    backgroundColor: 'grey',
    marginRight: '4px',
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    textAlign: 'left',
    backgroundColor: isSelected ? theme.darkGrey : isFocused ? theme.midGrey : theme.white,
  }),
  menu: base => base,
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  group: base => ({
    ...base,
    padding: 0,
  }),
  singleValue: base => ({
    ...base,
    color: theme.white,
  }),
  dropdownIndicator: base => ({
    ...base,
    color: theme.white,
  }),
  placeholder: base => ({
    ...base,
    color: theme.white,
  }),
  input: base => ({
    ...base,
    color: theme.white,
  }),
};
