import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore, type UnknownAction } from '@reduxjs/toolkit';

import { authReducer, authSliceName } from './features/auth/slice';
import brandLineAddReducer, { type BrandLineAddState } from './features/brandLineAdd/reducer';
import { checksReducer, checksSliceName } from './features/checks/slice';
import {
  createBrandLineDialogReducer,
  createBrandLineDialogSliceName,
} from './features/createBrandLineDialog/slice';
import modalDialogReducer from './features/modalDialog/reducer';
import {
  renameRecordDialogReducer,
  renameRecordSliceName,
} from './features/renameRecordDialog/slice';
import {
  reviewBrandLineDialogReducer,
  reviewBrandLineDialogSliceName,
} from './features/reviewBrandLineDialog/slice';
import { swapBrandDialogReducer, swapBrandDialogSliceName } from './features/swapBrandDialog/slice';
import {
  swapBrandLineDialogReducer,
  swapBrandLineDialogSliceName,
} from './features/swapBrandLineDialog/slice';
import { swapCategory5DialogReducer } from './features/swapCategory5Dialog/slice';
import {
  swapDistributorDialogReducer,
  swapDistributorDialogSliceName,
} from './features/swapDistributorDialog/slice';
import { swapOwnerDialogReducer, swapOwnerDialogSliceName } from './features/swapOwnerDialog/slice';

export const store = configureStore({
  reducer: {
    [authSliceName]: authReducer,
    swapCategory5Dialog: swapCategory5DialogReducer,
    [checksSliceName]: checksReducer,
    [createBrandLineDialogSliceName]: createBrandLineDialogReducer,
    [renameRecordSliceName]: renameRecordDialogReducer,
    [reviewBrandLineDialogSliceName]: reviewBrandLineDialogReducer,
    [swapBrandDialogSliceName]: swapBrandDialogReducer,
    [swapBrandLineDialogSliceName]: swapBrandLineDialogReducer,
    [swapDistributorDialogSliceName]: swapDistributorDialogReducer,
    [swapOwnerDialogSliceName]: swapOwnerDialogReducer,
    brandLineAdd: brandLineAddReducer as (
      state: BrandLineAddState | undefined,
      action: UnknownAction
    ) => BrandLineAddState,
    modalDialog: modalDialogReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false, // todo: remove this once we remove mutatble state in the app
    }),
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default configureStore;
