// noinspection JSDeprecatedSymbols
import {
  type ChangeEvent,
  createRef,
  type KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { useClickAway } from 'use-click-away';

import type { FilterResults, SelectItem } from '../../utils/fiterSelectUtils';
import { filterOptions } from '../../utils/fiterSelectUtils';

const StyledContainer = styled.div`
  position: relative;
`;

const StyledList = styled.ul`
  position: absolute;
  display: block;
  z-index: 1;
  width: 100%;
  list-style: none;
  background-color: white;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding: 2px 0;
  max-height: 150px;
  overflow-y: auto;
`;

interface SelectableItemProps {
  selected?: boolean;
  highlighted?: boolean;
}

const SelectableItem = styled.li<SelectableItemProps>`
  margin: 0;
  padding: 8px 12px;
  color: ${(props: SelectableItemProps) => (props.selected ? 'white' : 'black')};
  background-color: ${(props: SelectableItemProps) => {
    if (props.selected) return '#1f84ff';
    if (props.highlighted) return '#deebff';
    return 'white';
  }};
`;

const DisabledItem = styled.li`
  margin: 0;
  padding: 5px 12px;
`;

const initialResults: FilterResults = {
  filteredOptions: [],
  exact: undefined,
  partial: false,
};

interface FilterSelectProps {
  id: string;
  options: SelectItem[];
  // eslint-disable-next-line react/require-default-props
  selected?: SelectItem;
  onSelect: (selected: SelectItem) => void;
  // eslint-disable-next-line react/require-default-props
  maxLength?: number;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  sortValues?: boolean;
  // eslint-disable-next-line react/require-default-props
  dataTestId?: string;
  createNew?: boolean;
  hideSearchText?: boolean;
}

const FilterSelect = ({
  id = 'filter-select',
  options,
  selected,
  onSelect,
  maxLength = 500,
  disabled,
  sortValues,
  dataTestId,
  createNew,
  hideSearchText,
}: FilterSelectProps) => {
  const [value, setValue] = useState(selected?.label ?? '');

  const [filterResults, setFilterResults] = useState<FilterResults>(initialResults);
  const [listVisible, setListVisible] = useState(false);
  const [highlighted, setHighlighted] = useState<SelectItem | undefined>();

  const triggerSelectChange = (v: string) => {
    const result = filterOptions(options, v.trim().replace(/\s+/g, ' '), sortValues);
    const newValue = result.filteredOptions[0];
    if (JSON.stringify(newValue) !== JSON.stringify(selected)) {
      setValue(v);
      // @ts-expect-error: legacy code
      onSelect(newValue);
    }
  };

  useEffect(() => {
    setValue(selected?.label ?? '');
    setHighlighted(selected);
  }, [selected]);

  const containerRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refs: any = {};

  const handleSelect = (item: SelectItem) => () => {
    triggerSelectChange(item.label);
    setListVisible(false);
  };

  const handleBlur = () => {
    setListVisible(false);
    if (highlighted) {
      handleSelect(highlighted)();
    }
  };

  // @ts-expect-error: legacy code
  // eslint-disable-next-line consistent-return
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    newValue = newValue.trimLeft();

    if (!/^\s?[a-zA-Z0-9-()'/%&._\b?:!+,#$ ]*$/.test(newValue.trim())) {
      return false;
    }

    setValue(newValue);

    const result = filterOptions(options, newValue.trim().replace(/\s+/g, ' '), sortValues);
    setFilterResults(result);

    if (!selected && result.filteredOptions.length > 0) setHighlighted(result.filteredOptions[0]);
    if (
      selected &&
      !result.filteredOptions.some((item: SelectItem) => item.value === selected.value)
    ) {
      // the selected item is no long in the filtered list
      setHighlighted(result.filteredOptions[0]);
    }
  };

  const getItemIndex = (itemToFind: SelectItem) =>
    filterResults.filteredOptions.findIndex((item: SelectItem) => item.value === itemToFind.value);

  const nextHighlight = () => {
    if (!highlighted) setHighlighted(filterResults.filteredOptions[0]);
    else {
      const index = getItemIndex(highlighted);
      if (index < filterResults.filteredOptions.length - 1) {
        const item = filterResults.filteredOptions[index + 1];
        // @ts-expect-error: legacy code
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const ref = refs[item.value];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        ref.current.scrollIntoView(false);
        setHighlighted(item);
      }
    }
  };

  const previousHighlight = () => {
    if (!highlighted) setHighlighted(filterResults.filteredOptions[0]);
    else {
      const index = getItemIndex(highlighted);
      if (index > 0) {
        const item = filterResults.filteredOptions[index - 1];
        // @ts-expect-error: legacy code
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const ref = refs[item.value];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        ref.current.scrollIntoView(true);
        setHighlighted(item);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  useClickAway(containerRef, handleBlur);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 27) {
      // Esc
      setListVisible(false);
      if (selected) {
        triggerSelectChange(selected.label);
      } else {
        triggerSelectChange('');
      }
    } else if (e.keyCode === 13) {
      // Enter
      handleBlur();
    } else if (e.keyCode === 40) {
      // Down arrow
      e.preventDefault();
      nextHighlight();
    } else if (e.keyCode === 38) {
      // Up arrow
      e.preventDefault();
      previousHighlight();
    } else if (e.keyCode === 9) {
      // tab
      handleBlur();
    } else {
      setListVisible(true);
    }
  };

  const handleHover = (item: SelectItem) => () => {
    setHighlighted(item);
  };

  const showList = listVisible && value && value.length >= 2;

  // @TODO : Need to incorportate this for when editing
  // eslint-disable-next-line no-empty
  if (hideSearchText) {
  }

  return (
    <StyledContainer ref={containerRef}>
      {/* @ts-expect-error: legacy code */}
      <Form.Control
        id={id}
        onFocus={() => setListVisible(true)}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        data-testid={dataTestId}
      />
      {showList && (
        <StyledList>
          {filterResults.filteredOptions.map((item: SelectItem) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            refs[item.value] = createRef();
            return (
              <SelectableItem
                key={item.value}
                onClick={handleSelect(item)}
                selected={item.value === selected?.value}
                highlighted={item.value === highlighted?.value}
                onMouseEnter={handleHover(item)}
                /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
                ref={refs[item.value]}
              >
                {createNew && <>{item.isNew && <>Create: </>}</>}
                {item.label}
              </SelectableItem>
            );
          })}
          {filterResults.partial && <DisabledItem key="more">...</DisabledItem>}
        </StyledList>
      )}
    </StyledContainer>
  );
};

export default FilterSelect;
