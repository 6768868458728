import type { SubcategoriesTreeOption } from 'src/components/BrandSales/AddBrandLine/utils/stepOne';
import type { Entity } from 'src/types/Entity';

const isLevel1CategoryChildless = (
  category: SubcategoriesTreeOption,
  categoriesNotInGrid: SubcategoriesTreeOption[]
) => {
  return !categoriesNotInGrid.some(cat => cat.parentId === category.id);
};

const getCategory2WithLegacyIdValueAsId = (
  category: SubcategoriesTreeOption
): SubcategoriesTreeOption => ({
  ...category,
  value: category.id as number,
});

const getCategoriesWithoutChildlessLevel1Categories = (
  categoriesNotInGrid: SubcategoriesTreeOption[]
) => {
  return categoriesNotInGrid.reduce<SubcategoriesTreeOption[]>((categories, category) => {
    const isCategoryLevel1 = category.level === 1;

    if (isCategoryLevel1) {
      const isChildless = isLevel1CategoryChildless(category, categoriesNotInGrid);
      return isChildless ? categories : categories.concat(category);
    }

    return categories.concat(getCategory2WithLegacyIdValueAsId(category));
  }, []);
};

const isCategoryNotInGrid = (gridCategories: Entity[]) => (category: SubcategoriesTreeOption) => {
  return !gridCategories.some(gridCategory => {
    const isLevel2 = category.level === 2;
    const isCategoryInGrid = category.id === gridCategory['category2Id'];
    return isCategoryInGrid && isLevel2;
  });
};

export const getCategoriesNotInGrid = (
  categories: SubcategoriesTreeOption[],
  gridCategories: Entity[]
): SubcategoriesTreeOption[] => {
  const categoriesNotInGrid = categories.filter(isCategoryNotInGrid(gridCategories));

  return getCategoriesWithoutChildlessLevel1Categories(categoriesNotInGrid);
};

export interface GroupedOption {
  label: string;
  options: SubcategoriesTreeOption[];
}

export const getGroupedCategories = (categories: SubcategoriesTreeOption[]): GroupedOption[] => {
  return categories.reduce<GroupedOption[]>((groups, category) => {
    if (category.level === 1) {
      const options = categories.filter(c => c.parentId === category.id);
      return groups.concat({ label: category.label, options });
    }

    return groups;
  }, []);
};
