type Args = [formState: Record<string, unknown>, fieldname: string, propname: string];

const getFormFieldProp = (...args: Args): string => {
  const [formState, fieldname, propname] = args;
  const { [fieldname]: formField } = formState;

  if (typeof formField === 'undefined') return '';
  if (typeof formField === 'string') return formField;
  if (typeof formField === 'number') return String(formField);

  if (typeof formField === 'object' && formField !== null) {
    const { [propname]: propValue } = formField as Record<string, unknown>;

    if (typeof propValue === 'undefined') return '';
    if (typeof propValue === 'string') return propValue;
    if (typeof propValue === 'number') return String(propValue);
  }

  return '';
};

export default getFormFieldProp;
