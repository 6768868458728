import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import { type ModalState, ModalStateChange } from '../../utils/addBrandLineUtils';
import AddBrandLine from '../BrandSales/AddBrandLine';

interface IModalState {
  brandLineAdd: {
    modalState: ModalState;
  };
}

const AddBrandLineModal = () => {
  const addModalVisible = useSelector((state: IModalState) =>
    pathOr(false, [ModalStateChange.BrandLineAdd, ModalStateChange.ModalVisible], state)
  );

  return <>{addModalVisible && <AddBrandLine />}</>;
};

export default AddBrandLineModal;
