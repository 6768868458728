import type { DropdownOption, DropdownOptions } from './types';

export const getSelectValue = (
  selected: string | number | undefined,
  options: DropdownOptions | undefined
): DropdownOption | undefined => {
  if (!selected || !options) {
    return;
  }

  return options.reduce<DropdownOption | undefined>((selectValue, option) => {
    const { options: optionsGroup } = option;
    const selectedOption = optionsGroup.find(
      optionGroup => optionGroup.value.toString() === selected
    );

    if (selectedOption) {
      return selectedOption;
    }

    return selectValue;
  }, undefined);
};

export const getSelectValueFromGroupedOptions = (
  selectedValue: string | number | undefined,
  options: DropdownOptions
) => getSelectValue(selectedValue?.toString(), options);
