import type { unsafe_CellRange } from 'src/components/DataGrid';

/*
  We're not catering for more than one range, so if there happens
  to be multiple (suppressMultiRangeSelection hasn't been set to
  false), only the first range will be processed.
*/
export const isRange = (ranges: unsafe_CellRange[] | null): boolean => {
  if (!ranges) return false;
  const startRow = ranges[0]?.startRow?.rowIndex;
  const endRow = ranges[0]?.endRow?.rowIndex;
  const numCols = ranges[0]?.columns.length;
  if (startRow === undefined || endRow === undefined || numCols === undefined) return false;
  if (startRow === endRow && numCols === 1) return false;
  return true;
};
