import type { FormulaFunction } from 'src/components/DataGrid';

export const onPremiseRelativeChange: FormulaFunction = (
  startPercentage: unknown,
  startVolume: unknown,
  endPercentage: unknown,
  endVolume: unknown
) => {
  if ((!startPercentage && startPercentage !== 0) || (!endPercentage && endPercentage !== 0))
    return undefined;

  const config = {
    startPercentage: !isNaN(startPercentage as number) ? (startPercentage as number) : 0,
    startVolume: !isNaN(startVolume as number) ? (startVolume as number) : 0,
    endPercentage: !isNaN(endPercentage as number) ? (endPercentage as number) : 0,
    endVolume: !isNaN(endVolume as number) ? (endVolume as number) : 0,
  };

  const startValue = config.startPercentage * config.startVolume;
  const endValue = config.endPercentage * config.endVolume;

  const relativeChange = (endValue - startValue) / startValue;

  if (isNaN(relativeChange)) return undefined;
  if (!isFinite(relativeChange)) return undefined;
  if (relativeChange === 0) return 0.00001;

  return relativeChange;
};
