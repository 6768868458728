import { type IAggFuncParams } from 'src/components/DataGrid';
import { type Volume } from 'src/types/Entity';

export interface OnPremiseSalesData {
  onPremisePercent?: Record<`${number}`, number>;
  allChannelsVolume?: Volume;
}

export const getOnPremiseVolumeTotals = (
  params: IAggFuncParams<OnPremiseSalesData, number | undefined>
) => {
  const [, yearAsString] = params.column.getColId().split('.');
  const year = Number(yearAsString);

  if (year) {
    const isLeafGroup = params.rowNode.leafGroup;

    const onPremiseVolumeTotal = params.rowNode.childrenAfterGroup?.reduce<number | null>(
      (total, { aggData: aggregateData, data }) => {
        if (!isLeafGroup && !aggregateData) return null;

        const aggData = aggregateData as Record<string, number | null>;

        const onPremisePercentForYear = isLeafGroup
          ? data?.onPremisePercent?.[`${year}`]
          : aggData[`onPremisePercent.${year}`];

        const allChannelsVolumeForYear = isLeafGroup
          ? data?.allChannelsVolume?.[`${year}`]
          : aggData[`allChannelsVolume.${year}`];

        if (onPremisePercentForYear == null || allChannelsVolumeForYear == null) return total;
        const onPremiseVolume = onPremisePercentForYear * allChannelsVolumeForYear;
        if (total == null) return onPremiseVolume;
        return total + onPremiseVolume;
      },
      null
    );

    if (onPremiseVolumeTotal == null) return null;

    return onPremiseVolumeTotal;
  }

  return null;
};

const getAllChannelsVolumeTotals = (
  params: IAggFuncParams<OnPremiseSalesData, number | undefined>
) => {
  const [, yearAsString] = params.column.getColId().split('.');
  const year = Number(yearAsString);

  if (year) {
    const isLeafGroup = params.rowNode.leafGroup;

    const allChannelsVolumeTotal = params.rowNode.childrenAfterGroup?.reduce<number | null>(
      (total, { aggData: aggregateData, data }) => {
        if (!isLeafGroup && !aggregateData) return null;
        const aggData = aggregateData as Record<string, number | null>;

        const onPremisePercentForYear = isLeafGroup
          ? data?.onPremisePercent?.[`${year}`]
          : aggData[`onPremisePercent.${year}`];

        const allChannelsVolumeForYear = isLeafGroup
          ? data?.allChannelsVolume?.[`${year}`]
          : aggData[`allChannelsVolume.${year}`];

        if (onPremisePercentForYear == null || allChannelsVolumeForYear == null) return total;
        if (total == null) return allChannelsVolumeForYear;
        return total + allChannelsVolumeForYear;
      },
      null
    );

    if (allChannelsVolumeTotal == null) return null;
    if (allChannelsVolumeTotal === 0) return 0;

    return allChannelsVolumeTotal;
  }

  return null;
};

export const getOnPremiseWeightedAverage = (
  params: IAggFuncParams<OnPremiseSalesData, number | undefined>
) => {
  const [, yearAsString] = params.column.getColId().split('.');
  const year = Number(yearAsString);

  if (year) {
    const onPremiseVolumeTotal = getOnPremiseVolumeTotals(params);
    const allChannelsVolumeTotal = getAllChannelsVolumeTotals(params);

    if (onPremiseVolumeTotal == null || allChannelsVolumeTotal == null) return null;
    if (onPremiseVolumeTotal === 0 || allChannelsVolumeTotal === 0) return 0;

    return onPremiseVolumeTotal / allChannelsVolumeTotal;
  }

  return null;
};
