import type { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildNumericValuesHandler } from 'src/utils/createBrandLineFormHandlers';
import { mapNumericsToValues, mapNumericToValue } from 'src/utils/createBrandLineFormMappers';
import { Label } from './styledComponents';

const CreateBrandLineAlcoholicStrengthSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  if (!formState.category) return null;
  if (!formState.alcoholicStrengthVisible) return null;
  return (
    <Form.Group>
      <Label>Alcoholic Strength</Label>
      <Select
        options={mapNumericsToValues(formState.alcoholicStrengthOptions)}
        value={mapNumericToValue(formState.alcoholicStrength)}
        onChange={buildNumericValuesHandler(dispatchFormAction, 'alcoholicStrength')}
        isDisabled={formState.alcoholicStrengthDisabled || formState.attributesDisabled}
        data-testid="create_brand_line_alcoholic_strength_dropdown"
        id="create_brand_line_alcoholic_strength_dropdown"
      />
    </Form.Group>
  );
};

export default CreateBrandLineAlcoholicStrengthSection;
