import type { FC } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import type { CreateBrandLineSectionProps } from 'src/types/CreateBrandLineTypes';
import { buildSelectBooleanValueHandler } from 'src/utils/createBrandLineFormHandlers';
import { findBooleanOption } from 'src/utils/createBrandLineFormMappers';
import { Label } from './styledComponents';

const CreateBrandLineBleSection: FC<CreateBrandLineSectionProps> = ({
  dispatchFormAction,
  formState,
}) => {
  if (!formState.category) return null;

  return (
    <Form.Group>
      <Label>Brand Line Extension</Label>
      <Select
        options={formState.bleOptions.map(_ => ({ label: _.text, value: _.value.toString() }))}
        value={{
          label: findBooleanOption(formState.bleOptions, formState.isBLE),
          value: formState.isBLE.toString(),
        }}
        onChange={buildSelectBooleanValueHandler(dispatchFormAction, 'isBLE')}
        isDisabled={formState.attributesDisabled}
        data-testid="create_brand_line_ble_dropdown"
        id="create_brand_line_ble_dropdown"
      />
    </Form.Group>
  );
};

export default CreateBrandLineBleSection;
