import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useRecords } from 'src/records/hooks/useRecords';
import type { DenormalizedBrandLine } from 'src/records/types/DenormalizedBrandLine';
import type { RecordKind } from 'src/store/features/types/BrandLineDialog';

interface Props {
  open: boolean;
  recordKind: RecordKind;
  GUID: string;
  onCancel: () => void;
  onSubmit: () => void;
}

export const RenameConfirmationDialog = ({ GUID, open, recordKind, onCancel, onSubmit }: Props) => {
  const brandLineRecords = useRecords<DenormalizedBrandLine>('brandLines');

  const getNumberOfAffectedBrandLines = () => {
    if (recordKind === 'brand') {
      return brandLineRecords.filter(brandLine => brandLine.brandGUID === GUID).length;
    }

    return brandLineRecords.filter(brandLine => brandLine.ownerGUID === GUID).length;
  };

  const getContentText = () => {
    // -1 to adjust for the brand line CURRENTLY being renamed.
    const numberOfAffectedBrandLines = getNumberOfAffectedBrandLines() - 1;
    const affectedText = `This will affect ${numberOfAffectedBrandLines} other `;
    return affectedText.concat(numberOfAffectedBrandLines === 1 ? 'brand line' : 'brand lines');
  };

  return (
    <Dialog fullWidth open={open} onClose={onCancel}>
      <DialogTitle fontWeight={600}>Rename {recordKind}?</DialogTitle>
      <DialogContent>
        <DialogContentText>{getContentText()}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Rename {recordKind}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
