import * as Sentry from '@sentry/react';

const getCognitoEmailAddress = () => {
  const context: Record<string, unknown> = {};

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key?.startsWith('CognitoIdentityServiceProvider') && key.endsWith('LastAuthUser')) {
      context['cognitoEmail'] = localStorage.getItem(key)!;
      break;
    }
  }

  return context;
};

export const logError = (error: string, context?: Record<string, unknown>) => {
  Sentry.setContext('error_details', {
    ...context,
    ...getCognitoEmailAddress(),
  });

  console.error(error);
  console.info(error, context);
};

export const logSyncingError = (
  error: string,
  transactionId: string,
  context: Record<string, unknown>
) => {
  logError(`${error}-(TID: ${transactionId})`, {
    ...context,
    transactionId,
    syncingException: true,
  });
};

export const logOutCollectorLocalStorageItems = () => {
  const interestingKeys = [
    'GridViewState',
    'GridViewLevelState',
    'sync-context-status',
    'cached-market-ids',
    'lastVisitedMarket',
  ];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key && interestingKeys.some(interestingKey => key.includes(interestingKey))) {
      console.log(key, localStorage.getItem(key));
    }
  }
};
