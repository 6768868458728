import { type EffectCallback, useRef } from 'react';

export const useInitialiseState = (callback: EffectCallback): void => {
  const isInitialized = useRef<boolean>(false);

  if (!isInitialized.current) {
    callback();
    isInitialized.current = true;
  }
};
