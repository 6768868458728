import { type FC, useContext, useEffect, useMemo } from 'react';
import { Navbar } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Spinner } from 'src/components/Spinner';
import { TablesNavigation } from 'src/components/TablesNavigation';
import NavStateContext from 'src/context/NavStateContext';
// @TODO - remove these legacy imports
import { StyledNavbar } from 'src/css/styled-components';
import { useMarket } from 'src/markets';
import { useTables } from 'src/views/hooks';

export const TablePage: FC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { market } = useMarket();
  const tables = useTables(market);

  const { tableSlug } = useParams<'tableSlug'>();
  const { mostRecentSubPathsForTables, setMostRecentSubPathsForTables } =
    useContext(NavStateContext);

  const table = useMemo(() => tables.find(table => table.slug === tableSlug), [tables, tableSlug]);

  useEffect(() => {
    if (!tableSlug) {
      if (!tables[0]) {
        throw new Error('No tables can be found.');
      }

      const marketBase = `/${market.marketSlug}`;
      if (location.pathname === marketBase) {
        navigate(`/${market.marketSlug}/${mostRecentSubPathsForTables[marketBase] ?? ''}`, {
          replace: true,
        });
        return;
      }

      const base = `/${market.marketSlug}/${tables[0].slug}`;
      if (mostRecentSubPathsForTables[base]) {
        navigate(
          `/${market.marketSlug}/${tables[0].slug}/${mostRecentSubPathsForTables[base] ?? ''}`,
          {
            replace: true,
          }
        );
        return;
      }

      navigate(tables[0].slug, { replace: true });
    }
  }, [
    navigate,
    tables,
    tableSlug,
    mostRecentSubPathsForTables,
    market.marketSlug,
    location.pathname,
  ]);

  useEffect(() => {
    const base = `/${market.marketSlug}/${tableSlug ?? ''}`;
    const subPath = location.pathname.replace(base, '').slice(1);

    if (subPath) {
      setMostRecentSubPathsForTables({
        ...mostRecentSubPathsForTables,
        [base]: subPath,
        [`/${market.marketSlug}`]: `${tableSlug ?? ''}${subPath ? `/${subPath}` : ''}`,
      });
    }
    // We're only interested in the pathname changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <StyledNavbar variant="dark" expand="sm" id="navSub" className="py-0" navopen="true">
        <Navbar.Collapse id="sub-navbar-nav">
          <TablesNavigation tables={tables} />
        </Navbar.Collapse>
      </StyledNavbar>
      {table ? (
        <Outlet context={{ market, table }} />
      ) : tableSlug || !tables[0] ? (
        <p>Table not found.</p>
      ) : (
        // The case where we redirect above
        <Spinner showImmediately />
      )}
    </>
  );
};
