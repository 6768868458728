import * as t from 'io-ts';

import { Filters, ViewRowGroupList } from './types';

// select/dropdown options

export const BaseOption = t.type({
  value: t.string,
  label: t.string,
});
export type BaseOption = t.TypeOf<typeof BaseOption>;

const ExtendedOption = t.intersection([
  BaseOption,
  t.partial({
    id: t.string,
    isDisabled: t.boolean,
  }),
]);
export type ExtendedOption = t.TypeOf<typeof ExtendedOption>;

// view options

const ViewOption = t.intersection([
  BaseOption,
  t.type({
    viewRowGroups: ViewRowGroupList,
    // viewFilters: { 'columnId': { values: ['value'] } }
    viewFilters: t.record(t.string, Filters),
  }),
]);
type ViewOption = t.TypeOf<typeof ViewOption>;

const ViewGroupOption = t.type({
  label: t.union([t.string, t.undefined]),
  viewTypeId: t.string,
  options: t.array(ViewOption),
});
export type ViewGroupOption = t.TypeOf<typeof ViewGroupOption>;

// forecasts brand filter options

const ForecastFilterOption = t.intersection([
  BaseOption,
  t.type({ attr: t.string }),
  t.partial({ displayOrder: t.number }),
]);
export type ForecastFilterOption = t.TypeOf<typeof ForecastFilterOption>;

// select dropdown options group / result

const AnyOption = t.union([ExtendedOption, ForecastFilterOption]);

const BaseOptionsList = t.array(
  t.type({
    options: t.array(AnyOption),
  })
);
export type BaseOptionsList = t.TypeOf<typeof BaseOptionsList>;
