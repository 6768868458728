import { type FormEvent, type FormEventHandler, useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import { Base, PasswordHelp } from './Base';

interface Props {
  onStateChange: (state: string, data?: unknown) => void;
  authData: { username: string };
}

export const RequireNewPassword = ({ onStateChange, authData: user }: Props) => {
  const [error, setError] = useState<{ message: string }>();
  const [password, setPassword] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (password.length === 0) {
      return setError({ message: 'Password is required' });
    }

    return Auth.completeNewPassword(user, password)
      .then((localUser: { challengeName: string }) => {
        switch (localUser.challengeName) {
          case 'SOFTWARE_TOKEN_MFA':
            return onStateChange('confirmSignIn', localUser);
          case 'MFA_SETUP':
            return onStateChange('TOTPSetup', localUser);
          default:
            return onStateChange('TOTPSetup', localUser);
        }
      })
      .catch(setError);
  };

  const handleCancel = () => {
    onStateChange('signIn');
  };

  const body = (
    <>
      <p>Change your password to something more memorable.</p>
      <Form.Group as={Form.Row}>
        <Form.Label column sm={3} htmlFor="newPassword-Password">
          Password
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="password"
            id="newPassword-Password"
            aria-describedby="newPassword-Password-help"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            isInvalid={!!error}
            ref={inputRef}
          />
        </Col>
      </Form.Group>
      <PasswordHelp id="newPassword-Password-help" />
    </>
  );

  return (
    <Base
      title="Change Password"
      body={body}
      submitTxt="Change"
      onSubmit={handleSubmit as FormEventHandler}
      cancelTxt="Back to sign in"
      onCancel={handleCancel}
      error={error}
    />
  );
};
