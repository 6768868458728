import { Container, Modal } from 'react-bootstrap';

import { Button, ModalHeader } from 'src/css/styled-components';

export enum GrowthCalculationErrorType {
  NONE = -1,
  NO_CURRENT_YEAR = 0,
  NO_HISTORIC_YEAR = 1,
}

interface GrowthCalculationErrorProps {
  closeModal: () => void;
  currentYear: number;
  error: GrowthCalculationErrorType;
}

export const GrowthCalculationError = ({
  closeModal,
  currentYear,
  error,
}: GrowthCalculationErrorProps) => (
  <Modal show centered animation={false} backdrop="static">
    <ModalHeader>Calculate growth</ModalHeader>
    <Container>
      <Modal.Body>
        {error === GrowthCalculationErrorType.NO_CURRENT_YEAR && (
          <p>
            You cannot automatically calculate growth on this record because the current year (
            {currentYear}) is blank.
          </p>
        )}

        {error === GrowthCalculationErrorType.NO_HISTORIC_YEAR && (
          <p>
            You cannot automatically calculate growth on this record because {currentYear - 4} is
            blank.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="primary" onClick={closeModal}>
          Ok
        </Button>
      </Modal.Footer>
    </Container>
  </Modal>
);
