import { type MutableRefObject, useCallback } from 'react';
import type { ColumnState, ColumnValueChangedEvent, GridApi } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { AggregationModel, GridOptions, Row } from '../../types';
import { isColGroupDef } from '../../utils';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useAggregationFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  gridApiRef: MutableRefObject<GridApi<R> | undefined>,
  props: Pick<
    DataGridProps<R>,
    'aggregation' | 'columnGrouping' | 'columns' | 'onAggregationChange'
  >
) => {
  const logger = useGridLogger(gridRef, 'useAggregationFeature');

  const { aggregation = {}, columnGrouping = [], columns, onAggregationChange } = props;

  const handleAggregationChange = useCallback(
    (e: ColumnValueChangedEvent) => {
      const aggregationModel: AggregationModel = {};
      const processedFields: Record<string, boolean> = {};

      e.api.getValueColumns().forEach(col => {
        let field: string;

        const parent = col.getOriginalParent();
        if (parent && Object.keys(parent.getColGroupDef() ?? {}).length > 0) {
          field = parent.getGroupId();
        } else {
          field = col.getId();
        }

        if (processedFields[field]) return;
        aggregationModel[field] = String(col.getAggFunc());
        processedFields[field] = true;
      });

      onAggregationChange?.(aggregationModel);
    },
    [onAggregationChange]
  );

  useInitialiseState(() => {
    Object.entries(aggregation).forEach(([field, aggregationFunction]) => {
      const colDef = gridRef.current.columnDefs?.find(col =>
        isColGroupDef<R>(col) ? col.groupId === field : col.field === field
      );

      if (!aggregationFunction || !colDef) return;

      if (isColGroupDef<R>(colDef)) {
        colDef.children.forEach(leafColumn => {
          const newLeafColumn = leafColumn;

          const column = columns.find(col => col.field === leafColumn.field);
          if (column && column.aggregable === true) {
            newLeafColumn.aggFunc = aggregationFunction;
          }
        });
      } else {
        const column = columns.find(col => col.field === colDef.field);
        if (column && column.aggregable === true) {
          colDef.aggFunc = aggregationFunction;
        }
      }
    });
    //gridRef.current.groupIncludeTotalFooter = Object.keys(aggregation).length > 0;
    gridRef.current.onColumnValueChanged = handleAggregationChange;
  });

  useEffectAfterFirstRender(() => {
    logger.log('Setting aggregation model');

    const aggregationRules: ColumnState[] = [];

    Object.entries(aggregation).forEach(([field, aggregationFunction]) => {
      const group = columnGrouping.find(group => group.groupId === field);
      if (group) {
        group.children.forEach(leafColumn => {
          const column = columns.find(col => col.field === leafColumn.field);
          if (column && column.aggregable === true) {
            aggregationRules.push({
              aggFunc: aggregationFunction,
              colId: column.field,
            });
          }
        });
      } else {
        const column = columns.find(col => col.field === field);
        if (column && column.aggregable === true) {
          aggregationRules.push({
            aggFunc: aggregationFunction,
            colId: field,
          });
        }
      }
    });

    gridApiRef.current?.applyColumnState({
      state: aggregationRules,
      defaultState: {
        aggFunc: null,
      },
    });
  }, [aggregation, columns, columnGrouping]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onAggregationChange` is not supported.');
  }, [onAggregationChange]);
};
