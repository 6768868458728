import { type FC, useCallback, useMemo } from 'react';

import type { Market, Option } from '../types';
import MarketDropdown from './MarketDropdown';

interface MarketSwitcherProps {
  current?: Market | undefined;
  markets: Market[];
  assignedMarketIds: number[];
  onChange: (market: Market) => void;
}

export const MarketSwitcher: FC<MarketSwitcherProps> = ({
  current,
  markets,
  assignedMarketIds,
  onChange,
}) => {
  const handleChange = useCallback(
    (_event: React.SyntheticEvent, option: Option) => {
      const selectedMarket = markets.find(market => market.marketSlug === option.value);
      selectedMarket && onChange(selectedMarket);
    },
    [markets, onChange]
  );

  const options = useMemo(() => {
    return markets.map(({ marketId, marketName, marketSlug }) => ({
      label: marketName,
      value: marketSlug,
      isAssigned: assignedMarketIds.includes(marketId),
    }));
  }, [markets, assignedMarketIds]);

  return (
    <MarketDropdown
      options={(options as Option[]).sort((a, b) =>
        a.isAssigned === b.isAssigned ? 0 : a.isAssigned ? -1 : 1
      )}
      placeholder="Select market"
      selected={current?.marketSlug}
      handleChange={handleChange}
    />
  );
};
