import { Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';

import { Spinner } from '../Spinner';
import { ConfirmSignIn } from './ConfirmSignIn';
import { ForgotPassword } from './ForgotPassword';
import { RequireNewPassword } from './RequireNewPassword';
import { SignIn } from './SignIn';
import { SignInWithPasswordAuth } from './SignInWithPasswordAuth';
import { TOTPSetup } from './TotpSetup';

type AuthState =
  | 'loading'
  | 'signIn'
  | 'signInWithPasswordAuth'
  | 'confirmSignIn'
  | 'forgotPassword'
  | 'requireNewPassword'
  | 'TOTPSetup'
  | 'signedIn';

const getCustomComponent = (authState: AuthState) => {
  switch (authState) {
    case 'loading':
      return Spinner;
    case 'signIn':
      return SignIn;
    case 'signInWithPasswordAuth':
      return SignInWithPasswordAuth;
    case 'confirmSignIn':
      return ConfirmSignIn;
    case 'forgotPassword':
      return ForgotPassword;
    case 'requireNewPassword':
      return RequireNewPassword;
    case 'TOTPSetup':
      return TOTPSetup;
    default:
      throw new Error(`Unknown authState: ${authState}`);
  }
};

interface Props {
  authState: AuthState;
  onStateChange: (state: string, data?: unknown) => void;
  authData: { username: string };
}

export const CustomAmplify = (props: Props) => {
  const { authState } = props;

  if (authState === 'signedIn') {
    return null;
  }

  const CustomComponent = getCustomComponent(authState);

  return (
    <Routes>
      <Route
        path="/signedOut"
        element={
          <Box sx={{ minHeight: 50, margin: 20 }}>
            <Spinner />
          </Box>
        }
      />
      <Route path="*" element={<CustomComponent {...props} />} />
    </Routes>
  );
};
