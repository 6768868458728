import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import type { unsafe_RowNode } from 'src/components/DataGrid';
import type { ChartMenuOptionValue } from 'src/components/ForecastChartMenu';
import type { DenormalizedBrandSales } from 'src/records/types/DenormalizedBrandSales';
import { type Volume } from 'src/types/Entity';
import type { PinnedTotal } from 'src/views/Pages/types/GridAPI';
import {
  getAxisCategoriesYears,
  getChartOptions,
  getChartSeriesOptions,
  getChartTitle,
  getMinMaxVolumeAxis,
} from './utils/chartUtils';

interface ChartProps {
  forecasts: DenormalizedBrandSales[];
  currentYear: number;
  pinnedTotal: PinnedTotal | undefined;
  previousForecastTotals: Volume | undefined;
  precision: number;
  previousForecastData: DenormalizedBrandSales[];
  secondAxisField: ChartMenuOptionValue;
  selectedCategories?: SelectedCategoriesProps | undefined;
  selectedGridRow?: unsafe_RowNode<DenormalizedBrandSales> | undefined;
  showCurrentYearForecast: boolean;
  showPreviousForecast: boolean;
  showSecondAxis: boolean;
  viewName: string;
}

export interface SelectedCategoriesProps {
  category5Id: number;
  category4Id: number;
  category3Id: number;
  category2Id: number;
  isForecastByOrigin: boolean;
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export const Chart = ({
  forecasts,
  currentYear,
  pinnedTotal,
  previousForecastTotals,
  precision,
  previousForecastData,
  secondAxisField,
  selectedCategories,
  selectedGridRow,
  showCurrentYearForecast,
  showPreviousForecast,
  showSecondAxis,
  viewName,
}: ChartProps) => {
  const selectedRowData = selectedGridRow?.data;
  const chartSeries = getChartSeriesOptions({
    currentYear,
    forecasts,
    previousForecastData,
    showCurrentYearForecast,
    showPreviousForecast,
    showSecondAxis,
    secondAxisField,
    selectedRowData,
    pinnedTotal,
    previousForecastTotals,
  });
  const { maxVolumeInFirstAxis, minVolumeInSecondAxis } = getMinMaxVolumeAxis({
    forecasts,
    previousForecastData,
    showPreviousForecast,
    pinnedTotal,
    selectedRowData,
    showSecondAxis,
    secondAxisField,
  });
  const chartTitle = getChartTitle({ saleRow: selectedRowData, selectedCategories, viewName });
  const axisCategoriesYears = getAxisCategoriesYears(currentYear);
  const chartOptions = getChartOptions({
    chartSeries,
    chartTitle,
    maxVolumeInFirstAxis,
    minVolumeInSecondAxis,
    axisCategoriesYears,
    valueDecimals: precision,
    selectedRowData,
    showSecondAxis,
  });

  return (
    // The wrapping `div` is needed to force the chart to expand to its full height.
    <div>
      <HighchartsReact options={chartOptions} highcharts={Highcharts} />
    </div>
  );
};
